import React from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { updateExp } from "../../../../slices/jobFilterSlice";


const SelectInput2 = ({ optionsArr, titleText }) => {
  const value = useSelector((state) => state.jobFilter.exp);
  const dispatch = useDispatch();

  return <Select value={value} onChange={(e) => dispatch(updateExp([e.target.value]))}>
    <option value="" disabled selected>Select {titleText}</option>
    {
      optionsArr.map((option, i) => (
        <option value={option.value} key={i}>{option.text}</option>
      ))
    }
  </Select>;
};

export default SelectInput2;

const Select = styled.select`
  height: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: var(--white);
  outline: none;
  font-size: 0.97rem;
  font-weight: 800;
  color: #A8A8A8;
  font-family: var(--font);

  option {
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    color: var(--color);
  }

`;
