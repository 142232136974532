// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  text-align: center;
  padding: 50px;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
`;

const Message = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  font-size: 1.2em;

  &:hover {
    text-decoration: underline;
  }
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      <Title>404 - Page Not Found</Title>
      <Message>Sorry, the page you are looking for does not exist.</Message>
      <StyledLink to="/">Go to Home</StyledLink>
    </NotFoundContainer>
  );
};

export default NotFound;
