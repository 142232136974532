import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useState } from "react";
import TimeSlot from "../commonComponents/TimeSlot";
import styled from "styled-components";
import { formatTime } from "../../../../functions/api/interview/timeFormator";
import { updateStatus } from "../../../../functions/api/interview/updateStatus";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import NewLoader from "../../../commonComponents/newLoader";
import { ReasonAndConfirmation } from "../../../../pages/ReasonAndConfirmation";
import { Box, TextField } from "@mui/material";

export const RescheduledTimeAndDate = ({ row, setRescheduleProcess }) => {
  const [value, setValue] = useState(dayjs(new Date()));
  const [isLoaded, setLoaded] = useState(false);
  const [reason, setReason] = useState("");
  const [canField, setCanField] = useState({
    email: row?.email || "",
    meetingLink: row?.meetingLink || "https:// test.com",
    interviewerId: row?.interviewerId || "",
  });

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));

  const accessToken = useSelector((state) => state.auth.userData.accessToken);

  let str = value.$d.toString().split(" ");
  const handleToggle = () => {
    setLoaded(false);
    setTimeout(() => {
      setRescheduleProcess({ isOpened: false });
    }, 2000);
  };

  const handleSubmit = async () => {
    setLoaded(true);
    const makeApiCall = async () => {
      const dateTime = moment(
        value.format("YYYY-MM-DD") +
          "T" +
          (selectedTimeSlot.$H < 10
            ? "0" + selectedTimeSlot.$H
            : selectedTimeSlot.$H) +
          ":" +
          (selectedTimeSlot.$m < 10
            ? "0" + selectedTimeSlot.$m
            : selectedTimeSlot.$m) +
          ":" +
          "00.000"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm");
      const date = dateTime.slice(0, 10);
      const time = dateTime.slice(11);

      const payload = {
        data: "reschedule",
        extra: {
          slotDate: date,
          slotTime: time,
          timeZone: "UTC",
          reason,
        },
      };
      try {
        const response = await updateStatus(
          row.interviewId,
          payload.data,
          accessToken,
          payload.extra
        );
        if (response.response?.data?.status == "FAILED") {
          throw new Error(JSON.stringify(response?.response?.data));
        }
        toast.success("Interview has been rescheduled");
        handleToggle();
      } catch (error) {
        // handleToggle();
        toast.error(error?.response?.data?.notify?.message || error?.message);
      } finally {
        setLoaded(false);
      }
    };
    makeApiCall();
  };

  return (
    <Container>
      {isLoaded && (
        <div className="loader">
          <NewLoader />
        </div>
      )}

      <div className="step1Box">
        <div className="timeSlot">
          {str[1]}/{str[2]}/{str[3]} - {formatTime(selectedTimeSlot)}
        </div>
        <div className="gridContainer">
          <div className="timeAndDates">
            <div className="step1ChildBox">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="calendarContainer">
                  <div className="calendarBox">
                    <DateCalendar
                      value={value}
                      onChange={(newValue) => setValue(dayjs(newValue))}
                      views={["day"]}
                      sx={{
                        height: "100%",
                        display: "flex",
                      }}
                    />
                  </div>
                </div>
              </LocalizationProvider>
              <div className="slotBox">
                <div className="timeContainer">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["TimePicker", "TimePicker"]}
                      className="slotChildBox"
                      sx={{ width: "40%" }}
                    >
                      <TimeSlot
                        selectedTimeSlot={selectedTimeSlot}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="secondPart">
            <div className="emailAndLink">
              <Box className="groups">
                <TextField
                  id="outlined-basic"
                  label="Interviewer ID"
                  variant="outlined"
                  fullWidth
                  value={canField.interviewerId}
                  onChange={(e) =>
                    setCanField((prev) => ({
                      ...prev,
                      interviewerId: e.target.value,
                    }))
                  }
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              </Box>
              <Box className="groups">
                <TextField
                  label="Meet Link"
                  variant="outlined"
                  fullWidth
                  disabled={!row.meetingLink}
                  value={canField.meetingLink}
                  onChange={(e) =>
                    setCanField((prev) => ({
                      ...prev,
                      meetingLink: e.target.value,
                    }))
                  }
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              </Box>
              <Box className="groups">
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={canField.email}
                  onChange={(e) =>
                    setCanField((prev) => ({ ...prev, email: e.target.value }))
                  }
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              </Box>
              <Box className="groups">
                <TextField
                  label="Reason"
                  type="reason"
                  variant="outlined"
                  fullWidth
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
        <div className="btns">
          <button
            className="btn"
            onClick={() => setRescheduleProcess({ isOpened: false })}
          >
            Back to Interview Flow
          </button>
          <button className="btn" onClick={() => handleSubmit()}>
            reschedule
          </button>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
  }
  .reasoning,
  .emailAndLink {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .emailAndLink {
    gap: 40px;
    width: 100%;

    .groups {
      width: 92%;
    }
  }
  .step1Box {
    width: 100%;
    // max-width: 1200px;
    // height: 540px;
    // display: block;
    // margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    // margin-top: 2rem;
    margin-bottom: 0rem;
    position: relative;
    background-color: white;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;

    .timeSlot {
      width: fit-content;
      display: block;
      margin: 30px auto;
      margin-top: 20px;
      margin-bottom: 15px;
      cursor: pointer;
      text-align: center;
      // border: 0.08rem solid lightgrey;
      outline-color: lightgrey;
      outline-width: 0.05rem;
      border-radius: 0.3rem;
      padding: 0.5rem 2rem;
      font-weight: 700;
      font-size: clamp(0.7rem, 2.5vw, 1.2rem) !important;
      font-family: "Quicksand" !important;
    }
    // .timeSlot:hover {
    //   border: 0.08rem solid var(--cyan);
    //   outline-color: var(--cyan);
    // }
    .btns {
      display: flex;
      flex-flow: row;
      justify-content: center;
      gap: 1rem;
      margin: 2rem 0 1rem;
      .btn {
        padding: 0.5rem 1rem;
        margin-top: 0rem;
        background-color: var(--cyan);
        border: none;
        color: var(--white);
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 0.5rem;
        cursor: pointer;
        font-family: var(--font);
        text-transform: capitalize;
      }
    }
    .gridContainer {
      display: grid;
      grid-template-columns: 3fr 2fr;
      .secondPart {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-top: 0.4rem;
      }
      .step1ChildBox {
        width: 100%;
        // height: inherit;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 50px 0px;
      }

      .calendarContainer {
        flex-basis: 48%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        .calendarBox {
          border: 0.08rem solid lightgrey;
          border-radius: 0.5rem;
          height: 340px;
        }
      }

      .slotBox {
        flex-basis: 48%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;

        .timeContainer {
          .css-gtuab9-MuiStack-root {
            width: 100%;
            overflow: hidden;
            .MuiStack-root,
            .css-gtuab9-MuiStack-root {
              overflow: hidden;
              width: 100%;
            }
          }
          .MuiStack-root,
          .css-gtuab9-MuiStack-root {
            overflow: hidden;
            width: 100%;
          }
          .slotChildBox {
            width: 100%;
          }
        }
      }
      .smallTextBox {
        position: absolute;
        bottom: 72px;
        left: 180px;
        width: 34%;
        display: flex;
        flex-flow: row;
        gap: 1rem;
        align-items: center;
      }
    }
  }
`;
