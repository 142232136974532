import axios from "axios";

export const getProductTypes = async (
  accessToken,
  clientCode

) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      ` ${process.env.REACT_APP_API_URL}/api/configs/value?key=BRAJ01.producttypes`,
      config
    );
    console.log("productypes:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
