import axios from "axios"

export const employerRegister = async (requestData) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/onboards/register`,requestData);
        console.log('Data:', response.data);
        return response.data;
      } catch (error) {
        throw error;
      }
}