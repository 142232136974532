import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const sendInvite = createAsyncThunk('invite/sendInvite', async ({ inviteData, accessToken, clientCode }) => {
    const response = await auth(inviteData, accessToken, clientCode);
    return response.data;
})

const initialState = {
    selectedResumes: [],
    selectedEmails: [],
    selectedRows: [],
    selectedJd: null,
    inviteData: null,
    inviteResponseData: null,
    status: 'idle',
    error: null,
    inviteStatus: [],
}

const inviteSlice = createSlice({
    name: 'invite',
    initialState,
    reducers: {
        addResumes: (state, action) => {
            state.selectedResumes = action.payload;
        },
        addSelectedEmails: (state, action) => {
            state.selectedEmails = action.payload;
        },
        addSelectedJd: (state, action) => {
            state.selectedJd = action.payload;
        },
        resetSelectedJd: (state) => {
            state.selectedJd = null;
        },
        toggleSelected: (state, action) => {
            const { id, value } = action.payload;
            const index = state.selectedRows.findIndex(item => item.id === id);
            if (index !== -1) {
                state.selectedRows[index].selected = value;
            } else {
                state.selectedRows.push({ id, selected: value });
            }
        },
        resetSelection: (state) => {
            state.selectedRows = [];
        },
        updateInviteStatus: (state, action) => {
            const { id, status } = action.payload;
            state.inviteStatus = [...state.inviteStatus, { id, status }];
        },
        resetInviteStatus: (state) => {
            state.inviteStatus = [];
        },      
    },

    extraReducers: (builder) => {
        builder.addCase(sendInvite.pending, (state) => {
            state.status = 'loading';
        }).addCase(sendInvite.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.status = 'succeeded';
        }).addCase(sendInvite.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    }
});
export const { addResumes, addSelectedEmails, addSelectedJd, resetSelectedJd, toggleSelected, resetSelection, updateInviteStatus, resetInviteStatus } = inviteSlice.actions;
export default inviteSlice.reducer;


