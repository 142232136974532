import React, { useState } from "react";
import styled from "styled-components";

function CustomInput({ accept, id, fileHandleFnc, text }) {
  const [fileSelected, setFileSelected] = useState("");

  const onChange = (e) => {
    setFileSelected(e.target.files[0].name);
    fileHandleFnc(e.target.files[0]);
  };

  return (
    <StyledInput>
      <label
        htmlFor={id}
        className={`customInput ${!fileSelected ? "labelInput" : "active"}`}
      >
        {text}
      </label>
      <input
        type="file"
        accept={accept}
        id={id}
        style={{ display: "none" }}
        onChange={onChange}
      />
      <span className="fileNameText">{fileSelected && `${fileSelected}`}</span>
    </StyledInput>
  );
}

export default CustomInput;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  .customInput {
    background-color: var(--cyan);
    color: var(--white);
    border: 0.1rem solid var(--white);
    border-radius: 0.5rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: var(--font);
    cursor: pointer;
  }

  .customInput::placeholder {
    color: var(--white);
  }

  .labelInput {
    color: var(--white);
  }

  .active {
  }

  .fileNameText {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;
