import axios from "axios";

export const aiEnhancement = async (
  maxWords,
  profile,
  profileExtra,
  accessToken
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };

  const data = {
    maxWords: maxWords,
    profile: profile,
    profileExtra: profileExtra,
  };

  try {
    // console.log("Sending request to enhanceProfile API:", {
    //   url: `${process.env.REACT_APP_API_URL}/api/users/profile-enhancer`,
    //   data: data,
    //   config: config,
    // });

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/profile-enhancer`,
      data,
      config
    );
    console.log("enhanceProfile API response:", response.data);
    return response.data;
  } catch (error) {
    // console.error("Full error in enhanceProfile:", error);
    // console.error("Error response data:", error.response?.data);
    console.error("An Error occurred while enhancing:", error);
    throw error;
  }
};
