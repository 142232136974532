import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Match from "./Match";
import DownloadIcon from "../../../../assets/icons/Profile/download.png";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import TableSearchBar from "../commonComponents/TableSearchBar";
import { useDispatch } from "react-redux";
import {
  addSelectedJd,
  resetInviteStatus,
} from "../../../../slices/invitationSlice";
import DOMPurify from "dompurify";
import BulkEmailInvite from "./BulkEmailInvite";
import Invite from "./Invite";
import MatchedResumeTabs from "./MatchedResumeTabs";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

function Row(props) {
  const {
    row,
    isSelected,
    onToggle,
    index,
    setBulkProcess,
    setIndividualProcess,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sanitizedDesc = DOMPurify.sanitize(row?.description);
  const plainTextDescription = sanitizedDesc.replace(/<[^>]+>/g, "");
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const handleBulkInviteNavigation = async (row) => {
    dispatch(addSelectedJd(row));
    setBulkProcess({
      isOpen: true,
      jdId: row.jdId,
    });
    // navigate(`/schedule/bulkInvite/${row?.jdId}`);
  };
  const handleIndividualNavigation = async (id) => {
    setIndividualProcess({
      isOpen: true,
      jdId: id,
    });
    // navigate(`/schedule/matches/${id}`);
  };

  const handleDownload = async (jdId) => {
    if (!jdId) {
      toast.warning("JD with this ID is not found");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/reports/download-matches?jdId=${jdId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      };

      const res = await axios.get(url, config);

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `matches_${jdId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download error:", error);
      toast.error("An error occurred while downloading. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""} ${
          isSelected ? "selected" : ""
        }`}
      >
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event from firing
              onToggle(row);
            }}
          >
            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row.jdId?.toUpperCase()}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
          className="tableCell"
        >
          <Match jdId={row.jdId} count={row.matchCount} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row.createdAt?.slice(0, 10)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row.recruiter}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row.hiringManager}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <img
            src={DownloadIcon}
            alt="download"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              cursor: "pointer",
              border: "0.08rem solid grey",
              padding: "0.3rem",
              borderRadius: "0.3rem",
            }}
            onClick={() => handleDownload(row.jdId, row.clientCode)}
          />
        </TableCell>{" "}
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <Button onClick={() => handleBulkInviteNavigation(row)}>
            Send Invite
          </Button>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <Button onClick={() => handleIndividualNavigation(row.jdId)}>
            View Profile
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
          className="tableCell"
        >
          <Collapse in={row.open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="body1" gutterBottom>
                <div style={{ fontSize: "0.7rem" }}>
                  <b>Title</b>: {row.title}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>Description</b>: {plainTextDescription}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>Skills</b>: {row.skills}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>Experience</b>: {row.experience}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>Location</b>: {row.location}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>WorkType</b>: {row.workType}
                </div>
                <br />
                <div style={{ fontSize: "0.7rem" }}>
                  <b>CTC</b>: {row.ctc}
                </div>
                <br />
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ManageJds({
  rows,
  total,
  page,
  setPage,
  size,
  setSize,
  search,
  setSearch,
}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [bulkProcess, setBulkProcess] = useState({});
  const [individualProcess, setIndividualProcess] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetInviteStatus());
  }, []);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const handleSearch = () => {};

  useEffect(() => {
    if (searchValue?.trim()) {
      // setSearch(true);
      setTableRows(() =>
        rows?.filter((item) =>
          item?.jdId?.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      // setSearch(false);
      setTableRows(rows);
    }
  }, [rows, searchValue]);

  const handleToggle = (row) => {
    const updatedRows = [...tableRows];
    const rowIndex = updatedRows.findIndex((r) => r.jdId === row.jdId);

    if (selectedRow === rowIndex) {
      // Deselect the row if it's already selected
      setSelectedRow(null);
      updatedRows[rowIndex].open = false;
    } else {
      if (selectedRow !== null) {
        updatedRows[selectedRow].open = false;
      }

      setSelectedRow(rowIndex);
      updatedRows[rowIndex].open = true;
    }

    setTableRows(updatedRows);
  };
  console.log("bulk process : ", bulkProcess);
  console.log("individual process : ", individualProcess);

  return (
    <StyledBox>
      {bulkProcess?.isOpen ? (
        <BulkEmailInvite
          setBulkProcess={setBulkProcess}
          jdId={bulkProcess.jdId}
        />
      ) : // ) : individualProcess?.isOpen && inviteProcess?.isOpen ? (
      //   <Invite />
      individualProcess?.isOpen ? (
        <MatchedResumeTabs
          setIndividualProcess={setIndividualProcess}
          jdId={individualProcess.jdId}
        />
      ) : (
        <TableContainer component={Paper} className="tableBox">
          <span className="mainTitle">
            <span className="title">Active Job Descriptions</span>
          </span>
          <SearchBarContainer>
            <TableSearchBar value={searchValue} setValue={setSearchValue} />
          </SearchBarContainer>
          <Table aria-label="collapsible table">
            <TableHead className="tableHead">
              <TableRow>
                <TableCell className="tableCell" />
                <TableCell align="center" className="tableCell">
                  JD ID
                </TableCell>

                <TableCell align="center" className="tableCell">
                  No of Profiles available
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Date of Creation
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Recruiter
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Hiring Manager
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Download Matched Records
                </TableCell>
                <TableCell align="center" className="tableCell">
                  Bulk Email Invite
                </TableCell>
                <TableCell align="center" className="tableCell">
                  View Matched Profiles
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {tableRows &&
                tableRows
                  // ?.filter((item) => item.jdId)
                  .map((row, index) => (
                    <Row
                      key={row.jdId}
                      row={row}
                      isSelected={selectedRow === index}
                      onToggle={handleToggle}
                      index={index}
                      setBulkProcess={setBulkProcess}
                      setIndividualProcess={setIndividualProcess}
                    />
                  ))}
            </TableBody>
          </Table>

          {!search && (
            <div className="paginationBox">
              <PaginationSizeFilter
                size={size}
                handleSizeChange={handleSizeChange}
              />
              <Pagination
                total={total}
                size={size}
                page={page}
                setPage={setPage}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </TableContainer>
      )}
    </StyledBox>
  );
}

const StyledBox = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 96%;
  padding: 0 2%;

  .colored {
    background-color: #ececec;
  }

  .title {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .mainTitle {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 1rem 0 1rem 3rem;
    width: calc(98% - 3rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--cyan);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 5px;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font);
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  white-space: nowrap;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;
`;
