import axios from "axios";

export const getJds = async (
  accessToken,
  clientCode,
  active = null,
  page = 1,
  size = 1000000
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "X-Client-Code": clientCode,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/api/jds?page=${page}&size=${size}`
  if (active == "ACTIVE") {
    url = `${process.env.REACT_APP_API_URL}/api/jds?page=${page}&size=${size}&active=true`
  }
  else if (active == "INACTIVE") {
    url = `${process.env.REACT_APP_API_URL}/api/jds?page=${page}&size=${size}&active=false`
  }
  try {
    const response =
      await axios.get(url, config);

    console.log("Data:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
