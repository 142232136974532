import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getScore } from "../functions/api/interview/getScore";
import ScorecardTemplate from "../components/Interviews/CurrentInterview/ScorecardTemplate";
import { styled } from "styled-components";
import Loader from "../components/commonComponents/Loader";
import { Link } from "react-router-dom";
import logo from "../assets/otohireNewLogo.jpg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch } from "react-redux";
import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { getInterviewDetails } from "../functions/api/interview/getInterviewDetails";
import { getEmployer } from "../functions/api/employers/profile/getEmployer";
import { removeAllFailedUploads } from "../slices/uploadSnapSlice";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: "1rem",
    },
  })
);

const ScorecardEmployer = () => {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [apiCall, setApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchAgainOption, setFetchAgainOption] = useState(true);
  const { interviewId } = useParams();
  const [trigger, setTrigger] = useState(true);
  const [data, setData] = useState(null);
  const [scoreArray, setScoreArray] = useState([]);
  const [countDown, setCountDown] = useState(5);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [employerDetails, setEmployerDetails] = useState(null);

  const [time, setTime] = useState("");
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAllFailedUploads());
  }, []);

  useEffect(() => {
    try {
      const getData = async () => {
        const res1 = await getInterviewDetails(interviewId, accessToken);
        if (res1) {
          console.log("======>>>>>>>>>", res1?.data);
          if (res1?.data?.clientCode && res1?.data?.clientCode !== "otohire") {
            const res2 = await getEmployer( res1?.data?.clientCode);
            if (res2) {
              setEmployerDetails(res2.data);
            }
          }
          setInterviewDetails(res1.data);
        }
      };

      getData();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleItemClick = (item) => {
    // Handle item click logic
    console.log(`Selected: ${item}`);
  };

  useEffect(() => {
    if (document.fullscreenElement) document.exitFullscreen();

    if (!accessToken) navigate("/login");
    async function fetchScore(id, accessToken) {
      try {
        setIsLoading(true);
        const scoreRes = await getScore(id, accessToken);
        if (!scoreRes) {
          setFetchAgainOption(true);
          setTrigger(false);
          setCountDown(5);
        } else {
          setData(scoreRes);
          setScoreArray(scoreRes?.data?.questions);
          setFetchAgainOption(false);
        }
        setIsLoading(false);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    if (fetchAgainOption && countDown > 0) {
      let intervalId = setInterval(() => {
        setCountDown((prevCd) => prevCd - 1);
        if (!fetchAgainOption) {
          setCountDown(0);
        }
        if (countDown === 0) {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }

    if (trigger === true) {
      fetchScore(interviewId, accessToken);
    }

    let timer = localStorage.getItem("time");
    if (timer) {
      timer = JSON.parse(timer);
      let str = `${60 - timer?.minutes - 1}min-${60 - timer?.seconds}sec`;
      setTime(str);
    }

    return () => {
      localStorage.removeItem("time");
    };
  }, [trigger, countDown]);

  return (
    <StyledScorecard>
      {isLoading ? (
        <Loader message="Fetching Score... please wait" />
      ) : fetchAgainOption ? (
        <div className="scoreEvalStyle">
          <h2>Interview not found</h2>
          <button
            onClick={() => setTrigger(true)}
            disabled={countDown > 0}
            className="scoreEvalStyleBtn"
          >
            {countDown === 0 ? "TRY AGAIN" : `TRY AGAIN IN ${countDown}s`}{" "}
          </button>
        </div>
      ) : (
        <div className="mainContainer">
          <div
            style={{
              height: "3.5rem",
              position: "absolute",
              top: "1rem",
              left: "3rem",
            }}
          >
            <img src={logo} style={{ height: "100%" }} />
          </div>
          <div className="head">
            <h3>CANDIDATE SCORECARD</h3>
            {/* <Link to="/dashboard/employer">
              <button id="another">Back to Dashboard</button>
            </Link> */}
          </div>

          <div className="summary">
            <h4>Total Questions: {data?.data?.totalQuestions}</h4>
            <h4>Attempted: {data?.data?.answeredCnt}</h4>
            {time && (
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  justifyContent: "center",
                }}
              >
                Time Taken: {time}
              </h4>
            )}
            <h4>
              Score:{" "}
              {apiCall ? <Loader1></Loader1> : (data?.data?.score).toFixed(2)}
            </h4>
            <h4>Maximum Score: {data?.data?.maxScore}</h4>
          </div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <span
                className={`menuTitle ${
                  expanded === "panel1" ? "selected" : ""
                }`}
              >
                Interview Details
              </span>
            </AccordionSummary>
            <AccordionDetails className="details">
              {interviewDetails?.jdId ? (
                <div className="interviewDetailsBox">
                  <span className="companyTitle">
                    <img
                      src={employerDetails?.companyLogo}
                      className="companyLogo"
                    />{" "}
                    {employerDetails?.companyName}
                  </span>
                  <span>
                    <b>Jd Id:</b> {interviewDetails?.jdId?.toUpperCase()}
                  </span>
                  <span>
                    <b>Test Type:</b>{" "}
                    {interviewDetails?.testType?.toUpperCase()}
                  </span>
                  <span>
                    <b>Difficulty Level:</b>{" "}
                    {interviewDetails?.difficultyLevel?.toUpperCase()}
                  </span>
                </div>
              ) : (
                <div className="interviewDetailsBox">
                  <span>
                    <b>Job Summary:</b> {interviewDetails?.jobSummary}
                  </span>
                  <span>
                    <b>Test Type:</b>{" "}
                    {interviewDetails?.testType?.toUpperCase()}
                  </span>
                  <span>
                    <b>Difficulty Level:</b>{" "}
                    {interviewDetails?.difficultyLevel?.toUpperCase()}
                  </span>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <div style={{ marginTop: "2rem" }}>
            <ScorecardTemplate
              rows={scoreArray}
              trigger={trigger}
              setTrigger={setTrigger}
              apiCall={apiCall}
              setApiCall={setApiCall}
              interviewId={interviewId}
            />
          </div>
          <span className="bottomText">
            Total score is {data?.data?.score} out of {data?.data?.maxScore}
          </span>
        </div>
      )}
    </StyledScorecard>
  );
};

export default ScorecardEmployer;

export const StyledScorecard = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 75%;

  .head {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .summary {
    display: flex;
    justify-content: space-evenly;

    h4 {
      border: 0.075rem solid var(--color);
      padding: 0.75rem;
      border-radius: 0.25rem;
    }
  }

  .scoreEvalStyle {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .scoreEvalStyleBtn {
    padding: 0.5rem 0.8rem;
    border: none;
    background-color: var(--cyan);
    color: var(--backgroundColor);
    border-radius: 0.3rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
  }

  #another {
    background-color: var(--cyan);
    color: var(--backgroundColor);
    border-radius: 0.4rem;
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
    cursor: pointer;
    border: none;
    font-family: var(--font);
    font-weight: 600;
  }

  .bottomText {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 5rem;
    text-align: center;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
  }

  .menuTitle {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .interviewDetailsBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.9rem;

    .companyLogo {
      width: 1.5rem;
      height: 1.5rem;
    }

    .companyTitle {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
`;

const Loader1 = styled.span`
  width: 1.3rem;
  height: 1.3rem;
  border: 1.5px solid var(--cyan);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
