import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Scorecard from "./pages/Scorecard";
import ScorecardEmployer from "./pages/ScorecardEmployer";
import ProctorReport from "./pages/ProctorReport";
import ProfilePage from "./pages/ProfilePage";
import SettingPage from "./pages/SettingPage";
import Activate from "./pages/Activate";
import About from "./pages/menuPages/About";
import Press from "./pages/menuPages/PressPage";
import Products from "./pages/menuPages/Products";
import Service from "./pages/menuPages/Service";
import ResearchPaper from "./pages/menuPages/ResearchPaper";
import CaseStudies from "./pages/menuPages/CaseStudies";
import Support from "./pages/menuPages/Support";
import Privacy from "./pages/menuPages/Privacy";
import Terms from "./pages/menuPages/Terms";
import Disclaimer from "./pages/menuPages/Disclaimer";
import ScrollToTop from "./components/commonComponents/ScrollToTop";
import Reset from "./pages/Reset";
import Contact from "./pages/menuPages/Contact";
import JobDescriptions from "./pages/JobDescriptions";
import JdDetails from "./pages/JdDetails";
import Home from "./pages/Home";
import Demo from "./pages/Demo";
import ContactUs from "./pages/ContactUs";
import CreateTicket from "./pages/CreateTicket";
import Forgot from "./pages/Forgot";
import ForgotPassLogedIn from "./pages/ForgotPassLogedIn";
import Otohire from "./pages/submenuPages/Otohire";
import OtoBoard from "./pages/submenuPages/OtoBoard";
import OtoSource from "./pages/submenuPages/OtoSource";
import Screening from "./pages/submenuPages/Screening";
import DataAnalytics from "./pages/submenuPages/DataAnalytics";
import TalentManagement from "./pages/submenuPages/TalentManagement";
import Enterprises from "./pages/submenuPages/Enterprises";
import RecruitmentAgency from "./pages/submenuPages/RecruitmentAgency";
import JobSeeker from "./pages/submenuPages/JobSeeker";
import JobSeekerDashboard from "./pages/JobSeekerDashboard";
import EmployerDashboard from "./pages/EmployerDashboard";
import ScheduleInterview from "./components/Interviews/EmployerDashboard/Schedule/ScheduleInterview";
import Career from "./pages/menuPages/Career";
import Sales from "./pages/menuPages/Sales";
import EmployerInterviewsStatus from "./components/Interviews/EmployerDashboard/sidebarPages/EmployerInterviewTabs";
import QuestionComponent from "./pages/ProctoredInterview";
import Proctored from "./pages/ProctoredInterview";
import SlotSelection from "./components/Interviews/SeekerDashboard/SlotSelection";
import CreateResumePage from "./pages/CreateResumePage";
import Invite from "./components/Interviews/EmployerDashboard/Schedule/Invite";
import InviteSuccess from "./components/Interviews/EmployerDashboard/Schedule/InviteSuccess";
import CreateInterview from "./pages/CreateInterview";
import PricingPlan from "./pages/menuPages/PricingPlan";
import AccessDenied from "./pages/AccessDenied";
import ProtectedRoute from "./components/commonComponents/ProtectedRoute";
import { AuthenticationConstants } from "./utils/constants";
import InterviewerInviteDashboard from "./components/commonComponents/InterviewerInviteDashboard";
import EmployerFeedback from "./components/Interviews/EmployerDashboard/EmployerFeedback";
import MatchedResumeTabs from "./components/Interviews/EmployerDashboard/Schedule/MatchedResumeTabs";
import JdListingTabs from "./pages/agency/JdListingTabs";
import CandidateListingTabs from "./pages/agency/CandidateListingTabs";
import ParameterSelector from "./components/Interviews/CreateInterview/ParamaterSelector";
import BulkEmailInvite from "./components/Interviews/EmployerDashboard/Schedule/BulkEmailInvite";
import BulkInviteSuccess from "./components/Interviews/EmployerDashboard/Schedule/BulkInviteSuccess";
import FeedbackPage from "./pages/FeedbackPage";
import ScreenCaptureComponent from "./pages/ScreenCaptureComponent";
import LinkedInOAuthCallback from "./functions/api/authentication/LinkedInOAuthCallback";
import FeedbackForm from "./components/Interviews/Feedback/FeedbackForm";
import ThankYouComponent from "./components/Interviews/Feedback/FeedbackContainer";
import NotFound from "./components/PageNotFound";
import MatchedReasoning from "./components/Interviews/EmployerDashboard/Schedule/MatchedReasoning";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/interviews/feedback" element={<FeedbackForm />} />
          <Route path="/matches/reasoning" element={<MatchedReasoning />} />
          <Route
            path="/interviews/feedback/submission"
            element={<ThankYouComponent />}
          />
          <Route path="/pwdreset/:id" element={<Reset />} />
          <Route path="/reset/:id" element={<Forgot />} />
          <Route path="/resetPwd/:id" element={<ForgotPassLogedIn />} />

          <Route element={<ProtectedRoute role={"basic"} />}>
            <Route path="/score/:interviewId" element={<Scorecard />} />

            <Route
              path="/candidateScore/:interviewId"
              element={<ScorecardEmployer />}
            />
            <Route
              path="/proctorReport/:interviewId"
              element={<ProctorReport />}
            />

            <Route
              element={
                <ProtectedRoute role={AuthenticationConstants.jobseeker} />
              }
            >
              <Route
                path="/dashboard/jobseeker"
                element={<JobSeekerDashboard />}
              />
              <Route path="/proctor-test" element={<QuestionComponent />} />
              <Route
                path="/slot-selection/:token"
                element={<SlotSelection />}
              />
              <Route
                path="/create/:skillsEndpoint"
                element={<ParameterSelector />}
              />
              <Route
                path="/create-interview/:interviewId/:interviewState"
                element={<CreateInterview />}
              />
              <Route
                path="/ongoing-interview/:interviewId/:interviewState"
                element={<Proctored />}
              />
            </Route>

            <Route
              element={
                <ProtectedRoute role={AuthenticationConstants.employer} />
              }
            >
              <Route
                path="/dashboard/employer"
                element={<EmployerDashboard />}
              />
              <Route path="/dashboard/agency" element={<EmployerDashboard />} />
              {/* <Route path="/schedule" element={<ScheduleInterview />} /> */}
              <Route
                path="/schedule/matches/:jdId"
                element={<MatchedResumeTabs />}
              />
              <Route path="/schedule/invite/:jdId" element={<Invite />} />
              <Route
                path="/schedule/invite/success"
                element={<InviteSuccess />}
              />
              <Route
                path="/schedule/bulkInvite/success"
                element={<BulkInviteSuccess />}
              />
              <Route
                path="/employer/interview-status/:jdId"
                element={<EmployerInterviewsStatus />}
              />
              <Route
                path="/schedule/bulkInvite/:jdId"
                element={<BulkEmailInvite />}
              />
            </Route>
          </Route>

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/press" element={<Press />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Service />} />
          <Route path="/research-paper" element={<ResearchPaper />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/pricing" element={<PricingPlan />} />

          <Route path="/employers/jds" element={<JobDescriptions />} />
          <Route path="/employers/:jobSummaryHash" element={<JdDetails />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/contactUS" element={<ContactUs />} />
          <Route path="/createTicket" element={<CreateTicket />} />

          {/* Submenu Pages  */}
          <Route path="/product/otohire" element={<Otohire />} />
          <Route path="/product/otosource" element={<OtoSource />} />
          <Route path="/product/otoboard" element={<OtoBoard />} />
          <Route path="/service/screening" element={<Screening />} />
          <Route path="/service/data-analytics" element={<DataAnalytics />} />
          <Route
            path="/service/talent-management"
            element={<TalentManagement />}
          />
          <Route path="/solution/enterprise" element={<Enterprises />} />
          <Route
            path="/solution/recruitment-agency"
            element={<RecruitmentAgency />}
          />
          <Route path="/solution/job-seeker" element={<JobSeeker />} />
          <Route
            path="/create-resume/:resumeId"
            element={<CreateResumePage />}
          />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/trial2" element={<InterviewerInviteDashboard />} />
          <Route path="/JdListing" element={<JdListingTabs />} />
          <Route
            path="/candidateListing/:empClientCode/:jdId"
            element={<CandidateListingTabs />}
          />
          <Route
            path="/interviewer-feedbacks/:token"
            element={<EmployerFeedback />}
          />
          <Route
            path="/auth/linkedin/callback"
            element={<LinkedInOAuthCallback />}
          />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/recording" element={<ScreenCaptureComponent />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
