export function formatTime(selectedTimeSlot) {
  const hour = selectedTimeSlot.$H;
  const minute = selectedTimeSlot.$m;

  let formattedHour;
  if (hour > 12) {
    formattedHour =
      hour % 12 < 10 ? "0" + (hour - 12) : hour % 12 >= 10 ? hour - 12 : "00";
  } else {
    formattedHour = hour > 9 && hour <= 12 ? hour : "0" + hour;
  }

  const formattedMinute = minute < 10 ? "0" + minute : minute;
  const period = hour > 11 ? "PM" : "AM";

  return `${formattedHour}:${formattedMinute} ${period}`;
}
