import { useState, useCallback, useEffect } from 'react';

export function useTabFocusDetection({ disabled } = { disabled: false }) {
    const [tabFocusStatus, setTabFocusStatus] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setTabFocusStatus(!document.hidden);
    }, []);

    useEffect(() => {
        if (disabled) return;

        const handleFocusChange = () => {
            setTabFocusStatus(true);
        };

        const handleBlurChange = () => {
            setTabFocusStatus(false);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        document.addEventListener('focus', handleFocusChange);
        document.addEventListener('blur', handleBlurChange);
        window.addEventListener('focus', handleFocusChange);
        window.addEventListener('blur', handleBlurChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('focus', handleFocusChange);
            document.removeEventListener('blur', handleBlurChange);
            window.removeEventListener('focus', handleFocusChange);
            window.removeEventListener('blur', handleBlurChange);
        };
    }, [disabled, handleVisibilityChange]);

    return { tabFocusStatus };
}
