import React from "react";
import styled from "styled-components";

const TestDetails = ({ data }) => {
  const getAnswerLetter = (options, answer) => {
    const index = options.findIndex((option) => option === answer);
    return index !== -1 ? String.fromCharCode(65 + index) : "";
  };
  return (
    <Box>
      <div className="mainBox">
        {data?.testQuestions?.map((que, i) => (
          <span className="questionBox">
            <span className="questionBox1">
              <span className="questionTop">
                <span>
                  <span className="bold">Que Type:</span> {que?.questionType}
                </span>
                <span>
                  <span className="bold">Difficulty Level:</span>{" "}
                  {que?.difficultyLevel}
                </span>
                <span>
                  <span className="bold">Category:</span> {que?.category}
                </span>
                <span>
                  <span className="bold">Tags:</span> {que?.tags}
                </span>
              </span>
              <span className="Text">
                <span className="bold">Que.</span> {que?.questionText}
              </span>
              {data.testType === "MCQ" && (
                <>
                  <span className="Text">
                    <span className="bold">Options:</span>
                    <ul className="optionsList">
                      {que?.mcqOptions.map((option, index) => (
                        <li key={index}>
                          {String.fromCharCode(65 + index)}. {option}
                        </li>
                      ))}
                    </ul>
                  </span>
                  <span className="Text1">
                    <span className="bold">Answer:</span>&nbsp;
                    {`${getAnswerLetter(que?.mcqOptions, que?.mcqAnswer)}) ${
                      que?.mcqAnswer
                    }`}
                  </span>
                </>
              )}
            </span>
          </span>
        ))}
      </div>
    </Box>
  );
};

export default TestDetails;

const Box = styled.div`
  width: 100%;
  font-size: 0.9rem;
  padding: 1rem 1rem 0 0;
  box-sizing: border-box;

  .mainBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    font-size: 0.9rem;
  }

  .questionBox {
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-size: 0.9rem;
    border: 0.05rem solid lightgrey;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
    box-sizing: border-box;

    .questionBox1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
    }

    .questionTop {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .bold {
      font-weight: 600;
    }
  }

  .optionsList {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0.5rem;
  }

  .optionsList li {
    margin-bottom: 0.25rem;
  }

  .Text1 {
    font-weight: 500;
  }
`;
