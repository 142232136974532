import React, { useState } from "react";
import styled from "styled-components";
import DemoHeader from "../components/commonComponents/DemoHeader";
import Footer from "../components/commonComponents/Footer";
import demoPageImg from "../assets/chatTICKET.png";
import { TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    if (!name || !email || !contact || !message) {
      toast.warning("Please fill in all required fields.");
      return;
    }

    if (!validateEmail(email)) {
      toast.warning("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/misc/contact-requests`,
        {
          name,
          email,
          contact,
          message,
          type: "CONTACT_US",
        }
      );

      if (response.status === 201) {
        toast.success("Your query has been submitted successfully!");
        setName("");
        setEmail("");
        setContact("");
        setMessage("");
      }
    } catch (error) {
      console.error("Error submitting contact ticket:", error);
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleContactChange = (newInput) => {
    setContact(newInput);
  };

  return (
    <StyledDiv>
      <DemoHeader />
      <div className="content">
        <div className="left">
          <img src={demoPageImg} alt="Contact Ticket" />
        </div>
        <div className="right">
          <span className="title">
            Please leave your query here, we will get back to you ASAP!
          </span>
          <TextField
            id="outlined-basic"
            className="textInput"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextField
            id="outlined-basic"
            className="textInput"
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <MuiTelInput
            defaultCountry="IN"
            className="textInput"
            forceCallingCode
            preferredCountries={["IN", "US"]}
            value={contact}
            onChange={handleContactChange}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextField
            id="outlined-multiline-static"
            className="textInput"
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <Footer />
    </StyledDiv>
  );
};

export default ContactUs;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
  }

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left > img {
    width: 60%;
  }

  .textInput {
    width: 80%;
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .btn {
    font-family: var(--font);
    font-weight: 600;
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
    width: 35%;
  }

  @media (max-width: 800px) {
    .left {
      display: none;
    }
    .right {
      width: 100%;
    }
    form {
      width: 90%;
    }
    select {
      width: 80%;
    }
  }

  .Toastify__toast-container {
    z-index: 9999;
  }

  .Toastify__toast {
    font-family: var(--font);
    font-size: 0.9rem;
  }

  .Toastify__toast--success {
    background: var(--lightGreen);
  }

  .Toastify__toast--error {
    background: var(--lightRed);
  }
`;
