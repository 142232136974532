import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllResumes } from "../../../../functions/api/jobSeekers/getAllResumes";
import { useSelector } from "react-redux";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import downloadIcon from "../../../../assets/icons/Profile/download.png";
import { toast } from "react-toastify";

const EnhanceResume = () => {
  const profileId = useSelector(
    (state) => state.auth.userData?.user?.profileId
  );
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const [resumeId, setResumeId] = useState(null);
  const [resumeData, setResumeData] = useState([]);
  const [jdText, setJdText] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getAllResumes(profileId, accessToken);
        if (res) {
          setResumeData(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, []);

  const handleCheckboxChange = (id) => {
    setResumeId(id);
  };

  const handleSubmit = () => {};

  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }

      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );

      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", `${name}`);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Component>
      <Form onSubmit={handleSubmit}>
        <div className="mainBox">
          <div className="leftBox">
            <h4>Select Resume</h4>
            {resumeData?.map((resume) => (
              <ResumeItem key={resume.id}>
                {resume?.srcFilename}
                <div className="rightBox1">
                  <img
                    src={downloadIcon}
                    className="icon"
                    onClick={() =>
                      handleDownload(resume?.id, resume?.srcFilename)
                    }
                  />
                  <div className="horizontalLine"></div>
                  <Checkbox
                    checked={resumeId == resume?.id}
                    onChange={() => handleCheckboxChange(resume?.id)}
                  />
                </div>
              </ResumeItem>
            ))}
          </div>
          <div className="rightBox">
            <h4>JD</h4>
            <textarea
              value={jdText}
              onChange={(e) => setJdText(e.target.value)}
              rows={5}
            />
          </div>
        </div>
        <SubmitButton type="submit">Create Cover Letter</SubmitButton>
      </Form>
    </Component>
  );
};

export default EnhanceResume;

const Component = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  font-size: 0.9rem;
  background-color: var(--white);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;

  .mainBox {
    display: flex;
    gap: 2rem;
    margin: 0 0 1rem 0;
    width: 90%;
    justify-content: space-between;

    .leftBox {
      width: 100%;
    }

    .rightBox {
      width: 100%;

      h4 {
        line-height: 0.5rem;
      }

      textarea {
        width: 100%;
        height: calc(100% - 2rem);
        padding: 1rem;
        box-sizing: border-box;
      }
    }
  }
`;

const SubmitButton = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--white);
  font-weight: 600;
  font-size: 0.9rem;
  font-family: var(--font);
  margin-top: 1.5rem;
`;

const ResumeItem = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1rem;
  border: 0.075rem solid lightgrey;
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;

  .rightBox1 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 1.5rem;

    .icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    .horizontalLine {
      height: 100%;
      width: 0.01rem;
      background-color: var(--color);
    }
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-right: 1rem;
  cursor: pointer;
`;
