import React from "react";
import styled from "styled-components";
import imgNew1 from "../../assets/HomePageReviews/imgNew1.jpg";
import imgNew2 from "../../assets/HomePageReviews/imgNew2.jpg";
import imgNew3 from "../../assets/HomePageReviews/imgNew3.jpg";
import imgNew4 from "../../assets/HomePageReviews/imgNew4.jpg";
import linkedin from "../../assets/HomePageReviews/linkedin.png";

const reviews = [
  {
    profileImg: imgNew3,
    title: "Leading Industrial Expert",

    text: '"Otohire seamlessly streamlines workflows while attracting high-caliber candinames through sophisticated algorithms and data-driven insights. Witnessing my partners across industries consistently secure top talent with this platform confirms its transformative potential,  It helps you find the perfect fit, streamline processes, and build high-performing teams. Explore its capabilities and unlock a future where hiring excellence becomes your reality."',
  },
  {
    profileImg: imgNew4,
    title: "Recruitment Specialist",
    text: '"This AI-based interview portal streamlines hiring for managerial roles, providing efficient evaluations and reducing screening time. The intuitive interface, powered by cutting-edge AI, enhances our recruitment process for managerial positions. Real-time collaboration and insightful analytics contribute to informed decision-making, making it an invaluable asset for Managers. The platform\'s adaptability to diverse roles showcases its versatility, ensuring a seamless user experience."',
  },
  {
    profileImg: imgNew1,
    title: "AI Integration Specialist",
    text: '"Incorporating this AI-driven interview portal into our workflow has been a game-changer for Engineering and Production Managers. It provides deep insights into candinames\' technical, cognitive, and soft skills, reducing time-to-hire and improving hiring quality for managerial roles. Realistic virtual interview simulations, coupled with real-time feedback, make it an indispensable tool for recruitment efficiency in engineering and production management."',
  },
  {
    profileImg: imgNew2,
    title: "Talent Acquisition Consultant",
    text: '"Otohires powerful features offer a streamlined hiring process, superior candiname matching, and data-driven insights that significantly enhance hiring quality. Clients across diverse industries report impressive results, highlighting the platform\'s ability to attract and secure top talent. To modernize your recruitment strategy and unlock a competitive edge, explore this platform and experience the difference."',
  },
];

const ReviewsSection = () => {
  const openLinkedInProfile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Timeline>
      {reviews.map((review, i) => (
        <div
          className={`timeline__event animated fadeInUp delay-${
            4 - i
          }s timeline__event--${review.color}`}
          key={i}
        >
          <div className="timeline__event__icon">
            <img src={review.profileImg} alt={review.name} />
          </div>

          <div className="timeline__event__content">
            <div className="timeline__event__name">
              {review.name}
              {review.linkedinUrl && (
                <img
                  src={linkedin}
                  className="linkedinIcon"
                  onClick={() => openLinkedInProfile(review.linkedinUrl)}
                />
              )}
            </div>

            <div className="timeline__event__title">{review.title}</div>

            <div className="timeline__event__description">
              <p>{review.text}</p>
            </div>
          </div>
        </div>
      ))}
    </Timeline>
  );
};

export default ReviewsSection;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0;
  align-items: center;

  .timeline__event {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    margin: 17px 0;
    border-radius: 20px;
    align-self: center;
    width: 90%;
    max-width: 1000px;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
      .timeline__event__name {
        border-radius: 0 6px 6px 0;
        text-align: right;
      }
      .timeline__event__content {
        border-radius: 30px 0 30px 0px;
      }
    }

    &__title {
      font-size: 0.9rem;
      line-height: 1.4;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    &__content {
      font-size: 0.8rem;
      line-height: 1.5;
      font-weight: 400;
      padding: 15px;
      box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: 100%;
      border-radius: 0px 30px 0 30px;
      display: flex;
      flex-direction: column;

      gap: 10px;

      .linkedinIcon {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    &__name {
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      border-radius: 6px 0 0 6px;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      align-self: center;
      border-radius: 100%;
      width: 185px;
      height: 135px;
      box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      padding: 5px;
      position: relative;
      margin-right: 40px;
      margin-left: 30px;
      background: #8df3eb;
      i {
        font-size: 32px;
      }
    }

    &--type1 {
      .timeline__event__name {
        color: #00000;
        background: #8ff2eb;
      }

      .timeline__event__icon {
        background: #f6a4ec;
        color: #9251ac;

        &:before,
        &:after {
          background: $color3;
        }
      }
      .timeline__event__title {
        color: #00000;
      }
    }

    &--type2 {
      .timeline__event__name {
        color: #00000;
        background: #8ff2eb;
      }

      .timeline__event__icon {
        background: #87bbfe;
        color: #555ac0;
      }
      .timeline__event__title {
        color: #00000;
      }
    }
    .timeline__event__icon img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
