import axios from "axios"

export const getQuestions = async (accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tests/questions`, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}

