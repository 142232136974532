import axios from "axios"

import { toast } from "react-toastify";


export const updateStatus = async (id, data, accessToken, extra) => {

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };

  const requestData = {
    data,
    op: "statusUpdate",
    ...(extra ? { extra } : {}),
  };
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/interviews/${id}`, requestData, config);
    // console.log('Data:', response);
    return response.data;
  } catch (error) {
    throw error;
  }
}

