import React from "react";

export const ViewDetails = ({ row }) => {
  console.log(row);
  return (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        padding: "2rem",
        borderRadius: "20px",
      }}
    >
      <h3>jdId : {row.jdId}</h3>
      <p dangerouslySetInnerHTML={{ __html: row.jobSummary }}></p>
    </div>
  );
};
