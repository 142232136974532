import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { styled } from "styled-components";
import validate from "../functions/validate";
import loginImg from "../assets/loginPageSecureImg.png";
import { toast } from "react-toastify";
import { reset } from "../functions/api/authentication/reset";
import logo from "../assets/otohireNewLogo.jpg";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Reset = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const clientCode = searchParams.get("clientCode");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirm) {
        toast.error("Confirm Password didn't match");
        return;
      }
      const res = await reset(confirm, password, email, id, clientCode);
      if (res) {
        toast.success("Password reseted successfully");
        navigate("/login");
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  // func for password validation
  useEffect(() => {
    // custom rules
    ValidatorForm.addValidationRule("hasSpecialCharacter", (value) => {
      return /[!@#$%^&*]/.test(value);
    });

    ValidatorForm.addValidationRule("hasCapitalLetter", (value) => {
      return /[A-Z]/.test(value);
    });

    ValidatorForm.addValidationRule("hasSmallLetter", (value) => {
      return /[a-z]/.test(value);
    });

    ValidatorForm.addValidationRule("hasNumberDigit", (value) => {
      return /\d/.test(value);
    });

    ValidatorForm.addValidationRule("hasMinLength", (value) => {
      return value.length >= 8;
    });

    // Cleanup the rules when component unmounts
    return () => {
      ValidatorForm.removeValidationRule("hasSpecialCharacter");
      ValidatorForm.removeValidationRule("hasCapitalLetter");
      ValidatorForm.removeValidationRule("hasSmallLetter");
      ValidatorForm.removeValidationRule("hasNumberDigit");
      ValidatorForm.removeValidationRule("hasMinLength");
    };
  }, [password]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <StyledLogin>
      <div
        style={{
          height: "3.5rem",
          position: "absolute",
          top: "1rem",
          left: "3rem",
        }}
      >
        <img src={logo} style={{ height: "100%" }} />
      </div>
      <div id="form">
        <h1>Reset Password</h1>
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="inputBox">
            <TextValidator
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errorMessages={["This field is required", "Email is not valid"]}
              validators={["required", "isEmail"]}
              fullWidth
              required
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
          </div>

          <div className="inputBox">
            <TextValidator
              label="New Password"
              type={passwordVisible ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              validators={[
                "required",
                "hasSpecialCharacter",
                "hasCapitalLetter",
                "hasSmallLetter",
                "hasNumberDigit",
                "hasMinLength",
                "maxStringLength:29",
              ]}
              errorMessages={[
                "This field is required",
                "Must contain a special character",
                "Must contain a capital letter",
                "Must contain a small letter",
                "Must contain a number digit",
                "Must be at least 8 characters long",
                "Must be less than 30 chatacters long",
              ]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
            <FontAwesomeIcon
              icon={faEye}
              id="eye"
              onClick={togglePasswordVisibility}
              className={`eye-icon ${passwordVisible ? "visible" : ""}`}
            />
          </div>

          <div className="inputBox">
            <TextValidator
              label="Confirm New Password"
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirmPassword"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              validators={[
                "required",
                "hasSpecialCharacter",
                "hasCapitalLetter",
                "hasSmallLetter",
                "hasNumberDigit",
                "hasMinLength",
                "maxStringLength:29",
              ]}
              errorMessages={[
                "This field is required",
                "Must contain a special character",
                "Must contain a capital letter",
                "Must contain a small letter",
                "Must contain a number digit",
                "Must be at least 8 characters long",
                "Must be less than 30 chatacters long",
              ]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
            <FontAwesomeIcon
              icon={faEye}
              id="eye"
              onClick={toggleConfirmPasswordVisibility}
              className={`eye-icon ${confirmPasswordVisible ? "visible" : ""}`}
            />
          </div>

          <button type="submit">Reset Password</button>
        </ValidatorForm>
        {/* <p>
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                </p> */}
      </div>
      <div id="cover">
        <img src={loginImg} />
      </div>
    </StyledLogin>
  );
};

export default Reset;

const StyledLogin = styled.div`
  display: flex;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 80%;
  }

  .inputBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;
  }

  .eye-icon {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    cursor: pointer;
    color: lightgray;
  }

  .eye-icon.visible {
    color: blue; /* Change to your desired color when password is visible */
  }

  #form {
    width: 50%;
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #cover {
    width: 50%;
    min-height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
    }
  }

  input {
    width: 100%;
    border-radius: 0.3rem;
  }

  button {
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.6rem 1rem;
    // width: 50%;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50%;

    #form {
      width: 100%;
    }

    #cover {
      display: none;
    }
  }
`;
