import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import { getInterviewDetails } from "../../../../functions/api/interview/getInterviewDetails";
import downloadIcon from "../../../../assets/icons/Profile/download.png";
import eyeIcon from "../../../../assets/icons/visible.png";
import { useSelector } from "react-redux";
import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import EmpCommonButton from "../../EmployerDashboard/commonComponents/EmpCommonButton";
import { handleTrackerUpdate } from "../../../../functions/interviewAction/holdAndNotSelected";
import { addResumes } from "../../../../slices/invitationSlice";
import { useDispatch } from "react-redux";

const SeekerInterviewDetails = ({
  jdId,
  row,
  jdFile,
  check = true,
  clientCode,
  setInviteProcess,
  toggleDrawer,
  getData,
}) => {
  const dispatch = useDispatch();
  const [selectedRound, setSelectedRound] = useState(1); // Default to round 1
  const [interviewDataByRound, setInterviewDataByRound] = useState({});
  const [feedbacks, setFeedbacks] = useState("N/A");
  const [sch, setSch] = useState(false);

  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  // Helper function to format timestamp
  const formatDateTime = (timestamp) => {
    if (!timestamp || isNaN(new Date(timestamp))) return "NA";
    const date = new Date(timestamp);
    const offset = 330; // IST offset in minutes
    const istDate = new Date(date.getTime() + offset * 60 * 1000);

    const day = istDate.getDate();
    const monthIndex = istDate.getMonth();
    const year = istDate.getFullYear();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[monthIndex];
    const hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm} IST`;
    const formattedDate = `${day} ${monthName} ${year}, ${formattedTime}`;
    return formattedDate;
  };

  // Helper function to open test report
  const handleTestReportClick = () => {
    const selectedRoundData = interviewDataByRound?.data;
    const interviewId = selectedRoundData?.id;
    if (interviewId) {
      window.open(`/candidateScore/${interviewId}`, "_blank");
    } else {
      toast.warning("No interview ID found", 5000);
    }
  };

  // Helper function to open proctor report
  const handleProctorReportClick = () => {
    const selectedRoundData = interviewDataByRound?.data;
    const interviewId = selectedRoundData?.id;
    if (interviewId) {
      window.open(`/proctorReport/${interviewId}`, "_blank");
    } else {
      toast.warning("No interview ID found", 5000);
    }
  };

  // Helper function to download resume
  const handleResumeDownload = async (row) => {
    try {
      let resumeId = null;
      if (row?.resumeId) resumeId = row?.resumeId;
      else if (row?.matchedRecord) resumeId = row?.matchedRecord?.resumeId;
      else if (row?.interview) resumeId = row?.interview?.resumeId;

      if (resumeId) {
        const url = `api/media/downloadById?fileType=resume&id=${resumeId}`;
        const config = {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/octet-stream",
            "X-Client-Code": clientCode,
          },
        };

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${url}`,
            config
          );
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const blobUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          const contentDisposition = response.headers["content-disposition"];
          let fileName = "resume.pdf";
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          a.setAttribute("download", fileName);
          a.click();
        } catch (error) {
          throw error;
        }
      } else {
        toast.warning("No resume found or invalid resumeId", 5000);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handleJdDownloads = async () => {
    if (!jdId) {
      toast.warn("jdId not found");
      return;
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jds/downloadByJdId?clientCode=${row?.clientCode}&jdId=${row?.jdId}`,
        config
      );
      const blob = new Blob([res.data], {
        type: "application/octet-stream",
      });
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      const contentDisposition = res.headers["content-disposition"];
      let fileName = "resume.pdf";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      a.setAttribute("download", fileName);
      a.click();
    } catch (error) {
      toast.warn("something wrong");
    }
  };

  const handleMoveNextRound = () => {
    dispatch(addResumes([row]));
    setInviteProcess({
      isOpen: true,
      jdId: jdId,
    });
  };

  const handleUpdating = (text) => {
    handleTrackerUpdate(
      text === "SELECTED"
        ? "SELECTED"
        : text === "NOT_SELECTED"
        ? "NOT_SELECTED"
        : "HOLD",
      row,
      accessToken,
      clientCode,
      toggleDrawer ? toggleDrawer : "",
      getData ? getData : undefined
    );
  };

  const convertStructuredTextToHtml = (structuredText) => {
    return structuredText
      .replace(/\[strong\](.*?)\[\/strong\]/g, "<strong>$1</strong>")
      .replace(/\[b\](.*?)\[\/b\]/g, "<b>$1</b>")
      .replace(/\[i\](.*?)\[\/i\]/g, "<i>$1</i>")
      .replace(/\[u\](.*?)\[\/u\]/g, "<u>$1</u>")
      .replace(/\[em\](.*?)\[\/em\]/g, "<em>$1</em>")
      .replace(/\n\n/g, "<p></p>") // Double newline to paragraph
      .replace(/\n/g, "<br>"); // Single newline to <br>
  };

  const getInterviewByStage = async (Id, stage) => {
    try {
      if (Id) {
        const url = `api/interviews/trackers/${Id}/${stage}`;
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Client-Code": clientCode,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${url}`,
          config
        );

        // Update the interviewDataByRound state with the fetched data
        setInterviewDataByRound(response.data);
      } else {
        toast.warning("No Interview ID found", 5000);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  useEffect(() => {
    if (interviewDataByRound?.data?.feedback?.interviewerRemarks) {
      const feedbackHtml = convertStructuredTextToHtml(
        interviewDataByRound.data.feedback.interviewerRemarks
      );
      setFeedbacks(feedbackHtml);
    } else {
      setFeedbacks("N/A");
    }
  }, [interviewDataByRound]);

  // Fetch initial interview data for round 1 when component mounts
  useEffect(() => {
    if (row?.id) {
      const latestRound = row?.stage || 1;
      setSelectedRound(latestRound);
      check && getInterviewByStage(row?.id, latestRound);
    }
    setSch(row?.interview?.status === "SCHEDULED" ? true : false);
  }, [row?.id, row?.stage]);

  // Render round buttons
  const RoundButtons = () => {
    const totalRounds = row?.stage;
    // const rounds = Array.from({ length: totalRounds }, (_, i) => i + 1);
    const rounds = Array.from(
      { length: totalRounds },
      (_, i) => totalRounds - i
    );

    return (
      <RoundButtonsContainer>
        {rounds.map((round) => (
          <RoundButton
            key={round}
            onClick={() => {
              setSelectedRound(round);
              if (row?.id) {
                check && getInterviewByStage(row?.id, round);
              } else {
                toast.warning("No Interview ID found", 5000);
              }
            }}
            isActive={round === selectedRound}
          >
            Round {round}
          </RoundButton>
        ))}
        {/* {!check && <RoundButton isActive={true}>round {row.stage}</RoundButton>} */}
      </RoundButtonsContainer>
    );
  };

  // Dummy interview data
  const interviewData = [
    {
      // summary:
      //   "The candidate demonstrated strong problem-solving skills and a solid understanding of algorithms and data structures. They successfully completed the coding challenge within the given time frame.",
    },
  ];
  return (
    <Box>
      <div className="box1">
        {/* Basic Details */}
        <span className="title">Basic Details</span>
        <div className="line" />
        <div className="textBox">
          <div className="childBox">
            <span className="text">
              {row?.userName ||
                row?.interview?.userName ||
                row?.matchedRecord?.name ||
                "NA"}
            </span>
          </div>
          <div className="childBox">
            <span className="text">
              {row?.interview?.usercontact ||
                row?.matchedRecord?.contact ||
                "NA"}
            </span>
          </div>
          <div className="childBox">
            <span className="text">
              {/* <span className="boldText">Email:</span> */}
              {row?.userEmail ||
                row?.interview?.userEmail ||
                row?.matchedRecord?.email ||
                "NA"}
            </span>
          </div>
        </div>
        <div className="buttons">
          <ResumeButtonContainer>
            {check && (
              <DownloadButton onClick={() => handleResumeDownload(row)}>
                <span className="boldText">Resume</span>
                <Icon src={downloadIcon} alt="Download Icon" />
              </DownloadButton>
            )}

            <DownloadButton onClick={handleJdDownloads}>
              <span className="boldText">JD</span>
              <Icon src={downloadIcon} alt="Download Icon" />
            </DownloadButton>
          </ResumeButtonContainer>
        </div>
      </div>

      <div className="box2">
        {interviewData.map((interview, index) => (
          <Card key={index}>
            <div className="cardBox" style={{}}>
              <div>
                <span className="title">Interview Details </span>
                <span className="cardText"> (JD ID&nbsp;:-&nbsp;{jdId})</span>
              </div>
              <div
                className={
                  row?.status === "NOT_SELECTED"
                    ? "red"
                    : row?.status === "IN_PROCESS"
                    ? "yellow"
                    : row?.status === "SELECTED"
                    ? "green"
                    : row?.status === "HOLD"
                    ? "orange"
                    : row?.status === "SHORTLISTED"
                    ? "lightGreen"
                    : "red"
                }
              >
                <span className="boldText">
                  {row?.status === "NOT_SELECTED"
                    ? "NOT SELECTED"
                    : row?.status === "IN_PROCESS"
                    ? "IN PROCESS"
                    : row?.status}
                </span>
              </div>
            </div>
            <div className="line" />

            <RoundButtons />
            {row?.stage > 0 && (
              <div>
                <div className="cardRow">
                  <div className="cardBox">
                    <span className="cardTitle">Interview Mode :</span>
                    <span className="cardText">
                      {row?.productType ||
                        interviewDataByRound?.data?.productType ||
                        row?.interview?.productType ||
                        "NA"}
                    </span>
                  </div>

                  {(interviewDataByRound?.data?.productType === "Employer" ||
                    row?.interview?.productType === "Employer" ||
                    row?.productType === "Employer") && (
                    <>
                      |
                      <div className="cardBox">
                        <span className="cardTitle">Interviewer :</span>
                        <span className="cardText">
                          {(row?.interview?.initiatedByName ||
                            row?.initiatedByName ||
                            row?.matchedRecord?.initiatedByName ||
                            "NA") +
                            " (" +
                            (row?.interview?.initiatedByEmail ||
                              row?.initiatedByEmail ||
                              row?.matchedRecord?.initiatedByEmail ||
                              "NA") +
                            ")"}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className="cardRow">
                  {(interviewDataByRound?.data?.stageName ||
                    row?.stageName) && (
                    <div className="cardBox">
                      <span className="cardTitle">Interview Name :</span>
                      <span className="cardText">
                        {interviewDataByRound?.data?.stageName ||
                          row?.stageName ||
                          "NA"}
                      </span>
                      |
                    </div>
                  )}
                  <div className="cardBox">
                    <span className="cardTitle">Status :</span>
                    <span className="cardText">
                      {interviewDataByRound?.data?.status ||
                        row?.interview?.status ||
                        row?.status ||
                        "NA"}
                    </span>
                  </div>
                  |
                  <div className="cardBox">
                    <span className="cardTitle">Scheduled time :</span>
                    <span className="cardText">
                      {formatDateTime(
                        interviewDataByRound?.data?.scheduledAt ||
                          row?.interview?.scheduledAt ||
                          row?.scheduledAt ||
                          "NA"
                      )}
                    </span>
                  </div>
                </div>
                <div className="cardRow">
                  <div className="cardBox">
                    <span className="cardTitle">Duration :</span>
                    <span className="cardText">
                      {(interviewDataByRound?.data?.durationInMins ||
                        row?.interview?.durationInMins ||
                        row?.durationInMins) === -1 ||
                      (interviewDataByRound?.data?.durationInMins ||
                        row?.interview?.durationInMins ||
                        row?.durationInMins) < 1
                        ? "1 min"
                        : `${
                            interviewDataByRound?.data?.durationInMins ||
                            row?.interview?.durationInMins ||
                            row?.durationInMins ||
                            0
                          } mins`}
                    </span>
                    |
                  </div>
                  <div className="cardBox">
                    <span className="cardTitle">No. of Questions :</span>
                    <span className="cardText">
                      {interviewDataByRound?.data?.noOfQuestions ||
                        row?.interview?.noOfQuestions ||
                        "NA"}
                    </span>
                  </div>
                  |
                  <div className="cardBox">
                    <span className="cardTitle">Difficulty level :</span>
                    <span className="cardText">
                      {interviewDataByRound?.data?.difficultyLevel ||
                        row?.interview?.difficultyLevel ||
                        "NA"}
                    </span>
                  </div>
                  |
                  <div className="cardBox">
                    <span className="cardTitle">Interview type :</span>
                    <span className="cardText">
                      {interviewDataByRound?.data?.testType ||
                        row?.interview?.testType ||
                        "NA"}
                    </span>
                  </div>
                </div>

                <div className="cardRow">
                  <div className="cardBox">
                    <span className="cardTitle">Score :</span>
                    <span className="cardText">
                      {/* {row?.interview?.aiScore || row?.matchedRecord?.aiScore}/ */}
                      {(interviewDataByRound?.data?.score ||
                        row?.interview?.score ||
                        "0") +
                        "/" +
                        (interviewDataByRound?.data?.maxScore ||
                          row?.interview?.maxScore ||
                          "0")}
                    </span>
                  </div>

                  {(interviewDataByRound?.data?.testType === "Employer" ||
                    row?.interview?.productType === "Employer") && (
                    <>
                      |
                      <div className="cardBox">
                        <span className="cardTitle">Candidate Rating:</span>
                        <span className="cardText">
                          {interviewDataByRound?.data?.feedback
                            ?.interviewerRating || "NA"}
                        </span>
                      </div>
                      |
                      <div className="cardBox">
                        <span className="cardTitle"> IsQualified:</span>
                        <span className="cardText">
                          {interviewDataByRound?.data?.feedback?.qualified
                            ? "qualified"
                            : interviewDataByRound?.data?.feedback
                                ?.qualified === false
                            ? "disQualified"
                            : "NA"}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {(interviewDataByRound?.data?.productType === "Employer" ||
                  row?.interview?.productType === "Employer") && (
                  <>
                    <div className="cardRow">
                      <div
                        className="cardBox"
                        style={{ alignItems: "flex-start" }}
                      >
                        <span className="cardTitle">Feedback:</span>
                        <span dangerouslySetInnerHTML={{ __html: feedbacks }} />
                      </div>
                    </div>
                  </>
                )}

                {check &&
                  interviewDataByRound?.data?.testType != "InPerson" && (
                    <div className="buttons">
                      <ResumeButtonContainer>
                        <DownloadButton onClick={handleTestReportClick}>
                          <span className="boldText">Test report</span>
                          <Icon src={eyeIcon} alt="Download Icon" />
                        </DownloadButton>
                        <DownloadButton onClick={handleProctorReportClick}>
                          <span className="boldText">Proctor report</span>
                          <Icon src={eyeIcon} alt="Download Icon" />
                        </DownloadButton>
                      </ResumeButtonContainer>
                    </div>
                  )}
              </div>
            )}
          </Card>
        ))}
      </div>
      {check &&
        (row?.status == "HOLD" ||
          row?.status === "IN_PROCESS" ||
          row?.status === "SHORTLISTED") && (
          <div className="box3">
            <div className="btnBox">
              <EmpCommonButton
                text="Put on Hold"
                func={() => handleUpdating("HOLD")}
              />

              {row?.status !== "SHORTLISTED" && (
                <EmpCommonButton
                  text="Move out from Interview"
                  func={() => handleUpdating("NOT_SELECTED")}
                />
              )}

              <EmpCommonButton
                text="Move to next Round"
                className={sch ? "disabled" : ""}
                func={sch ? undefined : () => handleMoveNextRound()}
              />
              {row?.interview?.status === "COMPLETED" && (
                <EmpCommonButton
                  text="Mark as Selected"
                  func={() => handleUpdating("SELECTED")}
                />
              )}
            </div>
          </div>
        )}
    </Box>
  );
};

export default SeekerInterviewDetails;
const RoundButtonsContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 0.5rem;
`;

const RoundButton = styled.button`
  background-color: ${(props) => (props.isActive ? "#18E2D3" : "#ccc")};
  color: ${(props) => (props.isActive ? "#fff" : "#333")};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.7rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.isActive ? "#18E2D3" : "#aaa")};
  }
`;

const DownloadButton = styled.button`
  background: none;
  border: 0.08rem solid #707070;
  border-radius: 0.3rem;
  cursor: pointer;
  display: column;
  align-items: center;
  padding: 0.3rem;
  gap: 0.3rem;
  font-family: inherit;
  font-size: 0.7rem;
`;

const Icon = styled.img`
  margin-left: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
`;
const ResumeButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;
`;
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .box1 {
    background-color: #f4f4f4;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .line {
      width: 100%;
      height: 2px;
      background-color: #ccc;
      margin: 0rem 0;
    }

    .textBox {
      display: flex;
      flex-direction: row;
      //   gap: 3%;

      .childBox {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        font-size: 0.85rem;
        font-weight: 900;

        .boldText {
          font-size: 0.8rem;
          font-weight: 400;
        }
      }

      .resumeButton {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        font-size: 0.75rem;
        font-weight: 400;
        align-items: start;
        margin-top: 0.5rem;

        .boldText {
          font-size: 0.8rem;
          font-weight: 600;
        }

        button {
          width: 3.3rem;
          height: 1.5rem;
          background-color: var(--white);
          border: 0.08rem solid var(--color);
          border-radius: 0.3rem;
          cursor: pointer;
        }
      }
    }
  }
  .box3 {
    .btnBox {
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      gap: 2rem;
    }
  }

  .box2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: #ccc;
      margin: 0rem 0;
    }
    .cardMainBox {
      font-size: 0.8rem;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  gap: 0.75rem;

  .cardMainTitle {
    font-size: 1rem;
    font-weight: 600;
  }

  .cardRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .cardBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    gap: 0.5rem;
    .red,
  .yellow,
  .green,
  .orange,
  .lightGreen {
    border-radius: 10px;
    padding: 5px 0px;
    border: 0.5px solid gray;
    width: 125px;
    display: flex;
    justify-content: center;
    font-size:0.8rem;
  }
  .red {
    background-color: #f4433673;
  }
  .green {
    background-color: #4caf508a;
  }
  .lightGreen {
    background-color: #8bc34a85;
  }
  .yellow {
    background-color: #ffeb3b85;
  }
  .orange {
    background-color: #ff980087;
  }

    &.feedback {
      flex-direction: column;
      align-items: flex-start;
    }

    .cardTitle {
      font-size: 0.85rem;
      font-weight: 400;
    }

    .cardText {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }


    .nameText {
      font-size: 0.8rem;
      font-weight: 400;
      display: flex;
      gap: 1rem;
    }

    .boldNameText {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
`;
