import axios from "axios";
import HTTP from "../../../../backendApi";

export const getEmployer = async (
  clientCode,
  ) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //     "Content-Type": "application/json"
  //   },
  // };
  try {
    const response =
      await HTTP.get(`/employers/${clientCode}`);

    console.log("Data:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
