import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { jobListings } from "../../utils/contantData";
import { getInterviewByStatus } from "../../functions/api/getInterviewByStatus";
import Loader from "../commonComponents/Loader";
import view from "../../assets/icons/visible.png";
import CommonDrawer from "../commonComponents/CommonDrawer";
import SeekerInterviewDetails from "./SeekerDashboard/sidebarPages/SeekerInterviewDetails";
import { timeZoneConversion } from "../../utils/timeZoneConversation";
import SeekerTableSearchBar from "./SeekerDashboard/seekerCommonComponents/SeekerTableSearchBar";
import {
  Pagination,
  PaginationSizeFilter,
} from "../commonComponents/Pagination";
import { toast } from "react-toastify";
import { getEmployer } from "../../functions/api/employers/profile/getEmployer";
import ConfigurableModal from "./SeekerDashboard/seekerCommonComponents/ConfigurableModal";
import OngoingInterviews from "./SeekerDashboard/sidebarPages/OngoingInterviews";
import { shouldStartInterview } from "../../functions/api/interview/shouldStartInterview";
import axios from "axios";

function Row(props) {
  const { row, index, setIsLoading, setLoaderMessage } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({});
  const [jdData, setJdData] = useState([]);
  const [interviewStartStatus, setInterviewStartStatus] = useState(false);

  useEffect(() => {
    const handleShouldStartInterview = async (id) => {
      try {
        const res = await shouldStartInterview(id, accessToken);
        setInterviewStartStatus(res);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    handleShouldStartInterview(row?.id);
  }, [row?.id]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEmployer( row?.clientCode);
        if (res) {
          setCompanyDetails(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getData();
  }, []);

  const startInterview = async () => {
    localStorage.setItem("currentInterview", "profile");
    if (row.testType === "InPerson") {
      window.open(row.meetingLink);
    } else {
      navigate(`/create-interview/${row?.id}/start`);
    }
  };

  const [state, setState] = React.useState({
    right: false,
  });

  //trackers
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const res = await getAllTrackers(
  //         accessToken,
  //         row?.clientCode,
  //         1,
  //         1000000,
  //         row.jdId,
  //         "",
  //         ""
  //       );
  //       // setTotal(res?.data?.total);
  //       // setAllCandidateData(res?.data?.data);
  //     } catch (error) {
  //       const errMsg =
  //         error?.response?.data?.notify?.message ||
  //         "An error occurred. Please try again.";
  //       toast.error(errMsg, 8000);
  //     }
  //   };
  //   getData();
  // }, []);

  const toggleDrawers = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="logo tableCell"
        >
          <img src={companyDetails?.companyLogo} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {companyDetails?.companyName}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.jdId}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.scheduledAt && timeZoneConversion(row?.scheduledAt)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.status}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <Button
            onClick={startInterview}
            disabled={interviewStartStatus === false}
          >
            {/* {Attend} */}
            {row.testType === "InPerson" ? "Join Meet" : "Attend"}
          </Button>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CommonDrawer
              toggleDrawer={toggleDrawers}
              state={state}
              component={
                <div>
                  <SeekerInterviewDetails
                    check={false}
                    row={row}
                    jdId={row.jdId}
                    clientCode={row.clientCode}
                    // jdFile = {jdDataIs}
                  />
                </div>
              }
            />
            <img
              src={view}
              style={{
                width: "0.8rem",
                height: "0.8rem",
                cursor: "pointer",
                border: "0.08rem solid grey",
                padding: "0.3rem",
                borderRadius: "0.3rem",
              }}
              onClick={toggleDrawers("right", true)}
            />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ScheduledInterviewList = () => {
  const [appliedJobs, setAppliedJobs] = useState();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [openOngoingModal, setOpenOngoingModal] = useState(false);
  const [ongoingInterview, setOngoingInterview] = useState([]);
  const [ongoingInterviewCount, setOngoingInterviewCount] = useState(0);
  const [ongoingTrigger, setOngoingTrigger] = useState(false);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    try {
      const getOngoingInterview = async () => {
        const res = await getInterviewByStatus(
          "STARTED",
          accessToken,
          1,
          1000000
        );
        if (res) {
          setOngoingInterview(res?.data?.data);
          setOngoingInterviewCount(res?.data?.total);
        }
      };
      getOngoingInterview();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, [ongoingTrigger]);

  useEffect(() => {
    try {
      const getScheduledData = async () => {
        const res = await getInterviewByStatus(
          "SCHEDULED",
          accessToken,
          page,
          size
        );
        if (res) {
          setFilteredJobs(res?.data?.data);
          setTotal(res?.data?.total);
        }
      };
      getScheduledData();
      const filteredJobs = jobListings.filter((job) => job?.applied === true);
      if (filteredJobs) {
        setAppliedJobs(filteredJobs);
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, [size, page]);

  if (total === 0 && ongoingInterviewCount === 0) {
    return <h6 style={{ fontSize: "1.2rem" }}>No interview Here</h6>;
  }
  return (
    <Container1>
      <StyledBox>
        <ConfigurableModal
          open={openOngoingModal}
          setOpen={setOpenOngoingModal}
          component={
            <OngoingInterviews
              data={ongoingInterview}
              total={ongoingInterviewCount}
            />
          }
          style={{ width: "90%", height: "85%" }}
        />
        {isLoading && <Loader message={loaderMessage} />}
        {!isLoading && (
          <TableContainer component={Paper} className="tableBox">
            <span className="title">Scheduled Interviews</span>
            <SearchBarContainer>
              <SeekerTableSearchBar
                value={searchValue}
                setValue={setSearchValue}
              />
              <button
                className="btn"
                onClick={() => {
                  setOpenOngoingModal(true);
                  setOngoingTrigger((prev) => !prev);
                }}
              >
                Ongoing Interviews: {ongoingInterviewCount}
              </button>
            </SearchBarContainer>
            <Table aria-label="collapsible table">
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell align="center" className="tableCell"></TableCell>
                  <TableCell align="center" className="tableCell">
                    Company
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    JD ID
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Scheduled Date/Time
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Status
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Interview Link
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {filteredJobs
                  ?.filter((item) =>
                    item.jdId
                      .toLocaleLowerCase()
                      .includes(searchValue.toLocaleLowerCase())
                  )
                  .map((row, index) => (
                    <Row
                      key={row?.jobId}
                      row={row}
                      index={index}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      loaderMessage={loaderMessage}
                      setLoaderMessage={setLoaderMessage}
                    />
                  ))}
              </TableBody>
            </Table>
            <div className="paginationBox">
              <PaginationSizeFilter
                size={size}
                handleSizeChange={handleSizeChange}
              />
              <Pagination
                total={total}
                size={size}
                page={page}
                handlePageChange={handlePageChange}
                setPage={setPage}
              />
            </div>
          </TableContainer>
        )}
      </StyledBox>
    </Container1>
  );
};

export default ScheduledInterviewList;

const StyledBox = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 0;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--cyan);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font);
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .rowText {
    font-size: 0.75rem;
  }

  .logo {
    width: 2rem;
    height: 2rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10%;
    }
  }
`;

const Container1 = styled.div`
  width: 95%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;
`;

const Button = styled.button`
  background-color: var(--cyan);
  color: ${(props) => (props.disabled ? "#e9e9e9" : "var(--white)")};
  padding: 0.5rem 0.8rem;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  font-family: var(--font);
`;
