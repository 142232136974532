import React from "react";
import styled from "styled-components";
import downloadIcon from "../../../../assets/icons/Profile/download.png";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import { toast } from "react-toastify";

const CandidateDetails = ({ candidate, accessToken, clientCode }) => {
  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }
      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );

      // Ensure the filename ends with .pdf
      const pdfName = name.endsWith(".pdf") ? name : `${name}.pdf`;

      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", pdfName);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const dateConversion = (date, isDOB = false) => {
    if (date) {
      const givenDate = new Date(isDOB ? date : date.split("T")[0]);
      const day = givenDate.getDate();
      const month = givenDate.toLocaleString("default", { month: "long" });
      const year = givenDate.getFullYear();

      return `${month} ${day}, ${year}`;
    }
  };

  return (
    <Wrapper>
      <Box>
        <div className="basicDetails">
          <div className="leftColumn">
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">First Name:&nbsp;</div>
                {candidate?.firstName ? (
                  <span className="value">{candidate?.firstName}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Email:&nbsp;</div>
                {candidate?.email ? (
                  <span className="value">{candidate?.email}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Location:&nbsp;</div>
                {candidate?.currentLocation ? (
                  <span className="value">{candidate?.currentLocation}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <span className="label labeltype">Date of Birth: &nbsp;</span>
                {candidate?.dob ? (
                  <span className="value">
                    {dateConversion(candidate?.dob, true)}
                  </span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Education:&nbsp;</div>
                {candidate?.education ? (
                  <span className="value">{candidate?.education}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Highest Degree:&nbsp;</div>
                {candidate?.highestDegree ? (
                  <span className="value">{candidate?.highestDegree}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Year of Passing:&nbsp;</div>
                {candidate?.yearOfPassing ? (
                  <span className="value">{candidate?.yearOfPassing}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <span className="boldText">Nationality:&nbsp; </span>
                {candidate?.nationality ? (
                  <span className="value">{candidate?.nationality}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
          </div>
          <div className="rightColumn">
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Last Name:&nbsp;</div>
                {candidate?.lastName ? (
                  <span className="value">{candidate?.lastName}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Phone:&nbsp;</div>
                {candidate?.contact ? (
                  <span className="value">{candidate?.contact}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Experience:&nbsp;</div>
                {candidate?.yoe ? (
                  <span className="value">{candidate?.yoe} years</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">
                  Date of Registration:&nbsp;
                </div>
                {candidate?.createdAt ? (
                  <span className="value">
                    {dateConversion(candidate?.createdAt)}
                  </span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Position:&nbsp;</div>
                {candidate?.position ? (
                  <span className="value">{candidate?.position}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Major:&nbsp;</div>
                {candidate?.major ? (
                  <span className="value">{candidate?.major}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Grades:&nbsp;</div>
                {candidate?.grades ? (
                  <span className="value">{candidate?.grades}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            <div className="component">
              <div className="labelWrapper">
                <span className="label labeltype">Source: &nbsp;</span>
                {candidate?.source ? (
                  <span className="value">{candidate?.source}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div>
            {/* <div className="component">
              <div className="labelWrapper">
                <div className="label labeltype">Remarks:&nbsp;</div>
                {candidate?.remarks ? (
                  <span className="value">{candidate?.remarks}</span>
                ) : (
                  <span className="value">N/A</span>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="tags">
          <div className="labelWrapper">
            <span className="title">Tags:</span>
            <div className="skillBox">
              {candidate?.tags && candidate.tags.trim() !== "" ? (
                candidate.tags.split(",").map((tag, index) => (
                  <span className="text" key={index}>
                    {tag}
                  </span>
                ))
              ) : (
                <span className="textNA">N/A</span>
              )}
            </div>
          </div>
        </div>

        <div className="downloadResume">
          <div
            className="labelWrapper"
            style={{
              borderRadius: "0.75rem",
              padding: "0.8rem 2rem",
            }}
          >
            <span className="title">Download Resume:</span>
            <DownloadButton
              onClick={() =>
                handleDownload(
                  candidate?.resume?.id,
                  candidate?.resume?.srcFilename
                )
              }
            >
              <Icon src={downloadIcon} alt="Download Icon" />
            </DownloadButton>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
};

export default CandidateDetails;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.7rem;
`;

const Box = styled.div`
  background-color: #f4f4f4;
  border-radius: 0.75rem;
  padding: 0.7rem;
  width: 100%;
  margin: 0;

  .basicDetails {
    display: flex;
    justify-content: space-between;

    .component {
      padding: 0.8rem 2rem;
      margin-bottom: 0.5rem;
    }

    .labelWrapper {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .value {
      font-size: 0.95rem;
      font-weight: 400;
    }

    .boldText {
      font-weight: 600;
    }
  }

  .tags {
    padding: 1rem 2rem;
    // margin-bottom: 1rem;
  }

  .downloadResume {
    // margin-bottom: 1rem;
  }

  .labelWrapper {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
    margin-right: 1rem;
  }

  .skillBox {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;

    .text {
      font-size: 0.9rem;
      font-weight: 420;
      border: 0.08rem solid lightgrey;
      padding: 0.2rem 0.7rem;
      border-radius: 0.5rem;
    }
    .textNA {
      font-size: 0.9rem;
      font-weight: 400;
      padding: 0.2rem 0.7rem;
      border-radius: 0.5rem;
    }
  }
`;

const DownloadButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  border: 0.08rem solid grey;
  padding: 0.3rem;
  border-radius: 0.3rem;
`;
