import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { addEmployments } from "../../../../functions/api/jobSeekers/addEmployments";
import { updateEmployment } from "../../../../functions/api/jobSeekers/updateEmployment";
import addIcon from "../../../../assets/icons/Profile/addIcon.png";
import deleteIcon from "../../../../assets/icons/Profile/deleteIcon.png";

const EmploymentDetails = ({
  data,
  mode,
  handleClose,
  id,
  trigger,
  setTrigger,
}) => {
  const profileId = useSelector(
    (state) => state.auth.userData?.user?.profileId
  );
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [formData, setFormData] = useState();

  const [issueDate, setIssueDate] = useState(dayjs(new Date()));
  const [expirationDate, setExpirationDate] = useState(dayjs(new Date()));
  const [desc, setDesc] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgLocation, setOrgLocation] = useState("");
  const [orgId, setOrgId] = useState("");
  const [skills, setSkills] = useState([{ name: "", experienceMonths: 0 }]);
  const [companyStatus, setCompanyStatus] = useState("no");

  const handleRadioChange = (event) => {
    setCompanyStatus(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSkillChange = (index, field, value) => {
    const updatedSkills = [...skills];
    updatedSkills[index] = {
      ...updatedSkills[index],
      [field]: value,
    };
    setSkills(updatedSkills);
  };

  const handleAddSkill = () => {
    setSkills([...skills, { name: "", experienceMonths: 0 }]);
  };

  const handleRemoveSkill = (index) => {
    if (skills.length == 1) {
      setSkills([{ name: "", experienceMonths: 0 }]);
    } else {
      setSkills(skills.filter((item, ind) => ind != index));
    }
  };
  useEffect(() => {
    if (mode === "edit") {
      setFormData(data);
      setIssueDate(dayjs(data?.startDate));
      setExpirationDate(dayjs(data?.endDate));
      setDesc(data?.description);
      setOrgName(data?.orgDetail?.name);
      setOrgLocation(data?.orgDetail?.address);
      setOrgId(data?.orgDetail?.id);
      setCompanyStatus(data?.current ? "yes" : "no");
    }
  }, []);

  const handleSubmit = async () => {
    try {
      if (!formData?.designation || !formData?.role) {
        toast.error("Please fill in all required fields.");
        return;
      }
      const startDate = moment(issueDate.format("YYYY-MM-DD"));
      const endDate = moment(expirationDate.format("YYYY-MM-DD"));

      if (mode === "create") {
        const filteredSkills = skills.filter(
          (skill) => skill.name.trim() !== ""
        );
        if (!orgName.trim()) {
          toast.warn("Organisation name is required");
          return;
        }
        const payload = {
          designation: formData?.designation,
          description: desc,
          employmentType: formData?.employmentType,
          managerName: formData?.managerName,
          managerEmail: formData?.managerEmail,
          role: formData?.role,
          startDate: startDate?._i,
          orgName: orgName,
          endDate: endDate?._i,
          orgDetail: {
            address: orgLocation,
            name: orgName,
          },
          skillsUsed2: filteredSkills,
          current: companyStatus == "yes",
        };
        if (companyStatus == "yes") delete payload?.endDate;

        const res = await addEmployments(profileId, payload, accessToken);

        if (res) {
          toast.success("Employment added successfully");
          handleClose();
          setTrigger(!trigger);
        }
      } else {
        const payload = {
          designation: formData?.designation,
          description: desc,
          employmentType: formData?.employmentType,
          managerName: formData?.managerName,
          managerEmail: formData?.managerEmail,
          role: formData?.role,
          startDate: startDate?._i,
          endDate: endDate?._i,
          orgDetail: {
            address: orgLocation,
            name: orgName,
            id: orgId,
          },
          current: companyStatus == "yes",
        };
        if (companyStatus == "yes") delete payload?.endDate;
        const res = await updateEmployment(id, payload, accessToken);

        if (res) {
          toast.success("Employment updated successfully");
          handleClose();
          setTrigger(!trigger);
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Box>
      <span className="title">
        {mode === "create" ? "Add Your Employments" : "Update Your Employment"}
      </span>

      <Form>
        <RadioGroup>
          <p>Is this your current company?</p>
          <RadioLabel>
            <RadioButton
              type="radio"
              name="companyStatus"
              value="yes"
              checked={companyStatus === "yes"}
              onChange={handleRadioChange}
            />
            <p>Yes</p>
          </RadioLabel>
          <RadioLabel>
            <RadioButton
              type="radio"
              name="companyStatus"
              value="no"
              checked={companyStatus === "no"}
              onChange={handleRadioChange}
            />
            <p>No</p>
          </RadioLabel>
        </RadioGroup>
        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            type="text"
            value={orgName || " "}
            onChange={(e) => setOrgName(e.target.value)}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="Company Location"
            variant="outlined"
            type="text"
            sx={{ backgroundColor: "#F6F6FB" }}
            name="orgDetail"
            value={orgLocation || " "}
            onChange={(e) => setOrgLocation(e.target.value)}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Your Designation"
            variant="outlined"
            type="text"
            name="designation"
            value={formData?.designation || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="Role"
            variant="outlined"
            type="text"
            name="role"
            value={formData?.role || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ width: "100%" }}>
              <DatePicker
                label="Start Date"
                sx={{
                  backgroundColor: "#F6F6FB",
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                  "& .MuiInputBase-input": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                value={issueDate}
                onChange={(newValue) => setIssueDate(dayjs(newValue))}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ width: "100%" }}>
              <DatePicker
                label="End Date"
                sx={{
                  backgroundColor: "#F6F6FB",
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                  "& .MuiInputBase-input": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                value={expirationDate}
                onChange={(newValue) => setExpirationDate(dayjs(newValue))}
                disabled={companyStatus == "yes"}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Manager Name"
            variant="outlined"
            type="text"
            name="managerName"
            value={formData?.managerName || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="Manager Email"
            variant="outlined"
            type="email"
            name="managerEmail"
            value={formData?.managerEmail || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
        </div>

        <div className="inputBox" style={{ width: "calc(50% - 1rem)" }}>
          <FormControl
            sx={{ backgroundColor: "#F6F6FB", padding: "0" }}
            fullWidth
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Employment Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Employment Type"
              size="small"
              name="employmentType"
              value={
                (formData?.employmentType?.length > 0 &&
                  formData?.employmentType) ||
                " "
              }
              onChange={handleChange}
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.3rem 0",
              }}
            >
              <MenuItem value="part-time">Part-time</MenuItem>
              <MenuItem value="full-time">Full-time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="textAreaBox">
          <label className="textAreaLabel">Description</label>
          <ReactQuill
            theme="snow"
            className="textEditor"
            value={desc}
            onChange={setDesc}
          />
        </div>

        {mode == "create" &&
          skills?.map((skill, i) => (
            <div className="inputBox">
              <TextField
                id="outlined-basic"
                label="Skill"
                variant="outlined"
                type="text"
                value={skill?.name || " "}
                onChange={(e) => handleSkillChange(i, "name", e.target.value)}
                sx={{ backgroundColor: "#F6F6FB" }}
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                label="Months of Experience"
                variant="outlined"
                type="number"
                value={skill?.experienceMonths || " "}
                onChange={(e) =>
                  handleSkillChange(i, "experienceMonths", e.target.value)
                }
                sx={{ backgroundColor: "#F6F6FB" }}
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                fullWidth
              />
              <div className="iconBox">
                {i === skills.length - 1 && (
                  <img
                    className="addIcon"
                    src={addIcon}
                    onClick={handleAddSkill}
                  />
                )}
                {skills.length > 1 && (
                  <img
                    className="addIcon"
                    src={deleteIcon}
                    onClick={() => handleRemoveSkill(i)}
                  />
                )}
              </div>
            </div>
          ))}

        <Button onClick={handleSubmit}>
          {mode === "create" ? "Add" : "Save Changes"}
        </Button>
      </Form>
    </Box>
  );
};

export default EmploymentDetails;

const Box = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.3rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 2rem;
    display: block;
    margin-bottom: 1rem;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;

  .iconBox {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 1rem;
  }

  .addIcon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-color: #f6f6fb;
    padding: 0.5rem;
    border-radius: 0.3rem;
  }

  .addIcon:hover {
    background-color: var(--cyan);
  }

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .textAreaBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .textEditor {
      background-color: #f6f6fb;
    }

    .textAreaLabel {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-family: var(--font);
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
`;

const RadioLabel = styled.label`
  display: flex;
  margin-right: 10px;
  margin-left: 0.5rem;
`;

const RadioButton = styled.input``;
