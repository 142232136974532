import React, { useState } from "react";
import styled from "styled-components";

const EmpCommonButton = ({ text, className = "", func, width }) => {
  return (
    <Button
      onClick={func}
      style={{ width: width ? width : "" }}
      className={className}
    >
      {text}
    </Button>
  );
};

export default EmpCommonButton;

const Button = styled.button`
  background-color: var(--cyan);
  color: var(--white);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: center;

  &.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;
