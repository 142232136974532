import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { scheduleByCandidate } from "../../../functions/api/employers/schedule/scheduleByCandidate";
import { getInviteDetails } from "../../../functions/api/employers/schedule/getInviteDetails";
import moment from "moment-timezone";
import { persistor } from "../../../store";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import TimeSlot from "../EmployerDashboard/commonComponents/TimeSlot";
import dayjs from "dayjs";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .infoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .redirectBtn {
    background-color: var(--cyan);
    color: var(--white);
    text-decoration: none;
    padding: 0.5rem 0.8rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .mainBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .detailBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    min-width: 20rem;
  }
`;

const ScheduleButton = styled.button`
  background-color: var(--cyan);
  color: #fff;
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
`;

const SlotSelection = () => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));
  const dispatch = useDispatch();

  const [inviteDetails, setInviteDetails] = useState(null);
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await getInviteDetails(token, accessToken);
        setInviteDetails(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getDetails();
  }, []);

  const handleScheduleInterview = () => {
    const dateTime = moment(
      inviteDetails?.slotDate +
        "T" +
        selectedTimeSlot.$H +
        ":" +
        (selectedTimeSlot.$m === 0 || selectedTimeSlot.$m === 5
          ? "0" + selectedTimeSlot.$m
          : selectedTimeSlot.$m) +
        ":" +
        "00.000"
    )
      .utc()
      .format("YYYY-MM-DD HH:mm");

    if (
      dayjs(new Date()).$H * 60 + dayjs(new Date()).$m + 31 >
      selectedTimeSlot?.$H * 60 + selectedTimeSlot?.$m
    ) {
      toast.warning(
        "Slot should be 30 minutes after current date and time",
        8000
      );
      return;
    }

    const date = dateTime?.slice(0, 10);
    const time = dateTime?.slice(11);
    const scheduleTest = async () => {
      try {
        const res = await scheduleByCandidate(
          {
            slot: date + "T" + time,
            token: token,
          },
          accessToken
        );

        if (res) {
          localStorage.removeItem("token");
          localStorage.removeItem("key");
          toast.success(
            `Scheduled interview on ${inviteDetails.slotDate} at ${
              selectedTimeSlot?.$H +
              ":" +
              (selectedTimeSlot.$m === 0 || selectedTimeSlot.$m === 5
                ? "0" + selectedTimeSlot.$m
                : selectedTimeSlot.$m)
            }`
          );
          navigate("/dashboard/jobseeker");
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    scheduleTest();
  };

  const handleRedirect = () => {
    persistor.purge();
    dispatch(logout());
  };

  return (
    <PageContainer>
      <h2>Schedule Interview</h2>
      {inviteDetails ? (
        <div className="mainBox">
          <div className="detailBox">
            <span>Email: {inviteDetails?.email}</span>
            <span>Jd Id: {inviteDetails?.jdId}</span>
            <span>Slot Date: {inviteDetails?.slotDate}</span>
            <span>Test Type: {inviteDetails?.testType}</span>
          </div>

          <TimeSlot
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
          />

          <ScheduleButton onClick={handleScheduleInterview}>
            Schedule Interview
          </ScheduleButton>
        </div>
      ) : (
        <div className="infoBox">
          <h2>Login with same email you got invite on to schedule</h2>
          <Link onClick={handleRedirect} to={"/login"} className="redirectBtn">
            Redirect to Login Page
          </Link>
        </div>
      )}
    </PageContainer>
  );
};

export default SlotSelection;
