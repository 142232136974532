import axios from "axios"
import {toast} from "react-toastify"


export const reset = async (confirm,password,email,token,clientCode = 'otohire') => {

    const requestData = {
    cnfpwd: confirm,
    email,
    pwd:password,
    token
    };

    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          "x-client-code": clientCode
        }
      };

 console.log(requestData)
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/resetpwd`,requestData,config);
        console.log('Data:', response.data);
        toast.success(response.message);
        return response.data;
      } catch (error) {
        throw error;
      }
}