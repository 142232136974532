import React from 'react';

const SkillProgressBar = ({ score }) => {

    const finalScore = score === 'Novice' ? 1 : score === 'Beginner' ? 2 : score === 'Skillful' ? 3 : score === 'Experienced' ? 4 : score === 'Expert' ? 5 : score === 'Native' ? 2 : score === 'Proficient' ? 4 : score === 1 ? 1 : score === 2 ? 2 : score === 3 ? 3 : score === 4 ? 4 : score === 5 ? 5 : 5
    const filledWidth = (finalScore / 5) * 100; // Calculate the filled width based on the skill score

    const progressBarStyle = {
        width: '100%',
        height: '0.3rem',
        background: 'lightgrey',
        position: 'relative',
        marginLeft: '0.5rem'
    };

    const filledStyle = {
        width: `${filledWidth}%`,
        height: '100%',
        background: 'grey',
        position: 'absolute',
        transition: 'width 0.5s ease-in-out',
    };

    return (
        <div style={progressBarStyle}>
            <div style={filledStyle}></div>
        </div>
    );
};

export default SkillProgressBar;
