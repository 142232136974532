import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled, { css } from "styled-components";
import visibleIcon from "../../../../assets/icons/visible.png";
import { useSelector } from "react-redux";
import TableSearchBar from "../commonComponents/TableSearchBar";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { getJobApplicationsByJdId } from "../../../../functions/api/jobApplication/getJobApplicationsByJdId";
import {
  dateConversion,
  timeZoneConversion,
} from "../../../../utils/timeZoneConversation";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import iIcon from "../../../../assets/icons/iIcon.png";
import { getEmployer } from "../../../../functions/api/employers/profile/getEmployer";
import { getJdByClientCode } from "../../../../functions/api/employers/getJdByClientCode";
import downloadIcon from "../../../../assets/icons/Profile/download.png";
import { toast } from "react-toastify";

function Row(props) {
  const { row, jdId, index, rowsLength } = props;
  const [agencyData, setAgencyData] = useState({});
  const accessToken = useSelector(
    (state) => state?.auth?.userData?.accessToken
  );
  const clientCode = useSelector(
    (state) => state?.auth?.userData?.user?.clientCode
  );
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }

      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );
      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", `${name}`);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };

  const handleGetAgencyInfo = async (agencyCode) => {
    try {
      const res = await getEmployer( agencyCode);
      if (res) setAgencyData(res.data);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell align="center" className="tableCell">
          {row?.seekerName}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.seekerContact}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.seekerEmail}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.appliedAt && timeZoneConversion(row?.appliedAt)}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.appliedBy}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.appliedBy == "AGENCY" ? (
            <BoxRow isLast={index >= rowsLength - 2}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.2rem",
                }}
              >
                <p>{row?.agencyCode}</p>
                <img
                  src={iIcon}
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  className={`i-icon ${
                    openDropdownIndex === index ? "active" : ""
                  }`}
                  onClick={() => {
                    if (openDropdownIndex === index) {
                      closeAllDropdowns();
                    } else {
                      openDropdown(index);
                      handleGetAgencyInfo(row?.agencyCode);
                    }
                  }}
                />
              </div>
              <div
                className={`dropdown-content ${
                  openDropdownIndex === index ? "open" : ""
                }`}
                ref={dropdownRef}
              >
                <p>
                  <b>Agency Name:</b> <br />
                  {agencyData?.companyName}
                </p>
                <p>
                  <b>Co-ordinator Name:</b> <br />
                  {agencyData?.coOrdinatorName}
                </p>
              </div>
            </BoxRow>
          ) : (
            "NA"
          )}
        </TableCell>
        <TableCell align="center" className="tableCell">
          ...
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          <div
            style={{
              display: "flex",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={downloadIcon}
              className="icon"
              onClick={() =>
                handleDownload(row?.resume?.id, row?.resume?.srcFilename)
              }
            />
          </div>
        </TableCell>
        {/* <TableCell align="center" className="tableCell">
          <button className="btn1">Schedule Interview</button>
        </TableCell> */}
      </TableRow>
    </React.Fragment>
  );
}

const EmpApplicationCandidatesList = ({ page, setPage }) => {
  const [tableRows, setTableRows] = useState([]);
  const [total, setTotal] = useState(0);
  const dropdownRef2 = useRef(null);
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const [jdBox, setJdBox] = useState(false);
  const [jdInfo, setJdInfo] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allCandidateData, setAllCandidateData] = useState([]);

  const [page1, setPage1] = useState(1);
  const [size, setSize] = useState(5);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getJobApplicationsByJdId(
          page.jdId,
          accessToken,
          clientCode,
          page1,
          size
        );
        setTotal(res?.data?.total);
        setTableRows(res?.data?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, [page1, size]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getJobApplicationsByJdId(
          page.jdId,
          accessToken,
          clientCode,
          1,
          1000000,
          null
        );
        setTotal(res?.data?.total);
        setAllCandidateData(res?.data?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (searchValue?.trim()) {
      setSearch(true);
      setFilteredData(() =>
        allCandidateData?.filter((item) =>
          item?.seekerEmail?.toLowerCase()?.includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setSearch(false);
    }
  }, [searchValue]);

  console.log(allCandidateData);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage1(1);
  };

  const handlePageChange = (change) => {
    if (change && page1 < Math.ceil(+total / +size)) {
      setPage1((prev) => prev + 1);
    } else if (!change && page1 > 1) {
      setPage1((prev) => prev - 1);
    }
  };

  const handleGetJdInfo = async (jdId) => {
    try {
      const res = await getJdByClientCode(jdId, accessToken, clientCode);
      if (res) setJdInfo(res?.data);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <span className="title">
            <BoxRow>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.2rem",
                }}
              >
                <p>Application Lists for JD ID:- {page.jdId.toUpperCase()}</p>
                <img
                  src={iIcon}
                  style={{
                    width: "0.8rem",
                    height: "0.8rem",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  className={`i-icon ${jdBox ? "active" : ""}`}
                  onClick={() => {
                    if (jdBox) {
                      setJdBox(false);
                    } else {
                      setJdBox(true);
                      handleGetJdInfo(page?.jdId);
                    }
                  }}
                />
              </div>
              <div
                className={`dropdown-content ${jdBox ? "open" : ""}`}
                ref={dropdownRef2}
              >
                <p>
                  <b>JD Title:</b> <br />
                  {jdInfo?.title}
                </p>
                <p>
                  <b>Hiring Manager:</b> <br />
                  {jdInfo?.hiringManager}
                </p>
              </div>
            </BoxRow>
          </span>
          <button
            className="btn1"
            onClick={() => setPage({ index: 1, jdId: null })}
          >
            Back to Applications
          </button>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
          <span className="headerText">Total Applications: {total}</span>
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">
                Candidate Name
              </TableCell>
              <TableCell align="center" className="tableCell">
                Contact
              </TableCell>
              <TableCell align="center" className="tableCell">
                Email
              </TableCell>
              <TableCell align="center" className="tableCell">
                Applied at
              </TableCell>
              <TableCell align="center" className="tableCell">
                Source
              </TableCell>
              <TableCell align="center" className="tableCell">
                Agency Code
              </TableCell>
              <TableCell align="center" className="tableCell">
                Ai Score
              </TableCell>
              <TableCell align="center" className="tableCell">
                Download Resume
              </TableCell>
              {/* <TableCell align="center" className="tableCell">
                Actions
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {search
              ? filteredData?.map((row, index) => (
                  <Row
                    key={row?.id}
                    row={row}
                    jdId={page?.jdId}
                    index={index}
                    rowsLength={filteredData?.length}
                  />
                ))
              : tableRows?.map((row, index) => (
                  <Row
                    key={row?.id}
                    row={row}
                    jdId={page?.jdId}
                    index={index}
                    rowsLength={tableRows?.length}
                  />
                ))}
          </TableBody>
        </Table>

        {!search && (
          <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page1}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>
        )}
      </TableContainer>
    </Content>
  );
};

export default EmpApplicationCandidatesList;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;

  .headerText {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .skillBox {
    position: relative;
    width: 35%;
    display: flex;
    align-items: center;
    background-color: #ececec;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;

    img {
      width: 1.2rem;
    }
  }

  .skillInput {
    flex-grow: 1;
    border: none;
    height: 1rem;
    width: 50%;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
  }
`;

const Content = styled.div`
  margin: 1rem 0% 2rem 0%;
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font);
  color: var(--color);

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .icon {
    width: 0.8rem;
    height: 0.8rem;
    cursor: pointer;
    border: 0.075rem solid grey;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }

  .colored {
    background-color: #ececec;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .titleBox {
      width: 99%;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .btn1 {
    padding: 0.5rem 0.8rem;
    background-color: var(--cyan);
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .iIcon {
    width: 1.1rem;
    cursor: pointer;
  }
`;
const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .i-icon {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
    botton: 0.3rem;
    ${(props) =>
      props.isLast &&
      css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown-content span:hover {
    background: #e2dbdb36;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;
