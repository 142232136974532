

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ConfigurableModal from '../components/Interviews/SeekerDashboard/seekerCommonComponents/ConfigurableModal';
import { useNavigate } from 'react-router';
import useScreenCapture from '../components/Interviews/ProctoringHooks/useScreenCapture';
import useWebcamCapture from '../components/Interviews/ProctoringHooks/useWebcamCapture';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeAllFailedUploads } from '../slices/uploadSnapSlice';


const ScreenCaptureComponent = () => {
    const { capturedImages, showModal, setShowModal, handleScreenShotAccess, stopCapturingScreenshots, permissionGranted } = useScreenCapture();
    const { webcamShots, webcamAccessGranted, handleWebcamAccess, stopCapturingWebcamScreenshots } = useWebcamCapture();
    const navigate = useNavigate();
    const uploadSnap = useSelector((state) => state.uploadSnap.failedScreenshotUploadsQueue);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(removeAllFailedUploads());
    }, [])


    const handleGoBack = () => {
        navigate('/dashboard/jobseeker');
        toast.success("Successfully navigated to dashboard");
    }

    const handleAccessButtonClick = () => {
        if (!webcamAccessGranted) {
            handleWebcamAccess();
        }
        handleScreenShotAccess();
    };


    return (
        <div>
            {showModal && (
                <ConfigurableModal open={showModal} setOpen={setShowModal} component={<div>
                    <h3>Allow Screen Sharing Access?</h3>
                    <button onClick={() => {
                        handleAccessButtonClick();
                    }}>Allow Access</button>
                    <button onClick={handleGoBack}>Go Back to Dashboard</button>
                </div>} style={{ width: "90%", height: '85%' }} />
            )}
            <h1>Screen Capture Component</h1>
            <button onClick={() => {
                handleAccessButtonClick();
            }}>Start Capturing</button>
            <button onClick={() => {
                stopCapturingScreenshots();
                stopCapturingWebcamScreenshots();
            }}>Stop Capturing</button>

            <div>
                <h2>Captured Images</h2>
                <Box>
                    {capturedImages.map((image, index) => (
                        <img key={index} src={URL.createObjectURL(image)} alt={`Screenshot ${index}`} />
                    ))}
                </Box>
            </div>

            <div>
                <h2>Webcam Images</h2>
                <Box>
                    {webcamShots.map((image, index) => (
                        <img key={index} src={URL.createObjectURL(image)} alt={`Screenshot ${index}`} />
                    ))}
                </Box>

            </div>
        </div>
    );
}

export default ScreenCaptureComponent;


const Box = styled.div`
display: flex;
flex-flow: row wrap;


img {
    width: 15rem;
}


`


