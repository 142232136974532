import HTTP from "../../../backendApi";

// export const getAgencyMetrics = () => {
//   return HTTP.get(`/employers/agencyMetrics`, {
//     useClientCode: true,
//   })
//     .then((response) => {
//       if (response.data) {
//         return response.data;
//       }
//     })
//     .catch((err) => {
//       throw err;
//     });
// };

export const getAgencyMetrics = async () => {
  try {
    const response = await HTTP.get(`/employers/agencyMetrics`, {
      useClientCode: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
