import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { auth } from "../api/authApis";
import { auth } from "../functions/api/authentication/auth";

export const performLogin = createAsyncThunk(
  "auth/performLogin",
  async ({ password, email, clientCode }) => {
    try {
      const response = await auth(password, email, clientCode);
      if (response.response?.data?.status == "FAILED") {
        throw new Error(JSON.stringify(response?.response?.data));
      }
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.notify?.message || error?.message);
    }
  }
);

const initialState = {
  userData: null,
  userType: null,
  status: "idle",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.userData = null;
      state.userType = null;
      state.status = "idle";
      state.error = null;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    performLoginWithGoogle: (state, action) => {
      state.userData = action.payload;
      state.status = "succeeded";
    },
    performSignUpWithGoogle: (state, action) => {
      state.userData = action.payload;
      state.status = "succeeded";
    },
    performLoginWithLinkedIn: (state, action) => {
      state.userData = action.payload;
      state.status = "succeeded";
    },
    performSignUpWithLinkedIn: (state, action) => {
      state.userData = action.payload;
      state.status = "succeeded";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(performLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(performLogin.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.status = "succeeded";
      })
      .addCase(performLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = "Username or password is incorrect.";
      });
  },
});
export const {
  logout,
  setUserType,
  performLoginWithGoogle,
  performSignUpWithGoogle,
  performLoginWithLinkedIn,
  performSignUpWithLinkedIn,
} = authSlice.actions;
export default authSlice.reducer;
