import axios from "axios";


export const activate = async (accessToken,clientCode = 'otohire') => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-code': clientCode
    }
  }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/activate/${accessToken}`,config);
        console.log('Data:', response.data);
        return response.data;
      } catch (error) {
        throw error;
      }
}
