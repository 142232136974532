import React, { useEffect, useState } from 'react'
import CommonDialog from '../../../commonComponents/CommonDialog'
import DeleteDialogContent from '../../../commonComponents/DeleteDialogContent'
import styled from 'styled-components'
import { getAllResumes } from '../../../../functions/api/jobSeekers/getAllResumes'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addResume } from '../../../../functions/api/jobSeekers/addResume'
import { deleteResume } from '../../../../functions/api/jobSeekers/deleteResume'
import { getBlobData } from '../../../../functions/api/resume/getBlobData'
import addIcon from '../../../../assets/icons/addIcon.png'
import downloadIcon from '../../../../assets/icons/Profile/download.png'
import deleteIcon from '../../../../assets/icons/delete.png'


const ProfileResumes = () => {

    const profileId = useSelector((state) => state.auth.userData?.user?.profileId);
    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData?.user?.clientCode
    );
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [resumeId, setResumeId] = useState('');

    const [resumeArr, setResumeArr] = useState([]);
    const [resumeUploadTrigger, setResumeUploadTrigger] = useState(false);


    useEffect(() => {
        const getAllResume = async () => {
            try {
                const allResumeRes = await getAllResumes(profileId, accessToken);

                if (allResumeRes) {
                    setResumeArr(allResumeRes?.data);
                }
            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
        getAllResume();

    }, [resumeUploadTrigger])

    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);

                const uploadRes = await addResume(profileId, formData, accessToken);

                if (uploadRes) {
                    toast.success("Resume uploaded successfully", 5000);
                    setResumeUploadTrigger(!resumeUploadTrigger);
                }

            } catch (error) {
                const errMsg =
                    error?.response?.data?.notify?.message ||
                    "An error occurred. Please try again.";
                toast.error(errMsg, 8000);
            }
        }
    };


    const handleResumeDelete = async (id) => {
        try {

            const res = await deleteResume(profileId, id, accessToken);

            if (res) {
                toast.success('Resume deleted successfully');
                setOpenDeleteDialog(false);
                setResumeUploadTrigger(!resumeUploadTrigger);
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    }

    const handleDownload = async (id, name) => {
        try {
            if (!id) {
                toast.warning("No resume found or invalid resumeId", 5000);
                return;
            }
            const res = await getBlobData(
                `api/media/downloadById?fileType=resume&id=${id}`,
                accessToken,
                clientCode
            );

            const a = document.createElement("a");
            a.href = res;
            a.setAttribute("download", `${name}`);
            a.click();
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg, 8000);
        }
    };

    return (
        <ResumeBox>
            <CommonDialog
                open={openDeleteDialog}
                handleClose={() => setOpenDeleteDialog(false)}
                component={
                    <DeleteDialogContent
                        handleClose={() => setOpenDeleteDialog(false)}
                        text="Resume"
                        handleDelete={handleResumeDelete}
                        deleteId={resumeId}
                    />
                }
            />
            <span className='mainTitle'>
                Resume
            </span>
            <span className='title'>Add upto 3 Resumes</span>

            <div className='resumeChildBox'>
                {
                    resumeArr?.map((resume) => (
                        <div className='resumeCard'>
                            {resume?.srcFilename}

                            <div className='rightBox'>
                                <img src={downloadIcon} className='icon' onClick={() => handleDownload(resume?.id, resume?.srcFilename)} />
                                <div className='horizontalLine'></div>
                                <img src={deleteIcon} className='icon' onClick={() => {
                                    setOpenDeleteDialog(true)
                                    setResumeId(resume?.id)
                                }} />
                            </div>
                        </div>
                    ))
                }

                {
                    resumeArr?.length < 3 &&

                    <div className='addResumeBox'>
                        <Label htmlFor='resumeInput'><img src={addIcon} /></Label>
                        <input
                            id='resumeInput'
                            type="file"
                            onChange={handleFileChange}
                            accept='.pdf, .doc'
                            style={{ display: 'none' }}
                        />
                    </div>
                }
            </div>
        </ResumeBox>
    )
}

export default ProfileResumes

const ResumeBox = styled.div`
width: 90%;
padding: 1rem 1.25%;
display: flex;
flex-direction: column;
gap: 1rem;
justify-content: center;
border: 0.07rem solid lightgrey;
border-radius: 0.5rem;
background-color: #FEFFFE;
margin: 0 auto;



    .mainTitle {
        font-size: 1.2rem;
        font-weight: 600;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .title {
        font-size: 1rem;
        font-weight: 500;
        margin-top: -0.5rem;
    }

    .resumeChildBox {
        display: flex;
        gap: 1rem;
        align-items: start;
        flex-direction: column;

        .deleteIcon {
            border: 0.075rem solid grey;
            border-radius: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;

            img {
                width: 0.9rem;
                height: 0.9rem;
            }
        }

.resumeCard {
  display: flex;
  box-sizing: border-box;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  min-width: 40%;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border: 0.05rem solid lightgrey;
  padding: 0.5rem 1rem;

  .rightBox {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 1.5rem;
    
    .icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    .horizontalLine {
      height: 100%;
      width: 0.01rem;
      background-color: var(--color);
    }
  }

        }


        .addBtn {
            width: 2rem;
            cursor: pointer;

            img {
                width: 100%;
            }
        }
    }
}

`

const Label = styled.label`
  font-weight: 600;
  margin: 0.7rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;


  img {
    width: 2rem;
  }
  
  span {
    color: var(--color);
    
  }
`;