import React from 'react';
import { triggerFullscreen, useFullScreenDetection } from './useFullScreenDetection';
import { useTabFocusDetection } from './useTabFocusDetection';
import { useCopyPasteDisable } from './useCopyPasteDisable';
import { useDisableContextMenu } from './useDisableContextMenu';
import { useSelectionDisable } from './useSelectionDisable';

export function useProctoring({
    preventTabSwitch = false,
    forceFullScreen = false,
    preventContextMenu = false,
    preventUserSelection = false,
    preventCopyPaste = false,
}) {
    useDisableContextMenu({ disabled: !preventContextMenu });

    useCopyPasteDisable({ disabled: !preventCopyPaste });
    useSelectionDisable({ disabled: !preventUserSelection });

    const { tabFocusStatus } = useTabFocusDetection({
        disabled: !preventTabSwitch,
    });


    const { fullScreenStatus } = useFullScreenDetection({
        disabled: !forceFullScreen,
    });

    return {
        fullScreen: { status: fullScreenStatus, trigger: triggerFullscreen },
        tabFocus: { status: tabFocusStatus },
    };
}
