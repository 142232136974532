import { TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  div {
    width: 100%;
  }
`;
const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.variant === "confirm" ? "#4CAF50" : "#f44336"};
  color: #fff;
  font-weight: bold;

  &:hover {
    background-color: ${(props) =>
      props.variant === "confirm" ? "#45a049" : "#d32f2f"};
  }
`;
export const ModalBox = ({
  values,
  onConfirm,
  setActions,
  action,
  resetActions,
}) => {
  const handleChange = (e, text) => {
    const reasons = {
      cancel: "cancellationReason",
      NOT_SELECTED: "moveOutReason",
      default: "holdReason",
    };

    const key = reasons[text] || reasons.default;

    setActions((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const onClose = () => {
    resetActions();
  };
  return (
    <ModalBackground>
      <ModalContainer>
        <h3>Are you sure you want to {action?.type.toLowerCase()} ?</h3>
        <div>
          <TextField
            label="reason"
            variant="outlined"
            value={values}
            onChange={(e) => handleChange(e, action?.type)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#18E2D3",
                  color: "#18E2D3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#18E2D3",
                  color: "#18E2D3",
                },
              },
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#18E2D3",
                },
              },
            }}
          />
        </div>
        <div>
          <Button onClick={onClose}>No</Button>
          <Button variant="confirm" onClick={onConfirm}>
            Yes
          </Button>
        </div>
      </ModalContainer>
    </ModalBackground>
  );
};

// onClick={() =>
//   handleTrackerUpdate("NOT_SELECTED", {
//     jdId: "",
//     resumeIds: [row?.resumeId],
//     status: "NOT_SELECTED",
//     trackerIds: [row.id],
//   })
// }
