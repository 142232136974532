import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    failedWebcamUploadsQueue: [],
    failedScreenshotUploadsQueue: [],
};

const uploadSnapSlice = createSlice({
    name: 'uploadSnap',
    initialState,
    reducers: {
        addFailedWebcamUpload: (state, action) => {
            const { id, image } = action.payload;
            if (!state.failedWebcamUploadsQueue.some(upload => upload.id === id)) {
                state.failedWebcamUploadsQueue = [...state.failedWebcamUploadsQueue, {id, image}]
            }
        },
        addFailedScreenshotUpload: (state, action) => {
            const { id, image } = action.payload;
            if (!state.failedScreenshotUploadsQueue.some(upload => upload.id === id)) {
                state.failedScreenshotUploadsQueue = [...state.failedScreenshotUploadsQueue, {id, image}]
            }
        },
        removeFailedWebcamUpload: (state, action) => {
            const { id } = action.payload;
            const index = state.failedWebcamUploadsQueue.findIndex(upload => upload.id === id);
            if (index !== -1) {
                state.failedWebcamUploadsQueue.splice(index, 1);
            }
        },
        removeFailedScreenshotUpload: (state, action) => {
            const { id } = action.payload;
            const index = state.failedScreenshotUploadsQueue.findIndex(upload => upload.id === id);
            console.log(id);
            console.log(index);
            if (index !== -1) {
                state.failedScreenshotUploadsQueue.splice(index, 1);
            }
        },
        removeAllFailedUploads: (state) => {
            state.failedWebcamUploadsQueue = [];
            state.failedScreenshotUploadsQueue = [];
        },
    },
});

export const {
    addFailedWebcamUpload,
    addFailedScreenshotUpload,
    removeFailedWebcamUpload,
    removeFailedScreenshotUpload,
    removeAllFailedUploads
} = uploadSnapSlice.actions;

export default uploadSnapSlice.reducer;
