import axios from "axios"




export const createInterview = async (payload, accessToken) => {
  // const token = JSON.parse(localStorage.getItem("token"));

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }
};
  

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/interviews`,payload,config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
      } catch (error) {
        throw error;
      }
}

