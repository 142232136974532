import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getQuestions } from "./../../../functions/api/employers/employerTest/getQuestions";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 1rem 1.7rem 1rem 1.7rem;
  border-radius: 0.5rem;
  width: 80%;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  margin-top: 4rem;
  z-index: 1000;

  .modalTitle {
    font-family: Quicksand, sans-serif;
    font-size: 1rem;
    font-weight: 550;
    margin-bottom: 1rem;
    display: block;
  }
`;
const ScrollableContent = styled.div`
  max-height: 77vh;
  overflow-y: auto;

  .tableBox {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .tableHead {
    background-color: #d1fff0;

    .tableCell {
      font-size: 0.9rem;
      font-family: Quicksand, sans-serif;
      font-weight: 500;
      color: #333;
      padding: 16px;
      border-bottom: none;
      white-space: nowrap;
    }
  }

  .tableBody {
    .tableRow {
      &:nth-of-type(odd) {
        background-color: #ececec;
      }
    }

    .tableCell {
      font-size: 0.8rem;
      font-family: Quicksand, sans-serif;
      font-weight: 400;
      color: #333;
      padding: 16px;
      border-bottom: none;
    }
  }

  .addButton {
    font-family: Quicksand, sans-serif;
    background-color: white;
    color: black;
    border: 1.5px solid #18e2d3;
    padding: 0.3rem 0.6rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8rem;
    white-space: nowrap;
    // display: inline-block;
  }
  .addButton:hover {
    background-color: #18e2d3;
    color: white;
    font-weight: 600;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
`;

function QuestionModal({
  isOpen,
  onClose,
  accessToken,
  clientCode,
  onSelectQuestions,
}) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getAllQuestions = async () => {
      try {
        const res = await getQuestions(accessToken, clientCode);
        if (res) {
          setQuestions(res);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg);
      }
    };
    if (isOpen) {
      getAllQuestions();
    }
  }, [isOpen, accessToken, clientCode]);

  const handleSelectQuestion = (question) => {
    onSelectQuestions(question);
    toast.success("Question Added");
  };

  if (!isOpen) return null;

  return (
    <Modal>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <span className="modalTitle">Select Questions to Add</span>
        <ScrollableContent>
          <TableContainer component={Paper} className="tableBox">
            <Table aria-label="available questions table">
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell align="left" className="tableCell">
                    Question
                  </TableCell>{" "}
                  <TableCell align="left" className="tableCell">
                    Question Type
                  </TableCell>
                  <TableCell align="left" className="tableCell">
                    Category
                  </TableCell>
                  <TableCell align="left" className="tableCell">
                    Difficulty Level
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {questions.map((question) => (
                  <TableRow key={question.id} className="tableRow">
                    <TableCell align="left" className="tableCell">
                      {question.questionText}
                    </TableCell>{" "}
                    <TableCell align="left" className="tableCell">
                      {question.questionType}
                    </TableCell>
                    <TableCell align="left" className="tableCell">
                      {question.category}
                    </TableCell>
                    <TableCell align="left" className="tableCell">
                      {question.difficultyLevel}
                    </TableCell>
                    <TableCell align="center" className="tableCell">
                      <button
                        className="addButton"
                        onClick={() => handleSelectQuestion(question)}
                      >
                        Add Question
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollableContent>
      </ModalContent>
    </Modal>
  );
}

export default QuestionModal;
