import axios from "axios"


export const addUserSettings = async (payload, accessToken, clientCode) => {

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'X-Client-Code': clientCode,
  }
};
  const requestData = [payload];

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/userSettings`,requestData,config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
      } catch (error) {
        throw error;
      }
}

