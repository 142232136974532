import { useCallback, useEffect } from "react";

export function useCopyPasteDisable({ disabled } = { disabled: false }) {
    const handleCopyPaste = useCallback((e) => {
        e.preventDefault();
    }, []);

    useEffect(() => {
        const removeListener = () => {
            document.removeEventListener("copy", handleCopyPaste);
            document.removeEventListener("paste", handleCopyPaste);
        };

        if (disabled) return removeListener;

        document.addEventListener("copy", handleCopyPaste);
        document.addEventListener("paste", handleCopyPaste);

        return removeListener;
    }, [disabled, handleCopyPaste]);

    return;
}
