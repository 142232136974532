import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editCandidate } from "./../../../functions/api/resume/editCandidate";
import styled from "styled-components";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { TextField } from "@mui/material";
import { getTagsConfig } from "./../../../functions/api/globalFunctions/getTagsConfig";
import browseIcon from "./../../../assets/icons/browse.png";

const Container = styled.div`
  width: 90%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 4rem;
`;

const Component = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  font-size: 0.9rem;
  background-color: var(--white);

  .updateBtn {
    padding: 0.5rem 0.8rem;
    background-color: var(--cyan);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 1rem;
    align-self: center;
    font-family: var(--font);
  }

  .title {
    margin-bottom: 2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    gap: 1rem;

    .MuiFormHelperText-root {
      font-size: 0.6rem;
    }

    span {
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    @media (max-width: 2000px) {
      #outlined-basic {
        padding: 0.75rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1700px) {
      #outlined-basic {
        padding: 0.85rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1350px) {
      #outlined-basic {
        padding: 0.95rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1200px) {
      #outlined-basic {
        padding: 1rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    .resumeBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
      margin-top: 0rem;
    }
  }
  #tags-standard {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
  }
`;

const Label = styled.label`
  font-weight: 600;
  margin: 0.7rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2.5rem;
  }

  span {
    color: var(--color);
  }
`;

const FileInput = styled.input`
  margin-bottom: 0rem;
  position: absolute;
  left: -9999px;
`;

const UpdateCandidate = ({
  candidate,
  handleClose,
  setCandidateTrigger,
  candidateTrigger,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    source: "",
    dob: null,
    yoe: "",
    position: "",
    nationality: "",
    currentLocation: "",
    education: "",
    // remarks:"",
    highestDegree: "",
    major: "",
    yearOfPassing: "",
    grades: "",
    tags: [],
  });

  const [selectedFileName, setSelectedFileName] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [contactTouched, setContactTouched] = useState(false);
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFileName(file?.name);
    }
  };
  useEffect(() => {
    if (candidate) {
      setFormData({
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        contact: candidate.contact,
        source: candidate.source,
        dob: dayjs(candidate.dob),
        yoe: candidate.yoe,
        position: candidate.position,
        nationality: candidate.nationality,
        currentLocation: candidate.currentLocation,
        education: candidate.education,
        // remarks: candidate.remarks,
        highestDegree: candidate.highestDegree,
        major: candidate.major,
        yearOfPassing: candidate.yearOfPassing,
        grades: candidate.grades,
        tags: candidate.tags ? candidate.tags.split(",") : [],
      });
      setSelectedTags(candidate.tags ? candidate.tags.split(",") : []);
    }
  }, [candidate]);
  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await getTagsConfig(accessToken, clientCode);
        if (res) {
          setAllTags(res?.data?.value?.split(","));
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg, 8000);
      }
    };

    getTags();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTagsChange = (_, newTags) => {
    setSelectedTags(newTags);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dobData = moment(formData.dob?.format("YYYY-MM-DD"));
      const updatedData = {
        ...formData,
        dob: dobData?._i,
        tags: selectedTags.join(","),
      };

      const res = await editCandidate(
        candidate.id,
        updatedData,
        accessToken,
        clientCode
      );

      if (res) {
        toast.success("Candidate details updated successfully", 5000);
        setCandidateTrigger(!candidateTrigger);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Container>
      <Component>
        <span className="title">Update Candidate Details</span>
        <ValidatorForm onSubmit={handleSubmit}>
          <TextValidator
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            name="email"
            value={formData.email}
            disabled
            // onChange={handleChange}
            // requirederrorMessages={[
            //   "This field is required",
            //   "Email is not valid",
            // ]}
            // validators={["required", "isEmail"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            type="tel"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            // required
            errorMessages={[
              // "This field is required",
              // "Must be a number",
              // "Must be at least 10 characters long",
              "Number must be at least 10 characters with country code",
            ]}
            validators={[
              "matchRegexp:^\\+?[0-9]{10,}$",
              "required",
              "isNumber",
              contactTouched ? "minStringLength:10" : "minStringLength:0",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              sx={{
                backgroundColor: "#F6F6FB",
                width: "100%",
                "& .MuiInputLabel-root": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
                "& .MuiInputBase-input": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              value={formData.dob}
              onChange={(newValue) =>
                setFormData({ ...formData, dob: dayjs(newValue) })
              }
            />
          </LocalizationProvider>
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Years of Experience
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Year of Experience"
              name="yoe"
              value={formData.yoe}
              onChange={handleChange}
              size="small"
              sx={{
                "& .MuiSelect-select": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                padding: "0rem 0 0.5rem 0",
              }}
            >
              {/* Map over the years of experience options */}
              {[...Array(31)].map((_, index) => (
                <MenuItem key={index} value={index}>
                  {index === 0 ? "Fresher" : `${index} year(s)`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextValidator
            id="outlined-basic"
            label="Position"
            variant="outlined"
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Nationality"
            variant="outlined"
            type="text"
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Current Location"
            variant="outlined"
            type="text"
            name="currentLocation"
            value={formData.currentLocation}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Education"
            variant="outlined"
            type="text"
            name="education"
            value={formData.education}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Highest Degree"
            variant="outlined"
            type="text"
            name="highestDegree"
            value={formData.highestDegree}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Major"
            variant="outlined"
            type="text"
            name="major"
            value={formData.major}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Year of Passing"
            variant="outlined"
            type="text"
            name="yearOfPassing"
            value={formData.yearOfPassing}
            onChange={handleChange}
            // required
            errorMessages={[
              "Must be a number",
              "Must be at least 4 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            // validators={["matchRegexp:^\\d{4}$"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Grade"
            variant="outlined"
            type="text"
            name="grades"
            value={formData.grades}
            onChange={handleChange}
            // required
            errorMessages={[
              "This field is required",
              "Must be at least 3 characters long",
              "Must be less than 30 characters long",
            ]}
            // validators={["required", "minStringLength:3", "maxStringLength:30"]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allTags}
              getOptionLabel={(option) => option}
              onChange={handleTagsChange}
              value={selectedTags}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  sx={{
                    backgroundColor: "#F6F6FB",
                    "& .MuiInputLabel-root": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                    },
                    "& .MuiInputBase-input": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Select Referral/Source
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="source"
              value={formData.source}
              label="Select Referral/Source"
              onChange={handleChange}
              size="small"
              sx={{
                "& .MuiSelect-select": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                padding: "0rem 0 0.5rem 0",
              }}
            >
              <MenuItem value="EmployeeReferral">Employee Referral</MenuItem>
              <MenuItem value="Agency">Agency</MenuItem>
              <MenuItem value="InternalSourcing">Internal Sourcing</MenuItem>
              <MenuItem value="SocialMedia">Social Media</MenuItem>
              <MenuItem value="CompanyPortal">Company Portal</MenuItem>
              <MenuItem value="Other">Others</MenuItem>
            </Select>
          </FormControl>
          <div className="resumeBox">
            <Label htmlFor="input">
              <img src={browseIcon} /> <span>{selectedFileName}</span>
            </Label>
            <FileInput
              id="input"
              type="file"
              accept="*"
              onChange={handleFileChange}
            />
            <span>Select New Resume</span>
          </div>
          <button className="updateBtn" type="submit">
            Update Candidate
          </button>
        </ValidatorForm>
      </Component>
    </Container>
  );
};

export default UpdateCandidate;
