import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { addJobApplication } from "../../../../functions/api/jobApplication/addJobApplication";
import { useSelector } from "react-redux";
import { getAllResumes } from "../../../../functions/api/jobSeekers/getAllResumes";
import { toast } from "react-toastify";
import { addResume } from "../../../../functions/api/jobSeekers/addResume";
import addIcon from "../../../../assets/icons/addIcon.png";
import downloadIcon from "../../../../assets/icons/Profile/download.png";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import { getUserSettings } from "../../../../functions/api/configSettings/getUserSettings";
import { addUserSettings } from "../../../../functions/api/configSettings/addUserSettings";
import uploadIcon from "../../../../assets/icons/uploadBrowseIcon.png";

const Label = styled.label`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border: 0.075rem solid #c4c4c4;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  span {
    color: var(--color);
  }

  .uploadIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

// Styled components
const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: start;
  background: white;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
`;

const ResumeItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1rem;
  border: 0.075rem solid lightgrey;
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: space-between;

  .rightBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 1.5rem;

    .icon {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    .horizontalLine {
      height: 100%;
      width: 0.01rem;
      background-color: var(--color);
    }
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-right: 1rem;
  cursor: pointer;
`;

const SendApplication = styled.button`
  align-self: center;
  background-color: var(--cyan);
  color: white;
  padding: 0.6rem 0.75rem;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-bottom: 0.4rem;
`;

const JobApplicationModal = ({
  jdId,
  empClientCode,
  handleClose,
  setTrigger,
}) => {
  const profileId = useSelector(
    (state) => state.auth.userData?.user?.profileId
  );
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const [resumeUploadTrigger, setResumeUploadTrigger] = useState(false);
  const [resumeId, setResumeId] = useState(null);
  const [resumeData, setResumeData] = useState([]);
  const [defaultResume, setDefaultResume] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res1 = await getAllResumes(profileId, accessToken);
        const res2 = await getUserSettings(accessToken, clientCode);
        if (res1) {
          setResumeData(res1?.data);
        }
        if (res2) {
          setDefaultResume(res2?.data[0]);
          setResumeId(res2?.data[0]?.itemValue);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, [resumeUploadTrigger]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const uploadRes = await addResume(profileId, formData, accessToken);
        if (uploadRes) {
          toast.success("Resume uploaded successfully", 5000);
          setResumeUploadTrigger(!resumeUploadTrigger);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
  };

  const handleCheckboxChange = (id) => {
    setResumeId(id);
  };

  const handleApply = async () => {
    if (resumeId === null) {
      toast.warning("Please select resume before apply");
      return;
    }

    try {
      const payload = {
        clientCode: empClientCode,
        jdId: jdId,
        resumeId: resumeId,
      };
      const res = await addJobApplication(payload, accessToken, clientCode);
      if (res) {
        toast.success("Applied Successfully");
        addDefaultResume(defaultResume?.id);
        setTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }
      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );

      if (res) {
        const a = document.createElement("a");
        a.href = res;
        a.setAttribute("download", `${name}`);
        a.click();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const addDefaultResume = async (id) => {
    try {
      const payload = {
        id: id,
        itemKey: "resumeId",
        itemValue: resumeId,
      };

      if (!payload?.id) {
        delete payload?.id;
      }

      const res2 = await addUserSettings(payload, accessToken, clientCode);
      if (res2) {
        console.log("Default added");
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <ModalWrapper>
      <h3>Select Resume</h3>
      {resumeData?.map((resume) => (
        <ResumeItem key={resume?.id}>
          {resume?.srcFilename}

          <div className="rightBox">
            <img
              src={downloadIcon}
              className="icon"
              onClick={() => handleDownload(resume?.id, resume?.srcFilename)}
            />
            <div className="horizontalLine"></div>
            <Checkbox
              checked={resumeId == resume?.id}
              onChange={() => handleCheckboxChange(resume?.id)}
            />
          </div>
        </ResumeItem>
      ))}
      <div className="addResumeBox">
        <Label htmlFor="resumeInput">
          Upload New Resume <img src={uploadIcon} className="uploadIcon" />
        </Label>
        <input
          id="resumeInput"
          type="file"
          onChange={handleFileChange}
          accept=".pdf, .doc"
          style={{ display: "none" }}
        />
      </div>
      <SendApplication onClick={handleApply}>Send Application</SendApplication>

      {/* Add apply button or other actions as needed */}
    </ModalWrapper>
  );
};

export default JobApplicationModal;
