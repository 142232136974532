import React, { useRef, useState, useEffect } from "react";
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import styled from "styled-components";
import forwardIcon from '../../assets/icons/forward.png'
import backwardIcon from '../../assets/icons/backward.png'

function AudioPlayer({ audioSrc, handleClose }) {
    const [isPlaying, setIsPlaying] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);

    const togglePlay = () => {
        setIsPlaying(prevIsPlaying => !prevIsPlaying);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    useEffect(() => {
        audioSrc.currentTime = 0
    }, [])

    useEffect(() => {
        audioSrc.onended = () => {
            setIsPlaying(false);
            handleClose();
        };
        return () => {
            audioSrc.onended = null; // Cleanup the event listener
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                setCurrentTime(audioSrc.currentTime);
            }
        }, 500);
        return () => clearInterval(interval); // Cleanup the interval
    }, [isPlaying]);

    useEffect(() => {
        if (isPlaying && audioSrc.paused) {
            audioSrc.play();
        } else if (!isPlaying && !audioSrc.paused) {
            audioSrc.pause();
        }
    }, [isPlaying]);

    const forward = () => {
        audioSrc.currentTime += 10;
    }

    const backward = () => {
        audioSrc.currentTime -= 10;
    }

    return (
        <Box>
            <audio src={audioSrc} />
            <div className="box1" onClick={togglePlay}>
                <img src={forwardIcon} onClick={() => forward()} className="audioBtn" />
                <div className="childBox">
                <span className="icon">{isPlaying ? <FaPause /> : <FaPlay />}</span>
                <span>{formatTime(currentTime)}</span>
                </div>
                <img src={backwardIcon} onClick={() => backward()} className="audioBtn" />
            </div>
        </Box>
    );
}

export default AudioPlayer;


const Box = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;


.audioBtn {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.box1 {
    display: flex;
    gap: 2rem;
    align-items: center;
} 

.childBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2rem;

    span {
        font-size: 0.9rem;
        font-weight: 500;
    }
}

.icon {
    cursor: pointer;
}
`