import React from 'react'

const SettingPage = () => {
    return (
        <div>SettingPage</div>
    )
}

export default SettingPage


