
import axios from "axios";

export const getGoogleClientId= async ()=>{
    
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/misc/key-value?key=google.oauth.clientid`);
        return response?.data;
      } catch (error) {
        throw error;
      }
}

