import axios from "axios"
import { toast } from "react-toastify"


export const googleAuthentication = async ( credentials,clientcode) => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-code': clientcode
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/google-login`, credentials, config);
    console.log('Data:', response.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
}