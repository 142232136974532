import axios from "axios"


export const updateResource = async (id, data, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${id}`, data, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

