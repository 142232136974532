import React, { useState, useCallback, useEffect } from 'react';
import { isIOS, isChrome } from 'react-device-detect';
import { toast } from 'react-toastify';

export const triggerFullscreen = () => {
    const methods = [
        'requestFullscreen',
        'webkitRequestFullscreen',
        'webkitRequestFullScreen',
        'mozRequestFullScreen',
        'msRequestFullscreen',
        'msRequestFullScreen',
        'webkitEnterFullscreen',
        'webkitEnterFullScreen',
        'mozRequestFullScreen',
        'webkitRequestFullScreen',
        'webkitRequestFullscreen',
        'msRequestFullscreen',
        'msRequestFullScreen',
        'webkitRequestFullScreen',
        'webkitRequestFullscreen',
        'mozRequestFullScreen',
        'msRequestFullscreen',
        'msRequestFullScreen',
    ];

    const ref = document.body;
    if (ref) {
        for (const name of methods) {
            if (name in ref) {
                ref[name]().catch((err) => {
                    toast.error(err, 8000);
                });
                ref.style.overflowY = 'auto';
                break;
            }
        }
    }
};

export const useFullScreenDetection = ({ disabled } = { disabled: false }) => {
    const [fullScreenStatus, setFullScreenStatus] = useState('pending');

    const changeFullscreenStatus = useCallback(() => {
        const isFullscreen =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement;

        setFullScreenStatus(isFullscreen ? 'on' : 'off');
    }, []);


    const listener = useCallback(
        (e) => {
            if (['F11', 'Escape'].includes(e.key)) {
                e.preventDefault();
            }
        },
        []
    );

    useEffect(() => {
        if (disabled) {
            setFullScreenStatus('pending');
            return;
        }

        if (isChrome && isIOS) {
            setFullScreenStatus('not-supported');
            return;
        }

        changeFullscreenStatus();

        const vendorPrefixes = ['', 'moz', 'webkit', 'ms'];

        const methods = [
            'fullscreenchange',
            'mozfullscreenchange',
            'webkitfullscreenchange',
            'MSFullscreenChange',
            'webkitfullscreenchange',
            'webkitfullscreenchange',
            'webkitfullscreenchange',
            'webkitfullscreenchange',
            'mozfullscreenchange',
            'msfullscreenchange',
        ];


        methods.forEach(method => {
            vendorPrefixes.forEach(prefix => {
                const eventName = prefix + method;
                document.addEventListener(eventName, changeFullscreenStatus);
            });
        });

        window.addEventListener('keydown', listener);

        return () => {
            methods.forEach(method => {
                vendorPrefixes.forEach(prefix => {
                    const eventName = prefix + method;
                    document.removeEventListener(eventName, changeFullscreenStatus);
                });
            });
            window.removeEventListener('keydown', listener);
        };
    }, [changeFullscreenStatus, disabled, listener]);

    return { fullScreenStatus };
};

