import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from './slices/authSlice'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import interviewReducer from "./slices/interviewSlice";
import inviteReducer from './slices/invitationSlice';
import jdReducer from './slices/jdSlice';
import configReducer from './slices/configSlice';
import cvBuilderReducer from './slices/cvBuilderSlice.js'
import jobFilterReducer from './slices/jobFilterSlice.js'
import accordionReducer from './slices/accordionSlice.js'
import uploadSnapReducer from './slices/uploadSnapSlice.js'
import oauthReducer from "./slices/getGoogleClientIdSlice.js";

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    clientId:oauthReducer,
    interview: interviewReducer,
    invite: inviteReducer,
    jd: jdReducer,
    config: configReducer,
    cvBuilder: cvBuilderReducer,
    jobFilter: jobFilterReducer,
    accordion: accordionReducer,
    uploadSnap: uploadSnapReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const store = configureStore({
    reducer: persistedReducer,
    middleware: customizedMiddleware
});

const persistor = persistStore(store);

export { store, persistor };
