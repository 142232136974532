import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { addMapping } from "../../../functions/api/employers/agency/addMapping";
import { useSelector } from "react-redux";
import { getAgencies } from "../../../functions/api/employers/agency/getAgencies";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--white);

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: block;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
    gap: 1rem;

    .inputBox {
      width: 100%;
      display: flex;
      gap: 2rem;
      margin-bottom: 1rem;
    }
    .input {
      width: 50%;
    }

    .MuiFormHelperText-root {
      font-size: 0.6rem;
    }

    @media (max-width: 2000px) {
      #outlined-basic {
        padding: 0.75rem 0.5rem;
        background-color: #f6f6fb;
        font-family: var(--font);
      }
    }

    @media (max-width: 1700px) {
      #outlined-basic {
        padding: 0.85rem 0.5rem;
        background-color: #f6f6fb;
        font-family: var(--font);
      }
    }

    @media (max-width: 1350px) {
      #outlined-basic {
        padding: 0.95rem 0.5rem;
        background-color: #f6f6fb;
        font-family: var(--font);
      }
    }

    @media (max-width: 1200px) {
      #outlined-basic {
        padding: 1rem 0.5rem;
        background-color: #f6f6fb;
        font-family: var(--font);
      }
    }
  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 600;
`;

function ManageAgencyForm({ array, handleClose }) {
  const [mode, setMode] = useState(array[1]);
  const [agencies, setAgencies] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [formData, setFormData] = useState({
    agencyCode: "",
    agencySpocName: "",
    agencySpocEmail: "",
    agencySpocContact: "",
  });

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  useEffect(() => {
    try {
      const getData = async () => {
        const res = await getAgencies(accessToken, clientCode);
        console.log(res);
        if (res) setAgencies(res?.data?.data);
      };
      getData();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }, [trigger]);

  useEffect(() => {
    if (array[0]) {
      setFormData(array[0]);
    }
    setMode(array[1]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addMapping(formData, accessToken, clientCode);
      if (res) {
        toast.success("Mapping added");
        setTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Container>
      <span className="title">Add Agency</span>

      <ValidatorForm onSubmit={handleSubmit} instantFeedback={false}>
        <div className="inputBox">
          <div className="input">
            <TextValidator
              select
              id="outlined-basic"
              label="Agency"
              variant="outlined"
              name="agencyCode"
              value={formData?.agencyCode}
              onChange={handleChange}
              errorMessages={["This field is required"]}
              validators={["required"]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "#F6F6FB",
                    },
                  },
                },
              }}
            >
              {agencies?.map((item) => (
                <MenuItem key={item?.clientCode} value={item?.clientCode}>
                  {item?.companyName}
                </MenuItem>
              ))}
            </TextValidator>
          </div>

          <div className="input">
            <TextValidator
              id="outlined-basic"
              label="Spoc Contact"
              variant="outlined"
              type="tel"
              name="agencySpocContact"
              value={formData?.agencySpocContact}
              onChange={handleChange}
              errorMessages={[
                "This field is required",
                "Must be a number",
                "Must be at least 10 characters long",
              ]}
              validators={["required", "isNumber", "minStringLength:10"]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
          </div>
        </div>

        <div className="inputBox">
          <div className="input">
            <TextValidator
              id="outlined-basic"
              label="Spoc Name"
              variant="outlined"
              type="text"
              name="agencySpocName"
              value={formData?.agencySpocName}
              onChange={handleChange}
              errorMessages={[
                "This field is required",
                "Must be a least 3 characters long",
                "Must be less than 30 chatacters long",
              ]}
              validators={[
                "required",
                "minStringLength:3",
                "maxStringLength:29",
              ]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
          </div>

          <div className="input">
            <TextValidator
              id="outlined-basic"
              label="Spoc Email"
              variant="outlined"
              type="email"
              name="agencySpocEmail"
              value={formData?.agencySpocEmail}
              onChange={handleChange}
              disabled={mode == "edit"}
              errorMessages={["This field is required", "Email is not valid"]}
              validators={["required", "isEmail"]}
              fullWidth
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
          </div>
        </div>

        <Button type="submit">
          {mode == "create" ? "Add Agency" : "Edit Agency"}
        </Button>
      </ValidatorForm>
    </Container>
  );
}

export default ManageAgencyForm;
