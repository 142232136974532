import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone, faPencilSquare, faBriefcase, faAlignCenter, faLanguage, faCertificate, faProjectDiagram, faSchool } from '@fortawesome/free-solid-svg-icons';
import { resumeExampleData } from "../../../../utils/contantData";
import { useSelector } from "react-redux";


const Resume1 = ({ personalDetails, professionalSummary, employmentHistory, education, social, skills, languages, projects, profileImg }) => {

    const mode = useSelector(state => state?.cvBuilder?.mode);

    return (
        <Wrapper>
            <PrintStyles />
            <Header>
                <span className="name">{(!personalDetails?.firstName && mode === 'create') ? resumeExampleData?.personalDetails?.firstName : personalDetails?.firstName} {(!personalDetails?.lastName && mode === 'create') ? resumeExampleData?.personalDetails?.lastName : personalDetails?.lastName}</span>
                <span className="infoItem">
                    {(((personalDetails?.address && mode === 'edit') || (resumeExampleData?.personalDetails?.address && mode === 'create')) || ((personalDetails?.city && mode === 'edit') || (resumeExampleData?.personalDetails?.city && mode === 'create')) || ((personalDetails?.country && mode === 'edit') || (resumeExampleData?.personalDetails?.country && mode === 'create'))) && <FontAwesomeIcon icon={faLocationDot} className="icon" />}
                    {(!personalDetails?.address && mode === 'create') ? resumeExampleData?.personalDetails?.address : personalDetails?.address} {(!personalDetails?.city && mode === 'create') ? resumeExampleData?.personalDetails?.city : personalDetails?.city} {(!personalDetails?.country && mode === 'create') ? resumeExampleData?.personalDetails?.country : personalDetails?.country}
                </span>
                <span className="infoItem">
                    {((personalDetails?.email && mode === 'edit') || (resumeExampleData?.personalDetails?.email && mode === 'create')) && <FontAwesomeIcon icon={faEnvelope} className="icon" />}
                    {(!personalDetails?.email && mode === 'create') ? resumeExampleData?.personalDetails?.email : personalDetails?.email}
                </span>
                <span className="infoItem">
                    {((personalDetails?.phone && mode === 'edit') || (resumeExampleData?.personalDetails?.phone && mode === 'create')) && <FontAwesomeIcon icon={faPhone} className="icon" />}
                    {(!personalDetails?.phone && mode === 'create') ? resumeExampleData?.personalDetails?.phone : personalDetails?.phone}
                </span>
            </Header>

            <Content>
                <div className="column1">
                    <Summary>
                        {((professionalSummary?.length > 0 && mode === 'edit') || (resumeExampleData?.professionalSummary && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faPencilSquare} className="icon2" />
                                Professional Summary
                            </h2>}
                        <span dangerouslySetInnerHTML={{ __html: (!professionalSummary && mode === 'create') ? resumeExampleData?.professionalSummary : professionalSummary }} className='textarea' />
                    </Summary>

                    <Employment>
                        {((employmentHistory?.length > 0 && mode === 'edit') || (resumeExampleData?.employmentHistory?.length > 0 && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faBriefcase} className="icon2" />
                                Employment History
                            </h2>}
                        <div className="experienceMainBox">
                            {
                                (employmentHistory?.length === 0 && mode === 'create') ?
                                    resumeExampleData?.employmentHistory?.map((exp, index) => (
                                        <div className="experienceBox" key={index}>
                                            <div className="experienceBoxTop">
                                                <span className="title">{exp?.jobTitle}</span>
                                                <span className="company">{exp?.companyName}</span>
                                            </div>
                                            <span className="location">{exp?.city}</span>
                                            <span className="date">{exp?.startDate} {exp?.startDate?.length > 0 && 'to'} {exp?.current ? "Present" : exp?.endDate}</span>
                                            <span dangerouslySetInnerHTML={{ __html: exp?.description }} className='textarea' />
                                        </div>
                                    )) : employmentHistory?.map((exp, index) => (
                                        <div className="experienceBox" key={index}>
                                            <div className="experienceBoxTop">
                                                <span className="title">{exp?.jobTitle}</span>
                                                <span className="company">{exp?.companyName}</span>
                                            </div>
                                            <span className="location">{exp?.city}</span>
                                            <span className="date">{exp?.startDate} {exp?.startDate?.length > 0 && 'to'} {exp?.current ? "Present" : exp?.endDate}</span>
                                            <span dangerouslySetInnerHTML={{ __html: exp?.description }} className='textarea' />
                                        </div>
                                    ))
                            }
                        </div>
                    </Employment>

                    <Education>
                        {((education?.length > 0 && mode === 'edit') || (resumeExampleData?.education?.length > 0 && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faSchool} className="icon2" />
                                Education
                            </h2>}
                        <div className="experienceMainBox">
                            {
                                (education?.length === 0 && mode === 'create') ?
                                    resumeExampleData?.education?.map((edu, index) => (
                                        <div className="educationBox" key={index}>
                                            <div className="educationBoxTop">
                                                <span className="title">{edu?.degree}</span>
                                                <span className="date">{edu?.startDate} {edu?.startDate?.length > 0 && 'to'} {edu?.current ? "Present" : edu?.endDate}</span>
                                            </div>
                                            <span className="company">{edu?.university}</span>
                                            <span dangerouslySetInnerHTML={{ __html: edu?.description }} className='textarea' />
                                        </div>
                                    )) : education?.map((edu, index) => (
                                        <div className="educationBox" key={index}>
                                            <div className="educationBoxTop">
                                                <span className="title">{edu?.degree}</span>
                                                <span className="date">{edu?.startDate} {edu?.startDate?.length > 0 && 'to'} {edu?.current ? "Present" : edu?.endDate}</span>
                                            </div>
                                            <span className="company">{edu?.university}</span>
                                            <span dangerouslySetInnerHTML={{ __html: edu?.description }} className='textarea' />
                                        </div>
                                    ))
                            }
                        </div>
                    </Education>

                    <Projects>
                        {((projects?.length > 0 && mode === 'edit') || (resumeExampleData?.projects?.length > 0 && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faProjectDiagram} className="icon2" />
                                Projects
                            </h2>}
                        <div className="experienceMainBox">
                            {
                                (projects?.length === 0 && mode === 'create') ?
                                    resumeExampleData?.projects?.map((project, index) => (
                                        <div className="projectBox" key={index}>
                                            <span className="title">{project?.title}</span>
                                            <span className="date">{project?.startDate} {project?.startDate?.length > 0 && 'to'} {project?.current ? "Present" : project?.endDate}</span>
                                            <span dangerouslySetInnerHTML={{ __html: project?.description }} className='textarea' />
                                        </div>
                                    )) : projects?.map((project, index) => (
                                        <div className="projectBox" key={index}>
                                            <span className="title">{project?.title}</span>
                                            <span className="date">{project?.startDate} {project?.startDate?.length > 0 && 'to'} {project?.current ? "Present" : project?.endDate}</span>
                                            <span dangerouslySetInnerHTML={{ __html: project?.description }} className='textarea' />
                                        </div>
                                    ))
                            }
                        </div>
                    </Projects>
                </div>

                <div className="column2">

                    <Skills>
                        {((skills?.length > 0 && mode === 'edit') || (resumeExampleData?.skills?.length > 0 && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faAlignCenter} className="icon2" />
                                Skills
                            </h2>}
                        <div className="skillBox">
                            {
                                (skills?.length === 0 && mode === 'create') ?
                                    resumeExampleData?.skills?.map((skill, index) => (
                                        (skill?.skill && <span className="skill" key={index}>{skill?.skill}</span>)
                                    )) : skills?.map((skill, index) => (
                                        (skill?.skill && <span className="skill" key={index}>{skill?.skill}</span>)
                                    ))
                            }
                        </div>
                    </Skills>

                    <Language>
                        {((languages?.length > 0 && mode === 'edit') || (resumeExampleData?.languages?.length > 0 && mode === 'create')) &&
                            <h2>
                                <FontAwesomeIcon icon={faLanguage} className="icon2" />
                                Languages
                            </h2>}
                        <div className="skillBox">
                            {
                                (languages?.length === 0 && mode === 'create') ?
                                    resumeExampleData?.languages?.map((lang, index) => (
                                        (lang?.language && <span className="skill" key={index}>{lang?.language}</span>)
                                    )) : languages?.map((lang, index) => (
                                        (lang?.language && <span className="skill" key={index}>{lang?.language}</span>)
                                    ))
                            }
                        </div>
                    </Language>
                </div>
            </Content>
            <br />
            <br />
        </Wrapper>
    );
};

export default Resume1;


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: calc(100% - 2rem);
  min-height: 600px;
  margin: 0rem auto;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--white);

  .textarea {
    padding: 0;
    margin: 0;
    margin-top: -0.5rem;
    margin-bottom: 0rem;
    font-size: 0.75rem;
  }

  .icon2 {
    color: grey;
  }

  h2 {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
  }

  .experienceMainBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
`;

const Content = styled.div`
display: flex;
flex-direction: row;
gap: 4rem;
width: 100%;


.column1 {
    width: calc(70% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.column2 {
    width: calc(30% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}



`

const Header = styled.div`

display: flex;
flex-direction: column;
gap: 0.7rem;


.name {
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

.infoItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;
}

.icon {
    color: grey;
}



`


const Summary = styled.div`


.text {
    font-size: 0.9rem;
    line-height: 1.2rem;
    letter-spacing: 0.05rem;
}

.textarea {
    margin-top: -0.5rem;
    margin-bottom: -1rem;
    display: block;
    font-size: 0.75rem;
}

`

const Employment = styled.div`



.experienceBox {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;


    .experienceBoxTop {
        display: flex;
        gap: 0.6rem;
        margin-top: 0.5rem;
    }
}


.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.company {
    font-size: 0.9rem;
    font-weight: 500;
    font-style: italic;
}

.location {
    font-size: 0.8rem;
    font-weight: 500;
}

.date {
    font-size: 0.8rem;
    font-weight: 500;
    color: blue;
}

.desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
}

`


const Education = styled.div`


.educationBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;


    .educationBoxTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0.6rem;
    }
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.company {
    font-size: 0.9rem;
    font-weight: 500;
    color: grey;
}

.date {
    font-size: 0.75rem;
    font-weight: 500;
    color: blue;
}


`


const Skills = styled.div`


.skillBox {
    display: flex;
    flex-flow: row wrap;
    row-gap: 0.3rem;
    column-gap: 0.3rem;
}

.skill {
    border: 0.05rem solid grey;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 0.3rem;
}

`


const Language = styled.div`

.skillBox {
    display: flex;
    flex-flow: row wrap;
    row-gap: 0.3rem;
    column-gap: 0.3rem;
}

.skill {
    border: 0.05rem solid grey;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 0.3rem;
}

`


const Projects = styled.div`


.projectBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
}

.date {
    font-size: 0.8rem;
    font-weight: 500;
    color: blue;
    margin-bottom: -0.5rem;
}

.desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
}



`


const PrintStyles = createGlobalStyle`
  @media print {
    body {
      margin: 2mm 1mm 1mm 2mm;
      zoom: 1.8;
    }
  }
`;