import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { addCv } from "../../../../functions/api/cv/addCv";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setCvData,
  setMode,
  setResumeId,
  setTemplateId,
} from "../../../../slices/cvBuilderSlice";
import { resumeExampleData } from "../../../../utils/contantData";
import { publicFileUpload } from "../../../../functions/api/globalFunctions/publicFileUpload";
import deleteIcon from "../../../../assets/icons/delete.png";
import SelectCVTempPage from "../../../../pages/SelectCVTempPage";
import CommonModal from "../../../commonComponents/CommonModal";
import { aiEnhancement } from "../../../../functions/api/jobSeekers/aiEnhancement";
import aIspark from "../../../../assets/icons/aIspark.png";
import aIsparkGIF from "../../../../assets/icons/aisparkGIF.gif";

const CreateResumeLeftBox = ({
  templateId,
  personalDetails,
  setPersonalDetails,
  professionalSummary,
  setProfessionalSummary,
  employmentHistory,
  setEmploymentHistory,
  education,
  setEducation,
  social,
  setSocial,
  skills,
  setSkills,
  languages,
  setLanguages,
  projects,
  setProjects,
  profileImg,
  setProfileImg,
  expanded,
  setExpanded,
  handleChange,
  handleAddEducation,
  handleRemoveEducation,
  handleRemoveExperience,
  handleRemoveProject,
  handleAddProject,
  handleAddLanguage,
  handleAddExperience,
  handleAddSkill,
  trigger,
  setTrigger,
  handleGeneratePdf,
}) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state) => state?.auth?.userData?.accessToken
  );
  const clientCode = useSelector(
    (state) => state?.auth?.userData?.user?.clientCode
  );

  const resumeId = useSelector((state) => state?.cvBuilder?.resumeId);
  const mode = useSelector((state) => state?.cvBuilder?.mode);
  const cvPrevData = useSelector((state) => state?.cvBuilder?.cvData);
  const [openModal, setOpenModal] = useState(false);
  const [selectedWordCount, setSelectedWordCount] = useState(80);
  const [isEnhancing, setIsEnhancing] = useState(false);

  const viewOnly = useSelector((state) => state?.cvBuilder?.viewOnly);

  useEffect(() => {
    if (cvPrevData) {
      var parsedCvData = JSON.parse(cvPrevData);
    }
    if (mode === "edit") {
      setProfileImg(parsedCvData?.profileImg);
      setPersonalDetails(parsedCvData?.personalDetails);
      setProfessionalSummary(parsedCvData?.professionalSummary);
      setEmploymentHistory(parsedCvData?.employmentHistory);
      setEducation(parsedCvData?.education);
      setSocial(parsedCvData?.social);
      setSkills(parsedCvData?.skills);
      setLanguages(parsedCvData?.languages);
      setProjects(parsedCvData?.projects);
    } else if (mode === "create") {
    }
  }, [mode, cvPrevData]);

  const handleSave = async () => {
    try {
      const cvData = JSON.stringify({
        profileImg,
        personalDetails,
        professionalSummary,
        employmentHistory,
        education,
        social,
        skills,
        languages,
        projects,
      });

      const editPayload = {
        cvData: cvData,
        templateName: templateId,
        title: personalDetails?.jobTitle,
        id: resumeId,
      };
      const createPayload = {
        cvData: cvData,
        templateName: templateId,
        title: personalDetails?.jobTitle,
      };

      const res = await addCv(
        mode === "edit" ? editPayload : createPayload,
        accessToken,
        clientCode
      );
      if (res) {
        setTrigger((prev) => !prev);
        if (mode === "edit") {
          toast.success("Resume Updated");
          dispatch(setCvData(res?.data?.cvData));
        } else if (mode === "create") {
          toast.success("Resume Added");
          dispatch(setCvData(res?.data?.cvData));
          dispatch(setResumeId(res?.data?.id));
          dispatch(setMode("edit"));
          dispatch(setTemplateId(templateId));
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handlePrint = () => {};

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const uploadRes = await publicFileUpload(
          formData,
          accessToken,
          clientCode
        );
        if (uploadRes) {
          setProfileImg(uploadRes?.data?.url);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
  };

  const handleDataChange = (section, index, fieldName, value) => {
    switch (section) {
      case "personalDetails":
        setPersonalDetails((prevDetails) => {
          const updatedDetails = { ...prevDetails };
          updatedDetails[fieldName] = value;
          return updatedDetails;
        });
        break;
      case "employments":
        setEmploymentHistory((prevEmployments) => {
          const updatedEmployments = [...prevEmployments];
          updatedEmployments[index][fieldName] = value;
          return updatedEmployments;
        });
        break;
      case "projects":
        setProjects((prevProjects) => {
          const updatedProjects = [...prevProjects];
          updatedProjects[index][fieldName] = value;
          return updatedProjects;
        });
        break;
      case "educations":
        setEducation((prevEducations) => {
          const updatedEducations = [...prevEducations];
          updatedEducations[index][fieldName] = value;
          return updatedEducations;
        });
        break;
      case "skills":
        setSkills((prevSkills) => {
          const updatedSkills = [...prevSkills];
          updatedSkills[index][fieldName] = value;
          return updatedSkills;
        });
        break;
      case "languages":
        setLanguages((prevLanguages) => {
          const updatedLanguages = [...prevLanguages];
          updatedLanguages[index][fieldName] = value;
          return updatedLanguages;
        });
        break;
      case "socials":
        setSocial((prevSocial) => {
          const updatedSocial = { ...prevSocial };
          updatedSocial[fieldName] = value;
          return updatedSocial;
        });
        break;
      default:
        break;
    }
  };

  const handleChangeTemplate = async () => {
    handleSave();
    setOpenModal(true);
  };
  const handleEnhanceSummary = async () => {
    if (isEnhancing) return;

    try {
      setIsEnhancing(true);
      const plainTextSummary = professionalSummary.replace(/<[^>]*>?/gm, "");

      const skillsString = skills
        ?.map((skill) => `skill: ${skill.skill.trim()}, ${skill.level.trim()}`)
        .join("; ");

      const educationsString = education
        ?.map(
          (edu) => `educations: ${edu.degree.trim()}, ${edu.university.trim()}`
        )
        .join("; ");

      const employmentsString = employmentHistory
        ?.map(
          (emp) =>
            `employments: ${emp.jobTitle.trim()}, ${emp.companyName.trim()}`
        )
        .join("; ");

      // const projectsString = projects
      //   ?.map((project) => `Project: ${project.title.trim()}`)
      //   .join("; ");

      const profileExtra = [
        skillsString,
        educationsString,
        employmentsString,
        // projectsString,
      ]
        .filter(Boolean)
        .join("; ")
        .trim();

      const response = await aiEnhancement(
        selectedWordCount,
        plainTextSummary,
        profileExtra,
        accessToken
      );

      if (response && response.message) {
        setProfessionalSummary(response.message, () => {
          setTimeout(() => {
            handleSave();
          }, 100);
        });
        toast.success("Professional summary enhanced successfully!");
      }
    } catch (error) {
      console.error("Full error object:", error);
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    } finally {
      setIsEnhancing(false);
    }
  };
  return (
    <Box>
      <CommonModal
        open={openModal}
        setOpen={setOpenModal}
        component={
          <SelectCVTempPage
            handleClose={() => setOpenModal(false)}
            mode="edit"
          />
        }
      />
      <div className="personalDetails mainBox">
        <div className="inputBoxMain">
          <span className="title">Personal Details</span>{" "}
          <Button onClick={handleChangeTemplate}>Change the template</Button>{" "}
        </div>

        <div className="inputBoxMain">
          <TextField
            id="outlined-basic"
            label="Job Title"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.jobTitle || " "}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "jobTitle", e.target.value)
            }
            disabled={viewOnly && mode === "edit"}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <div
            className="inputBox"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              marginTop: "0rem",
            }}
          >
            <label
              htmlFor="photo"
              className="label"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                cursor: "pointer",
              }}
            >
              <img src={profileImg} style={{ width: "3rem", height: "3rem" }} />
              <input
                type="file"
                id="photo"
                className="photoInput"
                style={{ display: "none" }}
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                disabled={viewOnly && mode === "edit"}
              />
            </label>
          </div>
        </div>

        <div className="inputBoxMain">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.firstName || " "}
            onChange={(e) =>
              handleDataChange(
                "personalDetails",
                1,
                "firstName",
                e.target.value
              )
            }
            disabled={viewOnly && mode === "edit"}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.lastName || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "lastName", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
        </div>

        <div className="inputBoxMain">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            size="small"
            value={personalDetails?.email || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "email", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            fullWidth
            type="tel"
            size="small"
            value={personalDetails?.phone || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "phone", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
        </div>

        <div className="inputBoxMain">
          <TextField
            id="outlined-basic"
            label="Country"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.country || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "country", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.city || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "city", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
        </div>

        <div className="inputBoxMain">
          <TextField
            id="outlined-basic"
            label="Address"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.address || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("personalDetails", 1, "address", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Nationality"
            variant="outlined"
            fullWidth
            type="text"
            size="small"
            value={personalDetails?.nationality || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange(
                "personalDetails",
                1,
                "nationality",
                e.target.value
              )
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
        </div>
      </div>

      <div className="profileSummary mainBox">
        <div className="titleBox">
          <span className="title">Professional Summary</span>{" "}
          <span
            className="enhanceBtn"
            onClick={handleEnhanceSummary}
            style={{ cursor: isEnhancing ? "not-allowed" : "pointer" }}
          >
            {isEnhancing
              ? "Enhancing, please wait..."
              : "Enhance my Summary with AI"}
          </span>
          <img src={aIspark} className="aiSparkImg"></img>
          <select
            value={selectedWordCount}
            onChange={(e) => setSelectedWordCount(Number(e.target.value))}
            className="wordCountDropdown"
          >
            <option value={40}>40 words</option>
            <option value={80}>80 words</option>
            <option value={150}>150 words</option>
          </select>
        </div>
        <span className="text">
          Write 2-4 short & energetic sentences to interest the reader! Mention
          your role, experience & most importantly - your biggest achievements,
          best qualities and skills.
        </span>

        <div className="textEditorBox">
          <ReactQuill
            theme="snow"
            className="textEditor"
            name="professionalSummary"
            value={professionalSummary || " "}
            onChange={setProfessionalSummary}
            readOnly={viewOnly && mode === "edit"}
          />
        </div>

        <span className="text">
          Recruiter tip: write 50-200 characters to increase interview chances
          176 / 200
        </span>
      </div>

      <div className="employmentHistory mainBox">
        <span className="title">Employment History</span>
        <span className="text">
          Show your relevant experience (last 10 years). Use bullet points to
          note your achievements, if possible - use numbers/facts (Achieved X,
          measured by Y, by doing Z).
        </span>

        {employmentHistory?.length > 0 &&
          employmentHistory?.map((exp, i) => (
            <Accordion
              expanded={expanded === `panel1${i}`}
              onChange={handleChange(`panel1${i}`)}
              style={{ width: "100%", boxShadow: "none", margin: "0" }}
              classes={{ root: "custom-accordion-root" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                classes={{
                  root: "custom-accordion-summary-root",
                  expanded: expanded === `panel1${i}` ? "expanded" : "",
                }}
              >
                <span
                  className={`menuTitle ${
                    expanded === `panel1${i}` ? "selected" : ""
                  }`}
                >
                  {exp?.companyName ? exp?.companyName : `Experience ${i + 1}`}{" "}
                  <img
                    src={deleteIcon}
                    className="deleteIcon"
                    onClick={() => handleRemoveExperience(i)}
                  />
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="mainBox">
                  <CheckBox>
                    <p>Currently working here.</p>
                    <input
                      type="checkbox"
                      checked={exp?.current}
                      onChange={(e) =>
                        handleDataChange(
                          "employments",
                          i,
                          "current",
                          e.target.checked
                        )
                      }
                      disabled={viewOnly && mode === "edit"}
                    />
                  </CheckBox>
                  <div className="inputBoxMain">
                    <TextField
                      id="outlined-basic"
                      label="Job Title"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={exp?.jobTitle || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "employments",
                          i,
                          "jobTitle",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Company Name"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={exp?.companyName || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "employments",
                          i,
                          "companyName",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />
                  </div>

                  <div className="inputBoxMain">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="Start Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(exp?.startDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "employments",
                              i,
                              "startDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={viewOnly && mode === "edit"}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="End Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(exp?.endDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "employments",
                              i,
                              "endDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={
                            exp?.current || (viewOnly && mode === "edit")
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className="inputMainBox">
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={exp?.city || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "employments",
                          i,
                          "city",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />
                  </div>

                  <div className="textAreaBox">
                    <label className="textAreaLabel">Description</label>
                    <div className="textEditorBox">
                      <ReactQuill
                        theme="snow"
                        className="textEditor"
                        value={exp?.description || " "}
                        onChange={(newValue) =>
                          handleDataChange(
                            "employments",
                            i,
                            "description",
                            newValue
                          )
                        }
                        readOnly={viewOnly && mode === "edit"}
                      />
                    </div>

                    <span className="text">
                      Recruiter tip: write 200+ characters to increase interview
                      chances.
                    </span>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        {employmentHistory?.length === 0 ? (
          <span className="addMoreText" onClick={handleAddExperience}>
            + Add employment
          </span>
        ) : (
          <span className="addMoreText" onClick={handleAddExperience}>
            + Add one more employment
          </span>
        )}
      </div>

      <div className="education mainBox">
        <span className="title">Education</span>
        <span className="text">
          A varied education on your resume sums up the value that your
          learnings and background will bring to job.
        </span>

        {education?.length > 0 &&
          education?.map((edu, i) => (
            <Accordion
              expanded={expanded === `panel2${i}`}
              onChange={handleChange(`panel2${i}`)}
              style={{ width: "100%", boxShadow: "none", margin: "0" }}
              classes={{ root: "custom-accordion-root" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                classes={{
                  root: "custom-accordion-summary-root",
                  expanded: expanded === `panel2${i}` ? "expanded" : "",
                }}
              >
                <span
                  className={`menuTitle ${
                    expanded === `panel2${i}` ? "selected" : ""
                  }`}
                >
                  {edu?.university ? edu?.university : `Education ${i + 1}`}{" "}
                  <img
                    src={deleteIcon}
                    className="deleteIcon"
                    onClick={() => handleRemoveEducation(i)}
                  />
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="mainBox">
                  <CheckBox>
                    <p>Currently studying here.</p>
                    <input
                      type="checkbox"
                      checked={edu?.current}
                      onChange={(e) =>
                        handleDataChange(
                          "educations",
                          i,
                          "current",
                          e.target.checked
                        )
                      }
                      disabled={viewOnly && mode === "edit"}
                    />
                  </CheckBox>
                  <div className="inputBoxMain">
                    <TextField
                      id="outlined-basic"
                      label="School"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={edu?.university || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "educations",
                          i,
                          "university",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Degree"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={edu?.degree || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "educations",
                          i,
                          "degree",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />
                  </div>

                  <div className="inputBoxMain">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="Start Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(edu?.startDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "educations",
                              i,
                              "startDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={viewOnly && mode === "edit"}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="End Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(edu?.endDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "educations",
                              i,
                              "endDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={
                            edu?.current || (viewOnly && mode === "edit")
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className="inputMainBox">
                    <TextField
                      id="outlined-basic"
                      label="City"
                      variant="outlined"
                      fullWidth
                      type="text"
                      size="small"
                      value={edu?.city || " "}
                      disabled={viewOnly && mode === "edit"}
                      onChange={(e) =>
                        handleDataChange(
                          "educations",
                          i,
                          "city",
                          e.target.value
                        )
                      }
                      inputProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#626264",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                        },
                      }}
                    />
                  </div>

                  <div className="textAreaBox">
                    <label className="textAreaLabel">Description</label>
                    <div className="textEditorBox">
                      <ReactQuill
                        theme="snow"
                        className="textEditor"
                        value={edu.description || " "}
                        onChange={(newValue) =>
                          handleDataChange(
                            "educations",
                            i,
                            "description",
                            newValue
                          )
                        }
                        readOnly={viewOnly && mode === "edit"}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        {education?.length === 0 ? (
          <span className="addMoreText" onClick={handleAddEducation}>
            + Add education
          </span>
        ) : (
          <span className="addMoreText" onClick={handleAddEducation}>
            + Add one more education
          </span>
        )}
      </div>

      <div className="urls mainBox">
        <span className="title">Websites & Social Links</span>
        <span className="text">
          You can add links to websites you want hiring managers to see! Perhaps
          It will be a link to your portfolio, LinkedIn profile, or personal
          website.
        </span>

        <div className="inputBoxMain">
          {/* 
                    <TextField id="outlined-basic" label="Portfolio Website" variant="outlined" fullWidth
                        type='url'
                        size='small'
                        value={social?.portfolio}
                        onChange={(e) => handleDataChange('socials', 1, 'portfolio', e.target.value)}
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }} /> */}

          <TextField
            id="outlined-basic"
            label="LinkedIn"
            variant="outlined"
            fullWidth
            type="url"
            size="small"
            value={social?.linkedin || " "}
            disabled={viewOnly && mode === "edit"}
            onChange={(e) =>
              handleDataChange("socials", 1, "linkedin", e.target.value)
            }
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
        </div>

        {/* <div className="inputBoxMain">

                    <TextField id="outlined-basic" label="Github" variant="outlined" fullWidth
                        type='url'
                        size='small'
                        value={social?.github}
                        onChange={(e) => handleDataChange('socials', 1, 'github', e.target.value)}
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }} />

                    <TextField id="outlined-basic" label="Other social link" variant="outlined" fullWidth
                        type='url'
                        size='small'
                        value={social?.others}
                        onChange={(e) => handleDataChange('socials', 1, 'others', e.target.value)}
                        inputProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                color: '#626264',
                                fontSize: '0.8rem',
                                fontWeight: '400'
                            },
                        }} />
                    </div> */}
      </div>

      <div className="skills mainBox">
        <span className="title">Skills</span>
        <span className="skillText">
          Choose 5 important skills that show you fit the position.{" "}
          <span className="addSkillBtn" onClick={handleAddSkill}>
            Add Skill
          </span>
        </span>

        {skills?.length > 0 &&
          skills?.map((skill, i) => (
            <span className="inputBoxMain">
              <TextField
                id="outlined-basic"
                label="Skill"
                variant="outlined"
                fullWidth
                type="text"
                size="small"
                value={skill?.skill || " "}
                disabled={viewOnly && mode === "edit"}
                onChange={(e) =>
                  handleDataChange("skills", i, "skill", e.target.value)
                }
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                  },
                }}
              />

              <FormControl
                sx={{ backgroundColor: "#fbfbfd", padding: "0" }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Level"
                  size="small"
                  value={skill?.level || " "}
                  disabled={viewOnly && mode === "edit"}
                  onChange={(e) =>
                    handleDataChange("skills", i, "level", e.target.value)
                  }
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "400",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "400",
                    },
                  }}
                  sx={{
                    padding: "0rem 0 0.3rem 0",
                  }}
                >
                  <MenuItem value="Novice">Novice</MenuItem>
                  <MenuItem value="Beginner">Beginner</MenuItem>
                  <MenuItem value="Skillful">Skillful</MenuItem>
                  <MenuItem value="Experienced">Experienced</MenuItem>
                  <MenuItem value="Expert">Expert</MenuItem>
                </Select>
              </FormControl>
            </span>
          ))}
      </div>

      <div className="language mainBox">
        <span className="title">Languages</span>
        <span className="skillText">
          <span>Choose Languages in which you're good.</span>
          <span className="addSkillBtn" onClick={handleAddLanguage}>
            Add Language
          </span>
        </span>

        {languages?.length > 0 &&
          languages?.map((language, i) => (
            <span className="inputBoxMain">
              <TextField
                id="outlined-basic"
                label="Language"
                variant="outlined"
                fullWidth
                type="text"
                size="small"
                value={language?.language || " "}
                disabled={viewOnly && mode === "edit"}
                onChange={(e) =>
                  handleDataChange("languages", i, "language", e.target.value)
                }
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                  },
                }}
              />

              <FormControl
                sx={{ backgroundColor: "#fbfbfd", padding: "0" }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Level"
                  size="small"
                  value={language?.level || " "}
                  disabled={viewOnly && mode === "edit"}
                  onChange={(e) =>
                    handleDataChange("languages", i, "level", e.target.value)
                  }
                  inputProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "400",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "400",
                    },
                  }}
                  sx={{
                    padding: "0rem 0 0.3rem 0",
                  }}
                >
                  <MenuItem value="Native">Native</MenuItem>
                  <MenuItem value="Proficient">Proficient</MenuItem>
                  <MenuItem value="VeryGoodCommand">Very good command</MenuItem>
                </Select>
              </FormControl>
            </span>
          ))}
      </div>

      <div className="projects mainBox">
        <span className="title">Projects</span>

        {projects?.length > 0 &&
          projects?.map((project, i) => (
            <Accordion
              expanded={expanded === `panel3${i}`}
              onChange={handleChange(`panel3${i}`)}
              style={{ width: "100%", boxShadow: "none", margin: "0" }}
              classes={{ root: "custom-accordion-root" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                classes={{
                  root: "custom-accordion-summary-root",
                  expanded: expanded === `panel3${i}` ? "expanded" : "",
                }}
              >
                <span
                  className={`menuTitle ${
                    expanded === `panel3${i}` ? "selected" : ""
                  }`}
                >
                  {project?.title ? project?.title : `Project ${i + 1}`}{" "}
                  <img
                    src={deleteIcon}
                    className="deleteIcon"
                    onClick={() => handleRemoveProject(i)}
                  />
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="mainBox">
                  <CheckBox>
                    <p>Ongoing project.</p>
                    <input
                      type="checkbox"
                      checked={project?.current}
                      onChange={(e) =>
                        handleDataChange(
                          "projects",
                          i,
                          "current",
                          e.target.checked
                        )
                      }
                      disabled={viewOnly && mode === "edit"}
                    />
                  </CheckBox>
                  <TextField
                    id="outlined-basic"
                    label="Project Title"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={project?.title || " "}
                    onChange={(e) =>
                      handleDataChange("projects", i, "title", e.target.value)
                    }
                    disabled={viewOnly && mode === "edit"}
                    inputProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.8rem",
                        fontWeight: "400",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.8rem",
                        fontWeight: "400",
                      },
                    }}
                  />
                  <div className="inputBoxMain">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="Start Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(project?.startDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "projects",
                              i,
                              "startDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={viewOnly && mode === "edit"}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DatePicker
                          label="End Date"
                          sx={{ backgroundColor: "#fbfbfd", width: "100%" }}
                          value={dayjs(project?.endDate)}
                          onChange={(newValue) =>
                            handleDataChange(
                              "projects",
                              i,
                              "endDate",
                              newValue.format("YYYY-MM-DD")
                            )
                          }
                          disabled={
                            project?.current || (viewOnly && mode === "edit")
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className="textAreaBox">
                    <label className="textAreaLabel">Description</label>
                    <div className="textEditorBox">
                      <ReactQuill
                        theme="snow"
                        className="textEditor"
                        value={project?.description || " "}
                        onChange={(newValue) =>
                          handleDataChange(
                            "projects",
                            i,
                            "description",
                            newValue
                          )
                        }
                        readOnly={viewOnly && mode === "edit"}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        {projects?.length === 0 ? (
          <span className="addMoreText" onClick={handleAddProject}>
            + Add project
          </span>
        ) : (
          <span className="addMoreText" onClick={handleAddProject}>
            + Add one more project
          </span>
        )}
      </div>

      <div className="buttonBox">
        <Button onClick={handleSave} disabled={viewOnly && mode === "edit"}>
          Save
        </Button>
        {mode === "edit" && (
          <Button onClick={() => handleGeneratePdf(templateId)}>Print</Button>
        )}
      </div>
    </Box>
  );
};

export default CreateResumeLeftBox;

const Box = styled.div`
  width: 96%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  background-color: var(--white);

  .enhanceBtn {
    padding-top: 0.2rem;
    padding-right: 0.35rem;
    font-size: 0.9rem;
    color: var(--cyan);
    font-weight: 700;
    text-decoration: none;
    align-items: center;
  }

  .enhanceBtn:hover {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
  }

  .deleteIcon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    border: 0.05rem solid lightgrey;
    border-radius: 0.25rem;
    box-sizing: border-box;
  }

  .menuTitle {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  #outlined-basic {
    padding: 0.8rem 0.8rem;
    background-color: #fbfbfd;
  }

  .inputBoxMain {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .inputBox {
      width: 45%;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
    }

    .photoInput {
      display: flex;
      align-items: center;
    }
  }

  .textAreaBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .textAreaLabel {
      font-size: 0.8rem;
    }
  }

  .mainBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .titleBox {
      display: flex;
      // justify-content: space-between;

      button {
        background-color: var(--cyan);
        color: var(--white);
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        border: none;
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
      }
    }

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-right: 6rem;
    }

    .text {
      font-size: 0.8rem;
      font-weight: 400;
      color: grey;
      line-height: 1rem;
    }
  }
  .wordCountDropdown {
    width: 5rem;
    height: 1.5rem;
    font-size: 0.7rem;
    font-weight: 550;
    margin-left: 1rem;
    border: 1.5px solid var(--cyan);
    border-radius: 0.3rem;
    background-color: white;
    color: var(--cyan);
    cursor: pointer;
    align-items: center;
  }
  .wordCountDropdown:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 156, 234, 0.3);
  }

  .aiSparkImg {
    width: 1rem;
    height: 1rem;
    align-items: center;
    padding-top: 0.2rem;
  }
  .addMoreText {
    color: var(--cyan);
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
  }

  .skillText {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 0.8rem;
    font-weight: 400;
    color: grey;
    line-height: 1rem;
  }

  .addSkillBtn {
    color: var(--cyan);
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .addSkillBtn:hover {
    font-weight: 700;
    text-decoration: underline;
  }

  .textEditor {
    background-color: #fbfbfd;
  }

  #demo-simple-select-label {
    font-size: 0.8rem;
  }

  .buttonBox {
    align-self: center;
    display: flex;
    gap: 1rem;
  }
`;

const Button = styled.button`
  background-color: var(--cyan);
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  align-self: center;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;

  input {
    cursor: pointer;
  }
`;
