import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import lottieLoader from "../../../assets/lottieLoader.json";

const LoaderDialog = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box>
      <Lottie options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default LoaderDialog;

const Box = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;
