import React, { useState } from "react";
import styled from "styled-components";
import DemoHeader from "../components/commonComponents/DemoHeader";
import Footer from "../components/commonComponents/Footer";
import demoPageImg from "../assets/demoPageImg.png";
import { Box, MenuItem, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import { toast } from "react-toastify";

const Demo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [employees, setEmployees] = useState("");

  const validateEmail = (email) => {
    // Email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    // Check if all required fields are filled
    if (!name || !email || !contact || !company || !employees) {
      toast.warning("Please fill in all required fields.");
      return;
    }

    // Validate email format
    if (!validateEmail(email)) {
      toast.warning("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/misc/contact-requests`,
        {
          name,
          email,
          contact,
          company,
          companySize: employees,
          type: "DEMO_REQUEST",
        }
      );

      if (response.status === 201) {
        toast.success("Demo request submitted successfully!");
        // Reset form fields after successful submission
        setName("");
        setEmail("");
        setContact("");
        setCompany("");
        setEmployees("");
      }
    } catch (error) {
      console.error("Error submitting demo request:", error);
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleContactChange = (newInput) => {
    setContact(newInput);
  };

  return (
    <StyledDiv>
      <DemoHeader />

      <div className="content">
        <div className="left">
          <img src={demoPageImg} alt="Demo Page" />
        </div>
        <div className="right">
          <span className="title">Get a Free Demo Now!</span>

          <TextField
            id="outlined-basic"
            className="textInput"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextField
            id="outlined-basic"
            className="textInput"
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <MuiTelInput
            defaultCountry="IN"
            className="textInput"
            forceCallingCode
            preferredCountries={["IN", "US"]}
            value={contact}
            onChange={handleContactChange}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextField
            id="outlined-basic"
            className="textInput"
            label="Company"
            variant="outlined"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextField
            id="outlined-select-currency"
            select
            label="Company Size"
            defaultValue=""
            className="textInput"
            value={employees}
            onChange={(e) => setEmployees(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select number of Employees
            </MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2-5">2-5</MenuItem>
            <MenuItem value="6-10">6-10</MenuItem>
            <MenuItem value="11-25">11-25</MenuItem>
            <MenuItem value="26-50">26-50</MenuItem>
            <MenuItem value="51-200">51-200</MenuItem>
            <MenuItem value="201-1000">201-1000</MenuItem>
            <MenuItem value="1001-10000">1001-10000</MenuItem>
            <MenuItem value="10001+">10001+</MenuItem>
          </TextField>

          <button className="btn" onClick={handleSubmit}>
            Schedule a Demo
          </button>
        </div>
      </div>
      <Footer />
    </StyledDiv>
  );
};

export default Demo;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
  }

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left > img {
    width: 75%;
  }

  .textInput {
    width: 80%;
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .btn {
    font-family: var(--font);
    font-weight: 600;
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
    width: 35%;
  }

  @media (max-width: 800px) {
    .left {
      display: none;
    }

    .right {
      width: 100%;
    }

    form {
      width: 90%;
    }

    select {
      width: 80%;
    }
  }

  .Toastify__toast-container {
    z-index: 9999;
  }

  .Toastify__toast {
    font-family: var(--font);
    font-size: 0.9rem;
  }

  .Toastify__toast--success {
    background: var(--lightGreen);
  }

  .Toastify__toast--error {
    background: var(--lightRed);
  }
`;
