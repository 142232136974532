import axios from "axios";

export const getAllTrackers = async (
    jdId,
    accessToken,
    page = 1,
    size = 1000000,
    trackerStatus,
    clientCode
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "x-client-code": clientCode
        },
    };
    const queryParams = [jdId, accessToken,]


    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/interviews/trackers?jdId=${jdId}&page=${page}&size=${size}&trackerStatus=${trackerStatus}`,
            config
        );
        console.log("Data:", response.data);
        console.log("Status:", response.status);
        return response.data;
    } catch (error) {
        throw error;
    }
};
