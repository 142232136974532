import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { updateEducation } from "../../../../functions/api/jobSeekers/updateEducation";
import { addEducations } from "../../../../functions/api/jobSeekers/addEducations";

const EducationDetails = ({
  data,
  mode,
  handleClose,
  id,
  trigger,
  setTrigger,
}) => {
  const profileId = useSelector(
    (state) => state.auth.userData?.user?.profileId
  );
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [formData, setFormData] = useState();

  const [issueDate, setIssueDate] = useState(dayjs(new Date()));
  const [expirationDate, setExpirationDate] = useState(dayjs(new Date()));
  const [educationStatus, setEducationStatus] = useState("no");

  const handleRadioChange = (event) => {
    setEducationStatus(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (mode === "edit") {
      setFormData(data);
      setIssueDate(dayjs(data?.startDate));
      setExpirationDate(dayjs(data?.endDate));
      setEducationStatus(data?.current ? "yes" : "no");
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const startDate = moment(issueDate.format("YYYY-MM-DD"));
      const endDate = moment(expirationDate.format("YYYY-MM-DD"));

      console.log("While updating", startDate._i);
      if (mode === "create") {
        const payload = {
          school: formData?.school,
          degree: formData?.degree,
          course: formData?.course,
          courseType: formData?.courseType,
          grade: formData?.grade,
          gradeType: formData?.gradeType,
          startDate: startDate?._i,
          endDate: endDate?._i,
          current: educationStatus == "yes",
        };
        const res = await addEducations(profileId, payload, accessToken);

        if (res) {
          toast.success("Education added successfully");
          handleClose();
          setTrigger(!trigger);
        }
      } else {
        const payload = {
          school: formData?.school,
          degree: formData?.degree,
          course: formData?.course,
          courseType: formData?.courseType,
          grade: formData?.grade,
          gradeType: formData?.gradeType,
          startDate: startDate?._i,
          endDate: endDate?._i,
          current: educationStatus == "yes",
        };

        const res = await updateEducation(id, payload, accessToken);

        if (res) {
          toast.success("Education updated successfully");
          handleClose();
          setTrigger(!trigger);
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <Box>
      <span className="title">
        {mode === "create" ? "Add Your Education" : "Update Your Education"}
      </span>

      <Form>
        <RadioGroup>
          <p>Is this your current course of study?</p>
          <RadioLabel>
            <RadioButton
              type="radio"
              name="educationStatus"
              value="yes"
              checked={educationStatus === "yes"}
              onChange={handleRadioChange}
            />
            <p>Yes</p>
          </RadioLabel>
          <RadioLabel>
            <RadioButton
              type="radio"
              name="educationStatus"
              value="no"
              checked={educationStatus === "no"}
              onChange={handleRadioChange}
            />
            <p>No</p>
          </RadioLabel>
        </RadioGroup>
        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="University Name"
            variant="outlined"
            type="text"
            value={formData?.school || " "}
            name="school"
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label="Degree"
            variant="outlined"
            type="tel"
            value={formData?.degree || " "}
            name="degree"
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Course"
            variant="outlined"
            type="text"
            name="course"
            value={formData?.course || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Course type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Course type"
              size="small"
              name="courseType"
              value={
                (formData?.courseType?.length > 0 && formData?.courseType) ||
                " "
              }
              onChange={handleChange}
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.3rem 0",
              }}
            >
              <MenuItem value="fullTime">Full Time</MenuItem>
              <MenuItem value="partTime">Part time</MenuItem>
              <MenuItem value="distance">Distance</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Grade type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Grade type"
              size="small"
              name="gradeType"
              value={
                (formData?.gradeType?.length > 0 && formData?.gradeType) || " "
              }
              onChange={handleChange}
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.3rem 0",
              }}
            >
              <MenuItem value="0-4cgpa">0 to 4 CGPA</MenuItem>
              <MenuItem value="0-10cgpa">0 to 10 CGPA</MenuItem>
              <MenuItem value="percentage">0 to 100%</MenuItem>
              <MenuItem value="a-f">A to F</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Grades"
            variant="outlined"
            type="text"
            name="grade"
            value={formData?.grade || " "}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ width: "100%" }}>
              <DatePicker
                label="Start Date"
                sx={{
                  backgroundColor: "#F6F6FB",
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                  "& .MuiInputBase-input": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                value={issueDate}
                onChange={(newValue) => setIssueDate(dayjs(newValue))}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ width: "100%" }}>
              <DatePicker
                label="End Date (or Expected)"
                sx={{
                  backgroundColor: "#F6F6FB",
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                  "& .MuiInputBase-input": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                value={expirationDate}
                onChange={(newValue) => setExpirationDate(dayjs(newValue))}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <Button onClick={handleSubmit}>
          {mode === "create" ? "Add" : "Save Changes"}
        </Button>
      </Form>
    </Box>
  );
};

export default EducationDetails;

const Box = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.3rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 2rem;
    display: block;
    margin-bottom: 1rem;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-family: var(--font);
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
`;

const RadioLabel = styled.label`
  display: flex;
  margin-right: 10px;
  margin-left: 0.5rem;
`;

const RadioButton = styled.input``;
