import axios from "axios";

export const shouldStartInterview = async (id, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/${id}/shouldStart`, config);
        console.log('Data:', response.data);
        console.log('Status:', response.status);
        return response.data;
    } catch (error) {
        throw error;
    }
}


