import { toast } from "react-toastify";
import { updateTrackers } from "../api/employers/tracker/updateTrackers";

export const handleTrackerUpdate = async (
  val,
  row,
  accessToken,
  clientCode,
  toggleDrawer,
  getData
) => {
  try {
    let resumeId = null;
    if (row?.resumeId) resumeId = row?.resumeId;
    else if (row?.matchedRecord) resumeId = row?.matchedRecord?.resumeId;
    else if (row?.interview) resumeId = row?.interview?.resumeId;
    const payloadData = {
      jdId: "",
      resumeIds: [resumeId],
      status: val,
      trackerIds: [row.id],
    };
    const updatedRes = await updateTrackers(
      payloadData,
      accessToken,
      clientCode
    );
    if (updatedRes) {
      toast.success(
        `${val === "HOLD" ? "Hold" : "Moved out of Interview"} Successfully`
      );
    }
  } catch (error) {
    const errMsg =
      error?.response?.data?.notify?.message ||
      "An error occurred. Please try again.";
    toast.error(errMsg, 8000);
  } finally {
    toggleDrawer("right", false);
    getData();
  }
};
