import axios from "axios";

export const getTagsConfig = async (accessToken, clientCode) => {
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
    };

    try {
        const response = await axios.get(` ${process.env.REACT_APP_API_URL}/api/configs/value?key=${clientCode}.candidate.tags`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};
