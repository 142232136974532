import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styled from "styled-components";
import { sendInvite } from "../../../../functions/api/employers/match/sendInvite";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Autocomplete, IconButton, Paper } from "@mui/material";
import moment from "moment-timezone";
import LogoHeader from "../../../commonComponents/LogoHeader";
import InviteSteps from "./InviteSteps";
import InviteReviewList from "./InviteReviewList";
import { TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import TimeSlot from "../commonComponents/TimeSlot";
import { Box, Stack } from "@mui/system";
import { technicalSkills } from "../../../../utils/contantData";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";
import { updateInviteStatus } from "../../../../slices/invitationSlice";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import AvailableTestList from "../../../../components/Interviews/EmployerDashboard/sidebarPages/AvailbleTestList.jsx";
import EmployerHeader from "../../../commonComponents/EmployerHeader.jsx";
import { InviteButton } from "./inviteButton.jsx";
import { formatTime } from "../../../../functions/api/interview/timeFormator.js";

const filter = createFilterOptions();

export default function Invite({ jdId, setInviteProcess }) {
  // const { jdId } = useParams();
  const { Id } = useParams();
  const [value, setValue] = useState(dayjs(new Date()));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(dayjs(new Date()));
  const [productTypes, setProductTypes] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [productType, setProductType] = useState("");
  const [interviewType, setInterviewType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [numberOfQue, setNumberOfQue] = useState(0);
  const [interviewerEmail, setInterviewerEmail] = useState("");
  const [meetUrl, setMeetUrl] = useState("");
  const [testType, setTestType] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [jdText, setJdText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [openTestModal, setOpenTestModal] = useState(false);
  const [attachedTest, setAttachedTest] = useState(null);
  const [testTime, setTestTime] = useState("60");
  const accessToken = useSelector((state) => state.auth.userData.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData.user.clientCode
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isTime, setIsTime] = useState(false);
  const [step, setStep] = useState(1);

  const handleCheckboxChange = () => {
    setIsTime(!isTime);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!accessToken || !clientCode) {
      toast.error("Login First");
      navigate("/login");
    }
    setProductTypes(["JD", "Resume", "JD + Resume", "Skill"]);
    setTestTypes(["mcq", "general", "coding"]);
  }, []);

  const handleSkillsChange = (_, newSkills) => {
    setSelectedSkills(newSkills);
  };

  const handleInputBlur = () => {
    if (inputValue.trim() !== "") {
      setSelectedSkills([...selectedSkills, inputValue?.trim()]);
      setInputValue("");
    }
  };

  const handleProductTypeChange = (inp) => {
    setProductType(inp);
  };

  const handleTestTypeChange = (inp) => {
    setTestType(inp);
    setNumberOfQue(0);
  };
  const handleDetachTest = () => {
    setAttachedTest(null);
  };
  const handleInterviewTypeChange = (inp) => {
    setInterviewType(inp);
  };
  const handleAttachTest = (test) => {
    setAttachedTest(test);
    setOpenTestModal(false);
  };

  const selectedResume = useSelector((state) => state?.invite?.selectedResumes);
  const selectedJd = useSelector((state) => state.invite?.selectedJd);
  const selectedCandidatesCount = selectedResume?.length || 0;

  const jdIdToIdsMap = selectedResume.reduce((accumulator, resume) => {
    const key = resume?.jdId || selectedJd;
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    if (!accumulator[key].includes(resume.id)) {
      accumulator[key].push(resume.id);
    }
    return accumulator;
  }, {});

  const handleInvite = async ({ setToggle }) => {
    if (productType?.trim()?.length == 0) {
      toast.warning("Please select product type", 5000);
      return;
    }
    if (productType === "InPerson") {
      if (interviewerEmail?.trim()?.length === 0) {
        toast.warning("Please enter interviewer email", 5000);
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(interviewerEmail)) {
        toast.warning("Please enter a valid email address", 5000);
        return;
      }
      if (
        meetUrl &&
        !meetUrl.startsWith("http://") &&
        !meetUrl.startsWith("https://")
      ) {
        toast.warning("Please enter a valid meet link", 5000);
        return;
      }
      if (productType === "AI") {
        if (interviewType?.trim()?.length == 0) {
          toast.warning("Please select interview based on", 5000);
          return;
        } else if (testType?.trim()?.length == 0) {
          toast.warning("Please select interview type", 5000);
          return;
        } else if (difficultyLevel?.trim()?.length == 0) {
          toast.warning("Please select difficulty level", 5000);
          return;
        } else if (numberOfQue == 0) {
          toast.warning("Please select number of Questions", 5000);
          return;
        } else if (interviewType == "JD" && jdText?.trim()?.length == 0) {
          toast.warning("JD text shouldn't be empty", 5000);
          return;
        } else if (interviewType == "Skill" && selectedSkills?.length == 0) {
          toast.warning("Skills shouldn't be empty", 5000);
          return;
        }
      } else if (productType === "Employer") {
        if (!attachedTest) {
          toast.warning("Please attach a test", 5000);
          return;
        }
      }
    }

    //ApiCall

    const makeApiCall = async () => {
      const dateTime = moment(
        value.format("YYYY-MM-DD") +
          "T" +
          (selectedTimeSlot.$H < 10
            ? "0" + selectedTimeSlot.$H
            : selectedTimeSlot.$H) +
          ":" +
          (selectedTimeSlot.$m < 10
            ? "0" + selectedTimeSlot.$m
            : selectedTimeSlot.$m) +
          ":" +
          "00.000"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm");
      const date = dateTime.slice(0, 10);
      const time = dateTime.slice(11);

      const payload = {
        productType: productType,
        jdId: jdId,
        trackerIds: jdIdToIdsMap[jdId],
        slotDate: date,
        slotTime: time,
        timeZone: "UTC",
        welcomeMessage: "string",
        expiryMin: testTime,
        ...(productType === "Employer" && {
          testId: attachedTest.id,
          testType: attachedTest.testType,
          noOfQuestions: attachedTest.numberOfQuestions,
        }),
        ...(productType === "AI" && {
          testType: testType === "allTypesGeneral" ? "general" : testType,
          interviewType: interviewType,
          jobSummary:
            interviewType == "JD" ? jdText : selectedSkills?.join(", "),
          noOfQuestions: numberOfQue,
          difficultyLevel: difficultyLevel,
        }),
      };

      if (payload.productType === "InPerson") {
        payload.interviewerEmail = interviewerEmail;
        payload.meetingLink = meetUrl;
        payload.productType = "Employer";
        payload.testType = "InPerson";
      }
      try {
        const response = await sendInvite(payload, accessToken, clientCode);

        if (response.status == "FAILED") {
          toast.error(response?.notify?.message, 8000);
          setToggle(false);
        } else {
          toast.success("Email Invite sent successfully", 5000);
          handleSuccessFunc();
          setToggle(false);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        setToggle(false);
        toast.error(errMsg, 8000);
      }
    };

    await makeApiCall();
    // setTimeout(() => {
    // setToggle(false)
    // }, 1000);
  };

  let str = value.$d.toString().split(" ");

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      setInviteProcess({ isOpen: false });
      // navigate("/dashboard/employer");
    }
  };

  const handleNext = () => {
    if (step < 3) {
      if (step === 1) {
        if (
          dayjs(new Date()).$D > value.$D &&
          dayjs(new Date()).$M >= value.$M &&
          dayjs(new Date()).$y >= value.$y
        ) {
          toast.warning("Please select future dates");
          return;
        } else if (
          !isTime &&
          dayjs(new Date()).$D == value.$D &&
          dayjs(new Date()).$M == value.$M &&
          dayjs(new Date()).$H >= selectedTimeSlot.$H &&
          dayjs(new Date()).$m >= selectedTimeSlot.$m
        ) {
          toast.warning("Please select future time slots");
          return;
        }
      } else if (step === 2) {
        if (productType?.trim()?.length == 0) {
          toast.warning("Please select product type", 5000);
          return;
        } else if (!testTime || parseInt(testTime) <= 0) {
          toast.warning("Please enter a valid interview time", 5000);
          return;
        } else if (productType === "InPerson") {
          if (interviewerEmail?.trim()?.length === 0) {
            toast.warning("Please enter interviewer email", 5000);
            return;
          }
          // Optionally validate email format
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(interviewerEmail)) {
            toast.warning("Please enter a valid email address", 5000);
            return;
          }
          // Check if meet link is empty
          if (meetUrl?.trim()?.length === 0) {
            toast.warning("Please enter a meet link", 5000);
            return;
          }
          // Validate meet link format
          if (
            !meetUrl.startsWith("http://") &&
            !meetUrl.startsWith("https://")
          ) {
            toast.warning("Please enter a valid meet link", 5000);
            return;
          }
        } else if (productType !== "Employer") {
          if (interviewType?.trim()?.length == 0) {
            toast.warning("Please select interview based on", 5000);
            return;
          } else if (testType?.trim()?.length == 0) {
            toast.warning("Please select interview type", 5000);
            return;
          } else if (difficultyLevel?.trim()?.length == 0) {
            toast.warning("Please select difficulty level", 5000);
            return;
          } else if (numberOfQue == 0) {
            toast.warning("Please select number of Questions", 5000);
            return;
          }
        } else {
          if (!attachedTest) {
            toast.warning("Please attach a test", 5000);
            return;
          }
        }
      }
      setStep(step + 1);
    }
  };

  const handleSuccessFunc = () => {
    navigate("/schedule/invite/success");
  };

  const handleIncreaseNumber = () => {
    if (numberOfQue < 5 && testType === "coding") {
      setNumberOfQue((prevNum) => prevNum + 1);
    } else if (numberOfQue < 30 && testType !== "coding") {
      setNumberOfQue((prevNum) => prevNum + 1);
    }
  };

  const handleDecreaseNumber = () => {
    if (numberOfQue > 0) {
      setNumberOfQue((prevNum) => prevNum - 1);
    }
  };

  return (
    <>
      {/* <MainContainer>
        <EmployerHeader /> */}
      {/* <LogoHeader /> */}
      <Container>
        <InviteSteps step={step} setStep={setStep} />
        {/* <IconButton onClick={handlePrev} className="prev">
            <ArrowBackIcon sx={{ fontSize: "30px" }} />
          </IconButton> */}
        <div className="mainBox">
          {step === 1 && (
            <div className="step1Box">
              <div className="timeSlot">
                {str[1]}/{str[2]}/{str[3]} - {formatTime(selectedTimeSlot)}
              </div>
              <div className="step1ChildBox">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="calendarContainer">
                    <div className="calendarBox">
                      <DateCalendar
                        value={value}
                        onChange={(newValue) => setValue(dayjs(newValue))}
                        views={["day"]}
                        sx={{
                          height: "100%",
                          display: "flex",
                        }}
                      />
                    </div>
                  </div>
                </LocalizationProvider>
                <div className="slotBox">
                  <div className="timeContainer">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker", "TimePicker"]}
                        className="slotChildBox"
                        sx={{ width: "40%" }}
                      >
                        {!isTime && (
                          <TimeSlot
                            selectedTimeSlot={selectedTimeSlot}
                            setSelectedTimeSlot={setSelectedTimeSlot}
                          />
                        )}
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                {/* <label className="smallTextBox">
                  <input
                    type="checkbox"
                    checked={isTime}
                    onChange={handleCheckboxChange}
                  />
                  <span className="smallText">
                    Allow slot selection to candidate (Interview Date will be
                    fixed)
                  </span>
                </label> */}
              </div>
              <div className="btns">
                <InviteButton
                  handleInvite={handleInvite}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  step={step}
                />
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="step2Box">
              <div className="defaultBoxMain">
                <div className="inputBoxMain">
                  <span className="title">Product Type</span>
                  <div className="childInputBoxMain">
                    <label className="label">
                      <input
                        type="radio"
                        value="AI"
                        checked={productType === "AI"}
                        onChange={() => handleProductTypeChange("AI")}
                      />
                      <span>AI</span>
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="Employer"
                        checked={productType === "Employer"}
                        onChange={() => handleProductTypeChange("Employer")}
                      />
                      <span>Employer</span>
                    </label>
                    {selectedCandidatesCount === 1 && (
                      <label className="label">
                        <input
                          type="radio"
                          value="InPerson"
                          checked={productType === "InPerson"}
                          onChange={() => handleProductTypeChange("InPerson")}
                        />
                        <span>In Person</span>
                      </label>
                    )}
                  </div>
                </div>

                {productType && (
                  <CustomTextField
                    id="test-time"
                    label="Interview Duration"
                    variant="outlined"
                    fullWidth
                    value={testTime}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      if (value.length <= 3) {
                        setTestTime(value);
                      }
                    }}
                    InputProps={{
                      endAdornment: <span className="timeUnit">Mins</span>,
                      inputProps: {
                        maxLength: 3,
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "400",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                    inputProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "400",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "450",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                  />
                )}
              </div>

              {productType === "InPerson" ? (
                <div className="textBox">
                  <TextField
                    id="outlined-basic"
                    label="Interviewer Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={interviewerEmail}
                    onChange={(e) => setInterviewerEmail(e.target.value)}
                    inputProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "500",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "450",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Meet Link"
                    variant="outlined"
                    fullWidth
                    type="url"
                    value={meetUrl}
                    onChange={(e) => setMeetUrl(e.target.value)}
                    inputProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "500",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#626264",
                        fontSize: "0.9rem",
                        fontWeight: "450",
                        fontFamily: "Quicksand, sans-serif",
                      },
                    }}
                  />
                </div>
              ) : (
                <>
                  {productType !== "Employer" && (
                    <>
                      <div className="inputBox">
                        <span className="title">Interview Based on</span>
                        <div className="childInputBox">
                          <label className="label">
                            <input
                              type="radio"
                              value="JD"
                              checked={interviewType === "JD"}
                              onChange={() => handleInterviewTypeChange("JD")}
                            />
                            <span>JD</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="Resume"
                              checked={interviewType === "Resume"}
                              onChange={() =>
                                handleInterviewTypeChange("Resume")
                              }
                            />
                            <span>Resume</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="JD + Resume"
                              checked={interviewType === "JD + Resume"}
                              onChange={() =>
                                handleInterviewTypeChange("JD + Resume")
                              }
                            />
                            <span>JD + Resume</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="Skill"
                              checked={interviewType === "Skill"}
                              onChange={() =>
                                handleInterviewTypeChange("Skill")
                              }
                            />
                            <span>Skill</span>
                          </label>
                        </div>
                      </div>

                      {interviewType === "Skill" && (
                        <div className="textBox">
                          <Stack
                            spacing={3}
                            sx={{ width: "100%", marginBottom: "1rem" }}
                          >
                            <Autocomplete
                              multiple
                              id="tags-standard"
                              options={technicalSkills}
                              getOptionLabel={(option) => option}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some(
                                  (option) => inputValue === option
                                );

                                if (inputValue !== "" && !isExisting) {
                                  filtered.push(inputValue);
                                }

                                return filtered;
                              }}
                              onChange={handleSkillsChange}
                              value={selectedSkills}
                              selectOnFocus
                              clearOnBlur
                              freeSolo
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              onBlur={handleInputBlur}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{ backgroundColor: "#F0F0F6" }}
                                />
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Skills..."
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      )}

                      <div className="inputBox">
                        <span className="title">Interview Type</span>
                        <div className="childInputBox">
                          <label className="label">
                            <input
                              type="radio"
                              value="mcq"
                              checked={testType === "mcq"}
                              onChange={() => handleTestTypeChange("mcq")}
                            />
                            <span>MCQs</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="general"
                              checked={testType === "general"}
                              onChange={() => handleTestTypeChange("general")}
                            />
                            <span>Subjective</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="coding"
                              checked={testType === "coding"}
                              onChange={() => handleTestTypeChange("coding")}
                            />
                            <span>Coding</span>
                          </label>
                          <label className="label">
                            <input
                              type="radio"
                              value="allTypesGeneral"
                              checked={testType === "allTypesGeneral"}
                              onChange={() =>
                                handleTestTypeChange("allTypesGeneral")
                              }
                            />
                            <span>
                              General (Includes all types of Questions)
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="textBox">
                        <div className="inputBox">
                          <span className="title">Difficulty Level</span>
                          <div className="childInputBox">
                            <label className="label">
                              <input
                                type="radio"
                                value="easy"
                                checked={difficultyLevel === "easy"}
                                onChange={() => setDifficultyLevel("easy")}
                              />
                              <span>Easy</span>
                            </label>
                            <label className="label">
                              <input
                                type="radio"
                                value="moderate"
                                checked={difficultyLevel === "moderate"}
                                onChange={() => setDifficultyLevel("moderate")}
                              />
                              <span>Moderate</span>
                            </label>
                            <label className="label">
                              <input
                                type="radio"
                                value="difficult"
                                checked={difficultyLevel === "difficult"}
                                onChange={() => setDifficultyLevel("difficult")}
                              />
                              <span>Difficult</span>
                            </label>
                          </div>
                        </div>

                        <div className="numberMainBox">
                          <label className="label">Number of Questions</label>
                          <div className="numberBox">
                            <button
                              className="numberBtn btn1"
                              onClick={handleDecreaseNumber}
                            >
                              -
                            </button>
                            <input
                              className="numberInput"
                              type="number"
                              value={numberOfQue}
                              onChange={(e) => setNumberOfQue(e.target.value)}
                              max={testType === "coding" ? 5 : 30}
                            />
                            <button
                              className="numberBtn btn2"
                              onClick={handleIncreaseNumber}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {productType === "Employer" && (
                <div className="inputBox attachedTestBox">
                  <span className="title">Select Test</span>
                  {attachedTest ? (
                    <div className="attachedTestInfo">
                      <div className="testDetails">
                        <p>
                          <span>Test Name:</span> {attachedTest.name}
                        </p>
                        <p>
                          <span>Test Type:</span> {attachedTest.testType}
                        </p>
                        <p>
                          <span>Role:</span> {attachedTest.role}
                        </p>
                        <p>
                          <span>Number of Questions:</span>{" "}
                          {attachedTest.testQuestions.length}
                        </p>
                      </div>
                      <button
                        className="detachButton"
                        onClick={handleDetachTest}
                      >
                        Unselect Test
                      </button>
                    </div>
                  ) : (
                    <div className="noTestAttached">
                      <p>
                        No test is selected. Please select one to schedule an
                        Employer Test.
                      </p>
                      <button
                        className="attachButton"
                        onClick={() => setOpenTestModal(true)}
                      >
                        Select Test
                      </button>
                    </div>
                  )}
                </div>
              )}
              <InviteButton
                handleInvite={handleInvite}
                handleNext={handleNext}
                handlePrev={handlePrev}
                step={step}
              />
            </div>
          )}

          <ModalHOC
            openNewInterviewModal={openTestModal}
            setOpenNewInterviewModal={setOpenTestModal}
            component={
              <AvailableTestList
                handleAttachTest={handleAttachTest}
                closeModal={() => setOpenTestModal(false)}
              />
            }
          />

          {step === 3 && (
            <div className="step3Box">
              <InviteReviewList
                handleInvite={handleInvite}
                handleNext={handleNext}
                handlePrev={handlePrev}
                step={step}
              />
            </div>
          )}
        </div>

        {/* <ButtonBox>
          <Button onClick={handlePrev}>Back</Button>
          {step <= 2 && <Button onClick={() => handleNext()}>Next</Button>}
          {step === 3 && <Button onClick={handleInvite}>Send Invite</Button>}
        </ButtonBox> */}
      </Container>
      {/* </MainContainer> */}
    </>
  );
}

const MainContainer = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 6.5rem 3rem 2rem 3rem;
  align-items: center;
  // width: calc(100% - 6rem);
  width: 100%;
  justify-content: center;

  /* Hide the up and down arrows in number input */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .numberMainBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: -0.75rem;

    .label {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  .attachedTestBox {
    padding: 1.5rem 1rem;
  }

  .attachedTestInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .testDetails {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .testDetails p {
    font-size: 0.9rem;
    margin: 0;
  }

  .testDetails p span {
    font-weight: 600;
    margin-right: 0.5rem;
  }

  .noTestAttached {
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .noTestAttached p {
    font-size: 0.9rem;
    margin: 0;
  }

  .detachButton,
  .attachButton {
    font-family: var(--font);
    background-color: #fff;
    color: black;
    border: 1.5px solid var(--cyan);
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--cyan);
      color: #fff;
    }
  }
  .numberBox {
    width: 40%;
    height: 3rem;
    display: flex;

    .numberBtn {
      height: 100%;
      width: 3.5rem;
      border: none;
      background-color: var(--cyan);
      color: var(--white);
      font-size: 1.4rem;
      cursor: pointer;
    }

    .btn1 {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      font-family: var(--font);
    }

    .btn2 {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      font-family: var(--font);
    }

    .numberInput {
      height: 100%;
      width: 6rem;
      padding: 0 2.3rem;
      box-sizing: border-box;
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      background-color: #f0f0f0;
      font-family: var(--font);
    }
  }

  .prev {
    background-color: var(--cyan);
    padding: 0.1rem;
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    color: var(--white);
    font-family: var(--font);
  }

  .prev:hover {
    color: var(--color);
  }

  .btn {
    padding: 0.5rem 1rem;
    margin-top: 0rem;
    background-color: var(--cyan);
    border: none;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .smallTextBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    padding-left: 2rem;
  }

  .textBox {
    display: flex;
    align-items: start;
    width: 100%;
    gap: 2rem;
  }

  .smallText {
    font-size: 0.75rem;
  }

  .mainBox {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    align-items: center;
    width: 100%;

    .step1Box {
      width: 100%;
      // max-width: 1200px;
      // height: 540px;
      display: block;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 0rem;
      position: relative;
      background-color: white;
      box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;

      .timeSlot {
        width: fit-content;
        display: block;
        margin: 30px auto;
        margin-top: 20px;
        margin-bottom: 15px;
        cursor: pointer;
        text-align: center;
        // border: 0.08rem solid lightgrey;
        outline-color: lightgrey;
        outline-width: 0.05rem;
        border-radius: 0.3rem;
        padding: 0.5rem 2rem;
        font-weight: 700;
        font-size: clamp(0.7rem, 2.5vw, 1.2rem) !important;
        font-family: "Quicksand" !important;
      }
      // .timeSlot:hover {
      //   border: 0.08rem solid var(--cyan);
      //   outline-color: var(--cyan);
      // }
      .btns {
        display: flex;
        justify-content: center;
        margin: 2rem 0 1rem;
      }

      .step1ChildBox {
        width: 100%;
        // height: inherit;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 5rem;
      }

      .calendarContainer {
        flex-basis: 48%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        .calendarBox {
          border: 0.08rem solid lightgrey;
          border-radius: 0.5rem;
          height: 340px;
        }
      }

      .slotBox {
        flex-basis: 48%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;

        .timeContainer {
          .css-gtuab9-MuiStack-root {
            width: 100%;
            overflow: hidden;
            .MuiStack-root,
            .css-gtuab9-MuiStack-root {
              overflow: hidden;
              width: 100%;
            }
          }
          .MuiStack-root,
          .css-gtuab9-MuiStack-root {
            overflow: hidden;
            width: 100%;
          }
          .slotChildBox {
            width: 100%;
          }
        }
      }
      .smallTextBox {
        position: absolute;
        bottom: 72px;
        left: 180px;
        width: 34%;
        display: flex;
        flex-flow: row;
        gap: 1rem;
        align-items: center;
      }
    }

    .step2Box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0rem 1rem;
      margin-top: 5rem;
      margin-bottom: 1rem;
      box-sizing: border-box;
      background-color: white;
      box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;

      .inputBoxMain {
        width: 97%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.08rem solid #c4c4c4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .childInputBoxMain {
        display: flex;
        gap: 2rem;
        flex-flow: row wrap;
      }
      .inputBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border: 0.08rem solid #c4c4c4;
        padding: 1rem 1rem;
        border-radius: 0.3rem;
        position: relative;
        box-sizing: border-box;
      }

      .childInputBox {
        display: flex;
        gap: 0.5rem;
        flex-flow: row wrap;
      }

      .title {
        font-size: 0.8rem;
        font-weight: 600;
        position: absolute;
        top: -0.6rem;
        background-color: white;
        padding: 0 0.3rem;
      }
    }

    .step3Box {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    margin-bottom: 0rem;

    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        background-color: #f0f0f6;
        &:before {
          box-shadow: inset 0 0 0 0.3rem var(--cyan);
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      padding: 0.3rem 0.75rem 0.3rem 0.3rem;
      border-radius: 99rem;
      transition: 0.25s ease;
      &:hover {
        background-color: mix(#fff, var(--cyan), 84%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em var(--cyan);
      }
    }
  }
  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff;
    background-color: #18e2d3 !important;
    font-weight: 500;
  }

  .MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff;
    background-color: #18e2d3 !important;
    font-weight: 500;
  }

  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus,
  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:active {
    will-change: unset !important;
    background-color: #18e2d3 !important;
  }

  .css-15a0tn7.Mui-selected:hover,
  .css-15a0tn7.Mui-selected:focus,
  .css-15a0tn7.Mui-selected:active {
    will-change: unset !important;
    background-color: #18e2d3 !important;
  }

  .css-6exafu.Mui-selected:hover,
  .css-6exafu.Mui-selected:focus,
  .css-6exafu.Mui-selected:active {
    will-change: unset !important;
    background-color: #18e2d3 !important;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-weight: 450 !important;
    font-family: Quicksand, sans-serif !important;
    height: 1.8rem;
  }

  .cpvhNf .MuiOutlinedInput-input {
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    font-weight: 450;
    font-family: Quicksand, sans-serif;
    color: #626264;
  }
  .timeInputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }

  .timeInputLabel {
    font-size: 0.9rem;
    font-family: Quicksand, sans-serif;
    color: #626264;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .timeInputField {
    font-family: Quicksand, sans-serif;
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
  }

  .timeInputField input {
    font-family: Quicksand, sans-serif;
    border: none;
    outline: none;
    font-size: 0.9rem;
    width: calc(100% - 40px);
    padding: 0.25rem;
    color: #626264;
    font-weight: 400;
  }

  .timeUnit {
    font-family: Quicksand, sans-serif;
    font-size: 0.9rem;
    color: black;
    font-weight: 500;
    margin-left: 0.5rem;
  }

  .defaultBoxMain {
    display: flex;
    gap: 2rem;
    width: 100%;
  }

  .MuiPickersCalendarHeader-labelContainer {
    font-family: Quicksand, sans-serif;
  }

  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
    font-family: Quicksand, sans-serif;
    font-weight: 500;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  margin: 3rem 0;
  gap: 2rem;
`;

const Button = styled.button`
  background-color: var(--cyan);
  color: var(--white);
  border: none;
  padding: 0.5rem 0.8rem;
  width: 135px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);
`;
const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 3.8rem;
  }

  & .MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75);
    font-size: 0.9rem;
    background-color: white !important;
    font-family: Quicksand, sans-serif !important;
    padding: 0 4px;
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  & .MuiOutlinedInput-input {
    padding: 0.5rem 0.8rem;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
    font-size: 1.1rem;
    font-weight: 600;
    color: black;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75);
    font-size: 1.1rem;
    font-weight: 600;
    color: black;
    background-color: white;
    padding: 0 4px;
  }
`;
