import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { performSignUpWithLinkedIn } from "../../../slices/authSlice";
import styled from "styled-components";
import Loader from "../../../components/commonComponents/Loader";

const LINKEDIN_CLIENT_SECRET = "fHkU2HEW3YaxIgQ4";
const LINKEDIN_CLIENT_ID = "777bowys7v4k6k";
const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_LINKEDIN_CALLBACK_URL;
const LinkedInOAuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLinkedInCallback = () => {
    const queryString = window.location.search;
    console.log("Query String:", queryString);
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    console.log("Query String:", code);

    if (code) {
      console.log("Received code from LinkedIn:", code);
      handleLogin(code);
    }
  };

  const handleLogin = async (code) => {
    try {
      // Exchange the code for an access token
      const data = await fetch(
        "https://www.linkedin.com/oauth/v2/accessToken",
        {
          method: "POST",
          // mode: "no-cors",
          body: new URLSearchParams({
            grant_type: "authorization_code",
            code,
            redirect_uri: LINKEDIN_CALLBACK_URL,
            client_id: LINKEDIN_CLIENT_ID,
            client_secret: LINKEDIN_CLIENT_SECRET,
          }),
          // headers: {
          //   "Content-Type": "application/x-www-form-urlencoded",
          //   "Access-Control-Allow-Origin": "*",
          // },
        }
      );

      if (data.ok) {
        const responseData = await data.json();
        const accessToken = responseData.access_token;
        console.log("Access token:", JSON.stringify(responseData));

        // Fetch the user's LinkedIn profile
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/linkedin-login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              credential: `${accessToken}`,
            }),
          }
        );
        if (response.ok) {
          const userData = await response.json();
          console.log("User data:", userData);
          dispatch(performSignUpWithLinkedIn(userData?.data?.data));
          navigate("/dashboard/jobseeker");
        } else {
          console.error("Failed to fetch user profile:", response.statusText);
        }
      } else {
        // Handle HTTP error status
        console.error("Failed to get access token:", data.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error.message);
    }
  };

  React.useEffect(() => {
    handleLinkedInCallback();
  }, []);

  return (
    <div>
      <Loader message="Logging you in...please wait" />
    </div>
  );
};

export default LinkedInOAuthCallback;

export const StyledScorecard = styled.div``;
