import React from "react";
import styled from "styled-components";
import img1 from "../../assets/HomePageReviews/img1.jpg";
import img2 from "../../assets/HomePageReviews/img2.jpg";
import img3 from "../../assets/HomePageReviews/img3.jpg";
import img4 from "../../assets/HomePageReviews/img4.jpeg";
import linkedin from "../../assets/HomePageReviews/linkedin.png";

const team = [
  {
    profileImg: img3,
    name: "Ranganath Raghavan",
    title: "Chief Mentor & Partner",
    // text: "About",
    linkedinUrl: "https://www.linkedin.com/in/ranganathraghavan/",
  },
  {
    profileImg: img2,
    name: "Braj Kishore",
    title: "Production Engineer",
    // text: "About",
    linkedinUrl: "https://www.linkedin.com/company/otohire/",
  },
  {
    profileImg: img1,
    name: "Amit Agarwal",
    title: "AI/ML Specialist",
    // text: "About",
    linkedinUrl: "https://www.linkedin.com/company/otohire/",
  },
  {
    profileImg: img4,
    name: "Radhika Singh",
    title: "Delivery Lead",
    // text: "About",
    linkedinUrl: "https://www.linkedin.com/company/otohire/",
  },
];

const TeamSection = () => {
  const openLinkedInProfile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <div className="floatingBox">
        {team.map((review, i) => (
          <div className="card" key={i}>
            <img className="img" src={review.profileImg} />
            <span className="name">
              {review.name}{" "}
              {review.linkedinUrl && (
                <img
                  src={linkedin}
                  className="linkedinIcon"
                  onClick={() => openLinkedInProfile(review.linkedinUrl)}
                />
              )}
            </span>
            <span className="title">{review.title}</span>
            <span className="text">{review.text}</span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default TeamSection;

const Box = styled.div`
  width: 90%;
  margin: 5rem auto 6rem auto;
  display: flex;
  background: linear-gradient(to bottom, #f0f0f0, #89f3eb);
  border-radius: 0.75rem;
  position: relative;
  height: 10rem;

  .floatingBox {
    width: 100%;
    display: flex;
    gap: 1%;
    justify-content: space-evenly;
    position: absolute;
    top: -1rem;
  }

  .card {
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 0.1rem 0.5rem 0.1rem rgba(0, 0, 0, 0.25);
    padding: 1rem 1%;
    border-radius: 1rem;
    background-color: var(--white);
    color: var(--color);

    .img {
      width: 4rem;
      border-radius: 2rem;
    }

    .name {
      font-size: 1rem;
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .linkedinIcon {
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
      }
    }

    .title {
      font-size: 0.85rem;
      font-weight: 600;
      line-height: 1rem;
      text-align: center;
    }

    .text {
      font-size: 0.725rem;
      font-weight: 400;
      line-height: 0.95rem;
      text-align: center;
      color: var(--color);
    }
  }
`;
