import axios from "axios";
import HTTP from "../../../backendApi";



export const getResourceById = async (id) => {
    

    // const config = {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //         'Content-Type': 'application/json'
    //     }
    // };

    try {
        const response = await HTTP.get(`/users/${id}`);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}




