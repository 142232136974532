import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NewLoader from "../components/commonComponents/newLoader";
import EmpCommonButton from "../components/Interviews/EmployerDashboard/commonComponents/EmpCommonButton";

export const ReasonAndConfirmation = ({ setConfirmation, setReason, reason, handleReschedule }) => {
  const handleClose = () => {
    setConfirmation(false);
  };
  const handleChange=(e)=>{
    setReason(e.target.value)
  }

  return (
    <StyledLimitsPopup>
      <div className="popup">
        <div className="close-button" onClick={handleClose}>
          &times;
        </div>
        <h1 className="headings">Candidate Reason</h1>
        <div className="textAreaBox">
          <label>Candidate Reason</label>
          <Textarea
            name="questionText"
            value={reason}
            onChange={handleChange}
          />
        </div>
        <div className="btnBox">
              
                <EmpCommonButton
                  text="Cancel"
                  func={() => handleClose()}
                />

                <EmpCommonButton
                  text="Submit"
                  func={() => handleReschedule(reason)}
                />

            </div>
      </div>
    </StyledLimitsPopup>
  );
};

const StyledLimitsPopup = styled.div`
.popup{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`
const Textarea = styled.textarea``


// const StyledLimitsPopup = styled.div`
//   .popup {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: white;
//     padding: 20px;
//     border: 1px solid #ccc;
//     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//     z-index: 2000;
//     width: 40%;
//   }
//   .headings {
//     text-align: center;
//     padding: 20px 0;
//   }
//     .textAreaBox {
//     width: 100%;
//     position: relative;
//     margin-top: 0.5rem;

//     label {
//       font-size: 0.7rem;
//       font-weight: 600;
//       position: absolute;
//       top: -0.5rem;
//       left: 0.5rem;
//       padding: 0 0.5rem;
//       background-color: var(--white);
//     }
//   }
//   .close-button {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     width: 24px;
//     height: 24px;
//     background-color: #f1f1f1;
//     color: #333;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 18px;
//     font-weight: bold;
//     cursor: pointer;
//     border-radius: 50%;
//     transition: background-color 0.3s ease;
//     text-align: center;

//     &:hover {
//       background-color: #ddd;
//     }
//   }
  
//   }
// `;

// const Textarea = styled.textarea`
//   padding: 10px;
//   width: 100%;
//   margin-bottom: 20px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-sizing: border-box;
//   outline-color: #1976d2;
//   background-color: #f6f6fb;
// `;