import React from "react";
import LinkedInlogo from "./../../../assets/linkedin_auth.png";

const LINKEDIN_CLIENT_SECRET = "fHkU2HEW3YaxIgQ4";
const LINKEDIN_CLIENT_ID = "777bowys7v4k6k";
const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_LINKEDIN_CALLBACK_URL;
const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  LINKEDIN_CALLBACK_URL
)}&state=foobar&scope=openid%20profile%20email`;

const LinkedInOAuth = () => {
  const handleLogin = async (code) => {
    try {
      // Exchange the code for an access token
      const data = await fetch(
        "https://www.linkedin.com/oauth/v2/accessToken",
        {
          method: "POST",
          body: new URLSearchParams({
            grant_type: "authorization_code",
            code,
            redirect_uri: LINKEDIN_CALLBACK_URL,
            client_id: LINKEDIN_CLIENT_ID,
            client_secret: LINKEDIN_CLIENT_SECRET,
          }),
        }
      );

      if (data.ok) {
        const responseData = await data.json();
        const accessToken = responseData.access_token;
        console.log("Access token:", JSON.stringify(responseData));

        // Fetch the user's LinkedIn profile
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/auth/linkedin-login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              credential: `Bearer ${accessToken}`,
            }),
          }
        );
        print(response);
        if (response.ok) {
          const userData = await response.json();
          console.log("User data:", userData);
          // Handle the user data as needed
          navigate("/dashboard/jobseeker");
        } else {
          console.error("Failed to fetch user profile:", response.statusText);
        }
      } else {
        // Handle HTTP error status
        console.error("Failed to get access token:", data.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error.message);
    }
  };

  const handleLinkedInCallback = () => {
    const queryString = window.location.search;
    console.log("Query String:", queryString);
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    if (code) {
      console.log("Received code from LinkedIn:", code);
      handleLogin(code);
    }
  };

  React.useEffect(() => {
    handleLinkedInCallback();
  }, []);

  return (
    <div>
      <a href={linkedinOAuthURL}>
        <img
          src={LinkedInlogo}
          style={{ height: "39px", width: "228.219px" }}
          alt="Sign in with LinkedIn"
        />
      </a>
    </div>
  );
};

export default LinkedInOAuth;
