import React, { useState } from "react";
import styled from "styled-components";

const CommonButton = ({ text, func, disabled = false, width }) => {
  return (
    <Button
      onClick={func}
      disabled={disabled}
      style={{ width: width ? width : "" }}
    >
      {text}
    </Button>
  );
};

export default CommonButton;

const Button = styled.button`
  background-color: ${(props) =>
    props.disabled ? "var(--backgroundColor)" : "var(--cyan)"};
  color: ${(props) => (props.disabled ? "var(--color)" : "var(--white)")};
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  border: ${(props) =>
    props.disabled ? "0.1rem solid var(--cyan)" : "0.1rem solid var(--cyan)"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;
