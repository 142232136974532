import axios from "axios"


export const addEmployee = async (formData, accessToken, clientCode) => {

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'X-Client-Code': clientCode,
  }
};
  const requestData = {...formData};

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/employers/employees`,requestData,config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
      } catch (error) {
        throw error;
      }
}

