import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RegisteredCandidates from "./sidebarPages/RegisteredCandidates";
import ActiveJds from "./sidebarPages/ActiveJds";
import EmpScheduledInterviews from "./sidebarPages/EmpScheduledInterviews";
import EmpScheduledCandidateList from "./sidebarPages/EmpScheduledCandidateList";
import { useSelector } from "react-redux";
import { getStatusWiseCount } from "../../../functions/api/interview/getStatusWiseCount";
import { getJdsForMatching } from "../../../functions/api/employers/match/getJdsForMatching";
import { getProfiles } from "../../../functions/api/resume/getProfiles";

import { EmpMetrics1 } from "../../../utils/contantData";
import { EmpMetrics2 } from "../../../utils/contantData";
import { EmpMetrics3 } from "../../../utils/contantData";
import { EmpMetrics4 } from "../../../utils/contantData";
import EmpApplications from "./sidebarPages/EmpApplications";
import EmpApplicationCandidatesList from "./sidebarPages/EmpApplicationCandidatesList";
import { getJobApplications } from "../../../functions/api/jobApplication/getJobApplications";
import scheduledInterviewImg from "../../../assets/icons/metric2.1.png";
import activeJdsImg from "../../../assets/icons/metric2.2.png";
import candidatePipelinesImg from "../../../assets/icons/metric2.4.png";
import EmployerAgencyJdList from "../EmployerAgency/EmployerAgencyJdList";
import EmployerAgencyCandidateList from "../EmployerAgency/EmployerAgencyCandidateList";
import JdListingTabs from "../../../pages/agency/JdListingTabs";
import { useDispatch } from "react-redux";
import { setCurrentMetric } from "../../../slices/configSlice";
import { getJobApplicationCount } from "../../../functions/api/jobApplication/getJobApplicationCount";
import { toast } from "react-toastify";
import { getAgencyMetrics } from "../../../functions/api/agency/getAgencyMetrics";
import Invite from "./Schedule/Invite";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
  width: 96%;
  padding: 0 2%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0% 2rem 0%;
  gap: 2%;

  .selected {
    background: linear-gradient(to bottom, #f0f0f0, #d9fbf9);
  }

  .achievedNumberBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.7rem;
    background-color: var(--white);
    padding: 1rem 0 1.5rem 0;
    width: 22.5%;
    height: 6rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

    .top {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .achievedNumberBox:hover {
    cursor: pointer;
  }

  .achievedNumberDigit {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color);
  }

  .achievedNumberText {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--color);
    text-align: center;
  }

  .hrLine {
    width: 100%;
    border-top: 0.1rem groove lightgrey;
    margin: -0.2rem 0 -0.9rem 0;
    box-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.25);
  }
`;

const EmployeMetrics = ({
  page,
  setPage,
  setCurrentItem,
  setEmpClientCode,
  setSelectedJDID,
}) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.userType);
  const currentMetric = useSelector((state) => state.config?.currentMetric);
  const [metrics, setMetrics] = useState([]);
  const [count, setCount] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [poolCount, setPoolCount] = useState(0);
  const [applicationCount, setApplicationCount] = useState(0);
  const [agencyMetrics, setAgencyMetrics] = useState({});
  const [inviteProcess, setInviteProcess] = useState({});

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector((state) => state.auth.userData?.clientCode);

  useEffect(() => {
    dispatch(
      setCurrentMetric(userType === "EMPLOYER" ? "interviews" : "jdListing")
    );
  }, []);

  useEffect(() => {
    const getCount = async () => {
      try {
        const res = await getStatusWiseCount(accessToken);
        setMetrics(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getCount();

    async function getData() {
      try {
        const res = await getJdsForMatching(accessToken, clientCode);
        if (res?.data?.data?.length) setCount(res?.data?.data?.length);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();

    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode);
        if (res) {
          setPoolCount(res?.data?.data?.length);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getCandidates();

    const getApplicationsCount = async () => {
      try {
        const res = await getJobApplicationCount(accessToken, clientCode);
        if (res) setApplicationCount(res?.data);
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getApplicationsCount();

    const getMetricsCount = () => {
      getAgencyMetrics()
        .then((res) => {
          if (res) {
            setAgencyMetrics(res);
          }
        })
        .catch((error) => {
          const errMsg =
            error?.response?.data?.notify?.message ||
            "An error occurred. Please try again.";
          toast.error(errMsg, { autoClose: 8000 });
        })
    };
        
    // const getMetricsCount = async () => {
    //   try {
    //     const res = await getAgencyMetrics();
    //     if (res) setAgencyMetrics(res?.data);
    //   } catch (error) {
    //     const errMsg =
    //       error?.response?.data?.notify?.message ||
    //       "An error occurred. Please try again.";
    //     toast.error(errMsg, 8000);
    //   }
    // };
    getMetricsCount();
  }, [currentMetric]);

  useEffect(() => {
    if (metrics?.length) {
      setCompleted(metrics?.find((item) => item?.status == "COMPLETED")?.count);
      setScheduled(metrics?.find((item) => item?.status == "SCHEDULED")?.count);
    }
  }, [metrics]);

  useEffect(() => {
    setPage({ index: 1, jdId: null });
  }, []);

  const handleCurrentMetricChange = (metric) => {
    dispatch(setCurrentMetric(metric));
  };

  return (
    <>
      {inviteProcess?.isOpen ? (
        <MainContainer>
          <Invite
            jdId={inviteProcess.jdId}
            setInviteProcess={setInviteProcess}
          />
        </MainContainer>
      ) : (
        <MainContainer>
          {userType === "EMPLOYER" ? (
            <Container>
              <div
                className={`achievedNumberBox ${
                  currentMetric === EmpMetrics1.text ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange(EmpMetrics1.text)}
              >
                <div className="top">
                  <img src={EmpMetrics1.img} />
                  <span className="achievedNumberDigit">
                    {scheduled ? scheduled : 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">{EmpMetrics1.title}</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === EmpMetrics2.text ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange(EmpMetrics2.text)}
              >
                <div className="top">
                  <img src={EmpMetrics2.img} />
                  <span className="achievedNumberDigit">{count}</span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">{EmpMetrics2.title}</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === EmpMetrics3.text ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange(EmpMetrics3.text)}
              >
                <div className="top">
                  <img src={EmpMetrics3.img} />
                  <span className="achievedNumberDigit">
                    {applicationCount ? applicationCount : 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">{EmpMetrics3.title}</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === EmpMetrics4.text ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange(EmpMetrics4.text)}
              >
                <div className="top">
                  <img src={EmpMetrics4.img} />
                  <span className="achievedNumberDigit">
                    {poolCount ? poolCount : 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">{EmpMetrics4.title}</span>
              </div>
            </Container>
          ) : (
            <Container>
              <div
                className={`achievedNumberBox ${
                  currentMetric === "jdListing" ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange("jdListing")}
              >
                <div className="top">
                  <img src={activeJdsImg} />
                  <span className="achievedNumberDigit">
                    {agencyMetrics?.activeJds ?? 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">JD Listings</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === "active-Jds" ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange("active-Jds")}
              >
                <div className="top">
                  <img src={activeJdsImg} />
                  <span className="achievedNumberDigit">
                    {agencyMetrics?.activeJds ?? 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">Active JDs</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === "scheduled-interviews" ? "selected" : ""
                }`}
                onClick={() =>
                  handleCurrentMetricChange("scheduled-interviews")
                }
              >
                <div className="top">
                  <img src={scheduledInterviewImg} />
                  <span className="achievedNumberDigit">
                    {agencyMetrics?.scheduled ?? 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">Scheduled Interviews</span>
              </div>
              <div
                className={`achievedNumberBox ${
                  currentMetric === "candidate-Pipelines" ? "selected" : ""
                }`}
                onClick={() => handleCurrentMetricChange("candidate-Pipelines")}
              >
                <div className="top">
                  <img src={candidatePipelinesImg} />
                  <span className="achievedNumberDigit">
                    {agencyMetrics?.pipeline ?? 0}
                  </span>
                </div>
                <span className="hrLine"></span>
                <span className="achievedNumberText">
                  Candidate in Pipelines
                </span>
              </div>
            </Container>
          )}
          {currentMetric === "interviews" && (
            <>
              {page?.index === 1 && (
                <EmpScheduledInterviews
                  page={page}
                  setPage={setPage}
                  setInviteProcess={setInviteProcess}
                />
              )}{" "}
              {page?.index === 2 && (
                <EmpScheduledCandidateList page={page} setPage={setPage} />
              )}
            </>
          )}
          {currentMetric === "applications" && (
            <>
              {page?.index === 1 && (
                <EmpApplications page={page} setPage={setPage} />
              )}{" "}
              {page?.index === 2 && (
                <EmpApplicationCandidatesList page={page} setPage={setPage} />
              )}
            </>
          )}
          {currentMetric === "activeJDs" && <ActiveJds />}
          {currentMetric === "candidatesPool" && <RegisteredCandidates />}

          {currentMetric === "active-Jds" && (
            <>
              {page?.index === 1 && (
                <EmployerAgencyJdList page={page} setPage={setPage} />
              )}{" "}
              {page?.index === 2 && (
                <EmployerAgencyCandidateList page={page} setPage={setPage} />
              )}
            </>
          )}
          {currentMetric === "jdListing" && (
            <JdListingTabs
              setCurrentItem={setCurrentItem}
              setEmpClientCode={setEmpClientCode}
              setSelectedJDID={setSelectedJDID}
            />
          )}
        </MainContainer>
      )}
    </>
  );
};

export default EmployeMetrics;
