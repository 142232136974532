import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import ReportDepartmentText from "../commonComponents/ReportDepartmentText";
import Chart from "react-google-charts";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Report = () => {
  const [BaseFilterData, setBaseFilterData] = useState();
  const [BusinessUnit, setBusinessUnit] = useState({
    selectedBusiness: [],
    businessUnitOptions: [],
  });
  const [JD, setJD] = useState({ selectedJD: [], jdOptions: [] });
  const [Recruiter, setRecruiter] = useState({
    selectedRecruiter: [],
    recruiterOptions: [],
  });

  const [selectedDuration, setSelectedDuration] = useState("Last 6 months");

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  // Mock data for charts (to be replaced with API data)
  const [openPosData, setOpenPosData] = useState([
    ["openPositions", "ratio"],
    ["Marketing", 26],
    ["Accounts", 22],
    ["Analytics", 22],
    ["IT", 16],
    ["Operations", 8],
    ["HR", 6],
  ]);

  const [candidateStatus, setCandidateStatus] = useState([
    ["CanStatus", "Req %"],
    ["In-Progress", 50],
    ["OnHold", 10],
    ["Rejected", 25],
    ["Offered", 15],
  ]);

  const [selectionRejectionData, setSelectionRejectionData] = useState([
    ["Interview Rounds", "Offered", "Rejected"],
    ["Round 1", 8, 2],
    ["Round 2", 5, 9],
    ["Round 3", 5, 2],
    ["Round 4", 3, 0],
  ]);

  // Chart options
  const openPosOptions = {
    pieHole: 0.3,
    is3D: true,
    backgroundColor: "transparent",
    legend: {
      position: "none",
    },
    colors: ["#fec007", "#eb3792", "#ef6102", "#6300ef", "#4384f4", "#d482e2"],
    chartArea: {
      left: 0,
      right: 0,
      top: 2,
      bottom: 2,
      width: "100%",
      height: "100%",
    },
    pieSliceTextStyle: {
      color: "white",
    },
    pieSliceBorderColor: "transparent",
    sliceVisibilityThreshold: 0,
    shadow: "none",
  };

  const candidateStatusOptions = {
    pieHole: 0.3,
    is3D: true,
    backgroundColor: "transparent",
    legend: {
      position: "none",
    },
    colors: ["#43a047", "#6300ef", "#D22B2B", "#c0cb32"],
    chartArea: {
      left: 0,
      right: 0,
      top: 2,
      bottom: 2,
      width: "100%",
      height: "100%",
    },
    pieSliceTextStyle: {
      color: "white",
    },
    pieSliceBorderColor: "transparent",
    sliceVisibilityThreshold: 0,
    shadow: "none",
  };

  const selectionRejectionOptions = {
    backgroundColor: "transparent",
    legend: {
      position: "none",
    },
    colors: ["#43a047", "#D22B2B"],
    chartArea: {
      left: 50,
      right: 50,
      top: 50,
      bottom: 50,
      width: "100%",
      height: "100%",
    },
    hAxis: {
      title: "Interview Rounds",
    },
    vAxis: {
      title: "Offered / Rejected Counts",
    },
    bar: { groupWidth: "75%" },
    isStacked: true,
    orientation: "horizontal",
  };

  //feeding data after the api hit
  const feedingBaseFilterData = (data) => {
    const jd = [];
    const bu = [];
    const rec = [];
    data.forEach((val) => {
      jd.unshift(val.jdId);
      bu.unshift(val.bu || "Empty");
      rec.unshift(val.recruiter);
    });
    setBusinessUnit((prev) => ({ ...prev, businessUnitOptions: bu }));
    setJD((prev) => ({ ...prev, jdOptions: jd }));
    setRecruiter((prev) => ({ ...prev, recruiterOptions: rec }));
  };

  //TODO replace mock data from real data

  // Effect to simulate fetching data from an API
  useEffect(() => {
    setBusinessUnit((prev) => ({ ...prev, selectedBusiness: [] }));
    setRecruiter((prev) => ({ ...prev, selectedRecruiter: [] }));
    setJD((prev) => ({ ...prev, selectedJD: [] }));

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const fetchData = async () => {
      let receivedData = [];
      try {
        // Example API endpoints
        const month = selectedDuration.split(" ");
        const BaseFilter = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/reports/jds?duration=${parseInt(
            month[1]
          )}`,
          config
        );
        receivedData = BaseFilter.data.data;
        feedingBaseFilterData(receivedData);
        setBaseFilterData(receivedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        return toast.error(error);
      }
    };

    fetchData();
  }, [selectedDuration]);

  const DurationOptions = [
    "Last 1 month",
    "Last 3 months",
    "Last 6 months",
    "Last 12 months",
  ];
  const totalApplicants = "100";
  const seekers = "15";
  const pool = "50";
  const agency = "35";
  const shortlistedCandidates = "80";
  const hired = "15";
  const inProgress = "65";

  // jd and recruiter option change according to business selectedOptions
  const filterBu = useCallback(
    (bu) => {
      let filterData = { recruiters: [], jds: [] };
      const processItem = (item) => {
        filterData.recruiters.push(item.recruiter);
        filterData.jds.push(item.jdId);
      };

      if (bu.length <= 0) {
        BaseFilterData?.forEach(processItem);
      } else {
        bu.forEach((item) => {
          BaseFilterData?.forEach((val) => {
            if ((item === "Empty" && val.bu === "") || val.bu === item) {
              processItem(val);
            }
          });
        });
      }
      return filterData;
    },
    [BaseFilterData, BusinessUnit.selectedBusiness]
  );

  //jd option change according to recruiter selectedOptions

  const filterRec = useCallback(
    (rec) => {
      let filtered = [];
      const processItem = (item) => {
        filtered.push(item.jdId);
      };

      if (rec.length <= 0) {
        if (BusinessUnit.selectedBusiness.length <= 0) {
          BaseFilterData?.forEach(processItem);
        } else {
          BusinessUnit.selectedBusiness.forEach((bu) => {
            BaseFilterData?.forEach((items) => {
              if ((bu === "Empty" && items.bu === "") || bu === items.bu) {
                processItem(items);
              }
            });
          });
        }
      } else {
        rec.forEach((items) => {
          BaseFilterData?.forEach((item) => {
            if (items === item.recruiter) {
              processItem(item);
            }
          });
        });
      }
      return filtered;
    },
    [BaseFilterData, BusinessUnit.selectedBusiness, Recruiter.selectedRecruiter]
  );

  // reset selectedJD and selectedRecruiter when selectedBU
  useEffect(() => {
    let filtered = filterBu(BusinessUnit.selectedBusiness);
    setRecruiter((prev) => ({
      ...prev,
      recruiterOptions: filtered.recruiters,
      selectedRecruiter:
        prev.selectedRecruiter.length > 0 ? [] : prev.selectedRecruiter,
    }));
    setJD((prev) => ({
      ...prev,
      jdOptions: filtered.jds,
      selectedJD: prev.selectedJD.length > 0 ? [] : prev.selectedJD,
    }));
  }, [BusinessUnit.selectedBusiness, filterBu]);

  //Reset selectedJD when selectedRecruiter changes
  useEffect(() => {
    setJD((prev) => ({
      ...prev,
      jdOptions: filterRec(Recruiter.selectedRecruiter),
      selectedJD: prev.selectedJD.length > 0 ? [] : prev.selectedJD,
    }));
  }, [Recruiter.selectedRecruiter, filterRec]);

  return (
    <Box>
      {/* Filter controls */}
      <FilterContainer>
        <StyledAutocomplete
          multiple
          value={BusinessUnit.selectedBusiness}
          onChange={(event, newValue) => {
            setBusinessUnit((prev) => ({
              ...prev,
              selectedBusiness: newValue,
            }));
          }}
          options={[...new Set(BusinessUnit.businessUnitOptions)]}
          renderInput={(params) => (
            <TextField {...params} label="Select Business Unit" />
          )}
        />

        <StyledAutocomplete
          multiple
          value={Recruiter.selectedRecruiter}
          onChange={(event, newValue) => {
            setRecruiter((prev) => ({ ...prev, selectedRecruiter: newValue }));
          }}
          options={[...new Set(Recruiter.recruiterOptions)]}
          renderInput={(params) => (
            <TextField {...params} label="Select Recruiter" />
          )}
        />
        <StyledAutocomplete
          multiple
          value={JD.selectedJD}
          onChange={(event, newValue) => {
            setJD((prev) => ({ ...prev, selectedJD: newValue }));
          }}
          options={[...new Set(JD.jdOptions)]}
          renderInput={(params) => (
            <TextField {...params} label="Select JD's" />
          )}
        />

        <StyledAutocomplete
          value={selectedDuration}
          onChange={(event, newValue) => {
            setSelectedDuration(newValue);
          }}
          options={DurationOptions}
          renderInput={(params) => (
            <TextField {...params} label="Select Duration" />
          )}
        />
      </FilterContainer>

      {/* Main content with charts */}
      <div className="box1">
        <div className="left">
          <StyledCard>
            <MainMetric>
              <MetricText>Total Applicants</MetricText>
              <MetricNumber>{totalApplicants}</MetricNumber>
            </MainMetric>
            <SubMetrics>
              <SubMetric>
                <SubMetricText>Seekers</SubMetricText>
                <SubMetricNumber>{seekers}</SubMetricNumber>
              </SubMetric>
              <SubMetric>
                <SubMetricText>Pool</SubMetricText>
                <SubMetricNumber>{pool}</SubMetricNumber>
              </SubMetric>
              <SubMetric>
                <SubMetricText>Agency</SubMetricText>
                <SubMetricNumber>{agency}</SubMetricNumber>
              </SubMetric>
            </SubMetrics>
          </StyledCard>
          <StyledCard>
            <MainMetric>
              <MetricText>Shortlisted Candidates:</MetricText>
              <MetricNumber>{shortlistedCandidates}</MetricNumber>
            </MainMetric>
            <SubMetrics>
              <SubMetric>
                <SubMetricText>Hired</SubMetricText>
                <SubMetricNumber>{hired}</SubMetricNumber>
              </SubMetric>
              <SubMetric>
                <SubMetricText>In-Progress</SubMetricText>
                <SubMetricNumber>{inProgress}</SubMetricNumber>
              </SubMetric>
            </SubMetrics>
          </StyledCard>
        </div>
        <div className="middle">
          <div className="mainCard child1">
            <span className="title">Open Positions</span>

            <div className="innerBox">
              <div className="innerBox1">
                {/* Mock data for charts (to be replaced with API data) */}
                <ReportDepartmentText text="Marketing" color="#fec007" />
                <ReportDepartmentText text="Accounts" color="#eb3792" />
                <ReportDepartmentText text="Analytics" color="#ef6102" />
                <ReportDepartmentText text="IT" color="#6300ef" />
                <ReportDepartmentText text="Operations" color="#4384f4" />
                <ReportDepartmentText text="HR" color="#d482e2" />
              </div>
              <div className="innerBox2">
                <Chart
                  chartType="PieChart"
                  width="28vw"
                  height="28vw"
                  data={openPosData}
                  options={openPosOptions}
                  className="donut-chart"
                />
              </div>
            </div>

            <div className="innerBoxBottom"></div>
          </div>
        </div>
      </div>

      <div className="box2">
        <div className="left">
          <div className="mainCard child1">
            <span className="title">Candidates Pipeline</span>

            <div className="innerBox">
              <div className="innerBox1">
                {/* Mock data for charts (to be replaced with API data) */}
                <ReportDepartmentText text="In-Progress" color="#43a047" />
                <ReportDepartmentText text="Offered" color="#c0cb32" />
                <ReportDepartmentText text="OnHold" color="#6300ef" />
                <ReportDepartmentText text="Rejected" color="#D22B2B" />
              </div>
              <div className="innerBox2">
                <Chart
                  chartType="PieChart"
                  width="28vw"
                  height="28vw"
                  data={candidateStatus}
                  options={candidateStatusOptions}
                  className="donut-chart"
                />
              </div>
            </div>

            <div className="innerBoxBottom"></div>
          </div>
        </div>
        <div className="middle">
          <div className="mainCard child1">
            <span className="title">
              Selection and Rejection Rates by Round
            </span>

            <div className="innerBox">
              <div className="innerBox2">
                <Chart
                  chartType="BarChart"
                  width="28vw"
                  height="28vw"
                  data={selectionRejectionData}
                  options={selectionRejectionOptions}
                  className="bar-chart"
                />
              </div>
            </div>

            <div className="innerBoxBottom"></div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const StyledAutocomplete = styled(Autocomplete)`
  width: 270px;
  // height:70px
  background-color: #f4f4f4;
  // background-color: white;

  .MuiInputLabel-root,
  .MuiInputBase-root {
    font-family: "Quicksand", sans-serif;
    color: black;
    font-weight: 500;
   
  .MuiAutocomplete-input {
    font-family: "Quicksand", sans-serif;
    color: black;

    .MuiChip-label {
      font-family: "Quicksand", sans-serif;
      color: black;
    }

    .MuiAutocomplete-option {
      font-family: "Quicksand", sans-serif;
      color: black;
    }
  }
`;

const StyledCard = styled.div`
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MainMetric = styled.div`
  height: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 0.5rem;
  background-color: #f4f4f4;
  border-radius: 0.25rem;
`;

const MetricText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  font-color: #c5c5c5;
`;

const MetricNumber = styled.span`
  font-size: 1.7rem;
  font-weight: 600;
`;

const SubMetrics = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

const SubMetric = styled.div`
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 0.25rem;
  padding: 2.5rem;
  flex: 1;
`;

const SubMetricText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  font-color: #c5c5c5;
`;

const SubMetricNumber = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 1.5rem;
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 0.5rem;
`;

const Box = styled.div`
  width: 96%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 1rem;

  .mainCard {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1.5rem 1rem;
    gap: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .innerBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;

      .innerBox1 {
        display: flex;
        gap: 0.75rem;
      }

      .innerBox2 {
      }
    }
  }
  .box1,
  .box2 {
    width: 100%;
    display: flex;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .middle {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-left: 1.5%;

      .child1 {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default Report;
