import axios from "axios";

export const canFeedBacks= async (Token)=>{

    try {
        const response=await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/interviewer/canFeedback?token=${Token}`)
       return response;
    } catch (error) {
        console.log(error)
        throw error;
    }
}