import axios from "axios"

export const uploadMedia = async (formdata, id, type, accessToken, clientCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
            'x-client-code': clientCode
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/interviews/uploadMedia/${id}?type=${type}`, formdata, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

