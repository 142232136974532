import React, { useState } from "react";
import styled from "styled-components";

const McqQueInterface = ({ queComp, options, value, setValue, seqOrder }) => {
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionSelect = (index) => {
    setSelectedOption(index);
  };

  return (
    <Box>
      <div className="queText">
        <span className="seqOrder">{seqOrder}. </span> {queComp && queComp}
      </div>

      <div className="mcqOptions">
        {options.map((option, index) => (
          <div
            className={`mcqOption`}
            onClick={() => {
              handleOptionSelect(index);
              setValue(option);
            }}
            style={{
              cursor: selectedOption == index ? "" : "pointer",
            }}
          >
            <span
              id="optionCheckBox"
              style={{
                borderColor: selectedOption === index ? "var(--cyan)" : "grey",
              }}
            >
              <span
                className="innerBox"
                style={{
                  backgroundColor:
                    selectedOption === index ? "var(--cyan)" : "transparent",
                }}
              ></span>
            </span>
            <span className="optionNumber">{option}.</span>
            {/* <span
              className="mcqOptionText"
              style={{
                color:
                  selectedOption === index ? "var(--cyan)" : "var(--color)",
              }}
            >
              {option?.split(".").slice(1).join(".")}
            </span> */}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default McqQueInterface;

const Box = styled.div`
  width: 100%;
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .queText {
    display: flex;
    width: 99%;
    gap: 1rem;

    .seqOrder {
      font-weight: 600;
    }
  }

  .mcqOptions {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 1rem;
  }

  .mcqOption {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--color);
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  #optionCheckBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.075rem solid grey;
    border-radius: 50%;

    .innerBox {
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      background-color: var(--white);
      border-radius: 50%;
      margin: 0.2rem;
    }
  }

  .mcqOptionText {
    display: flex;
    flex-flow: row wrap;
    padding: 0.5rem;
    width: 75%;
    border-radius: 0.3rem;
  }
`;
