import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGoogleClientId } from "../functions/api/authentication/getGoogleClientId";


export const fetchGoogleClientId = createAsyncThunk(
  'oauth/fetchGoogleClientId',
  async () => {
    
    try {
      const response = await getGoogleClientId();
      if (response?.status == "FAILED") {
        throw new Error(JSON.stringify(response?.response?.data));
      }
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.notify?.message || error?.message);
    }

  }
);

const oauthSlice = createSlice({
  name: 'oauth',
  initialState: {
    loading: false,
    clientId: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoogleClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGoogleClientId.fulfilled, (state, action) => {
        state.loading = false;
        state.clientId = action.payload;
      })
      .addCase(fetchGoogleClientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default oauthSlice.reducer;
