import axios from "axios";
import { toast } from "react-toastify";

export const forgetPasswordLogedIn = async (
  currentpassword,
  confirm,
  password,
  accessToken,
  clientCode = "otohire"
) => {
  const requestData = {
    oldPwd: currentpassword,
    cnfpwd: confirm,
    pwd: password,
    accessToken,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "x-client-code": clientCode,
    },
  };

  console.log(requestData);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/resetpwd`,
      requestData,
      config
    );
    console.log("Data:", response.data);
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    throw error;
  }
};
