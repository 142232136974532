import React, { useEffect, useState } from "react";
import styled from "styled-components";
import browseIcon from "../../../../assets/icons/browse.png";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addProfileWithFile } from "../../../../functions/api/resume/addProfileWithFile";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { getTagsConfig } from "../../../../functions/api/globalFunctions/getTagsConfig";
import LoaderDialog from "../../../commonComponents/infoDialog/LoaderDialog";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const Container = styled.div`
  width: 90%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 4rem;
`;

const Component = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  font-size: 0.9rem;
  background-color: var(--white);

  .registerBtn {
    padding: 0.5rem 0.8rem;
    background-color: var(--cyan);
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 1rem;
    align-self: center;
    font-family: var(--font);
  }

  .title {
    margin-bottom: 2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    gap: 1rem;

    .MuiFormHelperText-root {
      font-size: 0.6rem;
    }

    span {
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    @media (max-width: 2000px) {
      #outlined-basic {
        padding: 0.75rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1700px) {
      #outlined-basic {
        padding: 0.85rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1350px) {
      #outlined-basic {
        padding: 0.95rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    @media (max-width: 1200px) {
      #outlined-basic {
        padding: 1rem 0.5rem;
        background-color: #f6f6fb;
      }
    }

    .resumeBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
      margin-top: 0rem;
    }
  }
  #tags-standard {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
  }
`;

const Label = styled.label`
  font-weight: 600;
  margin: 0.7rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2.5rem;
  }

  span {
    color: var(--color);
  }
`;

const FileInput = styled.input`
  margin-bottom: 0rem;
  position: absolute;
  left: -9999px;
`;

const RegisterCandidate = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [refText, setRefText] = useState("");
  const [source, setSource] = useState("");
  const [dob, setDob] = useState(dayjs(new Date()));
  const [yoe, setYoe] = useState(0);
  const [position, setPosition] = useState("");
  const [nationality, setNationality] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [education, setEducation] = useState("");
  // const [remarks, setRemarks] = useState("");
  const [highestDegree, setHighestDegree] = useState("");
  const [major, setMajor] = useState("");
  const [yearOfPassing, setYearOfPassing] = useState("");
  const [grades, setGrades] = useState("");

  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [contactTouched, setContactTouched] = useState(false);
  const [positionTouched, setPositionTouched] = useState(false);
  const [nationalityTouched, setNationalityTouched] = useState(false);
  const [currentLocationTouched, setCurrentLocationTouched] = useState(false);
  const [educationTouched, setEducationTouched] = useState(false);
  // const [remarksTouched, setRemarksTouched] = useState(false);
  const [highestDegreeTouched, setHighestDegreeTouched] = useState(false);
  const [majorTouched, setMajorTouched] = useState(false);
  const [yearOfPassingTouched, setYearOfPassingTouched] = useState(false);
  const [gradesTouched, setGradesTouched] = useState(false);

  const [loading, setLoading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await getTagsConfig(accessToken, clientCode);
        if (res) {
          setAllTags(res?.data?.value?.split(","));
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg, 8000);
      }
    };

    getTags();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file?.name);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log("outside");
    try {
      const dobData = moment(dob?.format("YYYY-MM-DD"));

      if (selectedFileName == "") {
        toast.warning("All fields required");
        return;
      } else {
        console.log("Inside");
        setLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append(
          "payload",
          JSON.stringify({
            email,
            firstName,
            lastName,
            contact,
            source,
            dob: dobData?._i,
            yoe,
            position,
            nationality,
            currentLocation,
            education,
            // remarks,
            highestDegree,
            major,
            yearOfPassing,
            grades,
            tags: selectedTags?.join(","),
          })
        );

        const res = await addProfileWithFile(formData, accessToken, clientCode);

        if (res) {
          setLoading(false);
          toast.success("Profile successfully created");
          setEmail("");
          setContact("");
          setFirstName("");
          setLastName("");
          setSource("");
          setSelectedFile(null);
          setSelectedFileName("");
          setRefText("");
          setSelectedTags([]);
          setFirstNameTouched(false);
          setLastNameTouched(false);
          setContactTouched(false);
          setPositionTouched(false);
          setCurrentLocationTouched(false);
          setNationalityTouched(false);
          setEducationTouched(false);
          // setRemarksTouched(false);
          setHighestDegreeTouched(false);
          setMajorTouched(false);
          setYearOfPassingTouched(false);
          setGradesTouched(false);
          setCurrentLocation("");
          setEducation("");
          // setRemarks("");
          setHighestDegree("");
          setMajor("");
          setYearOfPassing("");
          setGrades("");
          setYoe(0);
          setPosition("");
          setNationality("");
          setDob(dayjs(new Date()));
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg);
      setLoading(false);
    }
  };

  const handleTagsChange = (_, newTags) => {
    setSelectedTags(newTags);
  };

  const expArr = [
    { value: "0", text: "Fresher" },
    { value: "1", text: "1 year" },
    { value: "2", text: "2 years" },
    { value: "3", text: "3 years" },
    { value: "4", text: "4 years" },
    { value: "5", text: "5 years" },
    { value: "6", text: "6 years" },
    { value: "7", text: "7 years" },
    { value: "8", text: "8 years" },
    { value: "9", text: "9 years" },
    { value: "10", text: "10 years" },
    { value: "11", text: "11 years" },
    { value: "12", text: "12 years" },
    { value: "13", text: "13 years" },
    { value: "14", text: "14 years" },
    { value: "15", text: "15 years" },
    { value: "16", text: "16 years" },
    { value: "17", text: "17 years" },
    { value: "18", text: "18 years" },
    { value: "19", text: "19 years" },
    { value: "20", text: "20 years" },
    { value: "21", text: "21 years" },
    { value: "22", text: "22 years" },
    { value: "23", text: "23 years" },
    { value: "24", text: "24 years" },
    { value: "25", text: "25 years" },
    { value: "26", text: "26 years" },
    { value: "27", text: "27 years" },
    { value: "28", text: "28 years" },
    { value: "29", text: "29 years" },
    { value: "30", text: "30 years" },
  ];

  return (
    <Container>
      {loading && <LoaderDialog />}
      <Component>
        <span className="title">Register New Candidate</span>
        <ValidatorForm onSubmit={handleRegister}>
          <TextValidator
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            errorMessages={["This field is required", "Email is not valid"]}
            validators={["required", "isEmail"]}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            fullWidth
          />

          <TextValidator
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            type="text"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameTouched(true);
            }}
            required
            errorMessages={[
              firstNameTouched ? "This field is required" : "",
              firstNameTouched ? "Must be a least 3 characters long" : "",
              firstNameTouched ? "Must be less than 30 chatacters long" : "",
            ]}
            validators={[
              "required",
              firstNameTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            type="text"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setLastNameTouched(true);
            }}
            required
            errorMessages={[
              lastNameTouched ? "This field is required" : "",
              lastNameTouched ? "Must be a least 3 characters long" : "",
              lastNameTouched ? "Must be less than 30 chatacters long" : "",
            ]}
            validators={[
              "required",
              lastNameTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            type="tel"
            value={contact}
            onChange={(e) => {
              setContact(e.target.value);
              setContactTouched(true);
            }}
            errorMessages={[
              // "This field is required",
              // "Must be a number",
              // "Must be at least 10 characters long",
              "Number must be at least 10 characters with country code",
            ]}
            validators={[
              "matchRegexp:^\\+?[0-9]{10,}$",
              "required",
              "isNumber",
              contactTouched ? "minStringLength:10" : "minStringLength:0",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            required
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{ width: "100%" }}>
              <DatePicker
                label="Date of Birth"
                sx={{
                  backgroundColor: "#F6F6FB",
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                  "& .MuiInputBase-input": {
                    color: "#626264",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                value={dob}
                onChange={(newValue) => setDob(dayjs(newValue))}
              />
            </DemoContainer>
          </LocalizationProvider>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Years of Experience
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Year of Experience"
              name="exp"
              value={yoe}
              onChange={(e) => setYoe(e.target.value)}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
              }}
            >
              {expArr?.map((exp) => (
                <MenuItem value={exp?.value}>{exp?.text}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextValidator
            id="outlined-basic"
            label="Position"
            variant="outlined"
            type="text"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
              setPositionTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              positionTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Nationality"
            variant="outlined"
            type="text"
            value={nationality}
            onChange={(e) => {
              setNationality(e.target.value);
              setNationalityTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              nationalityTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Current Location"
            variant="outlined"
            type="text"
            value={currentLocation}
            onChange={(e) => {
              setCurrentLocation(e.target.value);
              setCurrentLocationTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              currentLocationTouched
                ? "minStringLength:3"
                : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Education"
            variant="outlined"
            type="text"
            value={education}
            onChange={(e) => {
              setEducation(e.target.value);
              setEducationTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              educationTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          <TextValidator
            id="outlined-basic"
            label="Highest Degree"
            variant="outlined"
            type="text"
            value={highestDegree}
            onChange={(e) => {
              setHighestDegree(e.target.value);
              setHighestDegreeTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              highestDegreeTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Major"
            variant="outlined"
            type="text"
            value={major}
            onChange={(e) => {
              setMajor(e.target.value);
              setMajorTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              majorTouched ? "minStringLength:2" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Year of Passing"
            variant="outlined"
            type="text"
            value={yearOfPassing}
            onChange={(e) => {
              setYearOfPassing(e.target.value);
              setYearOfPassingTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a number",
              "Must be at least 4 characters long",
            ]}
            validators={[
              "required",
              "isNumber",
              yearOfPassingTouched ? "minStringLength:4" : "minStringLength:0",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />

          <TextValidator
            id="outlined-basic"
            label="Grades"
            variant="outlined"
            type="text"
            value={grades}
            onChange={(e) => {
              setGrades(e.target.value);
              setGradesTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              gradesTouched ? "minStringLength:1" : "minStringLength:0",
              "maxStringLength:10",
            ]}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          />
          {/* <TextValidator
            id="outlined-basic"
            label="Remarks"
            variant="outlined"
            type="text"
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
              setRemarksTouched(true);
            }}
            required
            errorMessages={[
              "This field is required",
              "Must be a least 3 characters long",
              "Must be less than 30 chatacters long",
            ]}
            validators={[
              "required",
              remarksTouched ? "minStringLength:3" : "minStringLength:0",
              "maxStringLength:29",
            ]}
            fullWidth
           inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
          /> */}
          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allTags}
              getOptionLabel={(option) => option}
              onChange={handleTagsChange}
              value={selectedTags}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  sx={{
                    backgroundColor: "#F6F6FB",
                    "& .MuiInputLabel-root": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                    },
                    "& .MuiInputBase-input": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              )}
            />
          </Stack>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Select Referral/Source
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={source}
              label="Select Referral/Source"
              onChange={(e) => setSource(e.target.value)}
              size="small"
              sx={{
                padding: "0rem 0 0.5rem 0",
                "& .MuiSelect-select": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            >
              <MenuItem value="EmployeeReferral">Employee Referral</MenuItem>
              <MenuItem value="Agency">Agency</MenuItem>
              <MenuItem value="InternalSourcing">Internal Sourcing</MenuItem>
              <MenuItem value="SocialMedia">Social Media</MenuItem>
              <MenuItem value="CompanyPortal">Company Portal</MenuItem>
              <MenuItem value="Other">Others</MenuItem>
            </Select>
          </FormControl>

          {source && (
            <TextValidator
              id="outlined-basic"
              label="Referral/Source text"
              variant="outlined"
              type="text"
              value={refText}
              onChange={(e) => setRefText(e.target.value)}
              errorMessages={[
                "This field is required",
                "Must be a least 3 characters long",
                "Must be less than 30 chatacters long",
              ]}
              validators={[
                "required",
                "minStringLength:3",
                "maxStringLength:29",
              ]}
              fullWidth
              required
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            />
          )}

          <div className="resumeBox">
            <Label htmlFor="input">
              <img src={browseIcon} /> <span>{selectedFileName}</span>
            </Label>
            <FileInput
              id="input"
              type="file"
              accept="*"
              onChange={handleFileChange}
            />
            <span>Select Resume</span>
          </div>
          <button className="registerBtn" type="submit">
            Register Candidate
          </button>
        </ValidatorForm>
      </Component>
    </Container>
  );
};

export default RegisterCandidate;
