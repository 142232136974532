import React from "react";
import styled from "styled-components";

export const InviteButton = ({handlePrev, handleNext, handleInvite, step}) => {
  return (
    <ButtonBox>
      <Button onClick={handlePrev}>Back</Button>
      {step < 3 && <Button onClick={() => handleNext()}>Next</Button>}
      {step === 3 && <Button onClick={handleInvite}>Send Invite</Button>}
    </ButtonBox>
  );
};

const ButtonBox = styled.div`
  display: flex;
  margin: 2rem 0 0;
  gap: 2rem;
`;
const Button = styled.button`
  background-color: var(--cyan);
  color: var(--white);
  border: none;
  padding: 0.5rem 0.8rem;
  width: 135px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);
`;