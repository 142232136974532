import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import threeDot from "../../../../assets/icons/threeDot.png";
import editIcon from "../../../../assets/icons/edit.png";
import deleteIcon from "../../../../assets/icons/delete.png";
import eyeIcon from "../../../../assets/icons/visible.png";
import shareIcon from "../../../../assets/icons/share.png";
import { getProfiles } from "../../../../functions/api/resume/getProfiles";
import { useSelector } from "react-redux";
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import CommonDialog from "../../../commonComponents/CommonDialog";
import DeleteDialogContent from "../../../commonComponents/DeleteDialogContent";
import { deleteCandidate } from "../../../../functions/api/resume/deleteCandidate";
import { searchCandidates } from "../../../../functions/api/resume/searchCandidates";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import TableSearchBar from "../commonComponents/TableSearchBar";
import { dateConversion } from "../../../../utils/timeZoneConversation";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { useDispatch } from "react-redux";
import download from "../../../../assets/icons/Profile/download.png";
import { toast } from "react-toastify";
import CandidateDetails from "./CandidateDetails";
import UpdateCandidate from "../UpdateCandidate";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import { getTagsConfig } from "../../../../functions/api/globalFunctions/getTagsConfig";

function Row(props) {
  const { row, index, candidateTrigger, setCandidateTrigger } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const dropdownRef = useRef(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const handleEdit = (row) => {
    setOpenEditModal(true);
    setCandidateData(row);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);
  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };
  // function to handle delete operation, which need to be passed to confirm delete dialog Comp as props
  const handleDelete = async (id) => {
    try {
      const res = await deleteCandidate(id, accessToken, clientCode);
      if (res) {
        toast.success("Candidate successfully deleted", 5000);
        setCandidateTrigger(!candidateTrigger);
      }
    } catch (error) {
      // Handle network errors or unexpected issues
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    } finally {
      // Close the modal or perform other cleanup tasks
      handleClose();
    }
  };

  // State, function to open and close Drawer
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, candidateData: CandidateDetails });
    // setState({ ...state, [anchor]: open });
  };

  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }
      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );
      // Ensure the filename ends with .pdf
      const pdfName = name.endsWith(".pdf") ? name : `${name}.pdf`;

      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", pdfName);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  useEffect(() => {
    console.log("filteredData:", filteredData);
    console.log("search:", search);
    console.log("total:", total);
  }, [filteredData, search, total]);

  return (
    <React.Fragment>
      <ModalHOC
        setOpenNewInterviewModal={setOpenEditModal}
        openNewInterviewModal={openEditModal}
        component={
          <UpdateCandidate
            candidate={row}
            handleClose={() => setOpenEditModal(false)}
            setCandidateTrigger={setCandidateTrigger}
            candidateTrigger={candidateTrigger}
          />
        }
      />
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell align="center" className="tableCell">
          {row?.firstName ? row?.firstName : "..."}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.email ? row?.email : "..."}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.contact ? row?.contact : "..."}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.createdAt ? dateConversion(row?.createdAt) : "..."}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.yoe}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.currentLocation}
        </TableCell>
        <TableCell align="center" className="tableCell tagBox">
          {row?.tags?.length !== 0 &&
            row?.tags
              ?.split(",")
              ?.map((tag, i) => <div className="tag">{tag}</div>)}
        </TableCell>

        <TableCell align="center" className="tableCell">
          <CommonDrawer
            toggleDrawer={(anchor, open) => toggleDrawer(anchor, open, row)}
            state={state}
            // component={<CandidateDetails candidate={row} />}
            component={
              <CandidateDetails
                candidate={row}
                accessToken={accessToken}
                clientCode={clientCode}
              />
            } // Pass candidate data to CommonDrawer
          />
          {/* <CommonDrawer toggleDrawer={toggleDrawer} state={state} /> */}
          <CommonDialog
            open={open}
            handleClose={handleClose}
            component={
              <DeleteDialogContent
                text="Candidate Data"
                handleClose={handleClose}
                handleDelete={() => handleDelete(row?.id)}
              />
            }
          />
          <div
            style={{
              display: "flex",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={download}
              style={{
                width: "0.8rem",
                height: "0.8rem",
                cursor: "pointer",
                border: "0.08rem solid grey",
                padding: "0.3rem",
                borderRadius: "0.3rem",
              }}
              onClick={() =>
                handleDownload(row?.resume?.id, row?.resume?.srcFilename)
              }
            />
            {/* <img
              src={shareIcon}
              style={{
                width: "0.8rem",
                height: "0.8rem",
                cursor: "pointer",
                border: "0.08rem solid grey",
                padding: "0.3rem",
                borderRadius: "0.3rem",
              }}
            /> */}
            <BoxRow>
              <img
                src={threeDot}
                style={{
                  width: "0.8rem",
                  height: "0.8rem",
                  cursor: "pointer",
                  border: "0.08rem solid grey",
                  padding: "0.3rem",
                  borderRadius: "0.3rem",
                }}
                className={`three-dots ${
                  openDropdownIndex === index ? "active" : ""
                }`}
                onClick={() => {
                  if (openDropdownIndex === index) {
                    closeAllDropdowns();
                  } else {
                    openDropdown(index);
                  }
                }}
              />
              <div
                className={`dropdown-content ${
                  openDropdownIndex === index ? "open" : ""
                }`}
                ref={dropdownRef}
              >
                <span onClick={toggleDrawer("right", true)}>
                  <img src={eyeIcon} className="threeDotIcon" /> View Details
                </span>
                <span onClick={() => handleEdit(row)}>
                  <img src={editIcon} className="threeDotIcon" /> Edit Details
                </span>
                <span onClick={handleClickOpen}>
                  <img src={deleteIcon} className="threeDotIcon" /> Delete
                </span>
              </div>
            </BoxRow>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function RegisteredCandidates() {
  const [candidates, setCandidates] = useState([]);
  const [candidateTrigger, setCandidateTrigger] = useState(false);
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allCandidatesData, setAllCandidatesData] = useState([]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [allTags, setAllTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const handleSearch = async () => {
    if (searchValue.trim() || selectedTag) {
      try {
        const searchKeys = ["firstName", "lastName", "email", "contact"];
        const searchText = searchValue.trim();

        console.log("Searching with:", {
          searchText,
          selectedTag,
          searchKeys,
          accessToken,
          clientCode,
        });

        const res = await searchCandidates(
          accessToken,
          clientCode,
          searchText,
          searchKeys.join(","),
          0,
          1000000,
          selectedTag
        );

        console.log("Search response:", JSON.stringify(res, null, 2));

        if (res && res.status === "SUCCESS" && res.data) {
          if (res.data.total > 0 && Array.isArray(res.data.data)) {
            setFilteredData(res.data.data);
            setTotal(res.data.total);
            setSearch(true);
            toast.success(`Found ${res.data.total} search results`, 5000);
          } else {
            setFilteredData([]);
            setTotal(0);
            setSearch(true);
            toast.info("No results found", 5000);
          }
        } else {
          console.error("Unexpected response structure:", res);
          setFilteredData([]);
          setTotal(0);
          setSearch(true);
          toast.warning("Unexpected response from server", 5000);
        }
      } catch (error) {
        console.error("Search error:", error);
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred while searching. Please try again.";
        toast.error(errMsg, 8000);
        setFilteredData([]);
        setTotal(0);
        setSearch(true);
      }
    } else {
      setSearch(false);
      setFilteredData([]);
      setCandidates(allCandidatesData.slice(0, size));
      setTotal(allCandidatesData.length);
    }
  };

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const getAllCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, 1, 1000000);
        if (res) {
          setAllCandidatesData(res?.data?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getAllCandidates();
  }, []);

  useEffect(() => {
    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, page, size);
        if (res) {
          setCandidates(res?.data?.data);
          setTotal(res?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getCandidates();
  }, [candidateTrigger, page, size]);

  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await getTagsConfig(accessToken, clientCode);
        if (res) {
          setAllTags(res?.data?.value?.split(","));
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg, 8000);
      }
    };

    getTags();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTagChange = (tag) => {
    setSelectedTag((prevTag) =>
      prevTag.includes(tag)
        ? prevTag.filter((t) => t !== tag)
        : [...prevTag, tag]
    );
  };

  const handleClear = () => {
    setSearchValue("");
    setSearch(false);
    setFilteredData([]);
    setSelectedTag("");
    setPage(1);
    setCandidates([]);
    setTotal(0);

    // Fetch the original data
    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, 1, size);
        if (res) {
          setCandidates(res?.data?.data);
          setTotal(res?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getCandidates();
  };

  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <div className="titleContent">
            <span className="title">Candidates Pool</span>
            <span className="totalCandidates">Total Candidates: {total}</span>
          </div>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
          <button className="applyButton" onClick={handleSearch}>
            Apply
          </button>
          <button className="clearButton" onClick={handleClear}>
            Clear
          </button>
          <TagDropdown ref={dropdownRef}>
            <DropdownToggle onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              Select Tags ({selectedTag.length})
            </DropdownToggle>
            {isDropdownOpen && (
              <DropdownContent>
                {allTags.map((tag, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={selectedTag.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                    />
                    {tag}
                  </label>
                ))}
              </DropdownContent>
            )}
          </TagDropdown>
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">
                Name
              </TableCell>
              <TableCell align="center" className="tableCell">
                Email
              </TableCell>
              <TableCell align="center" className="tableCell">
                Contact
              </TableCell>
              <TableCell align="center" className="tableCell">
                Date of Reg
              </TableCell>
              <TableCell align="center" className="tableCell">
                Year of Experience
              </TableCell>
              {/* <TableCell align="center" className="tableCell">Position</TableCell> */}
              <TableCell align="center" className="tableCell">
                Current Location
              </TableCell>
              <TableCell align="center" className="tableCell">
                Tags
              </TableCell>
              <TableCell align="center" className="tableCell">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {search
              ? filteredData.map((row, index) => (
                  <Row
                    key={row?.id || index}
                    row={row}
                    index={index}
                    candidateTrigger={candidateTrigger}
                    setCandidateTrigger={setCandidateTrigger}
                  />
                ))
              : candidates.map((row, index) => (
                  <Row
                    key={row?.id || index}
                    row={row}
                    index={index}
                    candidateTrigger={candidateTrigger}
                    setCandidateTrigger={setCandidateTrigger}
                  />
                ))}
          </TableBody>
        </Table>
        {!search && (
          <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>
        )}
      </TableContainer>
    </Content>
  );
}

const Content = styled.div`
  margin: 1rem 0% 2rem 0%;
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.5rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .title2 {
      margin-left: 9rem;
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .titleBox {
      width: 100%;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }

    .titleContent {
      display: flex;
      // flex-direction: column;
      gap: 1rem;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .totalCandidates {
      padding-left: 53rem;
      font-size: 0.9rem;
      // margin-top: 0.5rem;
      font-weight: 600;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.73rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }

    .tagBox {
      display: flex;
      flex-flow: row wrap;
      column-gap: 0.25rem;
      row-gap: 0.25rem;
      align-items: center;
      justify-content: center;

      .tag {
        background-color: var(--cyan);
        margin: 0.25rem 0.25rem 0 0;
        white-space: nowrap;
        color: var(--white);
        font-weight: 500;
        padding: 0.2rem 0.4rem;
        border-radius: 0.2rem;
      }
    }
  }

  .checkBox {
    cursor: pointer;
  }
`;
const TagDropdown = styled.div`
  margin-left: 20rem;
  position: relative;
  display: inline-block;
  width: 230px;
`;

const DropdownToggle = styled.div`
  padding: 0.5rem 0rem;
  background-color: var(--white);
  border: 1px solid var(--cyan);
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: var(--font);
  justify-content: center;
  text-align: center;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white);
  border: 1px solid var(--cyan);
  border-radius: 0.3rem;
  // padding: 0.5rem;
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
  width: 230px;

  label {
    display: block;
    padding: 0.3rem 0;
    font-size: 0.8rem;
    font-family: var(--font);
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }

    input {
      // margin-right: 0.5rem;
    }
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;

  .applyButton {
    padding: 0.5rem 1rem;
    background-color: var(--cyan);
    color: white;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 550;
    font-family: var(--font);
  }

  .clearButton {
    padding: 0.5rem 1rem;
    background-color: var(--white);
    color: var(--cyan);
    // color: black;
    border: 1px solid var(--cyan);
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 550;
    font-family: var(--font);
  }

  .clearButton:hover {
    background-color: #f4f4f4;
  }
`;
const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .three-dots {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
    transform: translateY(-135%);
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown-content span:hover {
    background: #e2dbdb36;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;
