import axios from "axios";

export const searchCandidates = async (
  accessToken,
  clientCode,
  text,
  keys,
  page = 0,
  size = 1000000,
  tags = ""
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "X-Client-Code": clientCode,
    },
  };

  let url = `${process.env.REACT_APP_API_URL}/api/candidates/search?page=${page}&size=${size}&keys=${keys}`;

  if (text) {
    url += `&text=${encodeURIComponent(text)}`;
  }

  if (tags) {
    url += `&tags=${encodeURIComponent(tags)}`;
  }

  try {
    const response = await axios.get(url, config);

    console.log("API Response:", response.data);

    return response.data;
  } catch (error) {
    console.error("API Error:", error.response || error);
    throw error;
  }
};
