import React, { useState } from 'react'
import styled from 'styled-components'
import ProfileFilter from '../seekerCommonComponents/ProfileFilter'
import JobSearchBar from './JobSearchBar'
import SavedJobsList from './SavedJobsList'


const SavedJobs = () => {
    const [exp, setExp] = useState();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const [workType, setWorkType] = useState([]);
    const [salaryRange, setSalaryRange] = useState([]);
    const [freshness, setFreshness] = useState([]);
    const [noticePeriod, setNoticePeriod] = useState([]);
    const [companyType, setCompanyType] = useState([]);
    const [candidateAvl, setCandidateAvl] = useState([]);

    const handleFilter = () => {
        console.log('Filter');
    }

    return (
        <MainBox>
            {/* <div className='mainBox'>
                <JobSearchBar exp={exp} setExp={setExp} selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} selectedSkills={selectedSkills} setSelectedSkills={setSelectedSkills} handleJobSearch={handleFilter} />
                <ProfileFilter workType={workType} setWorkType={setWorkType} salaryRange={salaryRange} setSalaryRange={setSalaryRange} noticePeriod={noticePeriod} setNoticePeriod={setNoticePeriod} companyType={companyType} setCompanyType={setCompanyType} candidateAvl={candidateAvl} setCandidateAvl={setCandidateAvl} freshness={freshness} setFreshness={setFreshness} applyFilters={handleFilter} />
            </div> */}
            <InnerBox>
                <SavedJobsList />
            </InnerBox>
        </MainBox>
    )
}

export default SavedJobs

const MainBox = styled.div`
padding-bottom: 4rem;
display: flex;
flex-direction: column;
width: 100%;


.mainBox {
  display: grid;
  width: 99%;
  align-items: center;
  grid-template-columns: 9fr 1fr;
}
`
const InnerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
`