import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import SkillProgressBar from "../resumeTemplates/commonComp/SkillProgress";
import profileImgDummy from "../../../../assets/resume/profileImg.png";
import { useSelector } from "react-redux";

const ProfileDownloadTemplate = ({
  personalDetails,
  professionalSummary,
  employmentHistory,
  education,
  social,
  skills,
  languages,
  projects,
  profileImg,
  certification,
}) => {
  return (
    <Wrapper>
      <PrintStyles />
      <Header>
        <img src={profileImg ? profileImg : profileImgDummy} />{" "}
        <div className="mainHeaderBox">
          <span className="name">
            {personalDetails?.firstName} {personalDetails?.lastName}
          </span>
          <span className="dest">{personalDetails?.jobTitle}</span>
        </div>
      </Header>
      <div className="headerBox">
        <span className="infoItem">
          {(personalDetails?.address ||
            personalDetails?.city ||
            personalDetails?.country) && (
            <FontAwesomeIcon icon={faLocationDot} className="icon" />
          )}
          {personalDetails?.address} {personalDetails?.city}{" "}
          {personalDetails?.country}
        </span>
        <span className="infoItem">
          {personalDetails?.email && (
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
          )}
          {personalDetails?.email && personalDetails?.email}
        </span>
        <span className="infoItem">
          {personalDetails?.primaryContact && (
            <FontAwesomeIcon icon={faPhone} className="icon" />
          )}
          {personalDetails?.primaryContact && personalDetails?.primaryContact}
        </span>
        <span className="infoItem">
          <a href={social && social} target="_blank" rel="noopener noreferrer">
            {social && (
              <>
                <FontAwesomeIcon icon={faLinkedin} className="icon" />
                Linkedin{" "}
              </>
            )}
          </a>
        </span>
      </div>
      <Content>
        <div className="column2">
          <Summary>
            {professionalSummary?.length > 0 && <h2>Professional Summary</h2>}
            <span
              dangerouslySetInnerHTML={{
                __html: professionalSummary && professionalSummary,
              }}
              className="textarea"
            />
          </Summary>

          <Employment>
            {employmentHistory?.length > 0 && <h2>Employment History</h2>}
            <div className="experienceMainBox1">
              {employmentHistory?.map((exp, index) => (
                <div className="experienceBox1" key={index}>
                  <div className="experienceBoxTop">
                    <span className="title">{exp?.designation}</span>
                    <span className="company">{exp?.orgDetail?.name}</span>
                  </div>
                  <span className="location">{exp?.orgDetail?.address}</span>
                  <span className="date">
                    {exp?.startDate} {exp?.startDate?.length > 0 && "to"}{" "}
                    {exp?.current ? "Present" : exp?.endDate}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: exp?.description }}
                    className="textarea"
                  />
                </div>
              ))}
            </div>
          </Employment>

          <Education>
            {education?.length > 0 && <h2>Education</h2>}
            <div className="experienceMainBox1">
              {education?.map((edu, index) => (
                <div className="educationBox1" key={index}>
                  <div className="educationBoxTop">
                    <span className="title">{edu?.degree}</span>
                    <span className="date">
                      {edu?.startDate} {edu?.startDate?.length > 0 && "to"}{" "}
                      {edu?.current ? "Present" : edu?.endDate}
                    </span>
                  </div>
                  <span className="company">{edu?.school}</span>
                </div>
              ))}
            </div>
          </Education>

          <Projects>
            {projects?.length > 0 && <h2>Projects</h2>}
            <div className="experienceMainBox1">
              {projects?.map((project, index) => (
                <div className="projectBox1" key={index}>
                  <span className="title">{project?.title}</span>
                  <span className="date">
                    {project?.startDate}{" "}
                    {project?.startDate?.length > 0 && "to"}{" "}
                    {project?.status !== "completed"
                      ? "Present"
                      : project?.endDate}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: project?.description }}
                    className="textarea"
                  />
                </div>
              ))}
            </div>
          </Projects>
          <Projects>
            {certification?.length > 0 && <h2>Certifications</h2>}
            <div className="experienceMainBox1">
              {certification?.map((cert, index) => (
                <div className="projectBox1" key={index}>
                  <div className="experienceBoxTop">
                    <span className="title">{cert?.title}</span>
                    <span className="company">{cert?.issuingOrganization}</span>
                  </div>
                  <span className="title"></span>
                  <span className="date">
                    {cert?.issueDate} {cert?.issueDate?.length > 0 && "to"}{" "}
                    {cert?.expirationDate}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: cert?.description }}
                    className="textarea"
                  />
                </div>
              ))}
            </div>
          </Projects>

          <br />
          <br />
          <br />
        </div>

        <div className="column1">
          <Skills>
            {skills?.length > 0 && <h2>Skills</h2>}
            <div className="skillBox">
              {skills?.map((skill, index) => (
                <>
                  {skill?.name && (
                    <span className="skill" key={index}>
                      {skill?.name}
                    </span>
                  )}
                  {skill?.rating && (
                    <SkillProgressBar
                      score={Number.parseInt(skill?.rating, 10)}
                    />
                  )}
                </>
              ))}
            </div>
          </Skills>
        </div>
      </Content>
    </Wrapper>
  );
};

export default ProfileDownloadTemplate;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: calc(100%);
  min-height: 85%;
  margin: 0rem auto;
  gap: 1rem;
  background-color: var(--white);
  box-sizing: border-box;

  .textarea {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    display: block;
    font-size: 0.75rem;
  }

  .icon2 {
    color: grey;
  }

  h2 {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
  }

  .experienceMainBox1 {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .point {
    color: grey;
    font-size: 0.8rem;
  }

  .headerBox {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 0.9rem;
    padding-right: 1rem;
    row-gap: 0.5rem;

    .infoItem {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.8rem;
    }

    a {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.8rem;
      text-decoration: none;
      color: var(--color);
    }

    .icon {
      color: grey;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: calc(100% - 4rem);
  height: 100%;
  padding: 0 2rem 1rem 2rem;

  .column2 {
    width: calc(70% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    height: 100%;
  }

  .column1 {
    width: calc(30% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    height: 100%;
  }
`;

const Summary = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  width: 100%;
  align-items: center;
  justify-content: start;
  background-color: lightblue;
  padding: 1.5rem 2rem 1rem 2rem;
  box-sizing: border-box;

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }

  .mainHeaderBox {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding-left: 2rem;
  }

  .dest {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .name {
    font-size: 2.5rem;
    font-weight: 600;
  }
`;

const Employment = styled.div`
  .experienceBox1 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .experienceBoxTop {
      display: flex;
      gap: 0.5rem;
    }
  }

  .title {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .company {
    font-size: 0.9rem;
    font-weight: 500;
    font-style: italic;
  }

  .location {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
  }

  .desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
  }
`;

const Education = styled.div`
  .educationBox1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .educationBoxTop {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 0.6rem;
    }
  }

  .title {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .company {
    font-size: 0.9rem;
    font-weight: 500;
    color: grey;
  }

  .date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
  }
`;

const Skills = styled.div`
  h2 {
    display: flex;
    width: 100%;
    justify-content: start;
  }

  .skillBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .skill {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .skillMain {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
`;

const Projects = styled.div`
  .projectBox1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .experienceBoxTop {
      display: flex;
      gap: 0.5rem;
    }
  }

  .title {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .date {
    font-size: 0.75rem;
    font-weight: 500;
    color: grey;
  }

  .desc {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
  }

  .educationBoxTop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -1rem;
  }
`;

const PrintStyles = createGlobalStyle`
  @media print {
    body {
      margin: 0mm 0mm 0mm 0mm;
      zoom: 1.8;
    }
  }
`;
