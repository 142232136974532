import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import logo from "../../../../assets/otohireNewLogo.jpg";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { toast } from "react-toastify";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: "1rem",
    },
  })
);

const CustomAccordion = styled(Accordion)`
  margin-top: 1rem;
  width: 80vw;
`;

const MatchedReasoning = () => {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const [searchParams] = useSearchParams();
  const [reasoningData, setReasoningData] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uuid = searchParams.get("uuid");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/matches/reasoning?uuid=${uuid}`
          //   {
          //     headers: {
          //       Authorization: `Bearer ${accessToken}`,
          //     },
          //   }
        );
        const data = await response.json();
        setReasoningData(data.data);
      } catch (error) {
        toast.error("An error occurred while details. Please try again.", 8000);
      }
    };

    fetchData();
  }, [accessToken, searchParams]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt="Otohire Logo" />
      </LogoContainer>
      <Head>
        <br />
        <Heading>Candidate Match Reasoning</Heading>
      </Head>
      <Content>
        {/* <span>Details</span> */}
        <br />
        <Line />
        <br />
        {reasoningData && (
          <>
            {" "}
            <TopDetailsContainer>
              <DetailItem>
                <Label>Matched for JD ID:</Label>
                <Value>{reasoningData.jdId || "N/A"}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Name:</Label>
                <Value>{reasoningData.name || "N/A"}</Value>
              </DetailItem>
              <DetailItem>
                <Label>AI Score:</Label>
                <Value>{reasoningData.aiScore || "N/A"}</Value>
              </DetailItem>
            </TopDetailsContainer>
            <Line />
            <br />
            {reasoningData.reasoning.map((section, index) => (
              <CustomAccordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <span
                    className={`menuTitle ${
                      expanded === `panel${index}` ? "selected" : ""
                    }`}
                  >
                    {section.headerText}
                  </span>
                </AccordionSummary>
                <Line />
                <AccordionDetails>
                  {section.fields.map((field, fieldIndex) => (
                    <DetailItem key={fieldIndex}>
                      <Label>{field.label}:</Label>
                      <Value>{field.value}</Value>
                    </DetailItem>
                  ))}
                </AccordionDetails>
              </CustomAccordion>
            ))}
          </>
        )}
      </Content>
    </Container>
  );
};

export default MatchedReasoning;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
`;
const TopDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;
const LogoContainer = styled.div`
  height: 3.5rem;
  position: absolute;
  top: 1rem;
  left: 3rem;
`;

const Logo = styled.img`
  height: 100%;
`;
const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 0rem 0;
`;
const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 3rem;
  box-sizing: border-box;
  margin-top: 4rem;
  margin-bottom: 1rem;
  position: relative;
`;

const Heading = styled.h2`
  margin: 0;
  position: absolute;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
`;

const DetailItem = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
`;

const Value = styled.span`
  flex: 1;
`;
