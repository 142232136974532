import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const createBlobUrl = async (url) => {
  const blob = new Blob([url], { type: "application/octet-stream" });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};