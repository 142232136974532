import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Autocomplete, Paper, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { allTags } from "../../../utils/contantData";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { createTest } from "../../../functions/api/employers/employerTest/createTest";
import { updateTest } from "../../../functions/api/employers/employerTest/updateTest";
import deleteIcon from "../../../assets/icons/delete.png";
import QuestionModal from "./QuestionModal";
const filter = createFilterOptions();

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  .textAreaBox {
    width: 100%;
    position: relative;
    margin-top: 0.5rem;

    label {
      font-size: 0.7rem;
      font-weight: 600;
      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      padding: 0 0.5rem;
      background-color: var(--white);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 0.7rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .inputBoxMap {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    flex-flow: row wrap;
  }

  .box {
    width: calc(50% - 1rem);
  }
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  display: flex;
  align-self: center;
  margin: 1rem auto;
`;

const QueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: -0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .addQuestions {
    font-size: 0.8rem;
    cursor: pointer;
    border: 1.5px solid cyan;
    border-radius: 0.2rem;
    padding: 5px;
    font-weight: 600;
  }
  .addQuestions:hover {
    color: var(--cyan);
    border-color: var(--cyan);
  }

  .questionBox {
    display: flex;
    justify-content: space-between;
    align-items: start;
    border: 0.05rem solid lightgrey;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;

    .iconBox {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-self: center;
    }

    img {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      padding: 0.3rem;
      border: 0.05rem solid lightgrey;
      border-radius: 0.25rem;
    }

    .questionBox1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .questionTop {
      display: flex;
      justify-content: start;
      gap: 2rem;
    }

    .bold {
      font-weight: 600;
    }
  }
`;

function CreateTestsForm({
  editingIndex,
  setEditingIndex,
  handleClose,
  editingTestId,
  editingTestData,
  setTestTrigger,
  testQuestionData,
}) {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  console.log(editingTestData);

  const [formData, setFormData] = useState({
    testType: "",
    tags: "",
    questionIds: [],
    role: "",
    name: "",
  });

  const [allQuestions, setAllQuestions] = useState(null);

  const [selectedTags, setSelectedTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");

  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const handleAddQuestion = (question) => {
    setAllQuestions([...allQuestions, question]);
    setFormData({
      ...formData,
      questionIds: [...formData.questionIds, question.id],
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      tags: selectedTags?.join(", "),
    });
  }, [selectedTags]);

  useEffect(() => {
    if (editingIndex != -1) {
      setFormData({
        testType: editingTestData?.testType,
        name: editingTestData?.name,
        role: editingTestData?.role,
        questionIds: editingTestData?.testQuestions?.map(
          (question) => question?.id
        ),
      });
      {
        editingTestData?.tags &&
          setSelectedTags(editingTestData?.tags?.split(", "));
      }
      {
        editingTestData?.testQuestions &&
          setAllQuestions(editingTestData?.testQuestions);
      }
    } else {
      {
        testQuestionData && setAllQuestions(testQuestionData);
      }
      setFormData({
        ...formData,
        questionIds: testQuestionData?.map((question) => question?.id),
      });
    }
  }, [editingIndex, editingTestData, editingTestId, testQuestionData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddTest = async (e) => {
    e.preventDefault();
    try {
      const res = await createTest(formData, accessToken, clientCode);

      if (res) {
        toast.success("Test added successfully", 5000);
        setFormData({
          testType: "",
          tags: "",
          questionIds: [],
          role: "",
          name: "",
        });
        setTestTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg);
    }
  };

  const handleUpdateTest = async (e) => {
    e.preventDefault();
    try {
      const res = await updateTest(
        formData,
        editingTestId,
        accessToken,
        clientCode
      );

      if (res) {
        toast.success("Test updated successfully", 5000);
        setFormData({
          testType: "",
          tags: "",
          questionIds: [],
          role: "",
          name: "",
        });
        setEditingIndex(-1);
        setTestTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg);
    }
  };

  const handleTagsChange = (_, newTags) => {
    setSelectedTags(newTags);
  };

  const handleTagInputBlur = () => {
    if (tagInputValue.trim() !== "") {
      setSelectedTags([...selectedTags, tagInputValue?.trim()]);
      setTagInputValue("");
    }
  };

  const handleDeleteQue = (id) => {
    const updatedQuestionIds = formData?.questionIds?.filter(
      (questionId) => questionId !== id
    );
    const updatedQue = allQuestions?.filter((ques) => ques?.id !== id);
    setFormData({
      ...formData,
      questionIds: updatedQuestionIds,
    });
    setAllQuestions(updatedQue);
  };

  return (
    <Container>
      <span className="title">
        {editingIndex === -1 ? "Create" : "Update"} Test Form
      </span>
      <Form onSubmit={editingIndex === -1 ? handleAddTest : handleUpdateTest}>
        <div className="inputBox">
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            label="Test Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            required
            fullWidth
          />

          <TextField
            id="outlined-basic"
            variant="outlined"
            type="text"
            label="Role"
            name="role"
            value={formData?.role}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            required
            fullWidth
          />
        </div>

        <div className="inputBox">
          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allTags}
              getOptionLabel={(option) => option}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              onChange={handleTagsChange}
              value={selectedTags}
              selectOnFocus
              clearOnBlur
              freeSolo
              fullWidth
              onInputChange={(event, newInputValue) => {
                setTagInputValue(newInputValue);
              }}
              onBlur={handleTagInputBlur}
              PaperComponent={(props) => (
                <Paper {...props} sx={{ backgroundColor: "#F0F0F6" }} />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags*"
                  sx={{
                    backgroundColor: "#F6F6FB",
                    "& .MuiInputLabel-root": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                    },
                    "& .MuiInputBase-input": {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              )}
            />
          </Stack>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} required fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Test Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Test Type"
              name="testType"
              value={formData?.testType}
              onChange={handleChange}
              size="small"
              sx={{
                padding: "0.5rem 0 0.5rem 0",
                "& .MuiSelect-select": {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
            >
              <MenuItem value="SUBJECTIVE">Subjective</MenuItem>
              <MenuItem value="MCQ">Objective</MenuItem>
              <MenuItem value="CODING">Coding</MenuItem>
            </Select>
          </FormControl>
        </div>

        <QueBox>
          <span className="title">
            Questions
            <span
              className="addQuestions"
              onClick={() => setIsQuestionModalOpen(true)}
            >
              Add More Questions
            </span>
          </span>
          {allQuestions?.map((que, i) => (
            <span className="questionBox">
              <span className="questionBox1">
                <span className="questionTop">
                  <span>
                    <span className="bold">Que Type:</span> {que?.questionType}
                  </span>
                  <span>
                    <span className="bold">Difficulty Level:</span>{" "}
                    {que?.difficultyLevel}
                  </span>
                </span>
                <span className="questionText">
                  <span className="bold">Que.</span> {que?.questionText}
                </span>
              </span>
              <span className="iconBox">
                <img
                  src={deleteIcon}
                  onClick={() => handleDeleteQue(que?.id)}
                />
              </span>
            </span>
          ))}
        </QueBox>

        <Button type="submit">
          {editingIndex === -1 ? "Create Test" : "Update Test"}
        </Button>
      </Form>

      {isQuestionModalOpen && (
        <QuestionModal
          isOpen={isQuestionModalOpen}
          onClose={() => setIsQuestionModalOpen(false)}
          accessToken={accessToken}
          clientCode={clientCode}
          onSelectQuestions={handleAddQuestion}
        />
      )}
    </Container>
  );
}

export default CreateTestsForm;
