import React from "react";
import styled from "styled-components";
import nextIcon from "../../assets/icons/nextIcon.png";
import greyNextIcon from "../../assets/icons/greyNextIcon.png";
import prevIcon from "../../assets/icons/prevIcon.png";
import greyPrevIcon from "../../assets/icons/greyPrevIcon.png";

export const Pagination = ({
  total,
  size,
  page,
  handlePageChange,
  setPage,
}) => {
  const totalPages = total ? Math.ceil(+total / +size) : 0;
  const visiblePageRange = 5; // Adjust this value as needed
  const startPage = Math.max(1, page - Math.floor(visiblePageRange / 2));
  const endPage = Math.min(totalPages, startPage + visiblePageRange - 1);

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const visiblePageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
  return (
    <PaginationWrapper>
      <span className="text">
        Page {page} of {totalPages}
      </span>
      <PaginationButton
        onClick={() => handlePageChange(false)}
        inActive={page === 1}
      >
        {page === 1 ? (
          <img src={greyPrevIcon} alt="Previous" />
        ) : (
          <img src={prevIcon} alt="Previous" />
        )}
      </PaginationButton>

      {visiblePageNumbers.map((pageNumber) => (
        <PaginationButton
        key={pageNumber}
        onClick={() => handlePageClick(pageNumber)}
        active={pageNumber === page}
        >
          {pageNumber}
        </PaginationButton>
      ))}

      <PaginationButton
        onClick={() => handlePageChange(true)}
        inActive={total / (page * size) < 1}
      >
        {total / (page * size) < 1 ? (
          <img src={greyNextIcon} alt="Next" />
        ) : (
          <img src={nextIcon} alt="Next" />
        )}
      </PaginationButton>
    </PaginationWrapper>
  );
};

export const PaginationSizeFilter = ({ size, handleSizeChange }) => {
  return (
    <SelectWrapper>
      <label htmlFor="pageSize">Rows per page </label>
      <Select id="pageSize" value={size} onChange={handleSizeChange}>
        <option value={5}>5</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        <option value={150}>150</option>
        <option value={500}>500</option>
      </Select>
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  height: 2rem;
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;

  label {
    font-weight: 500;
    font-size: 0.9rem;
    font-family: Quicksand, sans-serif;
  }
`;

const Select = styled.select`
  // width: 3.5rem;
  padding: 0.3rem 0rem 0.3rem 0.75rem;
  margin-left: 0.5rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  border: 0.075rem solid #ccc;
  border-radius: 0.25rem;
  font-family: Quicksand, sans-serif;

  option {
    font-family: Quicksand, sans-serif;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  font-family: Quicksand, sans-serif;

  .text {
    font-weight: 500;
    font-size: 0.9rem;
    font-family: Quicksand, sans-serif;
  }
`;

const PaginationButton = styled.button`
  width: ${({ active }) => (active ? "2.5rem" : "2rem")};
  height: 2rem;
  display: flex;
  font-size: 0.7rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: 0.075rem solid #ccc;
  border-radius: 0.25rem;
  background-color: ${({ active }) => (active ? "#f0f0f0" : "#fff")};
  cursor: ${({ inActive }) => (inActive ? "default" : "pointer")};
  font-family: Quicksand, sans-serif;

  &:hover {
    background-color: ${({ active }) => (active ? "#f0f0f0" : "#f5f5f5")};
  }

  img {
    width: 0.75rem;
  }
`;
