import axios from "axios";



export const bulkAddSync = async (formdata, accessToken, clientCode) => {


    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-client-code': clientCode,
            'Content-Type': 'multipart/form-data'
        }
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/candidates/bulkAddSync`, formdata, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

