import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { forgetPasswordLogedIn } from "../functions/api/authentication/forgetLogedIn";
import styled from "styled-components";
import logo from "../assets/otohireNewLogo.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import loginImg from "../assets/loginPageSecureImg.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import { persistor } from "../store";
import { useDispatch } from "react-redux";

const ForgotPassLogedIn = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [clientCode, setClientCode] = useState("");
  const captchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [password, setPassword] = useState("");
  const [currentpassword, setcurrentpassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      const res = await forgetPasswordLogedIn(
        currentpassword,
        password,
        confirm,
        accessToken,
        clientCode
      );

      // Log out the user
      persistor.purge();
      dispatch(logout());

      // Redirect to home page
      navigate("/");
    } catch (error) {
      const errMsg = error?.response?.data?.notify?.message;
      ("An error occurred. Please try again.");
      toast.error(errMsg, { autoClose: 8000 });
    }
  };
  // func for password validation
  useEffect(() => {
    // custom rules
    ValidatorForm.addValidationRule("hasSpecialCharacter", (value) => {
      return /[!@#$%^&*]/.test(value);
    });

    ValidatorForm.addValidationRule("hasCapitalLetter", (value) => {
      return /[A-Z]/.test(value);
    });

    ValidatorForm.addValidationRule("hasSmallLetter", (value) => {
      return /[a-z]/.test(value);
    });

    ValidatorForm.addValidationRule("hasNumberDigit", (value) => {
      return /\d/.test(value);
    });

    ValidatorForm.addValidationRule("hasMinLength", (value) => {
      return value.length >= 8;
    });

    // Cleanup the rules when component unmounts
    return () => {
      ValidatorForm.removeValidationRule("hasSpecialCharacter");
      ValidatorForm.removeValidationRule("hasCapitalLetter");
      ValidatorForm.removeValidationRule("hasSmallLetter");
      ValidatorForm.removeValidationRule("hasNumberDigit");
      ValidatorForm.removeValidationRule("hasMinLength");
    };
  }, [password]);

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <StyledDiv>
      <div
        style={{
          height: "3.5rem",
          position: "absolute",
          top: "1rem",
          left: "3rem",
        }}
      >
        <img src={logo} style={{ height: "100%" }} />
      </div>

      <IconButton onClick={() => navigate(-1)} className="prev">
        <ArrowBackIcon sx={{ fontSize: "30px" }} />
      </IconButton>

      <>
        <div id="form">
          <h1>Reset</h1>
          <p>Enter your current password, and then choose a new one.</p>
          <ValidatorForm onSubmit={handleReset}>
            <div className="inputBox">
              <TextValidator
                label="Current Password"
                type={currentPasswordVisible ? "text" : "password"}
                id="currentpassword"
                value={currentpassword}
                onChange={(e) => setcurrentpassword(e.target.value)}
                validators={[
                  "required",
                  "hasSpecialCharacter",
                  "hasCapitalLetter",
                  "hasSmallLetter",
                  "hasNumberDigit",
                  "hasMinLength",
                  "maxStringLength:29",
                ]}
                errorMessages={[
                  "This field is required",
                  "Must contain a special character",
                  "Must contain a capital letter",
                  "Must contain a small letter",
                  "Must contain a number digit",
                  "Must be at least 8 characters long",
                  "Must be less than 30 chatacters long",
                ]}
                fullWidth
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
              />
              <FontAwesomeIcon
                icon={faEye}
                id="eye"
                onClick={toggleCurrentPasswordVisibility}
                className={`eye-icon ${
                  currentPasswordVisible ? "visible" : ""
                }`}
              />
            </div>
            <div className="inputBox">
              <TextValidator
                label="New Password"
                type={passwordVisible ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validators={[
                  "required",
                  "hasSpecialCharacter",
                  "hasCapitalLetter",
                  "hasSmallLetter",
                  "hasNumberDigit",
                  "hasMinLength",
                  "maxStringLength:29",
                ]}
                errorMessages={[
                  "This field is required",
                  "Must contain a special character",
                  "Must contain a capital letter",
                  "Must contain a small letter",
                  "Must contain a number digit",
                  "Must be at least 8 characters long",
                  "Must be less than 30 chatacters long",
                ]}
                fullWidth
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
              />
              <FontAwesomeIcon
                icon={faEye}
                id="eye"
                onClick={togglePasswordVisibility}
                className={`eye-icon ${passwordVisible ? "visible" : ""}`}
              />
            </div>

            <div className="inputBox">
              <TextValidator
                label="Confirm New Password"
                type={confirmPasswordVisible ? "text" : "password"}
                id="confirmPassword"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                validators={[
                  "required",
                  "hasSpecialCharacter",
                  "hasCapitalLetter",
                  "hasSmallLetter",
                  "hasNumberDigit",
                  "hasMinLength",
                  "maxStringLength:29",
                ]}
                errorMessages={[
                  "This field is required",
                  "Must contain a special character",
                  "Must contain a capital letter",
                  "Must contain a small letter",
                  "Must contain a number digit",
                  "Must be at least 8 characters long",
                  "Must be less than 30 chatacters long",
                ]}
                fullWidth
                inputProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "#626264",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                  },
                }}
              />
              <FontAwesomeIcon
                icon={faEye}
                id="eye"
                onClick={toggleConfirmPasswordVisibility}
                className={`eye-icon ${
                  confirmPasswordVisible ? "visible" : ""
                }`}
              />
            </div>
            <ReCAPTCHA
              sitekey="6LfqApgpAAAAAMcXqcRXIy6lxHb-2Ny6j6q7bfDZ"
              ref={captchaRef}
              size="normal"
            />
            {captchaError && (
              <span className="captchaErrorText">
                Error: please verify captcha
              </span>
            )}
            <button type="submit" className="btn">
              Reset your Password
            </button>
          </ValidatorForm>
        </div>
        <div id="cover">
          <img src={loginImg} />
        </div>
      </>
    </StyledDiv>
  );
};

export default ForgotPassLogedIn;

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0rem;

  .captchaErrorText {
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
    margin-top: -0.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 80%;
  }
  .eye-icon {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    cursor: pointer;
    color: lightgray;
  }

  .eye-icon.visible {
    color: blue; /* Change to your desired color when password is visible */
  }
  .inputBox {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 1rem;
  }

  #form {
    width: 50%;
    background-color: var(--backgroundColor);
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #cover {
    width: 50%;
    min-height: 100vh;
    background-color: var(--backgroundColor);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
    }
  }

  input {
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }

  .btn {
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.6rem 1rem;
    // width: 50%;
    cursor: pointer;
  }

  .prev {
    background-color: var(--cyan);
    padding: 0.1rem;
    position: absolute;
    top: 6rem;
    left: 3rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50%;

    #form {
      width: 100%;
    }

    #cover {
      display: none;
    }
  }

  .Linkbtn {
    text-decoration: none;
    color: var(--color);
  }
  .Linkbtn:hover {
    text-decoration: underline;
  }
`;
