import axios from "axios"


export const searchJdsWithFilter = async (payload, skills, locations, accessToken, clientCode, page = 1, size = 1000000) => {

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-Client-Code': clientCode,
    }
  };

  console.log(payload);

  const queryParams = [
    `page=${page}`,
    ...skills.map(param => `searchParam=${encodeURIComponent(param)}`),
    ...locations.map(param => `searchParam=${encodeURIComponent(param)}`),
    `size=${size}`,
  ];

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jds/searchWithFilters?${queryParams.join('&')}`, payload, config);
    console.log('Data:', response.data);
    console.log("Status", response.status)
    return response.data;
  } catch (error) {
    throw error;
  }
}

