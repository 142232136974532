// ErrorComponent.js
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 50%;
  background-color: #f4f4f4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  color: #343a40;
  margin-bottom: 20px;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #18e2d3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #18e2d3;
  }
`;

const ErrorComponent = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/dashboard/jobseeker");
  };

  return (
    <Container>
      <Heading>
        We faced some issue, fixing it meanwhile go to dashboard
      </Heading>
      <Button onClick={handleButtonClick}>Back to Dashboard</Button>
    </Container>
  );
};

export default ErrorComponent;
