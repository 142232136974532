import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the animation
const grow = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
`;

// Styled div container
const LoaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 3em;
`;

// Styled span elements with animation
const LoaderSpan = styled.span`
  width: 0.3em;
  height: 1em;
  background-color: #18E2D3;

  &:nth-of-type(1) {
    animation: ${grow} 1s -0.45s ease-in-out infinite;
  }

  &:nth-of-type(2) {
    animation: ${grow} 1s -0.3s ease-in-out infinite;
  }

  &:nth-of-type(3) {
    animation: ${grow} 1s -0.15s ease-in-out infinite;
  }

  &:nth-of-type(4) {
    animation: ${grow} 1s ease-in-out infinite;
  }
`;

const NewLoader = () => {
  return (
    <LoaderContainer>
      <LoaderSpan />
      <LoaderSpan />
      <LoaderSpan />
      <LoaderSpan />
    </LoaderContainer>
  );
};

export default NewLoader;
