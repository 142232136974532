import { setCurrentItem, setCurrentMetric } from "../slices/configSlice";


export const formatRole = (role) => {
    switch (role) {
        case 'ROLE_SUSER':
            return 'Super User';
        case 'ROLE_USER':
            return 'User';
        case 'ROLE_EMPLOYER':
            return 'Employer';
        case 'ROLE_SYS_ADMIN':
            return 'System Admin';
        case 'ROLE_CLIENT_ADMIN':
            return 'Client Admin';
        case 'ROLE_TEST':
            return 'Testing';
        case 'ROLE_RECRUITER':
            return 'Recruiter';
        case 'ROLE_AGENCY':
            return 'Agency';
        case 'ROLE_OPERATOR':
            return 'Operator';
        default:
            return 'Unknown';
    }
}


export const navigateBasedOnUserRole = (currentUser, navigate, dispatch) => {
    if (currentUser === "EMPLOYER") {
        navigate("/dashboard/employer");
        dispatch(setCurrentItem('dashboard'));
        dispatch(setCurrentMetric('interviews'))
    } else if (currentUser === "AGENCY") {
        navigate("/dashboard/agency");
        dispatch(setCurrentItem('dashboard'));
        dispatch(setCurrentMetric('jdListing'))
    }
    else {
        navigate("/dashboard/jobseeker");
        dispatch(setCurrentMetric('interviewScheduled'));
        dispatch(setCurrentItem('dashboard'));
    }
};

export const dateConvert = (date) => {
    if (date) {
        const givenDate = new Date(date.split('T')[0]);
        const todayDate = new Date();
        const differenceInMilliseconds = todayDate - givenDate;
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        const finalDate = differenceInDays > 0 ? `${differenceInDays} days ago` : "Today";
        return finalDate;
    }
}
