import React from "react";
import { styled } from "styled-components";
import Header from "../../components/LandingPage/Header";
import Footer from "../../components/commonComponents/Footer";
import bg1 from "../../assets/BackGround/bg1.jpg";

//  PressRelease component
const PressRelease = ({ title, date, content }) => (
  <div className="pressRelease">
    <h2>{title}</h2>
    <p>
      <strong>Date:</strong> {date}
    </p>
    <p>{content}</p>
  </div>
);

const MediaContact = () => (
  <div className="mediaContact">
    <h3>Media Contact</h3>
    <p>
      Otohire welcomes inquiries from journalists, bloggers, and media
      professionals. For press inquiries, interview requests, or additional
      information, please contact our media relations team at:
      <div className="contactBox">
        <span className="text">
          <b>Email:</b> &nbsp;
          <a
            className="link"
            href="mailto:care@otohire.com"
            target="_blank"
            rel="noreferrer"
          >
            care@otohire.com
          </a>
        </span>
        <span className="text">
          <b>Phone:</b> &nbsp;
          <a className="link" href="tel:+918218698610">
            +91 8218698610
          </a>
        </span>
      </div>
    </p>
  </div>
);

const PressPage = () => {
  return (
    <StyledPage>
      <Header />
      <StyledContent>
        <span className="mainTitle">Press Room</span>

        <div className="top">
          <span className="topText">
            Welcome to our Press Room! Here, you'll find the latest news and
            announcements about our AI-driven hiring solutions. We're excited to
            share our journey with you as we revolutionize the interview
            process.
          </span>
          <span className="topText">
            At Otohire, we're dedicated to empowering job seekers and employers
            alike with innovative tools that streamline the hiring process.
            Explore our press releases to learn more about how we're
            transforming the recruitment landscape.
          </span>
        </div>

        <MediaContact />

        <PressRelease
          title="Revolutionizing the Interview Process: Otohire Introduces AI-Powered Hiring Platform"
          date="May 3, 2024"
          content="Otohire, the leading innovator in AI-driven hiring solutions, announces the launch of its groundbreaking interview platform designed to transform the recruitment landscape. Learn more about our mission, technology, and vision for the future."
        />

        <span className="text">
          Otohire is dedicated to revolutionizing the interview process through
          cutting-edge AI technology. Our platform empowers both job seekers and
          employers, streamlining hiring processes and ensuring a perfect match
          every time.
        </span>

        <span className="text">
          With our AI-based interview platform, candidates have the opportunity
          to showcase their skills and potential, while employers can make
          data-driven, informed hiring decisions.
        </span>

        <span className="text">
          Our platform offers several key features, including:
          <ul>
            <li>
              AI-Powered Interviews: Objective assessments based on skills,
              experience, and cultural fit.
            </li>
            <li>
              Efficiency: Automation of repetitive tasks like resume screening,
              saving time and resources.
            </li>
            <li>
              Unbiased Evaluations: Elimination of unconscious bias, promoting
              diversity and inclusion.
            </li>
            <li>
              Data-Driven Insights: Access to comprehensive analytics for
              informed decision-making.
            </li>
            <li>
              Seamless Integration: Compatibility with existing applicant
              tracking systems for smooth transition.
            </li>
          </ul>
        </span>

        <span className="text2">
          Whether you're a job seeker or an employer, Otohire is here to make
          the interview process more efficient, effective, and fair for everyone
          involved.
        </span>
      </StyledContent>
      <Footer />
    </StyledPage>
  );
};

export default PressPage;

export const StyledPage = styled.div`
  width: 100%;
  background-color: var(--white);
  color: black;
  display: flex;
  flex-direction: column;
`;

export const StyledContent = styled.div`
  margin-top: 7rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  gap: 1rem;

  .top {
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--white);
    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 1rem;
    align-items: center;
  }

  .topText {
    font-size: 1.1rem;
    text-align: center;
  }

  .mainTitle {
    font-weight: 900;
    font-size: 1.3rem;
  }

  .text {
    word-wrap: break-word;
    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 450;
    color: rgb(70, 78, 98);
  }
  .text2 {
    word-wrap: break-word;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: black;
  }
  .contactBox {
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    margin-top: 0.5rem;
  }

  .link {
    color: var(--cyan);
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
  }

  .link1 {
    color: var(--white);
  }
`;
