import { createSlice } from '@reduxjs/toolkit';

export const accordionSlice = createSlice({
    name: 'accordion',
    initialState: {},
    reducers: {
        toggleAccordion: (state, action) => {
            const { interviewId, rowIndex } = action.payload;
            if (!state[interviewId]) {
                state[interviewId] = {};
            }
            // Close all other accordions if the clicked accordion is not open
            if (!state[interviewId][rowIndex]) {
                Object.keys(state[interviewId]).forEach(key => {
                    if (key !== rowIndex) {
                        state[interviewId][key] = false;
                    }
                });
            }
            // Toggle the selected accordion
            state[interviewId][rowIndex] = !state[interviewId]?.[rowIndex];
        },
    },
});

export const { toggleAccordion } = accordionSlice.actions;

export default accordionSlice.reducer;
