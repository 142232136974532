import React, { useEffect, useState } from "react";
import ManageJds from "./ManageJds";
import { getJdsForMatching } from "../../../../functions/api/employers/match/getJdsForMatching";
import LogoHeader from "../../../commonComponents/LogoHeader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const ScheduleInterview = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const accessToken = useSelector(
    (state) => state?.auth?.userData?.accessToken
  );
  const clientCode = useSelector(
    (state) => state?.auth?.userData?.user?.clientCode
  );

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    if (!accessToken || !clientCode) {
      toast.error("Login First");
      navigate("/login");
    }
    async function getData() {
      try {
        const resObj = await getJdsForMatching(
          accessToken,
          clientCode,
          search ? 1 : page,
          search ? 1000000 : size
        );
        if (resObj) {
          setRows(resObj?.data?.data);
          setTotal(resObj?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData();
  }, [page, size, search]);

  return (
    <ManageJds
      rows={rows}
      total={total}
      page={page}
      setPage={setPage}
      size={size}
      setSize={setSize}
      search={search}
      setSearch={setSearch}
    />
  );
};

export default ScheduleInterview;
