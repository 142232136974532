import React, { useState } from "react";
import styled from "styled-components";
import { technicalSkills } from "../../../../utils/contantData";
import { locations } from "../../../../utils/contantData";
import searchIcon from "../../../../assets/icons/searchIcon.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import SelectInput2 from "../seekerCommonComponents/SelectInput2";
import { useDispatch } from "react-redux";
import {
  updateSelectedLocations,
  updateSelectedSkills,
} from "../../../../slices/jobFilterSlice";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Paper } from "@mui/material";

const filter = createFilterOptions();
const ExpOptionsArr = [
  { value: "0", text: "Fresher" },
  { value: "1", text: "1 year" },
  { value: "2", text: "2 years" },
  { value: "3", text: "3 years" },
  { value: "4", text: "4 years" },
  { value: "5", text: "5 years" },
  { value: "6", text: "6 years" },
  { value: "7", text: "7 years" },
  { value: "8", text: "8 years" },
  { value: "9", text: "9 years" },
  { value: "10", text: "10 years" },
  { value: "11", text: "11 years" },
  { value: "12", text: "12 years" },
  { value: "13", text: "13 years" },
  { value: "14", text: "14 years" },
  { value: "15", text: "15 years" },
  { value: "16", text: "16 years" },
  { value: "17", text: "17 years" },
  { value: "18", text: "18 years" },
  { value: "19", text: "19 years" },
  { value: "20", text: "20 years" },
  { value: "21", text: "21 years" },
  { value: "22", text: "22 years" },
  { value: "23", text: "23 years" },
  { value: "24", text: "24 years" },
  { value: "25", text: "25 years" },
  { value: "26", text: "26 years" },
  { value: "27", text: "27 years" },
  { value: "28", text: "28 years" },
  { value: "29", text: "29 years" },
  { value: "30", text: "30 years" },
];

const JobSearchBar = ({ handleJobSearch }) => {
  const selectedLocations = useSelector(
    (state) => state.jobFilter.selectedLocations
  );
  const selectedSkills = useSelector((state) => state.jobFilter.selectedSkills);
  const dispatch = useDispatch();
  const [skillInputValue, setSkillInputValue] = useState("");
  const [locationInputValue, setLocationInputValue] = useState("");

  const handleLocationsChange = (_, newLocations) => {
    dispatch(updateSelectedLocations(newLocations));
  };

  const handleSkillsChange = (_, newSkills) => {
    dispatch(updateSelectedSkills(newSkills));
  };

  const handleLocationInputBlur = () => {
    if (locationInputValue.trim() !== "") {
      dispatch(
        updateSelectedLocations([
          ...selectedLocations,
          locationInputValue.trim(),
        ])
      );
      setLocationInputValue("");
    }
  };

  const handleSkillInputBlur = () => {
    if (skillInputValue.trim() !== "") {
      dispatch(
        updateSelectedSkills([...selectedSkills, skillInputValue.trim()])
      );
      setSkillInputValue("");
    }
  };

  return (
    <SearchBarContainer>
      <div className="skillBox">
        <Stack spacing={3} sx={{ width: "100%" }}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={technicalSkills}
            getOptionLabel={(option) => option}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option
              );

              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            onChange={handleSkillsChange}
            value={selectedSkills}
            selectOnFocus
            clearOnBlur
            freeSolo
            onInputChange={(event, newInputValue) => {
              setSkillInputValue(newInputValue);
            }}
            onBlur={handleSkillInputBlur}
            PaperComponent={(props) => (
              <Paper {...props} sx={{ backgroundColor: "#F0F0F6" }} />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Skills, role and title."
              />
            )}
          />
        </Stack>
      </div>

      <div className="locationBox">
        <Stack spacing={3} sx={{ width: "100%" }}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={locations}
            getOptionLabel={(option) => option}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option
              );

              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            onChange={handleLocationsChange}
            value={selectedLocations}
            selectOnFocus
            clearOnBlur
            freeSolo
            onInputChange={(event, newInputValue) => {
              setLocationInputValue(newInputValue);
            }}
            onBlur={handleLocationInputBlur}
            PaperComponent={(props) => (
              <Paper {...props} sx={{ backgroundColor: "#F0F0F6" }} />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Locations"
              />
            )}
          />
        </Stack>
      </div>

      <SelectInput2 optionsArr={ExpOptionsArr} titleText="Experience" />

      <button className="btn" onClick={() => handleJobSearch()}>
        <img src={searchIcon} />
        Search
      </button>
    </SearchBarContainer>
  );
};

export default JobSearchBar;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  border: 0.08rem solid lightgrey;
  gap: 0.5rem;

  #tags-standard {
    font-family: var(--font);
    font-size: 1rem;
    font-weight: 900;
    color: #000000;
  }

  .locationBox {
    position: relative;
    width: 30%;
  }

  .skillBox {
    position: relative;
    width: 40%;
  }

  .btn {
    background-color: var(--cyan);
    padding: 0.5rem 1.1rem;
    border-radius: 1.1rem;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    font-family: var(--font);
  }

  .btn img {
    width: 1rem;
  }
`;
