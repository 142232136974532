import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import demoPageImg from "../assets/CreateTicketImg.png";
import { TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const CreateTicket = ({ clientCode }) => {
  const [message, setMessage] = useState("");
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const handleSubmit = async () => {
    if (!message) {
      toast.warning("Please add a Message.");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "X-Client-Code": clientCode,
      },
    };

    const requestData = {
      ticketCat: "GENERIC",
      ticketDesc: message,
      type: "GENERIC",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tickets`,
        requestData,
        config
      );

      if (response.status === 201) {
        toast.success("Your Ticket has been raised successfully!");
        setMessage("");
      }
    } catch (error) {
      console.error("Error submitting contact ticket:", error);
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <StyledDiv>
      <div className="content">
        <div className="right">
          <span className="title">
            Please leave your query here, we will get back to you ASAP!
          </span>
          <br />
          <img src={demoPageImg} alt="Contact Ticket" />
          <TextField
            id="outlined-multiline-static"
            className="textInput"
            label="Message"
            multiline
            rows={5}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "1rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            style={{ width: "35rem" }}
          />
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </StyledDiv>
  );
};

export default CreateTicket;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .right > img {
    width: 40%;
  }

  .textInput {
    width: 80%;
  }

  .title {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .btn {
    font-family: var(--font);
    font-weight: 600;
    background-color: var(--cyan);
    color: var(--backgroundColor);
    font-size: 1.2rem;
    border-radius: 0.4rem;
    border: none;
    padding: 0.7rem 1rem;
    cursor: pointer;
    margin-top: 1.5rem;
    width: 35%;
    // margin-bottom: 1rem;
  }

  @media (max-width: 800px) {
    .right {
      width: 100%;
      padding: 1rem;
    }
    .textInput {
      width: 90%;
    }
    select {
      width: 80%;
    }
  }

  .Toastify__toast-container {
    z-index: 9999;
  }

  .Toastify__toast {
    font-family: var(--font);
    font-size: 0.9rem;
  }

  .Toastify__toast--success {
    background: var(--lightGreen);
  }

  .Toastify__toast--error {
    background: var(--lightRed);
  }
`;
