import React from "react";
import styled from "styled-components";

const BasicDetails = ({ row }) => {
  return (
    <Box>
      <div className="box1">
        <div className="textBox">
          <div className="childBox">
            <span className="text">
              {row?.interview?.userName || row?.matchedRecord?.name || "NA"}
            </span>
          </div>
          <div className="childBox">
            <span className="text">
              {row?.interview?.userEmail || row?.matchedRecord?.email || "NA"}
            </span>
          </div>
          <div className="childBox">
            <span className="text">
              {row?.matchedRecord?.usercontact ||
                row?.interview?.contact ||
                "NA"}
            </span>
          </div>
        </div>
      </div>
    </Box>
  );
};
// console.log("BasicDetails");
export default BasicDetails;

const Box = styled.div`
  width: 100%;
  .box1 {
    padding: 1rem 2rem 0rem 2rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    .textBox {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .childBox {
        width: 30%;
        display: flex;
        justify-content: center;
        .text {
          display: flex;
          justify-content: center;
          font-size: 0.85rem;
          font-weight: 900;
        }
      }
    }
  }
`;
