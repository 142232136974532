
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    exp: [],
    selectedLocations: [],
    selectedSkills: [],
    workType: [],
    salaryRange: [],
    freshness: [],
    noticePeriod: [],
    companyType: [],
    candidateAvl: [],
};

const jobFilterSlice = createSlice({
    name: 'jobFilter',
    initialState,
    reducers: {
        updateExp: (state, action) => {
            state.exp = action.payload;
        },
        updateSelectedLocations: (state, action) => {
            state.selectedLocations = action.payload;
        },
        updateSelectedSkills: (state, action) => {
            state.selectedSkills = action.payload;
        },
        updateWorkType: (state, action) => {
            state.workType = action.payload;
        },
        updateSalaryRange: (state, action) => {
            state.salaryRange = action.payload;
        },
        updateFreshness: (state, action) => {
            state.freshness = action.payload;
        },
        updateNoticePeriod: (state, action) => {
            state.noticePeriod = action.payload;
        },
        updateCompanyType: (state, action) => {
            state.companyType = action.payload;
        },
        updateCandidateAvl: (state, action) => {
            state.candidateAvl = action.payload;
        }
    },
});

export const {
    updateExp,
    updateSelectedLocations,
    updateSelectedSkills,
    updateWorkType,
    updateSalaryRange,
    updateFreshness,
    updateNoticePeriod,
    updateCompanyType,
    updateCandidateAvl,
} = jobFilterSlice.actions;
export default jobFilterSlice.reducer;
