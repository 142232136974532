import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import filterIcon from '../../../../assets/icons/filterIcon.png'
import closeIcon from '../../../../assets/icons/closeIcon.png'
import CheckboxInput from './CheckboxInput';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateCandidateAvl, updateCompanyType, updateFreshness, updateNoticePeriod, updateSalaryRange, updateWorkType } from '../../../../slices/jobFilterSlice';

const workTypeArr = [
  { value: 'fullTime', text: 'Full-time' },
  { value: 'partTime', text: 'Part-time' },
  { value: 'contract', text: 'Contract' },
]

const salaryArr = [
  { value: '0-3', text: '0-3 lakhs' },
  { value: '3-6', text: '3-6 lakhs' },
  { value: '6-10', text: '6-10 lakhs' },
  { value: '10-20', text: '10-20 lakhs' },
  { value: '20+', text: '20+ lakhs' },
]

const noticePeriodArr = [
  { value: 'immediate', text: 'Immediate' },
  { value: '1week', text: '1 Week' },
  { value: '2weeks', text: '2 Weeks' },
  { value: '1month', text: '1 Month' },
  { value: '2months', text: '2 Months' },
  { value: '3months', text: '3 Months' },
]

const candidateAvlArr = [
  { value: 'immediate', text: 'Immediate' },
  { value: '1week', text: '1 Week' },
  { value: '2weeks', text: '2 Weeks' },
  { value: '1month', text: '1 Month' },
  { value: '2months', text: '2 Months' },
  { value: '3months', text: '3 Months' },
]

const companyTypeArr = [
  { value: 'service', text: 'Service' },
  { value: 'product', text: 'Product' },
  { value: 'serviceStartup', text: 'Service Start up' },
  { value: 'productStartup', text: 'Product Start up' },
]


function ProfileFilter({ applyFilters, setResetAllFilters }) {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const workType = useSelector((state) => state.jobFilter.workType);
  const salaryRange = useSelector((state) => state.jobFilter.salaryRange);
  const freshness = useSelector((state) => state.jobFilter.freshness);
  const noticePeriod = useSelector((state) => state.jobFilter.noticePeriod);
  const companyType = useSelector((state) => state.jobFilter.companyType);
  const candidateAvl = useSelector((state) => state.jobFilter.candidateAvl);


  const dispatch = useDispatch();

  const toggleDropdown = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({ top: rect.bottom });
    }
    setIsOpen(!isOpen);
  };


  const closeDropdown = () => {
    setIsOpen(false);
  };

  const resetFilters = () => {
    setResetAllFilters(prev => !prev);
    dispatch(updateWorkType([]));
    dispatch(updateSalaryRange([]));
    dispatch(updateFreshness([]));
    dispatch(updateNoticePeriod([]));
    dispatch(updateCompanyType([]));
    dispatch(updateCandidateAvl([]));
    closeDropdown();
  };


  const handleCheckboxChange = (value, setSelected, prevSelected) => {
    dispatch(setSelected((prevSelected.includes(value)) ? prevSelected.filter((selected) => selected !== value) : [...prevSelected, value]));
  };

  return (
    <FilterContainer>

      <button onClick={toggleDropdown} ref={buttonRef} className='mainBtn'><img src={filterIcon} /></button>

      {isOpen && (
        <div className="dropdown" style={{ top: position.top, left: position.left }}>
          <div className="buttons">
            <button onClick={resetFilters} className='button'>Reset Filters</button>
            <button onClick={() => {
              applyFilters()
              closeDropdown()
            }} className='button'>Apply Filters</button>
            <img src={closeIcon} className='image' onClick={closeDropdown} />
          </div>

          <div className="content">
            <InputBox>
              <span className="title">Work Type</span>
              <div className="childInputBox">
                {
                  workTypeArr.map((work) => (
                    <CheckboxInput
                      value={work.value}
                      selectedValues={workType}
                      handleInputChange={(value) => handleCheckboxChange(value, updateWorkType, workType)}
                      text={work.text} />
                  ))
                }
              </div>
            </InputBox>


            <InputBox>
              <span className="title">Salary</span>
              <div className="childInputBox">
                {
                  salaryArr.map((salary) => (
                    <CheckboxInput
                      value={salary.value}
                      selectedValues={salaryRange}
                      handleInputChange={(value) => handleCheckboxChange(value, updateSalaryRange, salaryRange)}
                      text={salary.text}
                    />
                  ))
                }

              </div>
            </InputBox>

            <InputBox>
              <span className="title">Notice Period</span>
              <div className="childInputBox">
                {
                  noticePeriodArr.map((notice) => (
                    <CheckboxInput
                      value={notice.value}
                      selectedValues={noticePeriod}
                      handleInputChange={(value) => handleCheckboxChange(value, updateNoticePeriod, noticePeriod)}
                      text={notice.text}
                    />
                  ))
                }
              </div>
            </InputBox>

            <InputBox>
              <span className="title">Company Type</span>
              <div className="childInputBox">
                {
                  companyTypeArr.map((company) => (
                    <CheckboxInput
                      value={company.value}
                      selectedValues={companyType}
                      handleInputChange={(value) => handleCheckboxChange(value, updateCompanyType, companyType)}
                      text={company.text}
                    />
                  ))
                }
              </div>
            </InputBox>

            <InputBox>
              <span className="title">Candidate Avl</span>
              <div className="childInputBox">
                {
                  candidateAvlArr.map((candidate) => (
                    <CheckboxInput
                      value={candidate.value}
                      selectedValues={candidateAvl}
                      handleInputChange={(value) => handleCheckboxChange(value, updateCandidateAvl, candidateAvl)}
                      text={candidate.text}
                    />
                  ))
                }
              </div>
            </InputBox>
          </div>
        </div>
      )}

    </FilterContainer>
  );
}

export default ProfileFilter;


const InputBox = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
background-color: var(--white);
border: 0.09rem solid lightgrey;
padding: 1.5rem 3rem;
height: 100%;
border-radius: 0.5rem;


.childInputBox {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.title {
  font-size: 0.9rem;
  font-weight: 600;

}



`

const FilterContainer = styled.div`
  width: 91.4%;
  border-radius: 0.8rem;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hrLine {
    width: 99%;
    border-top: 0.08rem solid grey;
    margin: 0.1rem 0 -0.3rem 0;
  }

  .dropdown {
    position: absolute;
    top: 0;
    right: 0.5rem;
    width: calc(100% - 20.3rem);
    height: 20rem;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    z-index: 1;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  .content {
    display: flex;
    justify-content: space-evenly;.
    align-items: center;
    height: 65%;
    margin-top: 1rem;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    position: relative;  
    gap: 0.8rem;
    

    .button {
      font-size: 0.8rem;
      background-color: var(--white);
      border: 0.08rem solid var(--color);
      padding: 0.2rem 0.3rem;
      border-radius: 0.2rem;
      cursor: pointer;
      font-family: var(--font);
    }

    .image {
      width: 1.4rem;
      height: 1.4rem;
      cursor: pointer;
    }

  }


  .mainBtn {
    align-self: start;
    background-color: var(--white);
    border: 0.08rem solid lightgrey;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    

    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  
`;
