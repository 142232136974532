// currentItemSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resumeId: "",
    templateId: "",
    mode: "",
    viewOnly: false,
    cvData: null,
};

const cvBuilderSlice = createSlice({
    name: 'cvBuilder',
    initialState,
    reducers: {
        setResumeId: (state, action) => {
            state.resumeId = action.payload;
        },
        setTemplateId: (state, action) => {
            state.templateId = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setCvData: (state, action) => {
            state.cvData = action.payload;
        },
        setViewOnly: (state, action) => {
            state.viewOnly = action.payload;
        },
    },
});

export const { setResumeId, setTemplateId, setMode, setCvData, setViewOnly } = cvBuilderSlice.actions;
export default cvBuilderSlice.reducer;
