import axios from "axios"


export const removeSavedJob = async (id, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/jds/${id}/savedJobs`, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

