import React from "react";
import styled from "styled-components";
import homepage1 from "../../assets/homepage1.png";
import homepage3 from "../../assets/homepage3.png";
import productCard1 from "../../assets/homepage2.jpg";
import productCard2 from "../../assets/homepage4.jpeg";
import productCard3 from "../../assets/homepage6.jpeg";
import bg1 from "../../assets/bg1.jpg";
import bg2 from "../../assets/bg5.jpg";
import { useNavigate } from "react-router";
import BenefitsSection from "./BenefitsSection";
import ReviewsSection from "./TeamSection";
import TeamSection from "./ReviewsSection";
import BusinessAssociatesSection from "./BusinessAssociates";

const DetailedContent = () => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <div className="topTextBox">
        <span className="topTitle">Empower your hiring ecosystem</span>
        <span className="topText">
          Otohire enables you with tools powered by AI & system that give you an
          edge, speed and cost effectiiveness on your regular and special hiring
          needs
        </span>
      </div>
      <div className="topBox">
        <div className="topBoxChild">
          <div className="topBoxChildAnime">1</div>
          <span className="topBoxChildTitle">AI powered Systems</span>
          <span className="topBoxChildText">
            Otohire provides you an edge with its AI enabled tools to power your
            HR systems
          </span>
        </div>
        <div className="topBoxChild">
          <div className="topBoxChildAnime">2</div>
          <span className="topBoxChildTitle">Automate Manual Tasks</span>
          <span className="topBoxChildText">
            Leave behind time and bandwidth consuming manual hiring processed to
            utilize your manpower better and getting things done with few clicks
          </span>
        </div>
        <div className="topBoxChild">
          <div className="topBoxChildAnime">3</div>
          <span className="topBoxChildTitle">Stream Line Processes</span>
          <span className="topBoxChildText">
            Simplify complex recruitment processes for smooth operations
          </span>
        </div>
        <div className="topBoxChild">
          <div className="topBoxChildAnime">4</div>
          <span className="topBoxChildTitle">Speed Up growth</span>
          <span className="topBoxChildText">
            We know what matter most and that where all we build aims to fuel
            your growth
          </span>
        </div>
      </div>
      <div className="visualDetail">
        <div className="visualDetailBox">
          <div className="visualDetailTextBox">
            <span className="visualDetailTitle">Efficient Sourcing</span>
            <span className="visualDetailText">
              One of the most time-consuming tasks in recruitment is reviewing
              resumes and job applications. AI-powered resume screening systems
              can significantly improve this process. These systems use natural
              language processing (NLP) and machine learning algorithms to
              quickly analyze and rank resumes based on their relevance to the
              job description.
            </span>
          </div>
          <div className="visualDetailImgBox">
            <img src={homepage1} />
          </div>
        </div>
        <div className="visualDetailBox">
          <div className="visualDetailImgBox">
            <img src={homepage3} />
          </div>
          <div className="visualDetailTextBox">
            <span className="visualDetailTitle">Processes to Fuel Growth</span>
            <span className="visualDetailText">
              To fully leverage the growth potential of AI in hiring processes,
              it's essential to choose the right AI tools and platforms,
              integrate them seamlessly into your existing systems, and provide
              appropriate training to HR professionals to use these tools
              effectively. Additionally, continuous monitoring and refinement of
              AI-driven processes are crucial to ensure ongoing success and
              alignment with your organization's growth goals.
            </span>
          </div>
        </div>
      </div>
      <BenefitsSection />
      <span className="teamTitle"> Our Team</span>
      <ReviewsSection />
      <span className="reviwersTitle"> Here's what clients say about us</span>
      <TeamSection />

      <span className="associatesTitle"> Our Corporate Partners</span>
      <BusinessAssociatesSection />
      <div className="contactSection">
        <span className="contactSectionTitle">
          Embark on a transformative journey with our cutting-edge Recruitment
          Solutions, where the precision of Al meets the warmth of Human Touch,
          propelling your growth at every step.
        </span>
        <button
          className="contactSectionBtn"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </button>
      </div>
      <div className="achievedNumber">
        <div className="achievedNumberBox">
          <span className="achievedNumberDigit">1500</span>
          <span className="achievedNumberText">Registered job seekers</span>
        </div>
        <div className="achievedNumberBox">
          <span className="achievedNumberDigit">30</span>
          <span className="achievedNumberText">Employers on board</span>
        </div>
        <div className="achievedNumberBox">
          <span className="achievedNumberDigit">4500</span>
          <span className="achievedNumberText">Interviews completed</span>
        </div>
        <div className="achievedNumberBox">
          <span className="achievedNumberDigit">150</span>
          <span className="achievedNumberText">Processes Automated</span>
        </div>
      </div>
      <div className="product">
        <span className="productTitle">Whats the latest we have built</span>
        <span className="productText">
          Explore our blog for insightful articles, personal reflections and
          ideas that inspire action on the topics you care about.
        </span>

        <div className="productCardBox">
          <div className="productCard">
            <img className="productCardImg" src={productCard1} />
            <span className="productCardText">
              AI benefits with human touch <br />{" "}
              <b onClick={() => navigate("/product/otohire")} className="kMore">
                Know more &gt;&gt;
              </b>
            </span>
          </div>
          <div className="productCard">
            <img className="productCardImg" src={productCard2} />
            <span className="productCardText">
              Streamline and Automate processes for better Opex <br />{" "}
              <b
                onClick={() => navigate("/product/otosource")}
                className="kMore"
              >
                Know more &gt;&gt;
              </b>
            </span>
          </div>
          <div className="productCard">
            <img className="productCardImg" src={productCard3} />
            <span className="productCardText">
              Technology and innovation that will inspire you <br />{" "}
              <b
                onClick={() => navigate("/product/otoboard")}
                className="kMore"
              >
                Know more &gt;&gt;
              </b>
            </span>
          </div>
        </div>
      </div>
      <div className="contactSectionBottom">
        <span className="contactSectionBottomTitle">Ready to get started?</span>
        <span className="contactSectionBottomText">
          Otohire is a highly automated set of AI tools and systems. Let your
          start your journey to build team of best talent available!
        </span>
        <button
          className="contactSectionBottomBtn"
          onClick={() => navigate("/signup")}
        >
          Try Now
        </button>
      </div>
    </StyledBox>
  );
};

export default DetailedContent;

const StyledBox = styled.div`

display: flex;
flex-direction: column;
width: 100%;
margin-top: 5rem;


.topTextBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.topTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.topText {
    font-size: 1rem;
    font-weight: 400;
    width: 60%;
    text-align: center;
    line-height: 1.5rem;
}

.topBox {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    gap: 5%;
    margin-top: 5rem;
    margin-bottom: 3rem;
    margin-left: 5%;
    margin-right: 5%;
}

.topBoxChild {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    width: 20%;
    min-height: 15rem;
}

.topBoxChildTitle {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
}

.topBoxChildText {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    word-wrap: break-word;
}

.topBoxChildAnime {
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--cyan);
    border-radius: 50%;
    box-shadow: 0 0 1rem var(--cyan);
}


.visualDetail {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    margin-left: 5%;
    margin-right: 5%;
}

.visualDetailBox {
    display: flex;
    flex-direction: row;
    gap: 5%;
}


.visualDetailTextBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.visualDetailTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.visualDetailText {
    font-size: 1rem;
    line-height: 1.5rem;
}

.visualDetailSpecBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.visualDetailSpec {
    display: flex;
    gap: 1rem;
    font-size; 1rem;
    font-weight: 500;
}

.rightSign {
    color: var(--orange);
    font-weight: 1000;
}

.visualDetailImgBox {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.visualDetailImgBox > img {
    width: 40%;
}


.contactSection {
    display: flex;
    flex-direction: column;
    background-color: var(--cyan);
    justify-content: center;
    align-items: center;
    padding: 4rem 7.5%;
    width: 85%;
    gap: 2rem;
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat; 
}


.contactSectionTitle {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--white);
    text-align: center;
}


.contactSectionBtn {
    background-color: var(--white);
    color: var(--color);
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.contactSectionBtn:hover {
    cursor: pointer;
    background-color: #FFD580;
}

.achievedNumber {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 5% 2rem 5%;
}

.achievedNumberBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.achievedNumberDigit {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--cyan);
}

.achievedNumberText {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color);
    text-align: center;
}


.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 5rem;
    gap: 1rem;
}

.productTitle {
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
}
.associatesTitle {
  margin-top: 4rem;
  margin-bottom: 0rem;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: center;
}
.teamTitle {
  font-size: 1.4rem;
  font-weight: 800;
  text-align: center;
  // margin-left: 4.5rem;
  // margin-top: 1rem
  margin-bottom: 0rem;
}
.reviwersTitle {
  font-size: 1.4rem;
  font-weight: 800;
  text-align: center;
  // margin-left: 4.5rem;
  // margin-top: 1rem
  margin-bottom: 2rem;
}
.productText {
    font-size: 1rem;
    line-height: 1rem;
    width: 60%;
    text-align: center;
    font-weight: 400;
}

.productCardBox {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
    padding: 0rem 2rem;
}

.productCard {
    width: 22.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    gap: 1rem;
    padding: 2rem 0rem;
    border-radius: 1rem;
}

.productCard:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
    background: linear-gradient(to bottom, #f0f0f0, #89f3eb);
}

.productCardImg {
    width: 60%;
}

.productCardText {
    width: 80%;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
}

.kMore {
    cursor: pointer;
    color: var(--cyan);
}



.contactSectionBottom {
    display: flex;
    flex-direction: column;
    background-color: var(--orange);
    justify-content: center;
    align-items: center;
    padding: 4rem 0%;
    width: 94%;
    margin: 0% 3%;
    gap: 2rem;
    border-radius: 1rem;
    margin-bottom: 5rem;


    background-image: url(${bg2});
    background-size: cover;
    background-repeat: no-repeat; 
}


.contactSectionBottomTitle {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--white);
    text-align: center;
}

.contactSectionBottomText {
    font-size: 1rem;
    font-weight: 400;
    color: var(--white);
    text-align: center;
    width: 60%;
    line-height: 1.5rem;
}

.contactSectionBottomBtn {
    background-color: var(--white);
    color: var(--color);
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.contactSectionBottomBtn:hover {
    cursor: pointer;
    background-color: #FFD580;
}


@media (max-width: 500px) {
    .topBoxChild {
        width: 40%;
        margin-top: 1rem;
    }
}

`;
