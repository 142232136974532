import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled, { css } from "styled-components";
import deleteIcon from "../../../../assets/icons/delete.png";
import CommonDialog from "../../../commonComponents/CommonDialog";
import DeleteDialogContent from "../../../commonComponents/DeleteDialogContent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import ManageAgencyForm from "../ManageAgencyForm";
import { getMappings } from "../../../../functions/api/employers/agency/getMapping";
import { removeMapping } from "../../../../functions/api/employers/agency/removeMapping";

function Row(props) {
  const { row, index, setUserTrigger } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleRemove = async (id) => {
    try {
      const res = await removeMapping(id, accessToken, clientCode);
      toast.success(
        `Mapping for Agency id ${row?.agencyName} Removed Successfully`
      );
      setUserTrigger((prev) => !prev);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <CommonDialog
          open={deleteOpen}
          handleClose={() => setDeleteOpen(false)}
          component={
            <DeleteDialogContent
              handleClose={() => setDeleteOpen(false)}
              text="agency"
              handleDelete={() => handleRemove(row?.id)}
            />
          }
        />
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.agencyName}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.agencySpocName}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.agencySpocEmail}
        </TableCell>
        <TableCell align="center" className="tableCell">
          {row?.agencySpocContact}
        </TableCell>
        <TableCell align="center" className="tableCell">
          <img
            src={deleteIcon}
            className="threeDotIcon"
            onClick={() => setDeleteOpen(true)}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ManageAgencies() {
  const [openBasic, setOpenBasic] = useState(false);
  const [mapped, setMapped] = useState([]);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  const [userTrigger, setUserTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getMappings(accessToken, clientCode, page, size);
        if (res) {
          setMapped(res?.data);
          setTotal(res?.data?.length);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getData();
  }, [userTrigger, page, size]);

  return (
    <StyledDiv>
      <TableContainer component={Paper} className="tableBox">
        <ModalHOC
          openNewInterviewModal={openBasic}
          setOpenNewInterviewModal={setOpenBasic}
          component={
            <ManageAgencyForm
              array={[null, "create"]}
              handleClose={() => {
                setOpenBasic(false);
                setUserTrigger(!userTrigger);
              }}
            />
          }
          handleClose={() => {
            setOpenBasic(false);
            setUserTrigger(!userTrigger);
          }}
        />

        <Component>
          <span className="title">Manage Agencies</span>
          <div className="btnBox">
            <EditButton onClick={() => setOpenBasic(true)}>
              Add Agency
            </EditButton>
          </div>
        </Component>

        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">
                Agency Name
              </TableCell>
              <TableCell align="center" className="tableCell">
                Spoc Name
              </TableCell>
              <TableCell align="center" className="tableCell">
                Spoc Email
              </TableCell>
              <TableCell align="center" className="tableCell">
                Spoc Contact
              </TableCell>
              <TableCell align="center" className="tableCell">
                Remove Agency
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {mapped?.map((row, index) => (
              <Row
                key={row?.id}
                row={row}
                index={index}
                setUserTrigger={setUserTrigger}
              />
            ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  margin: 1rem 0% 2rem 0%;
  width: 94%;
  padding: 0 3%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    &::-webkit-scrollbar {
      width: 0rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0rem;
    }

    & {
      scrollbar-width: none;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }

    .threeDotIcon {
      width: 0.8rem;
      height: 0.8rem;
      cursor: pointer;
      border: 0.08rem solid #fe4c4f;
      padding: 0.3rem;
      border-radius: 0.3rem;
    }
  }

  .checkBox {
    cursor: pointer;
  }
`;

const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .three-dots {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;

    ${(props) =>
      props.isLast &&
      css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }
  .dropdown-content span:hover {
    background: #e2dbdb36;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;

const Component = styled.div`
  width: 99%;
  padding: 0.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditButton = styled.button`
  background-color: var(--cyan);
  border: 0.1rem solid var(--cyan);
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 0.6rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  font-family: var(--font);
`;
