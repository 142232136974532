import axios from "axios";


export const getStatusWiseCount = async (accessToken,type) => {

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  },
  params: {

  }

};

if(type)config.params.type="mock"
console.log("============>>>>>.ppppppp",config.params)
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/statusWiseCount`,config);
        console.log('Data:', response.data);
        console.log('Status:', response.status);
        return response.data;
      } catch (error) {
        throw error;
      }
}


