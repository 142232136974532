import React from "react";
import styled from "styled-components";
import berlin from "../assets/resume/resume1.png";
import tokyo from "../assets/resume/resume2.png";
import nairobi from "../assets/resume/resume3.png";
import denver from "../assets/resume/resume4.png";
import rio from "../assets/resume/resume5.png";
import moscow from "../assets/resume/resume6.png";
import martin from "../assets/resume/resume7.png";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setMode } from "../slices/cvBuilderSlice";

const resumes = [
  { img: berlin, text: "berlin" },
  { img: tokyo, text: "tokyo" },
  { img: nairobi, text: "nairobi" },
  { img: denver, text: "denver" },
  { img: rio, text: "rio" },
  // { img: moscow, text: 'moscow' },
  { img: martin, text: "martin" },
];

const SelectCVTempPage = ({ handleClose, mode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTemplateClick = (resume) => {
    dispatch(setMode(mode === "create" ? "create" : "edit"));
    navigate(`/create-resume/${resume.text}`);
    if (mode === "edit") {
      handleClose();
    }
  };

  return (
    <Box>
      <span className="title">Choose Resume Template</span>
      <div className="childBox">
        {resumes.map((resume, index) => (
          <ChildBox onClick={() => handleTemplateClick(resume)}>
            <img src={resume.img} />
            <button className="btn" onClick={() => handleTemplateClick(resume)}>
              Use{" "}
              {resume.text.charAt(0).toUpperCase() +
                resume.text.slice(1).toLowerCase()}{" "}
              Template
            </button>
          </ChildBox>
        ))}
      </div>
    </Box>
  );
};

export default SelectCVTempPage;

const Box = styled.div`
  width: 100%;
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .childBox {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem auto 2rem auto;
    gap: 1rem;
    justify-content: center;
  }
`;

const ChildBox = styled.div`
  background-color: #eef3f9;
  display: flex;
  justify-content: center;
  align-items: center;
  postion: relative;
  cursor: pointer;
  border-radius: 0.5rem;

  img {
    width: 90%;
    height: 90%;
  }

  .btn {
    position: absolute;
    display: none;
    background-color: var(--cyan);
    border: none;
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--white);
    border-radius: 0.5rem;
    cursor: pointer;
  }

  &:hover .btn {
    display: block;
  }

  &:hover img {
    box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.1);
  }
`;
