import axios from "axios";

export const editCandidate = async (
  id,
  candidateDetails,
  accessToken,
  clientCode
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "X-Client-Code": clientCode,
    },
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/candidates/${id}`,
      candidateDetails,
      config
    );
    console.log("Data:", response.data);
    console.log("Status", response.status);
    return response.data;
  } catch (error) {
    throw error;
  }
};
