import HTTP from "../../../backendApi";

export const auth = async (password, email, clientCode) => {
  const requestData = {
    password: password,
    username: email,
  };

  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'x-client-code': clientcode
  //   }
  // };
  // console.log(requestData,config);

  try {
    const response = await HTTP.post(`/auth/login`, requestData, {
      headers: { "x-client-code": clientCode },
    });
    console.log("Data:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
