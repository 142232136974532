import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { addJd } from "../../../functions/api/employers/addJd";
import { useSelector } from "react-redux";
import { editJd } from "../../../functions/api/employers/editJd";
import { Paper, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import {
  locations,
  technicalSkills,
  allTags,
} from "../../../utils/contantData";
import { checkJdExist } from "../../../functions/api/employers/checkJdExist";
import { getTagsConfig } from "./../../../functions/api/globalFunctions/getTagsConfig";
import { setJdTrigger } from "../../../slices/jdSlice";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.3rem;

  .textEditor {
    background-color: #f6f6fb;
    height: calc(100% - 3rem);
  }

  .mainTitle {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 2rem;
    display: block;
    margin-bottom: 0.5rem;
  }

  .relative {
    position: relative;
    width: 100%;
  }

  .helperText {
    display: block;
    font-size: 0.75rem;
    margin: -0.5rem 0;
    color: red;
    font-weight: 400;
    position: absolute;
    top: calc(100% + 0.75rem);
    right: 0;
  }

  .check {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .fileInputBox {
    position: relative;
    width: 100%;
    height: 12rem;
    background-color: #f6f6fb;

    textarea {
      width: 100%;
      border: 1px solid #ccc;
      background-color: #f6f6fb;
      outline-color: #ccc;
      border-radius: 0.5rem;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
    }

    textarea:focus {
      outline-color: #1976d2;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #f6f6fb;
      font-family: var(--font);
    }
  }

  #demo-simple-select-label {
    font-size: 0.8rem;
    font-weight: 600;
    font-family: var(--font);
  }
  #tags-standard {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    background-color: #f6f6fb;
  }

  .css-1uzfhwi-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.75rem;
  }

  .css-84j6f5 {
    height: 1.75rem;
  }
`;

const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: 8px;
  font-weight: 500;
  color: grey;
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  background-color: #f6f6fb;
  font-family: var(--font);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 0 0.2rem;
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-family: var(--font);
`;

function JdForm({ array, handleClose }) {
  const [jdExist, setJdExist] = useState(false);
  const [mode, setMode] = useState("create");
  const [autoReq, setAutoReq] = useState(false);
  const [description, setDescription] = useState("");
  const [expMin, setExpMin] = useState("");
  const [expMax, setExpMax] = useState("");
  const [formData, setFormData] = useState({
    jdId: "",
    reqNumber: "",
    numOfReqs: 0,
    title: "",
    description: "",
    skills: "",
    bu: "",
    exp: "",
    location: "",
    certification: "",
    workType: "",
    ctcMin: "",
    ctcMax: "",
    keywords: "",
    jd: "",
    noticePeriod: "",
    companyType: "",
    candidateAvailability: "",
    hiringManager: "",
    recruiter: "",
    // jobSummary: '',
    tags: "",
    jdUpload: null,
    visibility: "",
    autoReqNumbers: true,
  });
  const [allTags, setAllTags] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [initialReqs, setInitialReqs] = useState(0);
  const [reqsError, setReqsError] = useState(false);
  const [skillInputValue, setSkillInputValue] = useState("");
  const [locationInputValue, setLocationInputValue] = useState("");
  const [tagInputValue, setTagInputValue] = useState("");

  const dispatch = useDispatch();
  const jdTrigger = useSelector((state) => state.jd?.JdTrigger);

  const handleLocationsChange = (_, newLocations) => {
    setSelectedLocations(newLocations);
  };

  const handleSkillsChange = (_, newSkills) => {
    setSelectedSkills(newSkills);
  };

  const handleTagsChange = (_, newTags) => {
    setSelectedTags(newTags);
  };

  const handleLocationInputBlur = () => {
    if (locationInputValue.trim() !== "") {
      setSelectedLocations([...selectedLocations, locationInputValue?.trim()]);
      setLocationInputValue("");
    }
  };

  const handleSkillInputBlur = () => {
    if (skillInputValue.trim() !== "") {
      setSelectedSkills([...selectedSkills, skillInputValue?.trim()]);
      setSkillInputValue("");
    }
  };

  const handleTagInputBlur = () => {
    if (tagInputValue.trim() !== "") {
      setSelectedTags([...selectedTags, tagInputValue?.trim()]);
      setTagInputValue("");
    }
  };
  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await getTagsConfig(accessToken, clientCode);
        if (res) {
          setAllTags(res?.data?.value?.split(","));
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg, 8000);
      }
    };

    getTags();
  }, []);
  useEffect(() => {
    setFormData({
      ...formData,
      skills: selectedSkills?.join(", "),
    });
  }, [selectedSkills]);

  useEffect(() => {
    setFormData({
      ...formData,
      location: selectedLocations?.join(", "),
    });
  }, [selectedLocations]);

  useEffect(() => {
    setFormData({
      ...formData,
      tags: selectedTags?.join(","),
    });
  }, [selectedTags]);

  const accessToken = useSelector((state) => state.auth?.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  useEffect(() => {
    if (array[0]) {
      if (array[1] != "edit") checkJdPresent(array[0]?.jdId);
      const formDataToSet = { ...array[0] };

      if (array[0].exp) {
        const [min, max] = array[0].exp.split("-");
        setExpMin(min);
        setExpMax(max);
      }

      if (formDataToSet.ctc) {
        const [ctcMin, ctcMax] = formDataToSet.ctc.split("-");
        formDataToSet.ctcMin = ctcMin;
        formDataToSet.ctcMax = ctcMax;
      }

      setFormData(array[0]);
      setFormData(formDataToSet);
      setDescription(array[0]?.description);
      setInitialReqs(array[0]?.numOfReqs);
      {
        array[0]?.skills && setSelectedSkills(array[0]?.skills?.split(", "));
      }
      {
        array[0]?.tags && setSelectedTags(array[0]?.tags?.split(","));
      }
      {
        array[0]?.location &&
          setSelectedLocations(array[0]?.location?.split(", "));
      }
    }
    setMode(array[1]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "expMin" || name === "expMax") {
      const numValue = Number(value);
      if (!isNaN(numValue) && numValue >= 0) {
        name === "expMin" ? setExpMin(value) : setExpMax(value);
        setFormData({
          ...formData,
          exp: `${name === "expMin" ? value : expMin}-${
            name === "expMax" ? value : expMax
          }`,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: name === "ctcMin" || name === "ctcMax" ? Number(value) : value,
      });
    }
  };
  useEffect(() => {
    if (description !== "") {
      setFormData({
        ...formData,
        description: description,
      });
    }
  }, [description]);

  const checkReqs = () => {
    if (formData?.numOfReqs < initialReqs) {
      setReqsError(true);
      setFormData({
        ...formData,
        numOfReqs: initialReqs,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      jdUpload: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...formData,
        ctc: `${formData.ctcMin}-${formData.ctcMax}`,
      };

      if (description?.trim()?.length === 0) {
        toast.warning("Job Description shouldn't be blank", 5000);
        return;
      } else if (selectedSkills?.length === 0) {
        toast.warning("Select at least one skill", 5000);
        return;
      } else if (selectedLocations?.length === 0) {
        toast.warning("Select at least one location", 5000);
        return;
      }

      if (mode === "create" || mode === "clone") {
        const resObj = await addJd(payload, accessToken, clientCode);
        if (resObj) {
          toast.success("JD successfully created", 5000);
          console.log(resObj);
          dispatch(setJdTrigger(!jdTrigger));
          handleClose();
        }
      } else {
        const editRes = await editJd(payload, accessToken, clientCode);
        if (editRes) {
          toast.success("JD successfully updated", 5000);
          console.log(editRes);
          dispatch(setJdTrigger(!jdTrigger));
          handleClose();
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const checkJdPresent = async (jdId) => {
    try {
      if (mode != "edit") {
        const res = await checkJdExist(accessToken, clientCode, jdId);
        if (res?.data) {
          setJdExist(true);
        }
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const handleJdPresentError = async () => {
    setJdExist(false);
  };

  return (
    <Container>
      <span className="mainTitle">JD Registration</span>

      <Form onSubmit={handleSubmit}>
        <div className="inputBox">
          <div className="relative">
            <TextField
              id="outlined-basic"
              label="JD ID"
              variant="outlined"
              type="text"
              name="jdId"
              value={formData?.jdId}
              onChange={handleChange}
              onBlur={() => checkJdPresent(formData?.jdId)}
              onFocus={handleJdPresentError}
              disabled={mode == "edit"}
              sx={{ backgroundColor: "#F6F6FB" }}
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              required
              fullWidth
            />
            {jdExist && <span className="helperText">JD already exists</span>}
          </div>

          <TextField
            id="outlined-basic"
            label="Number of Reqs"
            variant="outlined"
            type="number"
            name="numOfReqs"
            value={formData?.numOfReqs}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                handleChange(e);
              }
            }}
            onBlur={() => checkReqs()}
            onFocus={() => setReqsError(false)}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
            required
            fullWidth
          />
          {/* {reqsError && <span>Error Message:</span>} */}
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            type="text"
            name="title"
            value={formData?.title}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
            required
            fullWidth
          />
          <FormControl sx={{ backgroundColor: "#F6F6FB", width: "42.5%" }}>
            <TextField
              id="exp-min"
              label="Exp Min (Years)"
              variant="outlined"
              type="number"
              name="expMin"
              required
              value={expMin}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                min: 0,
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              disabled={jdExist}
              fullWidth
            />
          </FormControl>
          —
          <FormControl sx={{ backgroundColor: "#F6F6FB", width: "42.5%" }}>
            <TextField
              id="exp-max"
              label="Exp Max (Years)"
              variant="outlined"
              type="number"
              name="expMax"
              required
              value={expMax}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                min: 0,
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              disabled={jdExist}
              fullWidth
            />
          </FormControl>
        </div>

        <div className="fileInputBox">
          <Label>Job Description</Label>
          <ReactQuill
            theme="snow"
            name="description"
            value={description}
            onChange={setDescription}
            className="textEditor"
          />
        </div>

        <div className="inputBox">
          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={technicalSkills}
              getOptionLabel={(option) => option}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              onChange={handleSkillsChange}
              value={selectedSkills}
              selectOnFocus
              clearOnBlur
              freeSolo
              fullWidth
              onInputChange={(event, newInputValue) => {
                setSkillInputValue(newInputValue);
              }}
              onBlur={handleSkillInputBlur}
              PaperComponent={(props) => (
                <Paper {...props} sx={{ backgroundColor: "#F0F0F6" }} />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // required
                  label="Skills"
                  sx={{ backgroundColor: "#F6F6FB" }}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  disabled={jdExist}
                />
              )}
            />
          </Stack>

          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={locations}
              getOptionLabel={(option) => option}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              onChange={handleLocationsChange}
              value={selectedLocations}
              selectOnFocus
              clearOnBlur
              freeSolo
              onInputChange={(event, newInputValue) => {
                setLocationInputValue(newInputValue);
              }}
              onBlur={handleLocationInputBlur}
              PaperComponent={(props) => (
                <Paper {...props} sx={{ backgroundColor: "#F0F0F6" }} />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  sx={{ backgroundColor: "#F6F6FB" }}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  disabled={jdExist}
                />
              )}
              disabled={jdExist}
              fullWidth
            />
          </Stack>
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="BU"
            variant="outlined"
            type="text"
            name="bu"
            required
            value={formData?.bu}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
            fullWidth
          />

          <TextField
            id="outlined-basic"
            label="Certification"
            variant="outlined"
            type="text"
            name="certification"
            value={formData?.certification}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB", width: "42.5%" }}>
            {/* <InputLabel id="ctc-min-label">CTC Min</InputLabel> */}
            <TextField
              id="ctc-min"
              label="CTC Min (LPA)"
              variant="outlined"
              type="number"
              name="ctcMin"
              required
              value={formData?.ctcMin || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value) && Number(value) >= 0) {
                  handleChange(e);
                }
              }}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                min: 0,
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              disabled={jdExist}
              fullWidth
            />
          </FormControl>
          —
          <FormControl sx={{ backgroundColor: "#F6F6FB", width: "42.5%" }}>
            {/* <InputLabel id="ctc-max-label">CTC Max</InputLabel> */}
            <TextField
              id="ctc-max"
              label="CTC Max (LPA)"
              variant="outlined"
              type="number"
              name="ctcMax"
              required
              value={formData?.ctcMax || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value) && Number(value) >= 0) {
                  handleChange(e);
                }
              }}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
                min: 0,
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              disabled={jdExist}
              fullWidth
            />
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Keywords"
            variant="outlined"
            type="text"
            name="keywords"
            value={formData?.keywords}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
          />
        </div>

        <div className="inputBox">
          <TextField
            id="outlined-basic"
            label="Hiring Manager"
            variant="outlined"
            type="text"
            name="hiringManager"
            value={formData?.hiringManager}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            fullWidth
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
          />

          <TextField
            id="outlined-basic"
            label="Recruiter"
            variant="outlined"
            type="text"
            name="recruiter"
            value={formData?.recruiter}
            onChange={handleChange}
            sx={{ backgroundColor: "#F6F6FB" }}
            inputProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              },
            }}
            disabled={jdExist}
            fullWidth
          />
        </div>

        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel id="demo-simple-select-label">Work Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.workType}
              label="Work Type"
              onChange={handleChange}
              name="workType"
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
              }}
              disabled={jdExist}
            >
              <MenuItem value="fullTime">Full-time</MenuItem>
              <MenuItem value="partTime">Part-time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel id="demo-simple-select-label">Notice Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Notice Period"
              name="noticePeriod"
              value={formData?.noticePeriod}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
              }}
              disabled={jdExist}
            >
              <MenuItem value="immediate">Immediate</MenuItem>
              <MenuItem value="1week">1 Week</MenuItem>
              <MenuItem value="2weeks">2 Weeks</MenuItem>
              <MenuItem value="1month">1 Month</MenuItem>
              <MenuItem value="2months">2 Months</MenuItem>
              <MenuItem value="3months">3 Months</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel id="demo-simple-select-label">Company Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Company Type"
              name="companyType"
              value={formData?.companyType}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
              }}
              disabled={jdExist}
            >
              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="product">Product</MenuItem>
              <MenuItem value="softwareService">Software Service</MenuItem>
              <MenuItem value="serviceStartup">Service Start up</MenuItem>
              <MenuItem value="productStartup">Product Start up</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth>
            <InputLabel id="demo-simple-select-label">
              Candidate Availability
            </InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              label="Candidate Availability"
              name="candidateAvailability"
              value={formData?.candidateAvailability}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
              }}
              disabled={jdExist}
            >
              <MenuItem value="immediate">Immediate</MenuItem>
              <MenuItem value="1week">1 Week</MenuItem>
              <MenuItem value="2weeks">2 Weeks</MenuItem>
              <MenuItem value="1month">1 Month</MenuItem>
              <MenuItem value="2months">2 Months</MenuItem>
              <MenuItem value="3months">3 Months</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} fullWidth required>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Visibility
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Visibility"
              name="visibility"
              value={formData?.visibility}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0rem 0 0.5rem 0",
                color: "#626264",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
                height: "3.23rem",
              }}
              disabled={jdExist}
            >
              <MenuItem value="PUBLIC">Public</MenuItem>
              <MenuItem value="PRIVATE">Private</MenuItem>
            </Select>
          </FormControl>

          <Stack spacing={3} sx={{ width: "100%" }} required>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allTags}
              getOptionLabel={(option) => option}
              onChange={handleTagsChange}
              value={selectedTags}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  sx={{ backgroundColor: "#F6F6FB" }}
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      color: "#626264",
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#626264",
                      fontWeight: "500",
                      fontSize: "0.8rem",
                      fontFamily: "Quicksand, sans-serif",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </div>

        <Button type="submit" disabled={jdExist}>
          {mode == "create" ? "Submit" : "Save Changes"}
        </Button>
      </Form>
    </Container>
  );
}

export default JdForm;
