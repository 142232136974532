import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import searchBlack from "../../../../assets/icons/searchBlack.png";
import ProgressBar from "../../../commonComponents/ProgressBar";
import SeekerTableSearchBar from "../seekerCommonComponents/SeekerTableSearchBar";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { toast } from "react-toastify";
import { getInterviewByStatus } from "../../../../functions/api/getInterviewByStatus";
import { useSelector } from "react-redux";
import iIcon from "../../../../assets/icons/iIcon.png";
import ConfigurableModal from "../seekerCommonComponents/ConfigurableModal";
import ConfigurableBackdropModal from "../seekerCommonComponents/ConfigurableBackdropModal";

const Row = (props) => {
  const { row, index } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ConfigurableBackdropModal
        open={open}
        setOpen={setOpen}
        component={<JobSummaryViewModal jobSummary={row?.jobSummary} />}
        style={{ width: "50%", height: "70%" }}
      />
      <TableRow
        className={`${index % 2 == 1 ? "colored" : ""}`}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell customCell"
        >
          {row?.jobSummary?.slice(0, 25)}{" "}
          <img src={iIcon} className="icon" onClick={() => setOpen(true)} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.createdAt.split("T")[0]}
        </TableCell>
        {row?.status == "COMPLETED" && (
          <TableCell
            component="th"
            scope="row"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
            align="center"
            className="tableCell"
          >
            <ProgressBar progress={row?.score} maxScore={row?.maxScore} />
            <span style={{ fontSize: "0.7rem" }}>
              {row?.score}/{row?.maxScore}
            </span>
          </TableCell>
        )}

        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.status}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {row?.testType?.toUpperCase()}
        </TableCell>

        {row?.status == "COMPLETED" && (
          <TableCell
            component="th"
            scope="row"
            align="center"
            className="tableCell"
          >
            <button
              onClick={() => navigate(`/score/${row?.id}`)}
              className="btn"
            >
              Get Details
            </button>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

const MockInterviews = () => {
  const accessToken = useSelector(
    (state) => state?.auth?.userData?.accessToken
  );
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [filteredData, setFilteredData] = useState();
  const [value, setValue] = useState("COMPLETED");

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    async function getData(value) {
      try {
        const response = await getInterviewByStatus(
          value,
          accessToken,
          page,
          size,
          true
        );
        if (response) {
          setFilteredData(response?.data?.data);
          setTotal(response?.data?.total);
          console.log(response?.data?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }
    getData(value);
  }, [value, page, size]);

  if (total === 0) {
    return <h6 style={{ fontSize: "1.2rem" }}>No interview Here</h6>;
  }
  console.log(searchValue);
  return (
    <StyledInterviews>
      <TableContainer component={Paper} className="tableBox">
        <span className="title">Completed Mock Interviews</span>
        <SearchBarContainer>
          <SeekerTableSearchBar value={searchValue} setValue={setSearchValue} />
        </SearchBarContainer>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">
                JD/Skill
              </TableCell>
              <TableCell align="center" className="tableCell">
                Date of Interview
              </TableCell>
              <TableCell align="center" className="tableCell">
                Score
              </TableCell>
              <TableCell align="center" className="tableCell">
                Status
              </TableCell>
              <TableCell align="center" className="tableCell">
                Test Type
              </TableCell>
              <TableCell align="center" className="tableCell">
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {filteredData
              ?.filter((item) =>
                item.jobSummary
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              )
              ?.map((row, index) => (
                <Row key={index} row={row} index={index} />
              ))}
          </TableBody>
        </Table>
        <div className="paginationBox">
          <PaginationSizeFilter
            size={size}
            handleSizeChange={handleSizeChange}
          />
          <Pagination
            total={total}
            size={size}
            page={page}
            handlePageChange={handlePageChange}
            setPage={setPage}
          />
        </div>
      </TableContainer>
    </StyledInterviews>
  );
};

export default MockInterviews;

const JobSummaryViewModal = ({ jobSummary }) => {
  return <ModalBox>{jobSummary}</ModalBox>;
};

const ModalBox = styled.div``;

const StyledInterviews = styled.div`
  display: flex;
  width: 95%;
  margin: 2.5rem auto;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .tableBox {
    box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .btn {
    background-color: var(--cyan);
    padding: 0.5rem 0.8rem;
    border: none;
    color: var(--white);
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font);
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }

    .customCell {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;

      .icon {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
      }
    }
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 1rem auto 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;
`;
