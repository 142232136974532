import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import logo from "../assets/otohireNewLogo.jpg";
import prevIcon from "../assets/prevIcon2.png";
import nextIcon from "../assets/nextIcon2.png";
import { getInterviewDetails } from "../functions/api/interview/getInterviewDetails";
import { getEmployer } from "../functions/api/employers/profile/getEmployer";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { toast } from "react-toastify";
import BasicDetails from "../components/Interviews/SeekerDashboard/sidebarPages/BasicInterviewDetails";
import axios from "axios";
import ReactModal from "react-modal";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: "1rem",
    },
  })
);

ReactModal.setAppElement("#root");

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "80vh",
    border: "1px solid #ccc",
    background: "white",
    borderRadius: "0.3rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.2)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    opacity: 1,
  },
};

const ScreenshotsBox = ({ screenshots = [], openModal }) => (
  <StyledScreenshotsBox>
    <BoxTitle>Screenshots: ({screenshots.length})</BoxTitle>
    {screenshots && screenshots.length > 0 ? (
      screenshots.map((imageUrl, index) => (
        <ImagePreview
          key={index}
          src={imageUrl}
          alt={`Screenshot ${index}`}
          onClick={() => openModal(imageUrl, screenshots, index, "screenshots")}
        />
      ))
    ) : (
      <NoImagesText>No screenshots available.</NoImagesText>
    )}
  </StyledScreenshotsBox>
);

const WebcamSnapshotsBox = ({ snapshots = [], openModal }) => (
  <StyledWebcamSnapshotsBox>
    <BoxTitle>Webcam Snapshots: ({snapshots.length})</BoxTitle>
    {snapshots && snapshots.length > 0 ? (
      snapshots.map((imageUrl, index) => (
        <ImagePreview
          key={index}
          src={imageUrl}
          alt={`Snapshot ${index}`}
          onClick={() => openModal(imageUrl, snapshots, index, "snapshots")}
        />
      ))
    ) : (
      <NoImagesText>No webcam snapshots available.</NoImagesText>
    )}
  </StyledWebcamSnapshotsBox>
);

const CustomAccordion = styled(Accordion)`
  margin-top: 1rem;
  width: 80vw;
`;

const ProctorReport = () => {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const { interviewId } = useParams();
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [employerDetails, setEmployerDetails] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [screenshots, setScreenshots] = useState([]);
  const [snapshots, setSnapshots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageType, setImageType] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const res1 = await getInterviewDetails(interviewId, accessToken);
        if (res1) {
          console.log("Interview Details:", res1.data);
          if (res1.data.clientCode && res1.data.clientCode !== "otohire") {
            const res2 = await getEmployer( res1.data.clientCode);
            if (res2) {
              setEmployerDetails(res2.data);
            }
          }
          setInterviewDetails(res1.data);

          // Fetch image data
          await fetchImageData(res1.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getData();
  }, [interviewId, accessToken, clientCode]);

  const fetchImageData = async (interviewData) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/interviews/${interviewData.id}/proctor-events-summary`,
        config
      );

      const { screenshot, snapshot } = data.data;

      const screenshotUrls = await Promise.all(
        screenshot.map(async (item) => {
          const screenshotConfig = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              fileType: "screenshot",
              id: item.id,
            },
            responseType: "blob",
          };
          const { data: screenshotBlob } = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/media/downloadById`,
            screenshotConfig
          );
          return URL.createObjectURL(screenshotBlob);
        })
      );

      const snapshotUrls = await Promise.all(
        snapshot.map(async (item) => {
          const snapshotConfig = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              fileType: "snapshot",
              id: item.id,
            },
            responseType: "blob",
          };
          const { data: snapshotBlob } = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/media/downloadById`,
            snapshotConfig
          );
          return URL.createObjectURL(snapshotBlob);
        })
      );

      console.log("Fetched Screenshot URLs:", screenshotUrls);
      console.log("Fetched Snapshot URLs:", snapshotUrls);

      setScreenshots(screenshotUrls);
      setSnapshots(snapshotUrls);
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openModal = (imageUrl, imageArray, index, type) => {
    setSelectedImageUrl(imageUrl);
    setCurrentIndex(index);
    setIsModalOpen(true);
    setImageType(type);
  };

  const closeModal = (setIsModalOpen) => {
    setIsModalOpen(false);
    setSelectedImageUrl(null);
  };

  const handlePrevious = () => {
    if (imageType === "screenshots") {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          prevIndex === 0 ? screenshots.length - 1 : prevIndex - 1;
        setSelectedImageUrl(screenshots[newIndex]);
        return newIndex;
      });
    } else if (imageType === "snapshots") {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex === 0 ? snapshots.length - 1 : prevIndex - 1;
        setSelectedImageUrl(snapshots[newIndex]);
        return newIndex;
      });
    }
  };

  const handleNext = () => {
    if (imageType === "screenshots") {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          prevIndex === screenshots.length - 1 ? 0 : prevIndex + 1;
        setSelectedImageUrl(screenshots[newIndex]);
        return newIndex;
      });
    } else if (imageType === "snapshots") {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex === snapshots.length - 1 ? 0 : prevIndex + 1;
        setSelectedImageUrl(snapshots[newIndex]);
        return newIndex;
      });
    }
  };

  const Modal = ({
    isOpen,
    onRequestClose,
    style,
    contentLabel,
    setIsModalOpen,
  }) => (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => closeModal(setIsModalOpen)}
      style={style}
      contentLabel={contentLabel}
    >
      <CloseButton onClick={() => closeModal(setIsModalOpen)}>
        <CloseIcon>&times;</CloseIcon>
      </CloseButton>
      <ModalImageContainer>
        <PrevButton onClick={handlePrevious}></PrevButton>
        <ModalImage src={selectedImageUrl} alt="Enlarged Image" />
        <NextButton onClick={handleNext}></NextButton>
      </ModalImageContainer>
    </ReactModal>
  );

  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt="Otohire Logo" />
      </LogoContainer>
      <Head>
        <Heading>PROCTOR REPORT</Heading>
      </Head>
      <Content>
        <br />
        <span>Candidate Details</span>
        <br />
        <Line />
        <BasicDetails
          row={{
            interview: interviewDetails,
            matchedRecord: {
              name: interviewDetails?.userName,
              contact: interviewDetails?.userContact,
              email: interviewDetails?.userEmail,
            },
          }}
        />

        <CustomAccordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <span
              className={`menuTitle ${expanded === "panel1" ? "selected" : ""}`}
            >
              Interview Details
            </span>
          </AccordionSummary>
          <Line />
          <AccordionDetails className="details">
            {interviewDetails ? (
              <InterviewDetailsBox>
                {employerDetails && (
                  <CompanyTitle>
                    <CompanyLogo
                      src={employerDetails?.companyLogo}
                      alt="Company Logo"
                    />
                    {employerDetails?.companyName}
                  </CompanyTitle>
                )}

                {interviewDetails.jdId && (
                  <Detail>
                    <strong>Jd Id:</strong>&nbsp;
                    {interviewDetails.jdId.toUpperCase()}
                  </Detail>
                )}

                {interviewDetails.testType && (
                  <Detail>
                    <strong>Test Type:</strong>&nbsp;
                    {interviewDetails.testType.toUpperCase()}
                  </Detail>
                )}

                {interviewDetails.difficultyLevel && (
                  <Detail>
                    <strong>Difficulty Level:</strong>&nbsp;
                    {interviewDetails.difficultyLevel.toUpperCase() || "NA"}
                  </Detail>
                )}

                {interviewDetails.jobSummary && (
                  <Detail>
                    <strong>Job Summary:</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: interviewDetails.jobSummary,
                      }}
                    />
                  </Detail>
                )}
              </InterviewDetailsBox>
            ) : (
              <div>Loading...</div>
            )}
          </AccordionDetails>
        </CustomAccordion>
        <ImagesContainer>
          <ScreenshotsBox screenshots={screenshots} openModal={openModal} />
          <WebcamSnapshotsBox snapshots={snapshots} openModal={openModal} />
        </ImagesContainer>
      </Content>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Image Modal"
        setIsModalOpen={setIsModalOpen}
      />
    </Container>
  );
};

export default ProctorReport;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
`;

const LogoContainer = styled.div`
  height: 3.5rem;
  position: absolute;
  top: 1rem;
  left: 3rem;
`;

const Logo = styled.img`
  height: 100%;
`;

const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 3rem;
  box-sizing: border-box;
  margin-top: 4rem;
  margin-bottom: 1rem;
  position: relative;
`;

const Heading = styled.h3`
  margin: 0;
  position: absolute;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InterviewDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.9rem;
`;

const CompanyTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const CompanyLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 0rem 0;
`;

const Detail = styled.span``;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`;

const StyledScreenshotsBox = styled.div`
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
  width: 37vw;
  height: 19rem;
  overflow-y: auto;
  margin-top: 1rem;
`;

const StyledWebcamSnapshotsBox = styled.div`
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
  width: 37vw;
  height: 19rem;
  overflow-y: auto;
  margin-top: 1rem;
`;

const BoxTitle = styled.h3`
  margin-top: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 550;
`;

const NoImagesText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.75rem;
`;

const ImagePreview = styled.img`
  width: 10rem;
  height: 7rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  border: 1px solid #ccc;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled.span`
  font-size: 1.85rem;
  color: #333;
`;

const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  // z-index: 2; /* Adjusted z-index */
  background-image: url(${prevIcon});
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  z-index: 2; /* Adjusted z-index */
  background-image: url(${nextIcon});
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
`;

const ModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  background-color: white;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  border: 1px solid black;
`;
