import axios from "axios"


export const editJd = async (formData, accessToken, clientCode) => {

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-Client-Code': clientCode,
    }
  };
  // const requestData = { data: formData };

  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/jds/${formData?.id}`, formData, config);
    console.log('Data:', response.data);
    console.log("Status", response.status)
    return response.data;
  } catch (error) {
    throw error;
  }
}

