import axios from "axios"


export const updateCertification = async (certificationId, formdata, accessToken) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/profiles/${certificationId}/certification`, formdata, config);
        console.log('Data:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

