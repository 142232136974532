import axios from "axios";




export const getInterviewByStatus = async (status, accessToken, page, size, type) => {
  // const token = JSON.parse(localStorage.getItem("token"));

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },

    params: {
      page: page,
      size: size,
      status: status
    }
  };

  if (type) config.params.type = "mock";

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews`, config);
    console.log('Data:', response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}



