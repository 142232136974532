import profileImg from "../assets/resume/profileImg.png";

import metric1 from "../assets/icons/metric1.png";
import metric2 from "../assets/icons/metric2.png";
import metric3 from "../assets/icons/metric3.png";
import metric4 from "../assets/icons/metric4.png";
import metric5 from "../assets/icons/metric5.png";
import metric6 from "../assets/icons/metric6.png";
import metric7 from "../assets/icons/metric7.png";
import metric8 from "../assets/icons/metric8.png";

import EmpMetric1 from "../assets/icons/metric2.1.png";
import EmpMetric2 from "../assets/icons/metric2.2.png";
import EmpMetric3 from "../assets/icons/metric2.3.png";
import EmpMetric4 from "../assets/icons/metric2.4.png";
import EmpMetric5 from "../assets/icons/metric2.5.png";
import EmpMetric6 from "../assets/icons/metric2.6.png";
import EmpMetric7 from "../assets/icons/metric2.7.png";
import EmpMetric8 from "../assets/icons/metric2.8.png";

export const technicalSkills = [
  "account management",
  "accounting",
  "accounts payable",
  "accounts receivable",
  "analysis",
  "analytical",
  "analytical skills",
  "analytics",
  "analyze data",
  "analyzing data",
  "annual budget",
  "anchor",
  "asset management",
  "audit",
  "auditing",
  "banking",
  "billing",
  "budget management",
  "budgeting",
  "business administration",
  "business cases",
  "business issues",
  "business management",
  "business planning",
  "business plans",
  "business process",
  "business requirements",
  "business stakeholders",
  "business strategy",
  "business systems",
  "CAD",
  "cash flow",
  "CFA",
  "client relationships",
  "client service",
  "compliance",
  "consulting experience",
  "consulting services",
  "consumers",
  "contracts",
  "co-op",
  "cost effective",
  "cost reduction",
  "data analysis",
  "data collection",
  "data management",
  "data quality",
  "database",
  "deposits",
  "due diligence",
  "dynamic environment",
  "economics",
  "EMEA",
  "expenses",
  "external partners",
  "finance",
  "financial analysis",
  "financial management",
  "financial performance",
  "financial reporting",
  "financial reports",
  "financial services",
  "financial statements",
  "financing",
  "forecasting",
  "forecasts",
  "GAAP",
  "general ledger",
  "governance",
  "internal audit",
  "internal controls",
  "internal customers",
  "internal stakeholders",
  "international",
  "inventory",
  "inventory management",
  "investigate",
  "investigation",
  "investigations",
  "invoices",
  "invoicing",
  "journal entries",
  "law enforcement",
  "leadership development",
  "lean",
  "legal",
  "legislation",
  "litigation",
  "machine learning",
  "MIS",
  "mortgage",
  "non-profit",
  "partnership",
  "partnerships",
  "payments",
  "payroll",
  "physical security",
  "PMP",
  "policies",
  "portfolio management",
  "presentations",
  "process improvement",
  "project planning",
  "proposal",
  "prospecting",
  "purchase orders",
  "purchasing",
  "real estate",
  "reconcile",
  "reconciliation",
  "regulations",
  "relationship management",
  "reporting",
  "revenue growth",
  "RFP",
  "RFPs",
  "risk assessment",
  "risk assessments",
  "risk management",
  "security clearance",
  "small business",
  "spreadsheets",
  "statistical analysis",
  "statistics",
  "strategic direction",
  "strategic initiatives",
  "strategic planning",
  "strategic plans",
  "strategy",
  "Tableau",
  "tax",
  "technical knowledge",
  "transactions",
  "underwriting",
  "vendor management",
  "vendors",
  "beverage",
  "build relationships",
  "customer experience",
  "customer-facing",
  "customer requirements",
  "customer service",
  "employee relations",
  "filing",
  "help desk",
  "hospitality",
  "hotel",
  "hotels",
  "human resource",
  "human resources",
  "immigration",
  "in-store",
  "internship",
  "media relations",
  "office software",
  "on-boarding",
  "outreach",
  "PeopleSoft",
  "performance metrics",
  "phone calls",
  "retail",
  "sales",
  "sales experience",
  "sales goals",
  "sales operations",
  "Salesforce",
  "staffing",
  "supervising",
  "supervisory experience",
  "support services",
  "talent acquisition",
  "talent management",
  "travel",
  "travel arrangements",
  "administrative support",
  "admissions",
  "biology",
  "chemistry",
  "coaching",
  "computer science",
  "computer software",
  "counsel",
  "counseling",
  "fundraising",
  "German",
  "higher education",
  "mathematics",
  "physics",
  "program development",
  "public relations",
  "recruiting",
  "recruitment",
  "relationship building",
  "research projects",
  "researching",
  "spelling",
  "sports",
  "teaching",
  "training",
  "writing",
  "case management",
  "CPR",
  "FDA",
  "fitness",
  "health",
  "healthcare",
  "hospital",
  "logistics",
  "medical device",
  "nursing",
  "on-call",
  "ordering",
  "pharmaceutical",
  "pharmacy",
  "presentation",
  "psychology",
  "public health",
  "public policy",
  "safety",
  "therapeutic",
  ".NET",
  "algorithms",
  "android",
  "architecture",
  "architectures",
  "audio",
  "AutoCAD",
  "AWS",
  "big data",
  "business analysis",
  "business continuity",
  "C (programming language)",
  "C#",
  "C++",
  "certification",
  "Cisco",
  "cloud",
  "computer applications",
  "controls",
  "CSS",
  "D (programming language)",
  "data center",
  "data entry",
  "datasets",
  "design",
  "development activities",
  "digital marketing",
  "digital media",
  "distribution",
  "DNS",
  "ecommerce",
  "e-commerce",
  "end user",
  "experimental",
  "experiments",
  "frameworks",
  "front-end",
  "GIS",
  "graphic design",
  "hardware",
  "HTML5",
  "I-DEAS",
  "information management",
  "information security",
  "information technology",
  "intranet",
  "iOS",
  "iPhone",
  "IT infrastructure",
  "ITIL",
  "Java",
  "Javascript",
  "JIRA",
  "LAN",
  "licensing",
  "Linux",
  "MATLAB",
  "matrix",
  "mechanical engineering",
  "migration",
  "mobile",
  "modeling",
  "networking",
  "operations management",
  "oracle",
  "OS",
  "process development",
  "process improvements",
  "product development",
  "product knowledge",
  "program management",
  "programming",
  "protocols",
  "prototype",
  "Python",
  "quality assurance",
  "real-time",
  "research",
  "resource management",
  "root cause",
  "routing",
  "SaaS",
  "SAS",
  "SCI",
  "scripting",
  "scrum",
  "SDLC",
  "SEO",
  "service delivery",
  "software development",
  "software development life cycle",
  "software engineering",
  "SQL",
  "SQL server",
  "tablets",
  "technical issues",
  "technical support",
  "test cases",
  "test plans",
  "testing",
  "troubleshooting",
  "UI",
  "Unix",
  "usability",
  "user experience",
  "UX",
  "variances",
  "VMware",
  "web services",
  "workflows",
  "assembly",
  "automation",
  "aviation",
  "budget",
  "chemicals",
  "circuits",
  "construction",
  "cross-functional team",
  "documentation",
  "documenting",
  "drafting",
  "drawings",
  "driving record",
  "electrical",
  "electrical engineering",
  "electronics",
  "engineering",
  "fabrication",
  "installation",
  "instructional design",
  "instrumentation",
  "ISO",
  "life cycle",
  "lifecycle",
  "manage projects",
  "management experience",
  "mining",
  "operations",
  "outsourcing",
  "performance improvement",
  "procurement",
  "product line",
  "product marketing",
  "product quality",
  "project delivery",
  "project management skills",
  "project plan",
  "QA",
  "quality control",
  "quality management",
  "quality standards",
  "raw materials",
  "regulatory compliance",
  "regulatory requirements",
  "repairs",
  "six sigma",
  "SolidWorks",
  "sourcing",
  "specifications",
  "standard operating procedures",
  "supply chain",
  "transport",
  "transportation",
  "valid drivers license",
  "warehouse",
  "acquisition",
  "Adobe",
  "Adobe Creative Suite",
  "advertising",
  "affiliate",
  "agile",
];

export const allTags = [
  "account management",
  "accounting",
  "accounts payable",
  "accounts receivable",
  "analysis",
  "analytical",
  "analytical skills",
  "analytics",
  "analyze data",
  "analyzing data",
  "annual budget",
  "anchor",
  "asset management",
  "audit",
  "auditing",
  "banking",
  "billing",
  "budget management",
  "budgeting",
  "business administration",
  "business cases",
  "business issues",
  "business management",
  "business planning",
  "business plans",
  "business process",
  "business requirements",
  "business stakeholders",
  "business strategy",
  "business systems",
  "CAD",
  "cash flow",
  "CFA",
  "client relationships",
  "client service",
  "compliance",
  "consulting experience",
  "consulting services",
  "consumers",
  "contracts",
  "co-op",
  "cost effective",
  "cost reduction",
  "data analysis",
  "data collection",
  "data management",
  "data quality",
  "database",
  "deposits",
  "due diligence",
  "dynamic environment",
  "economics",
  "EMEA",
  "expenses",
  "external partners",
  "finance",
  "financial analysis",
  "financial management",
  "financial performance",
  "financial reporting",
  "financial reports",
  "financial services",
  "financial statements",
  "financing",
  "forecasting",
  "forecasts",
  "GAAP",
  "general ledger",
  "governance",
  "internal audit",
  "internal controls",
  "internal customers",
  "internal stakeholders",
  "international",
  "inventory",
  "inventory management",
  "investigate",
  "investigation",
  "investigations",
  "invoices",
  "invoicing",
  "journal entries",
  "law enforcement",
  "leadership development",
  "lean",
  "legal",
  "legislation",
  "litigation",
  "machine learning",
  "MIS",
  "mortgage",
  "non-profit",
  "partnership",
  "partnerships",
  "payments",
  "payroll",
  "physical security",
  "PMP",
  "policies",
  "portfolio management",
  "presentations",
  "process improvement",
  "project planning",
  "proposal",
  "prospecting",
  "purchase orders",
  "purchasing",
  "real estate",
  "reconcile",
  "reconciliation",
  "regulations",
  "relationship management",
  "reporting",
  "revenue growth",
  "RFP",
  "RFPs",
  "risk assessment",
  "risk assessments",
  "risk management",
  "security clearance",
  "small business",
  "spreadsheets",
  "statistical analysis",
  "statistics",
  "strategic direction",
  "strategic initiatives",
  "strategic planning",
  "strategic plans",
  "strategy",
  "Tableau",
  "tax",
  "technical knowledge",
  "transactions",
  "underwriting",
  "vendor management",
  "vendors",
  "beverage",
  "build relationships",
  "customer experience",
  "customer-facing",
  "customer requirements",
  "customer service",
  "employee relations",
  "filing",
  "help desk",
  "hospitality",
  "hotel",
  "hotels",
  "human resource",
  "human resources",
  "immigration",
  "in-store",
  "internship",
  "media relations",
  "office software",
  "on-boarding",
  "outreach",
  "PeopleSoft",
  "performance metrics",
  "phone calls",
  "retail",
  "sales",
  "sales experience",
  "sales goals",
  "sales operations",
  "Salesforce",
  "staffing",
  "supervising",
  "supervisory experience",
  "support services",
  "talent acquisition",
  "talent management",
  "travel",
  "travel arrangements",
  "administrative support",
  "admissions",
  "biology",
  "chemistry",
  "coaching",
  "computer science",
  "computer software",
  "counsel",
  "counseling",
  "fundraising",
  "German",
  "higher education",
  "mathematics",
  "physics",
  "program development",
  "public relations",
  "recruiting",
  "recruitment",
  "relationship building",
  "research projects",
  "researching",
  "spelling",
  "sports",
  "teaching",
  "training",
  "writing",
  "case management",
  "CPR",
  "FDA",
  "fitness",
  "health",
  "healthcare",
  "hospital",
  "logistics",
  "medical device",
  "nursing",
  "on-call",
  "ordering",
  "pharmaceutical",
  "pharmacy",
  "presentation",
  "psychology",
  "public health",
  "public policy",
  "safety",
  "therapeutic",
  ".NET",
  "algorithms",
  "android",
  "architecture",
  "architectures",
  "audio",
  "AutoCAD",
  "AWS",
  "big data",
  "business analysis",
  "business continuity",
  "C (programming language)",
  "C#",
  "C++",
  "certification",
  "Cisco",
  "cloud",
  "computer applications",
  "controls",
  "CSS",
  "D (programming language)",
  "data center",
  "data entry",
  "datasets",
  "design",
  "development activities",
  "digital marketing",
  "digital media",
  "distribution",
  "DNS",
  "ecommerce",
  "e-commerce",
  "end user",
  "experimental",
  "experiments",
  "frameworks",
  "front-end",
  "GIS",
  "graphic design",
  "hardware",
  "HTML5",
  "I-DEAS",
  "information management",
  "information security",
  "information technology",
  "intranet",
  "iOS",
  "iPhone",
  "IT infrastructure",
  "ITIL",
  "Java",
  "Javascript",
  "JIRA",
  "LAN",
  "licensing",
  "Linux",
  "MATLAB",
  "matrix",
  "mechanical engineering",
  "migration",
  "mobile",
  "modeling",
  "networking",
  "operations management",
  "oracle",
  "OS",
  "process development",
  "process improvements",
  "product development",
  "product knowledge",
  "program management",
  "programming",
  "protocols",
  "prototype",
  "Python",
  "quality assurance",
  "real-time",
  "research",
  "resource management",
  "root cause",
  "routing",
  "SaaS",
  "SAS",
  "SCI",
  "scripting",
  "scrum",
  "SDLC",
  "SEO",
  "service delivery",
  "software development",
  "software development life cycle",
  "software engineering",
  "SQL",
  "SQL server",
  "tablets",
  "technical issues",
  "technical support",
  "test cases",
  "test plans",
  "testing",
  "troubleshooting",
  "UI",
  "Unix",
  "usability",
  "user experience",
  "UX",
  "variances",
  "VMware",
  "web services",
  "workflows",
  "assembly",
  "automation",
  "aviation",
  "budget",
  "chemicals",
  "circuits",
  "construction",
  "cross-functional team",
  "documentation",
  "documenting",
  "drafting",
  "drawings",
  "driving record",
  "electrical",
  "electrical engineering",
  "electronics",
  "engineering",
  "fabrication",
  "installation",
  "instructional design",
  "instrumentation",
  "ISO",
  "life cycle",
  "lifecycle",
  "manage projects",
  "management experience",
  "mining",
  "operations",
  "outsourcing",
  "performance improvement",
  "procurement",
  "product line",
  "product marketing",
  "product quality",
  "project delivery",
  "project management skills",
  "project plan",
  "QA",
  "quality control",
  "quality management",
  "quality standards",
  "raw materials",
  "regulatory compliance",
  "regulatory requirements",
  "repairs",
  "six sigma",
  "SolidWorks",
  "sourcing",
  "specifications",
  "standard operating procedures",
  "supply chain",
  "transport",
  "transportation",
  "valid drivers license",
  "warehouse",
  "acquisition",
  "Adobe",
  "Adobe Creative Suite",
  "advertising",
  "affiliate",
  "agile",
];

export const locations = [
  "Delhi",
  "Mumbai",
  "Bengaluru",
  "Bengaluru/Bangalore",
  "Bangalore",
  "Chennai",
  "Kolkata",
  "Hyderabad",
  "Pune",
  "Ahmedabad",
  "Noida",
  "Gurgaon",
  "Gurugram",
  "Jaipur",
  "Lucknow",
  "Kanpur",
  "Nagpur",
  "Indore",
  "Patna",
  "Chandigarh",
  "Coimbatore",
  "Vadodara",
  "Ludhiana",
  "Agra",
  "Surat",
  "Visakhapatnam",
  "Bhopal",
  "Amritsar",
  "Raipur",
  "Ranchi",
  "Varanasi",
  "Jodhpur",
  "Udaipur",
  "Shimla",
  "Manali",
  "Mysuru/Mysore",
  "Goa",
  "Kochi",
  "Thiruvananthapuram",
  "Ooty",
];

export const jds = [
  {
    id: 1,
    jobDescription:
      "As a Software Developer, you will be responsible for designing, coding, testing, and maintaining software applications. You'll collaborate with a team of developers to create high-quality software solutions.",
  },
  {
    id: 2,
    jobDescription:
      "As a Data Analyst, you will analyze and interpret data to help organizations make informed decisions. You'll work with data sets, generate reports, and provide valuable insights to drive business success.",
  },
  {
    id: 3,
    jobDescription:
      "As a UX Designer, you'll focus on enhancing user satisfaction by improving the usability, accessibility, and overall experience of digital products. You'll create wireframes, prototypes, and user interfaces that meet user needs and business goals.",
  },
  {
    id: 4,
    jobDescription:
      "As a Product Manager, you will lead the development and management of products or services from concept to launch. You'll define product strategy, prioritize features, and collaborate with cross-functional teams to deliver successful products.",
  },
  {
    id: 5,
    jobDescription:
      "As a DevOps Engineer, you'll bridge the gap between development and operations. You'll automate and streamline deployment processes, manage infrastructure, and ensure the reliability and scalability of software applications.",
  },
  {
    id: 6,
    jobDescription:
      "As a Frontend Developer, you'll focus on creating visually appealing and user-friendly web interfaces. You'll work with HTML, CSS, and JavaScript to build responsive and interactive web applications.",
  },
  {
    id: 7,
    jobDescription:
      "As a Backend Developer, you'll handle the server-side logic and databases of web applications. You'll write server code, APIs, and database queries to ensure the backend functions efficiently.",
  },
  {
    id: 8,
    jobDescription:
      "As a Database Administrator, you'll be responsible for managing and maintaining databases. You'll optimize database performance, troubleshoot issues, and ensure data security and integrity.",
  },
  {
    id: 9,
    jobDescription:
      "As a Network Engineer, you'll design, implement, and manage computer networks. You'll ensure network connectivity, troubleshoot network issues, and implement security measures to protect data.",
  },
  {
    id: 10,
    jobDescription:
      "As a Quality Assurance Tester, you'll test software applications to identify defects and ensure they meet quality standards. You'll create test cases, perform testing, and report issues to developers.",
  },
  {
    id: 11,
    jobDescription:
      "As a Digital Marketing Specialist, you'll develop and execute digital marketing campaigns to drive online visibility and engagement. You'll use SEO, social media, and other tactics to reach target audiences.",
  },
  {
    id: 12,
    jobDescription:
      "As an AI/Machine Learning Engineer, you'll design and implement machine learning algorithms and models. You'll work on data analysis, training models, and deploying AI solutions for various applications.",
  },
];

export const data = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
  {
    id: 11,
  },
  {
    id: 12,
  },
];

export const resumeData = {
  personalInfo: {
    name: "John Doe",
    title: "Web Developer",
    address: "123 Main St, City, State, ZIP",
    email: "johndoe@email.com",
    phone: "(123) 456-7890",
    linkedin: "https://www.linkedin.com/in/johndoe",
    github: "https://github.com/johndoe",
  },
  summary:
    "A passionate and detail-oriented web developer with 5 years of experience in building responsive and user-friendly websites. Proficient in HTML, CSS, and JavaScript.",
  education: [
    {
      degree: "Bachelor of Science in Computer Science",
      university: "University Name",
      year: "08/2016 - 07/2020",
    },
    {
      degree: "High School Diploma",
      university: "High School Name",
      year: "06/2014 - 08/2016",
    },
  ],
  experience: [
    {
      title: "Frontend Developer",
      company: "Tech Company",
      location: "City, State",
      date: "06/2020 - Present",
      description: [
        "Develop and maintain the user interface of the company's web applications.",
        "Collaborate with the design and backend teams to create seamless user experiences.",
      ],
    },
    {
      title: "Intern",
      company: "Startup Inc.",
      location: "City, State",
      date: "05/2019 - 07/2019",
      description: [
        "Assisted in the development and testing of web applications.",
        "Gained experience with various web technologies and tools.",
      ],
    },
  ],
  skills: [
    { name: "HTML", score: 4 },
    { name: "CSS", score: 4 },
    { name: "JavaScript", score: 4 },
    { name: "React", score: 5 },
    { name: "Responsive Design", score: 4 },
    { name: "Version Control (Git)", score: 5 },
    { name: "UI/UX Design", score: 3 },
  ],
  certifications: [
    {
      title: "Certified Web Developer",
      organization: "Web Development Institute",
      date: "2019",
    },
    {
      title: "Frontend Fundamentals",
      organization: "Tech Training Academy",
      date: "2017",
    },
  ],
  languages: [
    { name: "English", score: 4 },
    { name: "Spanish", score: 3 },
  ],
  projects: [
    {
      title: "E-commerce Website",
      description: [
        "Designed and developed a responsive e-commerce website using HTML, CSS, and JavaScript.",
        "Implemented a secure payment gateway and product catalog.",
      ],
      date: "2022 - 2023",
    },
    {
      title: "Portfolio Website",
      description: [
        "Created a personal portfolio website to showcase my projects and skills.",
        "Developed interactive components using React.",
      ],
      date: "2021 - 2022",
    },
  ],
};

export const resumeExampleData = {
  personalDetails: {
    address: "123 Main St",
    city: "Business Park",
    country: "USA",
    email: "johndoe@email.com",
    firstName: "John",
    lastName: "Doe",
    jobTitle: "Software Developer",
    nationality: "USA",
    phone: "(123) 456-7890",
  },
  social: {
    linkedin: "https://linkedin.com/in/abhipandey1402",
    github: "https://github.com/abhipandey1402",
  },
  professionalSummary:
    "A passionate and detail-oriented web developer with 5 years of experience in building responsive and user-friendly websites. Proficient in HTML, CSS, and JavaScript.",
  education: [
    {
      degree: "Bachelor of Science in Computer Science",
      university: "University Name",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
      description:
        "<p>Skilled up in Full stack MERN and SpringBoot Backend.</p>",
      city: "New York",
    },
    {
      degree: "High School Diploma",
      university: "High School Name",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
      description:
        "<p>Skilled up in Full stack MERN and SpringBoot Backend.</p>",
      city: "New York",
    },
  ],
  employmentHistory: [
    {
      jobTitle: "Frontend Developer",
      companyName: "Tech Company",
      city: "Miami",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
      description:
        "<p>Collaborate with the design and backend teams to create seamless user experiences.</p>",
    },
    {
      jobTitle: "Intern",
      companyName: "AI Company",
      city: "Florida",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
      description:
        "<p>Collaborate with the design and backend teams to create seamless user experiences.</p>",
    },
  ],
  skills: [
    { skill: "HTML", level: "Skillful" },
    { skill: "CSS", level: "Skillful" },
    { skill: "JavaScript", level: "Skillful" },
    { skill: "React", level: "Skillful" },
    { skill: "Responsive Design", level: "Skillful" },
    { skill: "Version Control (Git)", level: "Skillful" },
    { skill: "UI/UX Design", level: "Skillful" },
  ],
  languages: [
    { language: "English", level: "Proficient" },
    { language: "Spanish", level: "Proficient" },
  ],
  projects: [
    {
      title: "E-commerce Website",
      description: "<ul><li>Software Marketplace</li></ul>",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
    },
    {
      title: "Brain-Quiz",
      description: "<ul><li>AI-Based MCQ Practice Platform</li></ul>",
      startDate: "2020-02-14",
      endDate: "2024-02-14",
    },
  ],
};

export const jobListings = [
  {
    jobId: "1",
    companyLogo:
      "https://img.freepik.com/free-psd/google-icon-isolated-3d-render-illustration_47987-9777.jpg?size=626&ext=jpg&ga=GA1.1.388057789.1697095815&semt=ais",
    companyName: "Company A",
    jobTitle: "Frontend Developer",
    location: "New York, NY",
    postedDate: "2023-09-10",
    matchPercentage: 85,
    appliedDate: "2023-09-15",
    status: "Pending",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "2",
    companyLogo: "company2_logo.png",
    companyName: "Company B",
    jobTitle: "Software Engineer",
    location: "San Francisco, CA",
    postedDate: "2023-09-10",
    matchPercentage: 92,
    appliedDate: "2023-09-10",
    status: "Reviewed",
    isFavorite: false,
    isSaved: false,
    isCheckmark: true,
    isHidden: false,
    applied: false,
  },
  {
    jobId: "3",
    companyLogo: "company3_logo.png",
    companyName: "Company C",
    jobTitle: "Data Analyst",
    location: "Los Angeles, CA",
    postedDate: "2023-06-10",
    matchPercentage: 78,
    appliedDate: "2023-09-08",
    status: "Pending",
    isFavorite: true,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: false,
  },
  {
    jobId: "4",
    companyLogo: "company4_logo.png",
    companyName: "Company D",
    jobTitle: "UX Designer",
    location: "Chicago, IL",
    postedDate: "2023-07-12",
    matchPercentage: 95,
    appliedDate: "2023-09-01",
    status: "Reviewed",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: false,
  },
  {
    jobId: "5",
    companyLogo: "company5_logo.png",
    companyName: "Company E",
    jobTitle: "Backend Developer",
    location: "Seattle, WA",
    postedDate: "2023-09-10",
    matchPercentage: 88,
    appliedDate: "2023-09-14",
    status: "Pending",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: false,
  },
  {
    jobId: "6",
    companyLogo: "company6_logo.png",
    companyName: "Company F",
    jobTitle: "Product Manager",
    location: "Austin, TX",
    postedDate: "2023-09-10",
    matchPercentage: 90,
    appliedDate: "2023-09-13",
    status: "Reviewed",
    isFavorite: true,
    isSaved: false,
    isCheckmark: true,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "7",
    companyLogo: "company7_logo.png",
    companyName: "Company G",
    jobTitle: "Graphic Designer",
    location: "Boston, MA",
    postedDate: "2023-09-10",
    matchPercentage: 75,
    appliedDate: "2023-09-11",
    status: "Pending",
    isFavorite: true,
    isSaved: false,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "8",
    companyLogo: "company8_logo.png",
    companyName: "Company H",
    jobTitle: "DevOps Engineer",
    location: "Denver, CO",
    postedDate: "2023-09-10",
    matchPercentage: 86,
    appliedDate: "2023-09-15",
    status: "Pending",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "9",
    companyLogo: "company9_logo.png",
    companyName: "Company I",
    jobTitle: "Marketing Manager",
    location: "Miami, FL",
    postedDate: "2023-08-03",
    matchPercentage: 80,
    appliedDate: "2023-09-08",
    status: "Reviewed",
    isFavorite: false,
    isSaved: false,
    isCheckmark: true,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "10",
    companyLogo: "company10_logo.png",
    companyName: "Company J",
    jobTitle: "Frontend Developer",
    location: "New York, NY",
    postedDate: "2023-09-10",
    matchPercentage: 85,
    appliedDate: "2023-09-15",
    status: "Pending",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "11",
    companyLogo: "company11_logo.png",
    companyName: "Company K",
    jobTitle: "Software Engineer",
    location: "San Francisco, CA",
    postedDate: "2023-09-10",
    matchPercentage: 92,
    appliedDate: "2023-09-10",
    status: "Reviewed",
    isFavorite: false,
    isSaved: false,
    isCheckmark: true,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "12",
    companyLogo: "company12_logo.png",
    companyName: "Company L",
    jobTitle: "Data Analyst",
    location: "Los Angeles, CA",
    postedDate: "2023-08-10",
    matchPercentage: 78,
    appliedDate: "2023-09-08",
    status: "Pending",
    isFavorite: true,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "13",
    companyLogo: "company13_logo.png",
    companyName: "Company M",
    jobTitle: "UX Designer",
    location: "Chicago, IL",
    postedDate: "2023-08-10",
    matchPercentage: 95,
    appliedDate: "2023-09-01",
    status: "Reviewed",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "14",
    companyLogo: "company14_logo.png",
    companyName: "Company N",
    jobTitle: "Backend Developer",
    location: "Seattle, WA",
    postedDate: "2023-09-10",
    matchPercentage: 88,
    appliedDate: "2023-09-14",
    status: "Pending",
    isFavorite: false,
    isSaved: true,
    isCheckmark: false,
    isHidden: false,
    applied: true,
  },
  {
    jobId: "15",
    companyLogo: "company15_logo.png",
    companyName: "Company O",
    jobTitle: "Product Manager",
    location: "Austin, TX",
    postedDate: "2023-09-10",
    matchPercentage: 90,
    appliedDate: "2023-09-13",
    status: "Reviewed",
    isFavorite: true,
    isSaved: false,
    isCheckmark: true,
    isHidden: false,
    applied: true,
  },
];

export const profileData = {
  personalInfo: {
    name: "John Doe",
    img: profileImg,
    title: "Web Developer",
    address: "123 Main St, City, State, ZIP",
    email: "johndoe@email.com",
    phone: "(123) 456-7890",
    linkedin: "https://www.linkedin.com/in/johndoe",
    github: "https://github.com/johndoe",
  },
  summary:
    "A passionate and detail-oriented web developer with 5 years of experience in building responsive and user-friendly websites. Proficient in HTML, CSS, and JavaScript.",
  education: [
    {
      degree: "Bachelor of Science in Computer Science",
      university: "University Name",
      course: "React Development",
      courseType: "Part Time",
      year: "08/2016 - 07/2020",
    },
    {
      degree: "High School Diploma",
      university: "High School Name",
      course: "React Development",
      courseType: "Full Time",
      year: "06/2014 - 08/2016",
    },
  ],
  experience: [
    {
      title: "Frontend Developer",
      company: "Tech Company",
      location: "City, State",
      date: "06/2020 - Present",
      employmentType: "Full-time",
      skillsUsed: ["react", "redux", "java-script", "html", "css", "firebase"],
      description: [
        "Develop and maintain the user interface of the company's web applications.",
        "Collaborate with the design and backend teams to create seamless user experiences.",
      ],
    },
    {
      title: "Intern",
      company: "Startup Inc.",
      location: "City, State",
      date: "05/2019 - 07/2019",
      employmentType: "Internship",
      skillsUsed: ["react", "redux", "java-script", "html", "css", "firebase"],
      description: [
        "Assisted in the development and testing of web applications.",
        "Gained experience with various web technologies and tools.",
      ],
    },
  ],
  skills: [
    { name: "HTML", score: 4 },
    { name: "CSS", score: 4 },
    { name: "JavaScript", score: 4 },
    { name: "React", score: 5 },
    { name: "Responsive Design", score: 4 },
    { name: "Version Control (Git)", score: 5 },
    { name: "UI/UX Design", score: 3 },
  ],
  certifications: [
    {
      title: "Certified Web Developer",
      organization: "Web Development Institute",
      date: "06-2019",
      expirationDate: "09-2023",
      credentialId: "Id49585h94857hrj",
      credentialUrl: "ssoc.in/johndoe/Id49585h94857hrj",
    },
    {
      title: "Frontend Fundamentals",
      organization: "Tech Training Academy",
      date: "06-2019",
      expirationDate: "09-2023",
      credentialId: "Id49585h94857hrj",
      credentialUrl: "ssoc.in/johndoe/Id49585h94857hrj",
    },
  ],
  languages: [
    { name: "English", score: 4 },
    { name: "Spanish", score: 3 },
  ],
  projects: [
    {
      title: "E-commerce Website",
      description: [
        "Designed and developed a responsive e-commerce website using HTML, CSS, and JavaScript.",
        "Implemented a secure payment gateway and product catalog.",
      ],
      date: "2022 - 2023",
      status: "In progress",
    },
    {
      title: "Portfolio Website",
      description: [
        "Created a personal portfolio website to showcase my projects and skills.",
        "Developed interactive components using React.",
      ],
      date: "2021 - 2022",
      status: "Completed",
    },
  ],
};

export const seekerMetric1 = {
  title: "Interview Scheduled",
  text: "interviewScheduled",
  img: metric1,
};

export const seekerMetric2 = {
  title: "Interviews Completed",
  text: "interviewCompleted",
  img: metric2,
};

export const seekerMetric3 = {
  title: "Interview Invites",
  text: "interviewInvites",
  img: metric3,
};

export const seekerMetric4 = {
  title: "Applied Jobs",
  text: "appliedJobs",
  img: metric4,
};

export const seekerMetric5 = {
  title: "Mock Interviews",
  text: "mockInterviews",
  img: EmpMetric1,
};

export const seekerMetricOptions = [
  {
    title: "Interviews Upcoming (next 7 days)",
    text: "interviewsUpcoming",
    img: metric5,
  },
  {
    title: "Interviews in Progress",
    text: "interviewsInProgress",
    img: metric6,
  },
  {
    title: "Profile Views",
    text: "profileViews",
    img: metric7,
  },
  {
    title: "My Profile",
    text: "myProfile",
    img: metric8,
  },
];

export const EmpMetrics1 = {
  id: 1,
  title: "Interviews",
  text: "interviews",
  img: EmpMetric1,
};

export const EmpMetrics2 = {
  id: 2,
  title: "Active JDs",
  text: "activeJDs",
  img: EmpMetric2,
};

export const EmpMetrics3 = {
  id: 3,
  title: "Applications",
  text: "applications",
  img: EmpMetric3,
};

export const EmpMetrics4 = {
  id: 4,
  title: "Candidates Pool",
  text: "candidatesPool",
  img: EmpMetric4,
};

export const EmpMetricOptions = [
  {
    id: 5,
    title: "Invite Sent (Last 30 Days)",
    text: "inviteSent",
    img: EmpMetric5,
  },
  {
    id: 6,
    title: "Reviewed Profiles (Last 30 Days)",
    text: "reviewedProfile",
    img: EmpMetric6,
  },
  {
    id: 7,
    title: "JD Views (Last 30 Days)",
    text: "jdViews",
    img: EmpMetric7,
  },
  {
    id: 8,
    title: "Offers (Last 30 Days)",
    text: "offers",
    img: EmpMetric8,
  },
];
