import React from "react";
import styled from "styled-components";
import img1 from "../../assets/careercognizanc.jpeg";
import img2 from "../../assets/miledge_technologies.jpeg";
import linkedin from "../../assets/HomePageReviews/linkedin.png";

const reviews = [
  {
    profileImg: img1,
    name: " Career Cognizance",
    // title: "Partner",
    // text: "Career Cognizance, where mission is to empower individuals in their journey to fulfilling careers and lives. Career Cognizance offer comprehensive and personalized consulting, training, and coaching services tailored to inspire growth, self-discovery, and the development of essential skills necessary to navigate the ever-evolving world of career and life. With a commitment to excellence and expertise, Career Cognizance work with a diverse range of clients including corporates, businesses, colleges, students, entrepreneurs, and individuals, ensuring that each receives the specialized support they need to succeed",
    linkedinUrl: "https://www.linkedin.com/company/careercognizance/",
  },
  {
    profileImg: img2,
    name: "Miledge Technologies",
    // title: "Partner",
    // text: "It services and IT Consulting.",
    linkedinUrl: "https://www.linkedin.com/company/miledgetechnologies/",
  },
];

const BusinessAssociatesSection = () => {
  const openLinkedInProfile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <div className="floatingBox">
        {reviews.map((review, i) => (
          <div className="card" key={i}>
            <img className="img" src={review.profileImg} />
            <span className="name">
              {review.name}{" "}
              {review.linkedinUrl && (
                <img
                  src={linkedin}
                  className="linkedinIcon"
                  onClick={() => openLinkedInProfile(review.linkedinUrl)}
                />
              )}
            </span>
            <span className="title">{review.title}</span>
            <span className="text">{review.text}</span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default BusinessAssociatesSection;

const Box = styled.div`
  width: 75%;
  margin: 5rem auto 4rem auto;
  display: flex;
  background: linear-gradient(to bottom, #f0f0f0, #89f3eb);
  border-radius: 0.75rem;
  position: relative;
  height: 8.5rem;

  .floatingBox {
    width: 100%;
    height: 20rem;
    display: flex;
    // gap: 1%;
    justify-content: space-evenly;
    position: absolute;
    top: -1.5rem;
  }

  .card {
    width: 35%;
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 0.1rem 0.5rem 0.1rem rgba(0, 0, 0, 0.25);
    padding: 1rem 1%;
    border-radius: 1rem;
    background-color: var(--white);
    color: var(--color);

    .img {
      margin-top: 0rem;
      width: 4.5rem;
      border-radius: 2rem;
    }
    .img {
      margin-top: 0rem;
      width: 4.5rem;
      border-radius: 2rem;
    }

    .name {
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .linkedinIcon {
        width: 1.7rem;
        height: 1.7rem;
        cursor: pointer;
      }
    }

    .title {
      font-size: 0.85rem;
      font-weight: 600;
      line-height: 1rem;
      text-align: center;
    }

    .text {
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1rem;
      text-align: center;
      color: var(--color);
    }
  }
`;
