import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearError, updateInterview } from "../slices/interviewSlice";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
const style = {
  backgroundColor: "var(--backgroundColor)",
  color: "var(--cyan)",
  position: "fixed",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1.4rem",
  justifyContent: "center",
  alignItems: "center",
  left: 0,
  top: 0,
};

function CreateInterview() {
  const { interviewState } = useParams();
  console.log(localStorage.getItem("currentInterview"));
  console.log("interViewState", interviewState)
  const stringsArray =
    localStorage.getItem("currentInterview") == "profile"
      ? [
          "Analyzing JD",
          "Analyzing Resume",
          "Creating Questions",
          "Creating Interview",
          "Preparing Interview",
        ]
      : [
          "Analyzing skills",
          "Creating Questions",
          "Creating Interview",
          "Preparing Interview",
        ];

  const [currentString, setCurrentString] = useState(stringsArray[0]);
  const { interviewId } = useParams();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const interviewData = useSelector((state) => state?.interview?.interviewData);
  const interviewErrorData = useSelector((state) => state?.interview?.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let currentIndex = 1;
    console.log("ran");
    const intervalId = setInterval(() => {
      setCurrentString(stringsArray[currentIndex]);
      currentIndex++;

      if (currentIndex === stringsArray.length) {
        clearInterval(intervalId);
        setCurrentString(stringsArray[stringsArray.length - 1]);
      }
    }, 10000);

    dispatch(
      updateInterview({ id: interviewId, status: "started", accessToken })
    );

    return () => clearInterval(intervalId);
  }, []); //

  
  useEffect(() => {
    if (
      interviewData?.status == "STARTED" &&
      interviewData?.id == interviewId
    ) {
      navigate(`/ongoing-interview/${interviewId}/${interviewState}`);
    } else if (interviewErrorData) {
      toast.warn(interviewErrorData);
      navigate("/dashboard/jobseeker");
    }
  }, [interviewData]);
  return (
    <Box sx={style}>
      <CircularProgress color="inherit" />
      <span style={{ color: "var(--color)" }}>{currentString}</span>
    </Box>
  );
}

export default CreateInterview;
