import axios from "axios";



export const getPreFilteredCount = async (accessToken, status) => {

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/interviews/preFilteredCount?preFilter=${status}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}


