import axios from "axios"


export const getJobApplicationsByJdId = async (jdId, accessToken, clientCode, page = 1, size = 1000000,agencyCode) => {

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-client-code': agencyCode?agencyCode:clientCode
        }
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jds/${jdId}/jobApplications?clientCode=${clientCode}&page=${page}&size=${size}`, config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
    } catch (error) {
        throw error;
    }
}



