import React from 'react'
import styled from 'styled-components'

const FeedbackPage = () => {
    return (
        <Box>FeedbackPage</Box>
    )
}

export default FeedbackPage

const Box = styled.div`
display: flex;
height: 100vh;
width: 100vw;
align-items: center;
justify-content: center;
font-size: 1rem;
font-weight: 600;


`