import React from 'react';
import styled from 'styled-components';

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
`;

const FeedbackMessage = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
`;

const FeedbackDetails = styled.p`
  font-size: 1rem;
  color: #555;
  max-width: 600px;
  text-align: center;
  line-height: 1.5;
`;

const ThankYouComponent = () => {
  return (
    <FeedbackContainer>
      <FeedbackMessage>Thank You for Your Feedback</FeedbackMessage>
      <FeedbackDetails>
        We appreciate the time you took to provide us with your valuable feedback. Our team will review your comments and suggestions to improve our services continuously.
      </FeedbackDetails>
    </FeedbackContainer>
  );
};

export default ThankYouComponent;
