import axios from "axios";

export const getAllAlerts = async (
  accessToken,
  clientCode
  ) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "X-Client-Code": clientCode,
    },
  };


  try {
    const response =
      await fetch(`${process.env.REACT_APP_API_URL}/api/alerts
        `,config,);


    const data = await response.text();
    const parsedData = JSON.parse(data, (key, value) => {
      if (key === 'id') {
          return BigInt(value);
      }
      return value;
  });
    console.log(parsedData);
    return parsedData;
  } catch (error) {
    throw error;
  }
};
