import axios from "axios"


export const sendInvite = async (inviteData, accessToken,clientCode) => {

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'X-Client-Code': clientCode,
  }
};
  const requestData = {...inviteData};
  console.log("sendinvite",requestData)
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/interviews/invites`,requestData,config);
        console.log('Data:', response.data);
        console.log("Status", response.status)
        return response.data;
      } catch (error) {
        throw error;
      }
}

