import React from 'react'
import styled from 'styled-components'
import ProfileNew from './ProfileNew'
import ProfileResumes from '../seekerCommonComponents/ProfileResumes'

const ProfileWrapper = () => {
    return (
        <Box>
            <ProfileNew />
            <ProfileResumes />
        </Box>
    )
}

export default ProfileWrapper


const Box = styled.div`
display: flex;
flex-direction: column;
width: 100%;
padding-bottom: 2rem;
align-items: center;
gap: 1rem;
justify-content: space-between;

`