import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Autocomplete, Paper, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { createQuestion } from "../../../functions/api/employers/employerTest/createQuestion";
import { useSelector } from "react-redux";
import { updateQuestion } from "../../../functions/api/employers/employerTest/updateQuestion";
import { Stack } from "@mui/system";
import { allTags } from "../../../utils/contantData";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
  }

  @media (max-width: 2000px) {
    #outlined-basic {
      padding: 0.75rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1700px) {
    #outlined-basic {
      padding: 0.85rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1350px) {
    #outlined-basic {
      padding: 0.95rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  @media (max-width: 1200px) {
    #outlined-basic {
      padding: 1rem 0.5rem;
      background-color: #f6f6fb;
    }
  }

  .textAreaBox {
    width: 100%;
    position: relative;
    margin-top: 0.5rem;

    label {
      font-size: 0.7rem;
      font-weight: 600;
      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      padding: 0 0.5rem;
      background-color: var(--white);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 0.7rem;

  .inputBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .inputBoxMap {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    flex-flow: row wrap;
  }

  .box {
    width: calc(50% - 1rem);
  }
`;

const Label = styled.label`
  font-size: 0.8rem;
  margin-bottom: 8px;
  font-weight: 600;
`;

const Textarea = styled.textarea`
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline-color: #1976d2;
  background-color: #f6f6fb;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
`;

const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--cyan);
  color: var(--white);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  display: flex;
  align-self: center;
  margin: 1rem auto;
`;

function CreateQuestionForm({
  editingIndex,
  setEditingIndex,
  handleClose,
  editingQuestionId,
  editingQuestionData,
  setQuestionTrigger,
}) {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  const [formData, setFormData] = useState({
    questionType: "",
    difficultyLevel: "",
    tags: "",
    questionText: "",
    mcqOptions: ["", "", "", ""],
    mcqAnswer: "",
    category: "",
  });

  const [selectedTags, setSelectedTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");

  useEffect(() => {
    setFormData({
      ...formData,
      tags: selectedTags?.join(", "),
    });
  }, [selectedTags]);

  useEffect(() => {
    if (editingIndex != -1) {
      setFormData({
        questionType: editingQuestionData?.questionType,
        questionText: editingQuestionData?.questionText,
        difficultyLevel: editingQuestionData?.difficultyLevel,
        category: editingQuestionData?.category,
        mcqOptions: editingQuestionData?.mcqOptions || ["", "", "", ""],
        mcqAnswer: editingQuestionData?.mcqAnswer || "",
      });
      {
        editingQuestionData?.tags &&
          setSelectedTags(editingQuestionData?.tags?.split(", "));
      }
    }
  }, [editingIndex, editingQuestionData, editingQuestionId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChoiceChange = (e, index) => {
    const newChoices = [...formData?.mcqOptions];
    newChoices[index] = e.target.value;
    setFormData({
      ...formData,
      mcqOptions: newChoices,
    });
  };

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    try {
      const res = await createQuestion(formData, accessToken, clientCode);

      if (res) {
        toast.success("Question added successfully", 5000);
        setFormData({
          questionType: "",
          difficultyLevel: "",
          tags: "",
          questionText: "",
          category: "",
        });
        setQuestionTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg);
    }
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    try {
      const res = await updateQuestion(
        formData,
        editingQuestionId,
        accessToken,
        clientCode
      );

      if (res) {
        toast.success("Question updated successfully", 5000);
        setFormData({
          questionType: "",
          difficultyLevel: "",
          tags: "",
          questionText: "",
          category: "",
        });
        setEditingIndex(-1);
        setQuestionTrigger((prev) => !prev);
        handleClose();
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg);
    }
  };

  const handleTagsChange = (_, newTags) => {
    setSelectedTags(newTags);
  };

  const handleTagInputBlur = () => {
    if (tagInputValue.trim() !== "") {
      setSelectedTags([...selectedTags, tagInputValue?.trim()]);
      setTagInputValue("");
    }
  };

  return (
    <Container>
      <span className="title">
        {editingIndex === -1 ? "Create" : "Update"} Question Form
      </span>
      <Form
        onSubmit={
          editingIndex === -1 ? handleAddQuestion : handleUpdateQuestion
        }
      >
        <div className="inputBox">
          <FormControl sx={{ backgroundColor: "#F6F6FB" }} required fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Question Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Question Type"
              name="questionType"
              value={formData?.questionType}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  fontFamily: "Quicksand, sans-serif",
                },
              }}
              sx={{
                padding: "0.5rem 0 0.5rem 0",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              <MenuItem value="general">Subjective</MenuItem>
              <MenuItem value="mcq">MCQs</MenuItem>
              <MenuItem value="coding">Coding</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ backgroundColor: "#F6F6FB" }} required fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{
                color: "#626264",
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              Difficulty Level
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Difficulty Level"
              name="difficultyLevel"
              value={formData?.difficultyLevel}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                  color: "#626264",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "400",
                },
              }}
              sx={{
                padding: "0.5rem 0 0.5rem 0",
              }}
            >
              <MenuItem value="easy">Easy</MenuItem>
              <MenuItem value="moderate">Moderate</MenuItem>
              <MenuItem value="difficult">Difficult</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="inputBox">
          <Stack spacing={3} sx={{ width: "100%" }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={allTags}
              getOptionLabel={(option) => option}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              onChange={handleTagsChange}
              value={selectedTags}
              selectOnFocus
              clearOnBlur
              freeSolo
              fullWidth
              onInputChange={(event, newInputValue) => {
                setTagInputValue(newInputValue);
              }}
              onBlur={handleTagInputBlur}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    backgroundColor: "#F0F0F6",
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                    color: "#626264",
                  }}
                />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags*"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F6F6FB",
                    "& .MuiInputBase-root": {
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                      color: "#626264",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      fontFamily: "Quicksand, sans-serif",
                      color: "#626264",
                    },
                  }}
                />
              )}
            />
          </Stack>

          <FormControl
            sx={{
              backgroundColor: "#F6F6FB",
              fontSize: "0.9rem",
              fontWeight: "600",
              fontFamily: "Quicksand, sans-serif",
              color: "#626264",
            }}
            required
            fullWidth
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Quicksand, sans-serif",
                color: "#626264",
              }}
            >
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Category"
              name="category"
              value={formData?.category}
              onChange={handleChange}
              size="small"
              inputProps={{
                sx: {
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  fontFamily: "Quicksand, sans-serif",
                  color: "#626264",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "#626264",
                  fontSize: "0.9rem",
                  fontWeight: "400",
                },
              }}
              sx={{
                padding: "0.5rem 0 0.5rem 0",
              }}
            >
              <MenuItem value="technical">Technical</MenuItem>
              <MenuItem value="non-technical">Non-technical</MenuItem>
              <MenuItem value="aptitude">Aptitude</MenuItem>
              <MenuItem value="cultural">Cultural</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="textAreaBox">
          <label>Que Description</label>
          <Textarea
            name="questionText"
            rows={5}
            value={formData?.questionText}
            onChange={handleChange}
            inputProps={{
              sx: {
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
                color: "#626264",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
                color: "#626264",
              },
            }}
            required
          />
        </div>

        {formData?.questionType === "mcq" && (
          <>
            <Label>Choices</Label>
            <div className="inputBoxMap">
              {formData?.mcqOptions?.map((choice, index) => (
                <div key={index} className="box">
                  <TextField
                    id="outlined-basic"
                    label={index + 1}
                    variant="outlined"
                    type="text"
                    value={choice}
                    onChange={(e) => handleChoiceChange(e, index)}
                    inputProps={{
                      sx: {
                        fontSize: "0.9rem",
                        fontWeight: "500",
                        fontFamily: "Quicksand, sans-serif",
                        color: "#626264",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "0.9rem",
                        fontWeight: "500",
                        fontFamily: "Quicksand, sans-serif",
                        color: "#626264",
                      },
                    }}
                    required
                    fullWidth
                  />
                </div>
              ))}
            </div>

            <div
              style={{
                width: "calc(50% - 1rem)",
                fontSize: "0.9rem",
                fontWeight: "600",
                fontFamily: "Quicksand, sans-serif",
                color: "#626264",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Correct Answer"
                variant="outlined"
                type="text"
                name="mcqAnswer"
                value={formData?.mcqAnswer}
                onChange={handleChange}
                inputProps={{
                  sx: {
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "Quicksand, sans-serif",
                    color: "#626264",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    fontFamily: "Quicksand, sans-serif",
                    color: "#626264",
                  },
                }}
                required
                fullWidth
              />
            </div>
          </>
        )}

        <Button type="submit">
          {editingIndex === -1 ? "Add Question" : "Update Question"}
        </Button>
      </Form>
    </Container>
  );
}

export default CreateQuestionForm;
