import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { toast } from "react-toastify";
import { getTests } from "../../../../functions/api/employers/employerTest/getTests";
import { useSelector } from "react-redux";
import { dateConversion } from "../../../../utils/timeZoneConversation";

export default function AvailableTestList({ handleAttachTest, closeModal }) {
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );

  const [tests, setTests] = useState(null);

  useEffect(() => {
    const getAllTests = async () => {
      try {
        const res = await getTests(accessToken, clientCode);
        if (res) {
          console.log(res);
          setTests(res);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg);
      }
    };
    getAllTests();
  }, []);

  return (
    <Content>
      <span className="modalTitle">Available Tests to Select</span>
      <TableContainer component={Paper} className="tableBox">
        <Table aria-label="available tests table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="left" className="tableCell">
                Name
              </TableCell>
              <TableCell align="left" className="tableCell">
                Test Type
              </TableCell>
              <TableCell align="left" className="tableCell">
                Role
              </TableCell>
              <TableCell align="left" className="tableCell">
                Tags
              </TableCell>
              <TableCell align="left" className="tableCell">
                Date of Creation
              </TableCell>
              <TableCell align="center" className="tableCell">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {tests &&
              tests.map((row, index) => (
                <TableRow key={row?.id} className="tableRow">
                  <TableCell align="left" className="tableCell">
                    {row?.name}
                  </TableCell>
                  <TableCell align="left" className="tableCell">
                    {row?.testType}
                  </TableCell>
                  <TableCell align="left" className="tableCell">
                    {row?.role}
                  </TableCell>
                  <TableCell align="left" className="tableCell tagBox">
                    {row?.tags?.length !== 0 &&
                      row?.tags.split(", ").map((tag, i) => (
                        <div key={i} className="tag">
                          {tag}
                        </div>
                      ))}
                  </TableCell>
                  <TableCell align="left" className="tableCell">
                    {row?.createdAt && dateConversion(row?.createdAt)}
                  </TableCell>
                  <TableCell align="center" className="tableCell">
                    <button
                      className="selectButton"
                      onClick={() => handleAttachTest(row)}
                    >
                      Select Test
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
}

const Content = styled.div`
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1rem;

  .modalTitle {
    font-family: Quicksand, sans-serif;
    font-size: 1rem;
    font-weight: 550;
    margin-bottom: 1rem;
    display: block;
  }

  .tableBox {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .tableHead {
    background-color: #d1fff0;

    .tableCell {
      font-size: 0.9rem;
      font-family: Quicksand, sans-serif;
      font-weight: 500;
      color: #333;
      padding: 16px;
      border-bottom: none;
    }
  }

  .tableBody {
    .tableRow {
      &:nth-of-type(odd) {
        background-color: #ececec;
      }
    }

    .tableCell {
      font-size: 0.8rem;
      font-family: Quicksand, sans-serif;
      font-weight: 400;
      color: #333;
      padding: 16px;
      border-bottom: none;
    }

    .tagBox {
      display: flex;
      flex-flow: row wrap;
      gap: 0.25rem;
    }

    .tag {
      font-family: Quicksand, sans-serif;
      background-color: var(--cyan);
      color: var(--white);
      font-weight: 500;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
      font-size: 0.7rem;
    }
  }

  .selectButton {
    font-family: Quicksand, sans-serif;
    background-color: white;
    color: black;
    border: 1.5px solid cyan;
    padding: 0.3rem 0.6rem;
    border-radius: 0.3rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
