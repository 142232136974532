import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
// import fillFeedbackIcon from '../../assets/icons/fillFeedbackIcon.png'
import visibleIcon from '../../../../assets/icons/visible.png'
import editIcon from '../../../../assets/icons/edit.png'
import deleteIcon from '../../../../assets/icons/delete.png'
import viewIcon from '../../../../assets/icons/visible.png'
import { useSelector } from "react-redux";
import { getAllCvs } from "../../../../functions/api/cv/getAllCvs";
import DeleteDialogContent from "../../../commonComponents/DeleteDialogContent";
import CommonDialog from "../../../commonComponents/CommonDialog";
import { deleteCv } from "../../../../functions/api/cv/deleteCv";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCvData, setMode, setResumeId, setTemplateId, setViewOnly } from "../../../../slices/cvBuilderSlice";
import { useNavigate } from "react-router";
import { dateConversion, timeZoneConversion } from "../../../../utils/timeZoneConversation";


function Row(props) {
  const { row, index, setTrigger } = props;
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector((state) => state.auth.userData?.user?.clientCode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setData(JSON.parse(row.cvData));
  }, [])

  console.log(data);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteCv(id, accessToken, clientCode);
      toast.success('CV deleted successfully', 5000);
      setTrigger(prev => !prev);

      // if (res) {
      //   toast.success('CV deleted successfully', 5000);
      //   console.log(res);
      //   setTrigger(prev => !prev);
      // }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    } finally {
      setOpen(false);
    }
  };

  const handleViewCv = async (resumeId, templateId, cvData) => {
    dispatch(setResumeId(resumeId));
    dispatch(setTemplateId(templateId));
    dispatch(setMode('edit'));
    dispatch(setCvData(cvData));
    navigate(`/create-resume/${templateId}`)
    dispatch(setViewOnly(true))
    
  }

  const handleEditCv = async (resumeId, templateId, cvData) => {
    dispatch(setResumeId(resumeId));
    dispatch(setTemplateId(templateId));
    dispatch(setMode('edit'));
    dispatch(setCvData(cvData));
    navigate(`/create-resume/${templateId}`)
    dispatch(setViewOnly(false))
  }


  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }} className={`${index % 2 == 1 ? 'colored' : ''}`}>
        <TableCell align="center" className="tableCell">{data?.personalDetails?.jobTitle}</TableCell>
        <TableCell align="center" className="tableCell">{row?.templateName?.toUpperCase()}</TableCell>
        <TableCell align="center" className="tableCell">{row?.createdAt && timeZoneConversion(row?.createdAt)}</TableCell>
        <TableCell align="center" className="tableCell">{row?.updatedAt && timeZoneConversion(row?.updatedAt)}</TableCell>
        <TableCell align="center" className="tableCell">
          <CommonDialog
            open={open}
            handleClose={() => setOpen(false)}
            component={
              <DeleteDialogContent
                text="CV"
                handleClose={() => setOpen(false)}
                handleDelete={() => handleDelete(row?.id)}
              />
            }
          />
          <div
            style={{
              display: "flex",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={editIcon}
              className="icon"
              onClick={() => handleEditCv(row?.id, row?.templateName, row?.cvData)}
            />
            <img
              src={deleteIcon}
              className="icon"
              onClick={() => setOpen(true)}
            />
            <img
              src={viewIcon}
              className="icon"
              onClick={() => handleViewCv(row?.id, row?.templateName, row?.cvData)}
            />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CvTable = () => {
  const [tableRows, setTableRows] = useState([]);

  const accessToken = useSelector(state => state.auth.userData?.accessToken);
  const clientCode = useSelector(state => state.auth.userData?.user?.clientCode);

  const [trigger, setTrigger] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getAllCvs(accessToken, clientCode);
        if (res) {
          setTableRows(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    getData();
  }, [trigger]);

  const handleSearch = () => {
    console.log("Search");
  }


  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <span className="title">Resumes</span>
        </div>
        <Table aria-label="collapsible table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="center" className="tableCell">Title</TableCell>
              <TableCell align="center" className="tableCell">Template Name</TableCell>
              <TableCell align="center" className="tableCell">Created at</TableCell>
              <TableCell align="center" className="tableCell">Updated at</TableCell>
              <TableCell align="center" className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {tableRows?.map((row, index) => (
              <Row key={row?.id} row={row} index={index} setTrigger={setTrigger} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  )
}

export default CvTable;




const Content = styled.div`
margin: 2rem auto;
width: 95%;
padding: 0 1%;
display: flex;
flex-direction: column;
align-items: center;
font-family: var(--font);
color: var(--color);

.icon {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  border: 0.075rem solid grey;
  padding: 0.3rem;
  border-radius: 0.3rem;
}


.colored {
  background-color: #ececec;
}

.tableBox {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.20);
  border-radius: 0.5rem;
  padding-top: 1rem;

  .title {
    padding-left: 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .titleBox {
  width: 99%; 
  padding: 0.5rem 0rem;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }


}

.MuiTableCell-root {
  border: none;
}

.MuiTableRow-root {
  border-bottom: none;
}

.selected {
  background-color: #d9fbf9;
  color: white;
}

.tableHead {
  background-color: #d1fff0;
  width: 100%;

  .tableCell {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: var(--font);
    color: var(--color);
  }
  
}

.tableBody {
  width: 100%;

  .tableCell {
    font-size: 0.8rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--color);
  }
}




`


